import * as React from 'react'

export default function FlipFlopLogo(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="112" height="32" viewBox="0 0 112 32">
      <defs>
        <filter id="jhqzm6hgha">
          <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
        </filter>
        <path
          id="m4wwdhwl7b"
          d="M4.712 24.216c-1.152 0-2.064-.96-2.064-2.088V10.176c0-1.416.768-2.184 2.184-2.184h8.016c.936 0 1.728.792 1.728 1.752 0 .936-.792 1.728-1.728 1.728H6.776v2.664h5.904c.936 0 1.728.792 1.728 1.752s-.792 1.752-1.728 1.752H6.776v4.488c0 1.128-.912 2.088-2.064 2.088zM18.8 24c-1.416 0-2.184-.768-2.184-2.184V9.864c0-1.128.912-2.088 2.064-2.088 1.152 0 2.064.96 2.064 2.088V20.4h4.824c.984 0 1.8.792 1.8 1.8s-.816 1.8-1.8 1.8H18.8zm12.576.216c-1.152 0-2.064-.96-2.064-2.088V9.864c0-1.128.912-2.088 2.064-2.088 1.152 0 2.064.96 2.064 2.088v12.264c0 1.128-.912 2.088-2.064 2.088zm7.008 0c-1.152 0-2.064-.96-2.064-2.088V10.176c0-1.416.768-2.184 2.184-2.184h5.832c3.6 0 5.568 2.424 5.568 5.328 0 2.88-1.968 5.256-5.568 5.256h-3.888v3.552c0 1.128-.912 2.088-2.064 2.088zm7.32-10.92c0-1.104-.816-1.824-1.92-1.824h-3.336v3.6h3.336c1.104 0 1.92-.696 1.92-1.776zm8.112 10.92c-1.152 0-2.064-.96-2.064-2.088V10.176c0-1.416.768-2.184 2.184-2.184h8.016c.936 0 1.728.792 1.728 1.752 0 .936-.792 1.728-1.728 1.728H55.88v2.664h5.904c.936 0 1.728.792 1.728 1.752s-.792 1.752-1.728 1.752H55.88v4.488c0 1.128-.912 2.088-2.064 2.088zM67.904 24c-1.416 0-2.184-.768-2.184-2.184V9.864c0-1.128.912-2.088 2.064-2.088 1.152 0 2.064.96 2.064 2.088V20.4h4.824c.984 0 1.8.792 1.8 1.8s-.816 1.8-1.8 1.8h-6.768zm9.696-7.992c0-4.872 3.672-8.28 8.568-8.28 4.896 0 8.544 3.408 8.544 8.28s-3.648 8.28-8.544 8.28c-4.896 0-8.568-3.408-8.568-8.28zm12.912 0c0-2.616-1.704-4.632-4.344-4.632-2.664 0-4.368 2.016-4.368 4.632 0 2.592 1.704 4.632 4.368 4.632 2.64 0 4.344-2.04 4.344-4.632zm8.352 8.208c-1.152 0-2.064-.96-2.064-2.088V10.176c0-1.416.768-2.184 2.184-2.184h5.832c3.6 0 5.568 2.424 5.568 5.328 0 2.88-1.968 5.256-5.568 5.256h-3.888v3.552c0 1.128-.912 2.088-2.064 2.088zm7.32-10.92c0-1.104-.816-1.824-1.92-1.824h-3.336v3.6h3.336c1.104 0 1.92-.696 1.92-1.776z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FFF" d="M0 0H1920V2348H0z" transform="translate(-390 -9)" />
          <g>
            <path fill="#FFC01C" fillRule="nonzero" d="M0 0L1920 0 1920 50 0 50z" transform="translate(-390 -9)" />
            <g filter="url(#jhqzm6hgha)" transform="translate(-390 -9)">
              <g transform="translate(390 9)">
                <mask id="ziwie55owc" fill="#fff">
                  <use xlinkHref="#m4wwdhwl7b" />
                </mask>
                <g fill="#333" mask="url(#ziwie55owc)">
                  <path d="M0 0H112V32H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
