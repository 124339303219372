import Bowser from 'bowser';

interface Device {
  device_name: string
  device_os: string
  device_os_version: string
}

export const getAgent = (): Device => {
  const parsedAgent = (Bowser.getParser(navigator.userAgent || navigator.vendor || (window as any).opera) as any)?.parsedResult
  
  return {
    device_name: parsedAgent.browser.name + " " + parsedAgent.browser.version,
    device_os: parsedAgent.os.name,
    device_os_version: parsedAgent.os.version
  }
}

export const isIos = (): boolean => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function isWebRTCSupported(): boolean {
  const newVariable: any = window.navigator;

  return newVariable.getUserMedia ||
      newVariable.webkitGetUserMedia ||
      newVariable.mozGetUserMedia ||
      newVariable.msGetUserMedia ||
      window.RTCPeerConnection;
}


export const openNewPage = (path: string): void => {
  window.open(`${path}`, '_blank');
}