import * as React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '../components/button/Button';
import TextArea from '../components/input/TextArea';
import TextInput from '../components/input/TextInput';
import SwitchInput from '../components/input/SwitchInput'
import { MAX_LENGTH } from '../config/Config';
import { StoreState } from '../modules';
import { actions as streamingActions } from '../modules/streaming'

interface Props {
  toggleModal(): void
  actions: typeof streamingActions
  title: string
  description: string
  keepAlive: boolean
}

interface State {
  title: string
  description: string
  keepAlive: boolean
}

class LiveSettingsModalContent extends React.Component<Props, State> {
  state: State = {
    title: this.props.title,
    description: this.props.description,
    keepAlive: this.props.keepAlive
  }

  onChangeInput = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  onChangeKeepAlive = (keepAlive: boolean): void => {
    this.setState({ keepAlive })
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    // ADD: change live title, desc api
    this.props.toggleModal();
    this.props.actions.setTitle(this.state.title);
    this.props.actions.setDescription(this.state.description);
    this.props.actions.setKeepAlive(this.state.keepAlive)
  }
  
  public render(): JSX.Element {
    return (
      <>
        <form className="live-settings-modal-form" onSubmit={this.handleSubmit}>
          <TextInput type="text" id="title" name="title" value={this.state.title} onChange={this.onChangeInput} label="Title" maxLength={MAX_LENGTH.videoTitle} />
          <TextArea type="text" id="description" name="description" label="Description" value={this.state.description} onChange={this.onChangeInput} rows={3} maxLength={MAX_LENGTH.videoDesc} />
          <SwitchInput label="Keep alive" current={this.state.keepAlive} onChange={this.onChangeKeepAlive} />
          <div className="btn-group">
            <Button type="button" size="large" color="white" onClick={this.props.toggleModal}>
              Cancel
            </Button>
            <Button type="submit" size="large" color="red">
              Ok
            </Button>
        `</div>
        </form>
      </>
    )
  }
}


export default connect(
	({ streaming } : StoreState) => ({
    title: streaming.title,
    description: streaming.desc,
    keepAlive: streaming.keepAlive
  }),
	(dispatch) => ({
    actions: bindActionCreators(streamingActions, dispatch),
  })
)(LiveSettingsModalContent)
