import * as React from 'react'
import { connect } from 'react-redux'
import { StoreState } from '../modules'
import { actions as loginActions } from '../modules/login'
import { bindActionCreators } from 'redux'
import ProfileContent from './ProfileContent'
import { MemberInfo } from './Auth'
import './Profile.scss'
import { Config, Store } from '../config'
import { OverviewNor } from '../assets/svg'

interface Props {
  member: MemberInfo
  actions: typeof loginActions
}

interface State {
  content: number
}

class ProfileContainer extends React.Component<Props, State> {
  state: State = {
    content: 0
  }

  componentDidMount = (): void => {
    const loaded = Store.get(Config.MEMBER_KEY)
    if (loaded) {
      console.log("loaded", loaded)

      const member: MemberInfo = JSON.parse(loaded)
      this.props.actions.setMember(member)
    }
  }

  onClick = (content: number): void => {
    this.setState({ content })
  }

  onUpdate = (member: MemberInfo): void => {
    Store.set(Config.MEMBER_KEY, member)
    this.props.actions.setMember(member);
  }

  public render = (): JSX.Element | null => {
    const { member } = this.props
    console.log(member)

    if (!member) {
      return null
    }

    const { content } = this.state

    return (
      <div className="dashboard-container row">
        <div className="submenu-group">
          <h3 className="review-menu-title">Account Settings</h3>
          <ul className="list-group review-menu">
            <li className={"list-group-item" + (content === 0 ? ' active' : '')} onClick={this.onClick.bind(this, 0)}><OverviewNor className={content === 0 ? " active" : " "}/> Profile</li>
          </ul>
        </div>
        <ProfileContent member={member} onUpdate={this.onUpdate} />
      </div>
    )
  }
}

export default connect (
	({ login } : StoreState) => ({
    member: login.member,
  }),
	(dispatch) => ({
    actions: bindActionCreators(loginActions, dispatch)
  })
)(ProfileContainer)
