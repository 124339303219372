import { Client } from '../config'
import moment from 'moment';
import { AxiosPromise } from 'axios';
import { PageInfo, SearchRequest } from '../components/pagination/Paginations';
import { dateFormat } from '../config/Format';
import { errorHandler } from '../account/Auth';

export type PlanName = 'Free' | 'Business' | 'Basic' | 'Pro'

export type Plan = {
  id: number
  name: PlanName
  channel_count: number
  chat_participants: number
  retention_period: number
  vod_count: number
  expose_goods_count: number
  resolutions: string[]
  support: number
  support_cdn: number
  cost: number
  created_at: number
}

export class PlanInfo {
  name: PlanName
  channelCount: number
  chatParticipants: number
  retentionPeriod: number
  vodCount: number
  exposeGoodsCount: number
  resolutions: string[]
  support: boolean
  supportCdn: boolean
  cost: number
  createdAt: string

  constructor(json: Plan) {
    this.name = json.name
    this.channelCount = json.channel_count
    this.chatParticipants = json.chat_participants
    this.retentionPeriod = json.retention_period
    this.vodCount = json.vod_count
    this.exposeGoodsCount = json.expose_goods_count
    this.resolutions = json.resolutions
    this.support = json.support === 1 ? true: false
    this.supportCdn = json.support_cdn === 1 ? true: false
    this.cost = json.cost > 0 ? json.cost * 1000 : 0
    this.createdAt = dateFormat(json.created_at);
  }
}

const DEFAULT_FREE_PLAN = new PlanInfo({
  id: 0,
  name: 'Free',
  channel_count: 1,
  chat_participants: 10,
  retention_period: 0,
  vod_count: 0,
  expose_goods_count: 1,
  resolutions: [],
  support: 0,
  support_cdn: 0,
  cost: 0,
  created_at: 20200331030803
})

type Application = {
  name: string
  app_key: string
  app_secret: string
  created_at: number
  // fromNow: string
  access_token: string
  live_count: number
  live_total_count: number
  vod_total_count: number
  plan: Plan
}

export class ApplicationInfo {
  name: string
  appKey: string
  appSecret: string
  createdAt: string
  fromNow: string
  accessToken: string
  onLive: number
  totalLive: number
  totalVod: number
  plan: PlanInfo

  constructor(json?: Application) {
    this.name = json?.name ? json?.name : ''
    this.appKey = json?.app_key || ""
    this.appSecret = json?.app_secret || ""
    this.createdAt = dateFormat(json?.created_at);
    this.fromNow = moment(json?.created_at).fromNow();
    this.onLive = json?.live_count ? json?.live_count : 0
    this.totalLive = json?.live_total_count ? json?.live_total_count : 0
    this.totalVod= json?.vod_total_count ? json?.vod_total_count : 0
    this.accessToken = btoa(`${this.appKey}:${this.appSecret}`)

    this.plan = json?.plan ? new PlanInfo(json?.plan) : DEFAULT_FREE_PLAN
  }
}

export const getApplications = ( page: SearchRequest, callback :(applications: ApplicationInfo[], page: PageInfo) => void): void => {
  const params = {'page': page.page, 'size': page.size, 'search': page.search || '' }

  new Client(`/api/2/applications`)
    .get(params)
    .then(res => {
      console.log(res)

      const applications: ApplicationInfo[] = []
      const pageInfo = new PageInfo(res.data)

      for (const r of res.data.content) {
        applications.push(new ApplicationInfo(r))
      }

      callback(applications, pageInfo)
    }, errorHandler)
}

export const saveApplication = (request: {}): AxiosPromise => {
  return new Client('/api/2/applications')
      .post(request)
}

export const deleteApplication = (appKey: string): AxiosPromise => {
  return new Client(`/api/2/applications`)
      .delete(appKey)
}

export const changeApplication = (appKey: string, name: string): AxiosPromise => {
  const request = {
    name
  }

  return new Client(`/api/2/applications`)
      .patch(appKey, request)
}
