import { Client } from '../config'
import { WatcherInfo } from '../review/Watchers'
import { GoodsInfo } from './Goods'
import { AxiosPromise } from 'axios'
import { errorHandler } from '../account/Auth'
import { isJson } from '../config/Validator'


export type VideoUser = {
  user_id: string
  username: string
  profile_photo_url: string
}

export interface StreamVideoInfo { 
  title: string 
  content: string 
  user_id: string | number 
  user_name: string
  user_avatar_url: string
 }

export class VideoUserInfo {
  userId: string
  username: string
  profilePhotoUrl: string

  constructor(json?: VideoUser) {
    this.userId = json?.user_id || ""
    this.username = json?.username  || ""
    this.profilePhotoUrl = json?.profile_photo_url || ""
  }
}

type LiveStat = {
  total_watcher_count: number
  total_message_count: number
  max_watcher_count: number
  avg_live_watch_time: number
  guest_watcher_count?: number
}

export class LiveStatInfo {
  totalWatcherCount: number
  totalMessageCount: number
  maxWatcherCount: number
  avgLiveWatchTime: number
  guestWatcherCount?: number

  constructor(json: LiveStat) {
    this.totalWatcherCount = json.total_watcher_count
    this.totalMessageCount = json.total_message_count
    this.maxWatcherCount = json.max_watcher_count
    this.avgLiveWatchTime = json.avg_live_watch_time
    this.guestWatcherCount = json.guest_watcher_count
  }
}

type WatcherHistory = {
  count_list: number[]
  time_list: number[]
}

export class WatcherHistoryInfo {
  countList: number[]
  timeList: number[]

  constructor(json: WatcherHistory) {
    this.countList = json.count_list
    this.timeList = json.time_list
  }
}

type Video = {
  id: number
  app_key: string
  stream_id: number
  stream_key: string
  video_key: string
  user_id: string
  path: string
  url: string
  thumbnail_path: string
  thumbnail_url: string
  preview_url: string
  preview_range: string
  title: string
  content: string
  type: 'BROADCASTED'
  state: 'LIVE' | 'VOD' | 'CREATED'
  visibility: 'PUBLIC'
  muted: boolean
  locked: boolean
  duration: number
  live_key: string
  output_type: 'hls'
  data: string
  heart_count: number
  watch_count: number
  view_count: number
  like_count: number
  created_at: number
  user: VideoUser
  download_url: string
  ingestion_type: string
}

export class VideoInfo {
  id: number
  appKey: string
  streamId: number
  streamKey: string
  videoKey: string
  userId: string
  path: string
  url: string
  thumbnailPath: string
  thumbnailUrl: string
  previewUrl: string
  previewRange: string[]
  title: string
  content: string
  type: 'BROADCASTED' | 'UPLOADED'
  state: 'LIVE' | 'VOD' | 'CREATED'
  visibility: 'PUBLIC'
  muted: boolean
  locked: boolean
  duration: number
  liveKey: string
  outputType: 'hls'
  data: string
  heartCount: number
  watchCount: number
  viewCount: number
  likeCount: number
  createdAt: number
  user: VideoUserInfo
  downloadUrl: string
  ingestionType: string
  transcodingTime : number
  inputFileSize : number
  outputFileSize : number
  compressibility: number

  constructor(json: Video) {
    const parsedData = isJson(json.data) ? JSON.parse(json.data) : {}
    this.id = json.id
    this.appKey = json.app_key
    this.streamId = json.stream_id
    this.streamKey = json.stream_key
    this.videoKey = json.video_key
    this.userId = json.user_id
    this.path = json.path
    this.url = json.url
    this.thumbnailPath = json.thumbnail_path
    this.thumbnailUrl = json.thumbnail_url
    this.previewUrl = json.preview_url
    this.previewRange = json.preview_range ? json.preview_range.split(",") : []
    this.title = json.title
    this.content = json.content
    this.type = json.type
    this.state = json.state
    this.visibility = json.visibility
    this.muted = json.muted
    this.locked = json.locked
    this.duration = json.duration
    this.liveKey = json.live_key
    this.outputType = json.output_type
    this.data = json.data
    this.heartCount = json.heart_count
    this.watchCount = json.watch_count
    this.viewCount = json.view_count
    this.likeCount = json.like_count
    this.createdAt = json.created_at
    this.downloadUrl = json.download_url
    this.user = new VideoUserInfo(json.user)
    this.ingestionType = json.ingestion_type
    this.transcodingTime = parsedData?.transcoding_time || 587
    this.inputFileSize = parsedData?.input_file_size ? Math.round(parsedData?.input_file_size / 1000) : 0
    this.outputFileSize = parsedData?.output_file_size ? Math.round(parsedData?.output_file_size / 1000) : 0
    this.compressibility = this.inputFileSize && this.outputFileSize ? Math.round((this.outputFileSize / this.inputFileSize) * 100) : 0
  }
}

export const getVideo = (appKey: string, videoKey: string, callback: (video: VideoInfo, goods: any) => void): void => {
  new Client(`/api/2/applications/${appKey}/videos/${videoKey}`).get().then((response) => {
    console.log("getVideo", response)

    const data = isJson(response.data?.data) ? JSON.parse(response.data?.data) : []
    const goodsList: GoodsInfo[] = []

    console.log("data?.goods_list", data?.goods_list);
    if(data?.goods_list?.length > 0) {
      for (const g of data?.goods_list) {
        goodsList.push(new GoodsInfo(g))
      }
    }

    callback(new VideoInfo(response.data), goodsList)
  }, errorHandler)
}

export const getVod = (videoKey: string, callback: (video: VideoInfo, goods: any) => void): void => {
  new Client(`/api/2/videos/${videoKey}`).get().then((response) => {

    const data = isJson(response.data?.data) ? JSON.parse(response.data?.data) : []
    const goodsList: GoodsInfo[] = []

    if(data.length > 0) {
      for (const g of data?.goods_list) {
        goodsList.push(new GoodsInfo(g))
      }
    }

    callback(new VideoInfo(response.data), goodsList)
  }, errorHandler)
}

export const getLiveViewers = (appKey: string, videoKey: string, search: string, callback: (watchers: WatcherInfo[]) => void): void => {
  const param: {} = { app_key: appKey, search: search }
  new Client(`/api/2/videos/${videoKey}/watchers`).get(param).then(
    (response) => {
      console.log('live watchers', response)

      const watchers: WatcherInfo[] = []

      for (const m of response.data) {
        watchers.push(new WatcherInfo(m))
      }

      callback(watchers)
    }, errorHandler)
}

export const getLiveStat = (appKey: string, videoKey: string, callback: (liveStat: LiveStatInfo) => void): void => {
  new Client(`/api/2/applications/${appKey}/videos/${videoKey}/info?with=guest`).get().then(
    (response) => {
      console.log('live stat', response)

      callback(new LiveStatInfo(response.data))
    }, errorHandler)
}

export const blockUser = (appKey: string, videoKey: string, userId: string): AxiosPromise => {
  const param: {} = { app_key: appKey, video_key: videoKey, user_id: userId }

  return new Client(`/api/2/applications/user_block`).post(param)
}

export const unBlockUser = (appKey: string, userId: string): AxiosPromise => {
  const param: {} = { app_key: appKey, user_id: userId }

  return new Client(`/api/2/applications/user_unblock`).post(param)
}

export const getWatchersHistory = (videoKey: string, callback: (watchersHistory: WatcherHistoryInfo) => void): void => {
  const param: {} = { size: 20 }

  new Client(`/api/2/videos/${videoKey}/watch_history`).get(param).then((response) => {
    console.log('watcher history', response)

    callback(new WatcherHistoryInfo(response.data))
  }, errorHandler)
}

export const updateVideo = (appKey: string, videoKey: string, request: {}): void => {
  new Client(`/api/2/applications/${appKey}/videos/${videoKey}`)
    .patch('', request)
    .then(res => {
      console.log(res.data)
    }, errorHandler)
}

export const terminateLive = (appKey: string, videoKey: string): void => {
  const param = {} 
  new Client(`/api/2/applications/${appKey}/videos/${videoKey}/terminate`)
    .post(param)
    .then(res => {
      console.log(res.data)
    }, errorHandler)
}