import * as React from 'react';
import { actions as videoActions } from '../modules/video'
import { actions as streamActions } from '../modules/streaming'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import videojs from 'video.js';
import { Messages } from '../config';
import "./VideoPlayer.scss"

interface VideoProps {
  src: string;
  videos: typeof videoActions
  streams: typeof streamActions
  poster?: string;
  autoPlay? : boolean | undefined;
  retryable? : boolean
  retryLimit?: number  // 24 hour
  retryInterval?: number  // 5 sec
  onClick?: any
  [key: string]: any
}

class VideoPlayer extends React.Component<VideoProps> {
  private player?: videojs.Player;
  private videoNode?: HTMLVideoElement;

  constructor(props: VideoProps) {
    super(props)
    this.player = undefined;
    this.videoNode = undefined;
  }

  componentDidMount = (): void => {
    this.player = videojs(this.videoNode)
    this.props.videos.setPlayer(this.player);

    if (this.props.retryable) {
      const limit = this.props.retryLimit || 86400 // 24hours
      const interval = this.props.retryInterval || 3000
      this.addRetryHandler(limit, interval)
    }
  }

  addRetryHandler = (limit: number, interval: number): void => {
    if (this.player) {
      let retry = 0;
      const that = this.player
      this.player.on('error', () => {
        if(retry < limit){
          console.log(`Video source went wrong! Retrying.. ${++retry}`);
          setTimeout(() => {
            // console.log(that)
            if (that.player() !== null) {
              that.src(this.props.src)
            } 
          }, interval);
        }
      })
    }
  }

  componentWillUnmount = (): void => {
    if (this.player) {
      this.player.dispose();
    }
  }

  public render = (): JSX.Element => {
    const { src, poster, onClick } = this.props;
    const autoPlay = this.props.autoPlay ? this.props.autoPlay : false

    // console.log(`${src}`)

    return(
      <div className="video-player-container" onClick={onClick}>
        <div data-vjs-player="true">
          <video id="video-viewer" ref={(node: HTMLVideoElement) => this.videoNode = node } className="video-js vjs-default-skin vjs-big-play-centered" poster={poster} controls playsInline x-webkit-ariplay="allow" webkit-playsinline="allow" preload="metadata" autoPlay={autoPlay} width="300" height="300" muted data-setup='{"fluid": true}' {...this.props}>
            { src && src.indexOf('m3u8') > 0 ? 
              <source type="application/x-mpegurl" src={src} /> :
              <source type="video/mp4" src={src} />
            }
            <p className="vjs-no-js">{Messages.VIDEOJS_NO_JS}<a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>
          </video>
        </div>
      </div>
    );
  }
}

export default connect(
	() => ({}),
	(dispatch) => ({
    videos: bindActionCreators(videoActions, dispatch),
    streams: bindActionCreators(streamActions, dispatch),
  })
)(VideoPlayer)
