import * as React from 'react'
import './LoginContainer.scss'
import ResendEmailForm from './ResendEmailForm'
import DashboardHeader from '../components/DashboardHeader';

class ResendEmailContainer extends React.Component {
  public render = (): JSX.Element => {
    return  (
      <div className="container-fluid">
        <DashboardHeader />
        <div className="login-container">
          <ResendEmailForm />
        </div>
      </div>
    )
  }
}

export default ResendEmailContainer
