import React from 'react';
import { bindActionCreators } from 'redux';
import { FlipFlop } from 'flipflop-sdk-javascript/dist/flipflop'
import { actions as applicationActions } from '../modules/application'
import { getVideo, VideoInfo } from './Video';
import moment from 'moment'
import VideoPlayer from '../review/VideoPlayer';
import { Message, MessageInfo } from '../review/Messages';
import { GoodsInfo } from './Goods';
import { WatcherInfo } from '../review/Watchers';
import Toast from '../components/Toast'
import { AppUserInfo, getAppUser } from '../settings/Settings';
import MonitorUploaderProfile from './MonitorUploaderProfile';
import UploadedSubBox from './UploadedSubBox';
import { ApplicationInfo } from '../application/Applications';
import { connect } from 'react-redux';
import { StoreState } from '../modules';

interface Props {
  match: any
  app: ApplicationInfo
  appUser: AppUserInfo
  actions: typeof applicationActions
}

interface State {
  video: VideoInfo | null
  selectedUser: WatcherInfo | null
  goods: GoodsInfo[]
  messages: MessageInfo[]
  systemMessageCount: number
  shown: any[],
  cursor: number
}

class UploadedMonitorContainer extends React.Component<Props, State> {

  state: State = {
    video: null,
    selectedUser: null,
    goods: [],
    messages: [],
    systemMessageCount: 0,
    shown: [],
    cursor: 0,
  }

  player: any
  gossip: any
  sdk: any
  videoNode: any

  loadApplication = (): void => {
    const loaded = localStorage.getItem('app')
    const videoKey = this.props.match.params.id

    if (loaded) {
      const app: ApplicationInfo = JSON.parse(loaded)
      this.props.actions.setApplication(app)
      getAppUser(app.appKey, (appUser: AppUserInfo) => {
        this.props.actions.setAppUser(appUser)
        this.loadVideo(app.appKey, videoKey);
      })
    } else {
      window.location.href = '/applications'
    }
  }

  initToken = (video: VideoInfo): void => {
    console.log(video)
    this.initMonitor(video)
  }

  initMonitor = async (video: VideoInfo): Promise<void> => {
    const { app, appUser } = this.props;

    const appKey = app.appKey
    const appSecret = app.appSecret

    if (!appKey || !appSecret) {
      Toast.warn('appKey and appSecret parameters are required');
      return;
    }

    if (!appUser) {
      Toast.warn('Failed to load admin user.');
      return
    }

    if (!appKey || !appSecret) {
      Toast.warn('appKey and appSecret parameters are required');
      return;
    }

    const userId = appUser.userId
    const userName = appUser.username
    const profilePhoto = appUser.profilePhotoUrl

    console.log(`appKey: ${appKey}, appSecret: ${appSecret}`);
    console.log(`userId: ${userId}, userName: ${userName}, profilePhoto - ${profilePhoto}`);

    FlipFlop.initialize(appKey, appSecret);
    this.sdk = await FlipFlop.authentication(userId, userName, profilePhoto);
    
    this.player = this.sdk.getPlayer(video.videoKey);
    this.player.prepare(this);
  }

  setVideo = (video: VideoInfo, goods: GoodsInfo[]): void => {
    this.setState({ video, goods })

    if(video) {
      this.initToken(video)
    }
  }

  setMessages = (messages: MessageInfo[]): void => {
    this.setState({ messages })
  }

  loadVideo = (appKey: string, videoKey: string): void => {
    getVideo(appKey, videoKey, this.setVideo)
  }

  onTimeUpdate = (e: any): void => {
    const cursor = e.target.currentTime

    this.setState({ cursor })
    const { video } = this.state

    if (video?.state === 'VOD') {
      this.switchMessage(cursor)
    }
  }

  onPrepare = (): void => {
    console.log('onPrepare')
  }

  onStart = (): void => {
    console.log('onStart')
  }

  onStop = (): void => {
    console.log(`onStopped`)
  }

  onError = (error: string): void => {
    if (error){
      console.error(error)
    }
  }

  switchMessage = (now: number): void => {
    const { cursor, shown, messages } = this.state
    
    if (now < cursor) {
      this.setState({
        shown: [],
        messages: [...shown].concat(messages)
      })
      return
    } 
    
    const index = messages.findIndex(m => m.elapsed > now);
    console.log(`${cursor} cursor: ${now}, index: ${index}`)

    if (index > 0) {
      const toSwitch = messages.splice(0, index);
      console.log(toSwitch.length)
      
      if (toSwitch.length > 0) {
        const toShow = shown.concat(toSwitch)
        console.log(toShow)

        this.setState({
          shown: toShow
        })  
      } 
    }
  }

  onChatReceived = (message: Message): void => {
    console.log("onChatReceived", message)
    message.elapsedTime = Date.now() - message.created_at
     
    const { messages } = this.state;

    if(message.message_type === "JOIN" || message.message_type === "LEAVE") {
      this.setState(prev => ({ messages: messages.concat(new MessageInfo(message)), systemMessageCount: prev.systemMessageCount + 1 }));
    } else {
      this.setState({ messages: messages.concat(new MessageInfo(message))});
    }
  }

  sendMessage = (message: string): void => {
    if (this.player) {
      this.player.sendMessage(message)
    }
  }

  onChatMessgeReceived = (messages: MessageInfo[]): void => {
    console.log("messages", messages)

    const { video } = this.state
    if (!video) {
      return
    }

    if (messages) {
      for (const m of messages) {
        m.elapsed = (m.createdAt - video.createdAt) / 1000
        m.fromNow = moment(m.createdAt).from(video.createdAt)
        m.createdAtFormat = moment(m.createdAt).format('HH:mm:ss')
      }
  
      this.setState({ messages })    
    }
  }

  componentDidMount = (): void => {
    this.loadApplication()
  }

  componentWillUnmount(): void {
    if (this.videoNode) {
      this.videoNode.dispose()
    }
  }

  render(): JSX.Element | null {
    const { goods, video } = this.state
    const { app } = this.props
    console.log('goods', goods, video)

    if(!video) {
      return null;
    }

    return (
      <div className="monitor-container">
        <div className="monitor-content">
          <h3 className="monitor-title">{video?.title || "Untitled"}</h3>
          <div className="monitor-item-list">
            <div className="monitor-item double-box">
              {video?.url && <VideoPlayer src={video?.url || ""} autoPlay={true} onTimeUpdate={this.onTimeUpdate} />}
            </div>
            <div className="monitor-item">
              {video && <MonitorUploaderProfile video={video} /> }
              <UploadedSubBox appKey={app.appKey} videoKey={video.videoKey} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default connect(
	({ application } : StoreState) => ({
    app: application.application,
    appUser: application.appUser
  }),
	(dispatch) => ({
    actions: bindActionCreators(applicationActions, dispatch),
  })
)(UploadedMonitorContainer);