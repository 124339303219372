import * as React from 'react'
import { actions as appContentActions } from '../modules/application'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ApplicationInfo } from '../application/Applications'
import { StoreState } from '../modules'
import TextInput from '../components/input/TextInput'
import Button from '../components/button/Button'
import { updateBroadcast } from './Webinars'
import TextArea from '../components/input/TextArea'
import SwitchInput from '../components/input/SwitchInput'
import './StreamKeyForm.scss'
import Toast from '../components/Toast'
import { VideoInfo } from '../monitor/Video'
import { MAX_LENGTH } from '../config/Config'


interface Props {
  app: ApplicationInfo
  video: VideoInfo
  actions: typeof appContentActions
  onClose(): void
  onCancel(): void
}

interface State {
  title: string
  titleError: string
  content: string
  contentError: string
  visibility: boolean
}

class UpdateBroadcastForm extends React.Component<Props> {
  state: State = {
    title: this.props.video.title,
    titleError: '',
    content: this.props.video.content,
    contentError: '',
    visibility: this.props.video.visibility === 'PUBLIC' ? true : false
  }

  onChangeText = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value})
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { title, content, visibility } = this.state

    if (title === '') {
      this.setState({ titleError: 'Please enter title' })
      return
    }
    this.setState({ titleError: '' })

    if (content === '') {
      this.setState({ contentError: 'Please enter content' })
      return
    }
    this.setState({ contentError: '' })
    
    const { app, video } = this.props
    console.log(video)

    const request = {
      title,
      content,
      visibility: visibility ? 'PUBLIC' : 'PRIVATE'
    }
    
    updateBroadcast(app.appKey, video.videoKey, request, (v) => {
      Toast.notify('updated Broadcasting')
      console.log(v)
      this.props.onClose()
    })
  }

  onChangeVisiblity = (visibility: boolean): void => {
    console.log(visibility)
    this.setState({ visibility })
  }


  onClickStreamKey = (streamKey: string): void => {
    console.log(streamKey)
    this.setState({ streamKey })
  }

  public render = (): JSX.Element => {
    const { video } = this.props

    return (
      <form className="application-form" onSubmit={this.handleSubmit}>
        <TextInput type="text" name="title" value={(video as any).streamKey} onChange={this.onChangeText} label="Stream Key" required readOnly />
        <TextInput type="text" name="title" value={this.state.title} onChange={this.onChangeText} label="Title" maxLength={MAX_LENGTH.videoTitle} required error={this.state.titleError} />
        <TextArea type="text" name="content" value={this.state.content} onChange={this.onChangeText} label="Content" maxLength={MAX_LENGTH.videoDesc} required error={this.state.contentError} />
        <SwitchInput label="Visibility" current={this.state.visibility} onChange={this.onChangeVisiblity} />
        <div className="button">
          <Button type="button" size="large" color="white" onClick={this.props.onCancel}>Cancel</Button>
          <Button type="submit" size="large" color="red">Ok</Button>
        </div>
      </form>
    )
  }
}

export default connect(
  ({ application, webinar }: StoreState) => ({
    app: application.application,
    video: webinar.video
  }),
  (dispatch) => ({
    actions: bindActionCreators(appContentActions, dispatch),
  })
)(UpdateBroadcastForm)
