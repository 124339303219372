import { Client } from '../config'
import { FilterRequest, PageInfo, PageRequest } from '../components/pagination/Paginations'
import { dateFormat } from '../config/Format';
import { errorHandler } from '../account/Auth';

type Watcher = {
  profile_photo_url: string
  user_id: string
  username: string
  watched_at?: number
  block?: boolean
}

export class WatcherInfo {
  profilePhotoUrl: string
  userId: string
  username: string
  watchedAt: string
  block?: boolean

  constructor(json: Watcher) {
    this.profilePhotoUrl = json.profile_photo_url
    this.userId = json.user_id
    this.username = json.username
    this.watchedAt = dateFormat(json.watched_at)
    this.block = json.block
  }
}

type Viewer = {
  block: boolean
  created_at: number
  id: number
  profile_photo_url: string
  updated_at: number
  user_id: string
  username: string
}

export class ViewerInfo {
  block: boolean
  createdAt: string
  id: number
  profilePhotoUrl: string
  updatedAt: string
  userId: string
  username: string

  constructor(json: Viewer) {
    this.block = json.block
    this.createdAt = dateFormat(json.created_at);
    this.id = json.id
    this.profilePhotoUrl = json.profile_photo_url
    this.updatedAt = dateFormat(json.updated_at);
    this.userId = json.user_id
    this.username = json.username
  }
}

/**
 * Get list of viewer watched live
 * @param appKey 
 * @param videoKey 
 * @param callback 
 */
export const getWatchers = (appKey: string, videoKey: string, page: PageRequest, callback: (list: WatcherInfo[], page: PageInfo) => void): void => {
  const param: {} = { 'app_key': appKey, 'page': page.page, 'size': page.size }

  new Client(`/api/2/videos/${videoKey}/watchers`)
    .get(param)
    .then(response => {
      console.log("watchers", response)
      
      const watchers: WatcherInfo[] = []

      const { number, size, total_elements, total_pages } = response.data;
      const pageInfo : PageInfo = new PageInfo({ number, size, total_elements, total_pages })

      for (const m of response.data) {
        watchers.push(new WatcherInfo(m))
      }

      callback(watchers, pageInfo)
    }, errorHandler)
}

/**
 * Get list of viewer watched VOD
 * @param appKey 
 * @param videoKey 
 * @param callback 
 */
export const getViewers = (appKey: string, videoKey: string, page: PageRequest, callback: (list: ViewerInfo[], page: PageInfo) => void): void => {
  const param: {} = { 'app_key': appKey, 'page': page.page, 'size': page.size }

  new Client(`/api/2/videos/${videoKey}/viewers`)
    .get(param)
    .then(response => {
      console.log("viewer", response.data.content)
      
      const viewers: ViewerInfo[] = []


      const { number, size, total_elements, total_pages } = response.data;
      const pageInfo : PageInfo = new PageInfo({ number, size, total_elements, total_pages })

      for (const m of response.data.content) {
        viewers.push(new ViewerInfo(m))
      }

      callback(viewers, pageInfo)
    }, errorHandler)
}

export const getMonitorViewers = (appKey: string, videoKey: string, page: FilterRequest, callback: (list: ViewerInfo[], page: PageInfo, lastPage: number) => void): void => {
  const param: {} = { 'page': page.page, 'size': page.size, 'direction': page.direction || 0, 'search': page.search || '' }

  new Client(`/api/2/applications/${appKey}/videos/${videoKey}/viewers`)
    .get(param)
    .then(response => {
      console.log("viewer", response)
      
      const viewers: ViewerInfo[] = []


      const { number, size, total_elements, total_pages } = response.data;
      const pageInfo : PageInfo = new PageInfo({ number, size, total_elements, total_pages })

      for (const m of response.data.content) {
        viewers.push(new ViewerInfo(m))
      }

      callback(viewers, pageInfo, response.data.total_pages)
    }, errorHandler)
}
 
export const getMonitorWatchers = (appKey: string, videoKey: string, page: FilterRequest, callback: (list: WatcherInfo[], page: PageInfo, lastPage: number) => void): void => {
  const param: {} = { 'page': page.page, 'size': page.size, 'direction': page.direction || 0, 'search': page.search || '' }

  new Client(`/api/2/applications/${appKey}/videos/${videoKey}/live_viewers`)
    .get(param)
    .then(response => {
      console.log("watchers", response)
      
      const watchers: WatcherInfo[] = []

      const { number, size, total_elements, total_pages } = response.data;
      const pageInfo : PageInfo = new PageInfo({ number, size, total_elements, total_pages })

      for (const m of response.data.content) {
        watchers.push(new WatcherInfo(m))
      }

      callback(watchers, pageInfo, response.data.total_pages)
    }, errorHandler)
}

export const getViewersCount = (appKey: string, videoKey: string, callback: (totalElements: any) => void): void => {
  const param: {} = {}

  new Client(`/api/2/applications/${appKey}/videos/${videoKey}/viewer_info`)
    .get(param)
    .then(response => {
      console.log("viewer info", response)

      callback([response.data.watcher_count, response.data.viewer_count])
    }, errorHandler)
}