import React from 'react'
import { Config } from '../config'
import { MessageInfo, SystemMessageType } from '../review/Messages'

const MessaegProvider = (message: MessageInfo | SystemMessageType ) => {
  switch (message.messageType) {
    case "MESSAGE":
      return <NormalMessage message={message} />
    case "DM":
      return <DirectMessage message={message} />
    case "ADMIN": // UPDATE, ACTIVE, INACTIVE
      return null;
    case "JOIN":
    case "LEAVE":
    case "SYSTEM":
      return <SystemMessage message={message} />
    case "PENDING":
      return <PendingMessage message={message} />
    default:
      return <NormalMessage message={message as MessageInfo} />
  }
}

interface PendingMessageProps {
  message: MessageInfo | SystemMessageType
}

const PendingMessage = ({message}: PendingMessageProps) => (
  <div className="list-group-item pending-message">
    <div className="bmd-list-group-col">
      <p className="list-group-item-text">{message.message}</p>
    </div>
  </div>
)

interface DirectMessageProps {
  message: MessageInfo
}

const DirectMessage = ({message}: DirectMessageProps) => (
  <div className="list-group-item">
    <div className="normal-message-group">
      <div className="message-user-group">
        <img className="message-user-avatar" src={message?.user?.avatar || Config.DEFAULT_AVATAR} alt="user_avatar"></img>
        {message?.user && message?.user.username ? 
          <div className="message-user-name text-ellipsis">{`${message?.user.username} (DM)`}</div> : 
          <div className="message-user-name">{`noname (DM)`}</div>}
      </div>
      <div className="message-content">{message.message}</div>
    </div>
  </div>
)

interface SystemMessageProps {
  message: MessageInfo | SystemMessageType
}

const SystemMessage = ({message}: SystemMessageProps) => (
  <div className="list-group-item">
    <div className="bmd-list-group-col">
      <p className="list-group-item-text system-message">{message.message}</p>
    </div>
  </div>
)

interface NormalMessageProps {
  message: MessageInfo
}

const NormalMessage = ({message}: NormalMessageProps) => {
  return (
    <div className="list-group-item">
      <div className="normal-message-group">
        <div className="message-user-group">
          <img className="message-user-avatar" src={message?.user?.avatar || Config.DEFAULT_AVATAR} alt="user_avatar"></img>
          {message?.user && message?.user.username ? 
            <div className="message-user-name text-ellipsis">{`${message?.user.username}`}</div> : 
            <div className="message-user-name">noname</div>}
        </div>
        <div className="message-content">{message.message}</div>
      </div>
    </div>
  )
}

interface Props {
  message: MessageInfo | SystemMessageType
}

class ChatItem extends React.Component<Props> {

  render(): JSX.Element | null {
    const { message } = this.props
    return (
      MessaegProvider(message)
    )
  }
}


export default ChatItem