import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { actions as settingActions } from '../modules/setting'
import { StoreState } from '../modules';
import { FormBullet } from '../assets/svg'
import Dropdown from '../components/dropdown/Dropdown'
import SettingTextArea from '../components/input/SettingTextArea'
import SettingInputText from '../components/input/SettingTextInput'
import { MAX_LENGTH } from '../config/Config'

interface Props {
  profanityFilter: number,
  profanity: string,
  settingActions: typeof settingActions
}

interface State {
  wordsError: string,
  replacer: string,
  replacerError: string,
}

class ProhibitedWordsItem extends React.Component<Props, State> {
  state: State = {
    wordsError: '',
    replacer: '**',
    replacerError: '' 
  }

  onChangeReplacer = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  onChangeProfanity = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })

    this.props.settingActions.setProfanity(value)
  }

  onClickRule = (value: number): void => {
    this.props.settingActions.setProfanityFilter(value)
  }

  public render = (): JSX.Element => {
    const labelText = '금지어 설정'
    const options = [
      {
        value: 'Replace',
        onClick: this.onClickRule.bind(this, 1),
      },
      {
        value: 'Drop',
        onClick: this.onClickRule.bind(this, 2),
      }
    ]

    return (
      <div className="settings col-xl-12">
        <div className="settings-header">
          <h5 className="card-title">Profanity filter(금지어)</h5>
        </div>
        <div className="card settings-item">
          <div className="card-body prohibit-body">
            <div className="setting-input-group">
              <label htmlFor={labelText}>
                <FormBullet /> <span>{labelText}</span>
              </label>
            </div>
            <div className="rule-group">
              <Dropdown>
                <Dropdown.Button toggleIcon><span>{this.props.profanityFilter === 2 ? 'Drop' : 'Replace'}</span></Dropdown.Button>
                <Dropdown.Items options={options} />
              </Dropdown>
            </div>
            <div className="words-group">
              <SettingTextArea name="words" value={this.props.profanity} onChange={this.onChangeProfanity} maxLength={MAX_LENGTH.profanity}/>
              <SettingInputText name="replacer" value={this.state.replacer} onChange={this.onChangeReplacer} readOnly />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ setting }: StoreState) => ({
    profanityFilter: setting.profanityFilter,
    profanity: setting.profanity,
  }),
	(dispatch) => ({
    settingActions: bindActionCreators(settingActions, dispatch),
  })
)(ProhibitedWordsItem)
