import { Client } from "../config"
import { Config } from "../config"

type Goods = {
  id: number
  title: string
  price: string | number
  thumbnailUrl: string
}

export class GoodsInfo { 
  id: number
  title: string
  price: string | number
  thumbnailUrl: string

  constructor(json: Goods) {
    this.id = json.id
    this.title = json.title
    this.price = json.price;
    this.thumbnailUrl = json.thumbnailUrl
  }
}

export const getGoodsList = (appKey: string, callback:(goods: GoodsInfo[]) => void): void => {
  const param: {} = { 'app_key': appKey } 

  new Client(`/v2/admin/applications/${appKey}/goods`)
    .get(param)
    .then(response => {
      console.log(response)

      callback(response.data)
    }, error => {
      console.log(error)
      
      callback(Config.SAMPLE_GOODS)
    })

}
