import * as React from 'react'

export default function BlackNoContent(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="101" height="100" viewBox="0 0 101 100">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path d="M0 0H100V100H0z" transform="translate(-295 -681) translate(41 510) translate(223 171) translate(32)" />
                <g fill="#E0E4E7" stroke="#FFF" strokeWidth="2">
                  <path
                    d="M27-1c7.732 0 14.732 3.134 19.799 8.201S55 19.268 55 27c0 7.357-2.837 14.05-7.477 19.048-4.365 4.701-10.327 7.9-17.022 8.735l-.503.058L30 69c0 .828-.336 1.578-.879 2.121-.543.543-1.293.879-2.121.879-.828 0-1.578-.336-2.121-.879-.498-.497-.822-1.169-.872-1.916L24 69V54.91c-6.903-.77-13.057-4.03-17.532-8.863C1.833 41.042-1 34.352-1 27c0-7.732 3.134-14.732 8.201-19.799S19.268-1 27-1zm0 6c-6.075 0-11.575 2.462-15.556 6.444C7.462 15.424 5 20.924 5 27c0 6.075 2.462 11.575 6.444 15.556C15.424 46.538 20.924 49 27 49c6.075 0 11.575-2.462 15.556-6.444C46.538 38.576 49 33.076 49 27c0-6.075-2.462-11.575-6.444-15.556C38.576 7.462 33.076 5 27 5z"
                    transform="translate(-295 -681) translate(41 510) translate(223 171) translate(32) rotate(-45 57.52 19.858)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
