import * as React from 'react';

interface Props{
  index: number;
  actived: boolean;
  handlePage(page: number): void; 
}

export default class PageItem extends React.Component<Props> {
  
  handlePage = (e: React.FormEvent<HTMLAnchorElement>): void => {
		e.preventDefault()
    this.props.handlePage(this.props.index - 1);
	}

  public render = (): JSX.Element => {
    const {index, actived} = this.props;
    return (
      <li className={'page-item' + (actived ? ' active' : '')}>
      { actived ? 
        <a className="page-link disabled" href="#">{index}</a> :
        <a className="page-link" href="#" onClick={this.handlePage}>{index}</a> 
      }
      </li>
    );
  }
}