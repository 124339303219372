import * as React from 'react'

export default function LeftArrow(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path d="M0 0H11V11H0z" transform="translate(-780 -398) translate(740 200) translate(40 194) translate(0 4)"/>
                <path fill="#000" d="M4 3v2h5.5c.276 0 .5.224.5.5s-.224.5-.5.5H4v2L1.6 6h-.1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h.1L4 3z" transform="translate(-780 -398) translate(740 200) translate(40 194) translate(0 4)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}