import * as React from 'react'

interface Props {
  className?: string
  onClick?(e: React.MouseEvent<SVGElement>): void
}

export default function CopyIcon({className, onClick}: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" onClick={onClick} >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path d="M0 0L20 0 20 20 0 20z" transform="translate(-1470 -483) translate(610 180) translate(0 204) translate(20 89) translate(840 10)" />
                  <path className={className} fill="#F9FAFB" stroke="#888" d="M15 2.5c.414 0 .79.168 1.06.44.272.27.44.646.44 1.06h0v9c0 .414-.168.79-.44 1.06-.27.272-.646.44-1.06.44h0-6c-.414 0-.79-.168-1.06-.44-.272-.27-.44-.646-.44-1.06h0V4c0-.414.168-.79.44-1.06.27-.272.646-.44 1.06-.44h0z" transform="translate(-1470 -483) translate(610 180) translate(0 204) translate(20 89) translate(840 10)" />
                  <path className={className} fill="#F9FAFB" stroke="#888" d="M11 5.5c.414 0 .79.168 1.06.44.272.27.44.646.44 1.06h0v9c0 .414-.168.79-.44 1.06-.27.272-.646.44-1.06.44h0-6c-.414 0-.79-.168-1.06-.44-.272-.27-.44-.646-.44-1.06h0V7c0-.414.168-.79.44-1.06.27-.272.646-.44 1.06-.44h0z" transform="translate(-1470 -483) translate(610 180) translate(0 204) translate(20 89) translate(840 10)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
