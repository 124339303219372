import { createAction, handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import { AppUserInfo, CallbackSettingsInfo, WebhookSettingInfo } from '../settings/Settings';

const SET_APP_USER = 'setting/SET_APP_USER'
const SET_CALLBACK = 'setting/SET_CALLBACK'
const SET_THUMBNAIL_INTERVAL = 'setting/SET_THUMBNAIL_INTERVAL'
const SET_PROFANITY_FILTER = 'setting/SET_PROFANITY_FILTER'
const SET_PROFANITY = 'setting/SET_PROFANITY'
const SET_LIVE_TIMER = 'setting/SET_LIVE_TIMER'
const SET_WEBHOOK = 'setting/SET_WEBHOOK'

const RESET = 'setting/RESET'

export const actions = {
  setAppUser: createAction<AppUserInfo>(SET_APP_USER),
  setCallbacks: createAction<CallbackSettingsInfo[]>(SET_CALLBACK),
  setThumbnailInterval: createAction<number>(SET_THUMBNAIL_INTERVAL),
  setProfanityFilter: createAction(SET_PROFANITY_FILTER),
  setProfanity: createAction(SET_PROFANITY),
  setLiveTimer: createAction(SET_LIVE_TIMER),
  setWebhook: createAction(SET_WEBHOOK),
  reset: createAction(RESET),
}

export class SettingError {
  hasError: boolean;
  field: string;
  messaege: string;

  constructor(field?: string) {
    this.field = field || "";
    this.hasError = false;
  }
}

export interface SettingState {
  appUser: AppUserInfo
  callbacks: CallbackSettingsInfo[]
  thumbnailInterval: number
  liveTimer: number
  profanityFilter: number
  profanity: string
  webhook: WebhookSettingInfo
}

const initialState: SettingState = {
  appUser: new AppUserInfo(),
  callbacks: [new CallbackSettingsInfo(), new CallbackSettingsInfo(), new CallbackSettingsInfo()],
  liveTimer: 0,
  thumbnailInterval: 0,
  profanityFilter: 0,
  profanity: "",
  webhook: new WebhookSettingInfo(),
}

export default handleActions<SettingState, any>({
  [SET_APP_USER]: (state, action: Action<AppUserInfo>) => produce(state, draft => { draft.appUser = action.payload; }),
  [SET_CALLBACK]: (state, action: Action<CallbackSettingsInfo[]>) => produce(state, draft => { draft.callbacks = action.payload; }),
  [SET_THUMBNAIL_INTERVAL]: (state, action: Action<number>) => produce(state, draft => { draft.thumbnailInterval = action.payload }),
  [SET_PROFANITY_FILTER]: (state, action: Action<number>) => produce(state, draft => { draft.profanityFilter = action.payload }),
  [SET_PROFANITY]: (state, action: Action<string>) => produce(state, draft => { draft.profanity = action.payload }),
  [SET_LIVE_TIMER]: (state, action: Action<number>) => produce(state, draft => { draft.liveTimer = action.payload }),
  [SET_WEBHOOK]: (state, action: Action<WebhookSettingInfo>) => produce(state, draft => { draft.webhook = action.payload }),
  [RESET]: () => initialState,
	}, initialState
)