import * as React from 'react'

export default function Private(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="76" height="32" viewBox="0 0 76 32">
      <defs>
        <filter id="jrse6yp38a" width="125%" height="180%" x="-12.5%" y="-40%" filterUnits="objectBoundingBox">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g filter="url(#jrse6yp38a)" transform="translate(-824 -288) translate(830 294)">
            <path fill="#000" fillOpacity=".8" stroke="#000" strokeOpacity=".8" d="M54 .5c2.623 0 4.998 1.063 6.718 2.782C62.437 5.002 63.5 7.377 63.5 10s-1.063 4.998-2.782 6.718C58.998 18.437 56.623 19.5 54 19.5h0-44c-2.623 0-4.998-1.063-6.718-2.782C1.563 14.998.5 12.623.5 10s1.063-4.998 2.782-6.718C5.002 1.563 7.377.5 10 .5h0z" />
            <text fill="#FFF" fontFamily="Roboto-Bold, Roboto" fontSize="10" fontWeight="bold">
              <tspan x="22" y="14">
                Private
              </tspan>
            </text>
            <g transform="translate(10 5)">
              <g>
                <path d="M0 0H10V10H0z" />
                <circle cx="5.5" cy="5.5" r="3" stroke="#FFF" />
              </g>
              <rect width="3" height="1" x="4" y="5" fill="#FFF" rx=".5" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
