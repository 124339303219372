import { history } from '..'
import Toast from '../components/Toast'
import { Config, Client, Store, Messages } from '../config'


export const login = (email: string, password: string, callback:() => void): void => {
  const contentType = 'application/x-www-form-urlencoded'
  const request = {
    grant_type: 'client_credentials',
    email,
    secret: password
  }

  new Client('/oauth/token', Config.GUEST_TOKEN)
    .post(request, contentType)
    .then(t => {
      console.log(t)
      const accessToken = 'Bearer ' +  t.data.access_token
      Store.set(Config.ACCESS_TOKEN_KEY, accessToken)
      history.push('/');
      callback()
    }, err => {
      console.log(err.response)

      if (err.response.status === 401) {
        history.push('/resend');
      }

      errorHandler(err)
    })
}

interface GoogleAuthRequest {
  googleId: string;
  imageUrl: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
}

export const googleLogin = (req: GoogleAuthRequest, callback:() => void): void => {
  const contentType = 'application/x-www-form-urlencoded'
  const request = {
    grant_type: 'client_credentials',
    email: req.email,
    google_id: req.googleId,
  }

  new Client('/oauth/token', Config.GUEST_TOKEN)
    .post(request, contentType)
    .then(t => {
      console.log(t)
      const accessToken = 'Bearer ' +  t.data.access_token
      Store.set(Config.ACCESS_TOKEN_KEY, accessToken)
      callback()
    }, err => {
      console.log(err.response)
      errorHandler(err)
    })
}

interface SignUpRequest {
  email: string
  password: string
  company: string
  firstName: string
  lastName: string
}

export const signUp = (req: SignUpRequest, callback:() => void): void => {
  const contentType = 'application/x-www-form-urlencoded'
  const request = { email: req.email, password: req.password, company: req.company, first_name: req.firstName, last_name: req.lastName }

  new Client('/api/2/members', Config.GUEST_TOKEN)
    .post(request, contentType)
    .then(t => {
      console.log(t)
      callback()
    }, err => {
      console.log(err.response)
      if (err.response.status === 500 && err.response.data.message) {
        Toast.warn(err.response.data.message)
      } else {
        errorHandler(err)
      }
    })
}

export const errorHandler = (err: any): void => {
  console.log(err.response)
  if (err.response && err.response.data) {
    let msg
    if (err.response.data.message) {
      msg = err.response.data.message
    } 

    if (err.response.data.error_description) {
      msg = err.response.data.error_description
    }

    if (err.response.status === 401) {
      if(["/", "/verification", "/resend"].every(path => path !== window.location.pathname)) {
        Toast.warn(Messages.SESSION_EXPIRED)
        window.location.href = '/'
      }
      return
    }

    Toast.notify(msg)
  }
}

export const muteHandler = (err: any): void => {
  console.log(err.response)
  if (err.response) {
    if (err.response.status === 401) {
      window.location.href = '/'
      return
    }
  }
}

export const passwordReset = (email: string, callback:() => void): void => {
  const request = {
    email
  }

  new Client('/api/2/members/password_reset')
    .post(request)
    .then(t => {
      console.log(t)
    callback()
    }, errorHandler)
}

export const verification = (secret: string, callback:() => void, errorCallback:() => void): void => {
  new Client(`/v2/verifications?secret=${secret}`)
    .get()
    .then(t => {
      console.log(t)
      callback()
    }, err => {
      console.log("err", err)
      if ([400, 500].some(status => status === err.response.status)) {
        errorCallback()
      } else {
        errorHandler(err)
      }
    })
}

export const changePassword = (secret: string, password: string, callback:() => void): void => {
  const request = {
    secret,
    password
  }

  new Client(`/api/2/members/change_password?secret=${secret}`)
    .patch('', request)
    .then(t => {
      console.log(t)
      callback()
    }, errorHandler)
}

export const resendVerification = (email: string, callback:() => void, errorCallback:() => void): void => {
  const request = { email }
  new Client(`/api/2/members/email_verification`)
    .post(request)
    .then(t => {
      console.log(t)
      callback()
    }, err => {
      console.log(err.response)
      if (err.response.status === 500) {
        errorCallback()
      } else {
        errorHandler(err)
      }
    })
}

type Member = {
  email: string
  profile_photo_url: string
  first_name: string
  last_name: string
  company: string
  google_login : boolean
}

export class MemberInfo {
  email: string
  company: string
  firstName: string
  lastName: string
  avatarUrl: string
  googleLogin: boolean

  constructor(json: Member) {
    this.email = json.email
    this.company = json.company || ''
    this.firstName = json.first_name || ''
    this.lastName = json.last_name || ''
    this.avatarUrl = json.profile_photo_url || ''
    this.googleLogin = Boolean(json.google_login)
  }
}

export const updateMember = (request: {}, callback:(member: MemberInfo) => void): void => {
  new Client('/api/2/members/me')
    .patch('', request)
    .then(res => {
      console.log(res)
      const member = new MemberInfo(res.data)
      callback(member)
    }, err => {
      console.log(err.response)
      errorHandler(err)
    })
}

export const me = (callback:(member: MemberInfo) => void): void => {
  let member = null
  
  try {
    member = JSON.parse(Store.get(Config.MEMBER_KEY) || "{}")
  } catch(e) {
    console.log(e)
  }

  if(member && member instanceof MemberInfo) {
    callback(member);
  } else {
    new Client('/api/2/members/me')
      .get()
      .then(res => {
        console.log(res)
        const member = new MemberInfo(res.data)
        callback(member)
        if(["/"].some(path => path === window.location.pathname)) {
          history.push('/dashboard')
        }
      }, err => {
        console.log(err.response)
        if(!["/", "/terms_of_service", "/privacy_policy", "/verification", "/resend"].some(path => path === window.location.pathname)) {
          Store.logout();
          history.push('/')
          errorHandler(err)
        }
      })
  }
}
