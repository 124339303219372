import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Home, LiveMonitor, Terms, Privacy, PasswordConfirm, EmailVerification, ResendEmail, Dashboard, VodMonitor, UploadedMonitor, LiveStream } from './pages'
import { ToastContainer } from 'react-toastify';
import { Close } from './assets/svg';


export default class AppRouter extends React.Component {
  public render = (): JSX.Element => {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/streaming" component={LiveStream} />
          <Route exact path="/monitor/:id" component={LiveMonitor} />
          <Route exact path="/vod/:id" component={VodMonitor} />
          <Route exact path="/uploaded/:id" component={UploadedMonitor} />
          <Route exact path="/terms_of_service" component={Terms} />
          <Route exact path="/privacy_policy" component={Privacy} />
          <Route exact path="/password" component={PasswordConfirm} />
          <Route exact path="/verification" component={EmailVerification} />
          <Route exact path="/resend" component={ResendEmail} />
          <Route exact path="/applications/:id" component={Dashboard} />
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/applications" component={Home} />
          <Route exact path="/profile" component={Home} />
          <Route path="/" component={Home} />
        </Switch>
        <ToastContainer closeButton={<Close className="white-svg-icon"/>} />
      </div>
    )
  }
}