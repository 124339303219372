import * as React from 'react'
import { Config } from '../config'
import { actions as appContentActions } from '../modules/application'
import { connect } from 'react-redux'
import { StoreState } from '../modules'
import { bindActionCreators } from 'redux'
import ApplicationTable from './ApplicationTable'
import ApplicationForm from './ApplicationForm'
import { getApplications, ApplicationInfo } from './Applications'
import { PageState, PageInfo, SearchRequest } from '../components/pagination/Paginations'
import Pagination from '../components/pagination/Pagination'
import Modal from '../components/modal/Modal'
import './Application.scss'

interface Props {
  content: number
  demo: string
  actions: typeof appContentActions
}

interface State extends PageState {
  applicationCount: number
  applications: ApplicationInfo[]
  pageRequest : SearchRequest
}

class ApplicationContainer extends React.Component<Props, State> {
  state: State = {
    applicationCount: 0,
    applications: [],
    pageInfo: new PageInfo(null),
    pageRequest: new SearchRequest(0, Config.PAGE_SIZE, ""),
  }

  componentDidMount = () => {
    this.initPage()
    this.getApplicationList()
  }

  getApplicationList = () => {
    getApplications(this.state.pageRequest, this.setList)
  }

  setList = (applications: ApplicationInfo[], pageInfo: PageInfo) => {
    this.setState({ applications, pageInfo })
  }

  createApplication = () => {
    this.props.actions.setContent({ content: 1 })
  }

  searchApplication = (search: string) => {
    this.setState({ pageRequest: new SearchRequest(0, Config.PAGE_SIZE, search)}, () => {getApplications(this.state.pageRequest, this.setList)})
  }

  closeDemo = () => {
    this.props.actions.clearDemo()
  }

  initPage = () => {
    const { pageRequest } = this.state
    pageRequest.page = 0
    this.setState({ pageRequest })
  }

  handlePage = (page: number) => {
    const { pageRequest } = this.state
    pageRequest.page = page
    
    this.setState({ pageRequest })
    this.handleReload(pageRequest)
  }

  handleReload = (pageRequest: SearchRequest) => {
    getApplications(pageRequest, this.setList)
  }

  public render(): JSX.Element {
    console.log(this.props.content)

    return (
      <>
        <Modal
          visible={this.props.content !== 0}
          setVisible={() => {
            this.props.actions.setContent({ content: 0 })
          }}
          render={<ApplicationForm refreshApplication={this.getApplicationList} />}
          title={"Create application"}
        />
        <div className="app-container">
          <ApplicationTable applications={this.state.applications} refreshApplication={this.getApplicationList} searchApplication={this.searchApplication} />
          <div className="application-content mt-50">
            <Pagination page={this.state.pageInfo} handlePage={this.handlePage} />
          </div>
        </div>
      </>
    )
  }
}

export default connect(
  ({ application }: StoreState) => ({
    content: application.content,
    demo: application.demo,
  }),
  (dispatch) => ({
    actions: bindActionCreators(appContentActions, dispatch),
  }),
)(ApplicationContainer)
