import * as React from 'react'
import * as ReactDOM from 'react-dom'
import '../node_modules/video.js/dist/video-js.css'
import './index.css'
import { unregister } from './registerServiceWorker'
import { Router } from 'react-router-dom'
import AppRouter from './AppRouter'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import configureConnection from './connection/ConfigureConnection'

if (process.env.NODE_ENV === 'production') {
  console.log = () => ({})
  console.error = () => ({})
  console.debug = () => ({})
}

const store = configureConnection()
export const history = createBrowserHistory()

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <AppRouter />
    </Router>
  </Provider>,
  document.getElementById('root') as HTMLElement,
)

unregister()
