import { createAction, handleActions, Action } from 'redux-actions'
import { produce } from 'immer';

const SET_CONTENT = 'content/SET_CONTENT'

interface ContentPayload {
  content: number
}

export const actions = {
  setContent: createAction<ContentPayload>(SET_CONTENT)
}

export interface ContentState {
  content: number
}

const initialState: ContentState = {
  content: 0
}

export default handleActions<ContentState, unknown> ({
  [SET_CONTENT]: (state, action: Action<ContentPayload>) => produce(state, draft => { draft.content = action.payload.content })
}, initialState)