import * as React from 'react';
import DashboardContent from './OverviewContent';
import VideoContent from './VideoContent';
import UserContent from '../user/UserContent';
import BlockedUserContent from '../user/BlockedUserContent';
import { ApplicationInfo } from '../application/Applications';
import { connect } from 'react-redux';
import { StoreState } from '../modules';
import DeletedVideoContent from './DeletedVideoContent';
import BlockedVideoContent from './BlockedVideoContent';
import "./DashboardContainer.scss"
import { actions as applicationActions } from '../modules/application'
import { bindActionCreators } from 'redux'
import WebinarOverviewContent from '../webinar/WebinarOverviewContent';
import StreamKeyContent from '../webinar/StreamKeyContent';
import SettingsContent from '../settings/SettingsContent';
import DashboardHeader from '../components/DashboardHeader';
import Footer from '../components/Footer';
import { AppUserInfo, getAppUser } from '../settings/Settings';
import { OverviewNor, Settings, UserNor, VideoSel, Webinar } from '../assets/svg';


interface Props {
  app: ApplicationInfo
  actions: typeof applicationActions
}

interface State {  
  content: number
}

interface MenuState {
  current: number
  index: number
  icon: React.ReactNode
  label: string
  onClick(): void
}

const ListMenuItem: React.FunctionComponent<MenuState> = ({ current, icon, label, index, onClick }) => (
  <>
    <li className={"list-group-item" + (current === index ? ' active' : '')} onClick={onClick} data-toggle="collapse" data-target="#noMenu" aria-expanded="false" aria-controls="noMenu">{icon} {label}</li>
    <div className='collapse' id="noMenu" data-parent="#dashboardMenu" />
  </>
)

class DashboardContainer extends React.Component<Props, State> {
  state: State = {
    content: 0
  }

  componentDidMount = (): void => {
    console.log("this.props.app", this.props.app)
    if (!this.props.app) {
      this.loadApplication()
    }
  }

  loadApplication = (): void => {
    const loaded = localStorage.getItem('app')

    if (loaded) {
      const app: ApplicationInfo = JSON.parse(loaded)
      this.props.actions.setApplication(app)
      getAppUser(app.appKey, (appUser: AppUserInfo) => this.props.actions.setAppUser(appUser))
    } else {
      window.location.href = '/'
    }
  }

  onClick = (content: number): void => {
    this.setState({ content })
  }

  onClickVideoChildren = (): void => {
    this.onClick(11)
  }

  onClickDemoChildren = (): void => {
    this.onClick(21)
  }

  onClickUserChildren = (): void => {
    this.onClick(21)
  }

  onClickWebinarChildren = () => {
    this.onClick(31)
  }

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    if( nextProps.app !== this.props.app) {
      getAppUser(nextProps.app.appKey, (appUser: AppUserInfo) => this.props.actions.setAppUser(appUser))
      window.scrollTo({top: 0})
      return true
    }
    else if(nextState.content !== this.state.content) {
      window.scrollTo({top: 0})
      return true
    }
    return false
  }

  public render = (): JSX.Element | null => {
    const { app } = this.props
    const { content } = this.state

    if (!app) {
      return null
    }
    
    return (
      <div className="container-fluid">
        <DashboardHeader />
        <div className="app-body">
          <div className="content-container">
            <div className="dashboard-container row">
              <div className="submenu-group">
                <h3 className="review-menu-title">Dashboard</h3>
                <ul className="list-group review-menu" id="dashboardMenu">
                  <ListMenuItem current={content} icon={<OverviewNor className={content === 0 ? " active" : " "}/> }
                  index={0} label={'Overview'} onClick={this.onClick.bind(this, 0)} />
                  <li className={'list-group-item ' + (content >= 11 && content <= 13 ? 'text-bold' : '')} data-toggle="collapse" data-target="#videoMenuGroup" aria-expanded="false" aria-controls="videoMenuGroup"
                    onClick={this.onClickVideoChildren}><VideoSel className={content >= 11 && content <= 13 ? "active" : " "}/>Videos</li>
                  <div className='collapse' id="videoMenuGroup" data-parent="#dashboardMenu">
                    <ul className="list-group sub-menu">
                      <li className={'list-group-item' + (content === 11 ? ' active' : '')} onClick={this.onClick.bind(this, 11)}>
                        Videos</li>
                      <li className={'list-group-item' + (content === 12 ? ' active' : '')} onClick={this.onClick.bind(this, 12)}>Deleted Videos</li>
                      <li className={'list-group-item' + (content === 13 ? ' active' : '')} onClick={this.onClick.bind(this, 13)}>Blocked Videos</li>
                    </ul>
                  </div>
                  <li className={'list-group-item ' + (content >= 21 && content <= 22 ? 'text-bold' : '')} data-toggle="collapse" data-target="#userGroup" aria-expanded="false" aria-controls="userGroup"
                    onClick={this.onClickUserChildren}><UserNor className={content >= 21 && content <= 22 ? "user-icon active" : " "}/>Users</li>
                  <div className='collapse' id="userGroup" data-parent="#dashboardMenu">
                    <ul className="list-group sub-menu">
                      <li className={'list-group-item' + (content === 21 ? ' active' : '')} onClick={this.onClick.bind(this, 21)}>
                        Users</li>
                      <li className={'list-group-item' + (content === 22 ? ' active' : '')} onClick={this.onClick.bind(this, 22)}>Blocked User</li>
                    </ul>
                  </div>
                  <li className={'list-group-item ' + (content >= 31 && content <= 43 ? 'text-bold' : '')} data-toggle="collapse" data-target="#webinarGroup" aria-expanded="false" aria-controls="webinarGroup"
                    onClick={this.onClickWebinarChildren}><Webinar className={content >= 31 && content <= 43 ? "active" : " "}/>Webinar</li>
                  <div className='collapse' id="webinarGroup" data-parent="#dashboardMenu">
                    <ul className="list-group sub-menu">
                      <li className={'list-group-item' + (content === 31 ? ' active' : '')} onClick={this.onClick.bind(this, 31)}>Overview</li>
                      <li className={'list-group-item' + (content === 32 ? ' active' : '')} onClick={this.onClick.bind(this, 32)}>Stream key</li>
                    </ul>
                  </div>
                  <ListMenuItem current={content} icon={<Settings className={content === 90 ? " active" : " "} />} index={90} label={'Settings'} onClick={this.onClick.bind(this, 90)} />
                </ul>
              </div>
              { 
                content === 11 ? <VideoContent /> :
                content === 12 ? <DeletedVideoContent /> :
                content === 13 ? <BlockedVideoContent /> :
                content === 21 ? <UserContent />  :
                content === 22 ? <BlockedUserContent />  :
                content === 31 ? <WebinarOverviewContent /> :
                content === 32 ? <StreamKeyContent /> :
                content === 90 ? <SettingsContent /> :
                <DashboardContent />
              }
            </div>
          </div>
        </div>
        <Footer />
      </div>
  )}
} 

export default connect(
	({ application } : StoreState) => ({
    app: application.application,
  }),
	(dispatch) => ({
    actions: bindActionCreators(applicationActions, dispatch),
  })
)(DashboardContainer)
