import * as React from 'react'

interface Props {
  className?: string
}

export default function Settings({ className }:Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-175 -1018) translate(175 1018)">
            <g fillRule="nonzero">
                <path d="M0 0H20V20H0z"/>
            </g>
            <path fill="#C4C4C4" d="M11.982 2l.79 3.195 3.165-.912 1.982 3.434-2.371 2.282 2.371 2.284-1.982 3.434-3.163-.912L11.982 18H8.018l-.792-3.195-3.163.912-1.982-3.434L4.45 10l-2.37-2.282 1.982-3.434 3.163.911L8.018 2h3.964z" className={className} />
            <circle cx="10" cy="10" r="2" fill="#FFF" />
          </g>
        </g>
      </g>
    </svg>  

  )
}
