import * as React from 'react'
import { Config } from '../config'
import { ViewerInfo } from './Watchers'

interface Props {
  viewer: ViewerInfo
}

class ViewerItem extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { viewer } = this.props
    return <div className="list-group-item">
        <div className="bmd-list-group-col">
          <div className="list-group-item-text">
            <div className="message-item">
              <img className="avatar-thumbnail" src={viewer.profilePhotoUrl || Config.DEFAULT_AVATAR} />
              {viewer.userId ? 
              <div className="message-user text-ellipsis">{viewer.username || viewer.id}</div> : 
              <div className="message-user">unknown id</div> }
            </div>
            <span className="from-now">{viewer.updatedAt}</span>
          </div>
        </div>
      </div>
  }
}


export default ViewerItem