import * as React from 'react'

export default function Locked(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="32" viewBox="0 0 77 32">
      <defs>
        <filter id="ln0xmb5uga" width="124.6%" height="180%" x="-12.3%" y="-40%" filterUnits="objectBoundingBox">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g filter="url(#ln0xmb5uga)" transform="translate(-754 -288) translate(760 294)">
            <path fill="#000" fillOpacity=".8" stroke="#000" strokeOpacity=".8" d="M55 .5c2.623 0 4.998 1.063 6.718 2.782C63.437 5.002 64.5 7.377 64.5 10s-1.063 4.998-2.782 6.718C59.998 18.437 57.623 19.5 55 19.5h0-45c-2.623 0-4.998-1.063-6.718-2.782C1.563 14.998.5 12.623.5 10s1.063-4.998 2.782-6.718C5.002 1.563 7.377.5 10 .5h0z" />
            <text fill="#FFF" fontFamily="Roboto-Bold, Roboto" fontSize="10" fontWeight="bold">
              <tspan x="22" y="14">
                Locked
              </tspan>
            </text>
            <g>
              <path d="M0 0H10V10H0z" transform="translate(10 5)" />
              <path fill="#FFF" d="M5 2c1.054 0 1.918.816 1.995 1.85L7 4c.552 0 1 .448 1 1v1.5C8 7.88 6.88 9 5.5 9h-1C3.12 9 2 7.88 2 6.5V5c0-.552.448-1 1-1 0-1.105.895-2 2-2zm0 1c-.513 0-.936.386-.993.883L4 4h2c0-.552-.448-1-1-1z" transform="translate(10 5)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
