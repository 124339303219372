import * as React from 'react'

export default function Add(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path d="M0 0H18V18H0z" transform="translate(-1482 -504) translate(390 474) translate(1022 24) translate(70 6)"/>
                <path fill="#FFF" d="M9 4c.552 0 1 .448 1 1v2.999L13 8c.552 0 1 .448 1 1s-.448 1-1 1l-3-.001V13c0 .552-.448 1-1 1s-1-.448-1-1V9.999L5 10c-.552 0-1-.448-1-1s.448-1 1-1l3-.001V5c0-.552.448-1 1-1z" transform="translate(-1482 -504) translate(390 474) translate(1022 24) translate(70 6)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}