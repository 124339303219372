import * as React from 'react'
import { FormBullet } from '../assets/svg'
import Button from '../components/button/Button'
import Modal from '../components/modal/Modal'
import ChangePasswordForm from './ChangePasswordForm'


interface Props {
  member: any
  onChange(profile: any): void
}

interface State {
  confirm: boolean
}

class PasswordItem extends React.Component<Props, State> {
  state: State = {
    confirm: false
  }

  setConfirm = (confirm: boolean): void => {
    this.setState({ confirm })
  }

  onOk = (member: {}): void => {
    console.log(member)
    this.props.onChange(member)
    this.setConfirm(false)
  }

  public render = (): JSX.Element => {
    const labelText = 'Password'
    const { confirm } = this.state

    return (
      <div className="settings col-xl-12">
        <div className="settings-header">
          <h5 className="card-title">Security</h5>
        </div>
        <div className="card settings-item">
          <div className="card-body prohibit-body">
            <div className="setting-input-group">
              <label htmlFor={labelText}>
                <FormBullet /> <span>{labelText}</span>
              </label>
            </div>
            <div className="rule-group">
              <Button size="medium" color="blue" onClick={() => this.setConfirm(true)}>Change Password</Button>
            </div>
          </div>
        </div>
        <Modal
          visible={confirm}
          setVisible={() => this.setConfirm(false)}
          render={<ChangePasswordForm member={this.props.member} onCancel={() => this.setConfirm(false)} onOk={this.onOk}/>}
          title="Change Password"
        />
      </div>
    )
  }
}

export default PasswordItem