import * as React from 'react'

interface Props {
  className?: string
}

export default function Webinar({ className }: Props ): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path fillRule="nonzero" d="M0 0H20V20H0z" transform="translate(-400 -369) translate(390 127) translate(0 232) translate(10 10)"/>
                <path fill="#C4C4C4" d="M5 3h10c1.105 0 2 .895 2 2v7c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V5c0-1.105.895-2 2-2z" transform="translate(-400 -369) translate(390 127) translate(0 232) translate(10 10)" className={className} />
                <path fill="#FFF" d="M10.5 6L13 11 8 11z" transform="translate(-400 -369) translate(390 127) translate(0 232) translate(10 10) rotate(90 10.5 8.5)"/>
                <path fill="#C4C4C4" d="M7 15h6c.552 0 1 .448 1 1s-.448 1-1 1H7c-.552 0-1-.448-1-1s.448-1 1-1z" transform="translate(-400 -369) translate(390 127) translate(0 232) translate(10 10)" className={className}/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
