import * as React from 'react';
import { CheckBox } from '../components/CheckBox';
import { actions as webinarActions } from '../modules/webinar'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../modules';
import { StreamKeyInfo } from './Webinars';
import { Config, Messages } from '../config';
import StreamKeyItem from './StreamKeyItem';
import Button from '../components/button/Button';
import Modal from '../components/modal/Modal';
import StreamKeyForm from './StreamKeyForm';
import { NoContent, Add } from '../assets/svg';


interface Props {
  list: StreamKeyInfo[]
  checkList: StreamKeyInfo[]
  checkedAll: boolean
  actions: typeof webinarActions
  refresh(): void
}

interface State {
  content: number
}

class StreamKeyTable extends React.Component<Props, State> {
  state: State = {
    content: 0
  }

  checkedAll = (): void => {
    const { checkedAll, actions } = this.props
    console.log(`${checkedAll}`)

    if (!checkedAll) {
      actions.checkedAll()
    } else {
      actions.uncheckedAll()
    }
  }

  createStreamKey = (): void => {
    this.setState({ content: 1 })
  }

  setContent = (content: number): void => {
    this.setState({ content })
  }

  refreshAndClose = (): void => {
    this.props.refresh()
    this.setState({ content: 0 })
  }

  onClose = (): void => {
    this.setState({ content: 0 })
  }

  public render = (): JSX.Element => {
    const { list, checkList, checkedAll } = this.props
    const { content } = this.state
    
    const streamKeys = list.map((s, index) => (
      <StreamKeyItem key={index} streamKey={s} checked={checkList.findIndex(c => c.id === s.id) >= 0} refresh={this.props.refresh} />
    ))

    return (
      <>
      <div className="stream-content">
        <div className="title-bar">
          <div className="title-group">
            <p className="title">{`${Messages.STREAMKEY_TITLE}`}</p>
            <p className="desc">{`${Messages.STREAMKEY_DESC} ${list.length} ${list.length > 1 ? 'Stream keys' : 'stream key'}`}</p>
          </div>
          <div className="tool-bar">
            <Button size="create" color="red" onClick={this.createStreamKey} disabled={list.length > Config.MAX_OWN_STREAMKEY - 1}>
              <span>Create</span> <Add />
            </Button>
          </div>
        </div>
        <table className="table table-hover stream-table">
          <thead>
            <tr>
              <th scope="col" className="w-5">
                <CheckBox id={'all'} value={'all'} onChange={this.checkedAll} checked={checkedAll} />
              </th>
              <th scope="col" className="w-20">Nickname</th>
              <th scope="col" className="w-20">Stream Key</th>
              <th scope="col" className="w-12">State</th>
              <th scope="col" className="w-18">Created</th>
              <th scope="col" className="w-5"></th>
            </tr>
          </thead>
          <tbody>
          { streamKeys }
          </tbody>
        </table>
        { streamKeys.length === 0 ?
          <div className="no-content">
            <NoContent />
            <span>{ Messages.NO_STREAM_KEYS }</span>
          </div> : ''}
      </div>
      <Modal
          visible={content === 1}
          setVisible={this.onClose}
          render={<StreamKeyForm refresh={this.refreshAndClose} onCancel={this.onClose}/>}
          title="Create Stream Key"
        />
      </>
    )
  }
} 

export default connect(
	({ webinar } : StoreState) => ({
    list: webinar.list,
    checkList: webinar.checkList,
    checkedAll: webinar.checkedAll,
    page: webinar.page,
    pageRequest: webinar.pageRequest
	}),
	(dispatch) => ({
    actions: bindActionCreators(webinarActions, dispatch)
  })
)(StreamKeyTable)
