import * as React from 'react'

interface Props {
  className?: string
}

export default function Block({ className }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero" className={className}>
          <g>
            <g>
              <g>
                <g>
                  <path d="M12 4c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 2c-3.314 0-6 2.686-6 6s2.686 6 6 6 6-2.686 6-6-2.686-6-6-6zm3 5c.552 0 1 .448 1 1s-.448 1-1 1H9c-.552 0-1-.448-1-1s.448-1 1-1h6z" transform="translate(-397 -663) translate(41 510) translate(30 125) translate(306 20) translate(20 8)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
