import React from 'react'
import { MessageInfo, SystemMessageType } from '../review/Messages';
import ChatItem from './ChatItem'

interface Props {
  messages: MessageInfo[]
  loadMoreChat(): void
}

class ChatList extends React.Component<Props> {
  scroller: any
  
  addScrollEvent = (callback: () => void): void => {
    const scroller: HTMLElement | null = document.querySelector('.monitor-chat');
    if(scroller) {
      
      scroller.onscroll = () => {
        if(scroller.scrollTop === 0) {
          callback()
        }
      }
    }
  }

  componentDidMount (): void {
    this.addScrollEvent(this.props.loadMoreChat)
  }

  render(): JSX.Element {
    const { messages } = this.props
    const empty = messages.length === 0
    
    let items
    
    if (this.props.messages.length > 0) {
      items = this.props.messages.map((m, index) => (
        <ChatItem key={index} message={m} />
      ))
    }

    return (
      <div className="monitor-chat-group vod-chat-group">
        <div className={"monitor-chat " + (messages.length < 1 ? "empty" : "")}>
          <div id="message-scroller" ref={ scroller => this.scroller = scroller } className="list-group chat-group">
            { !empty && items }
          </div>
        </div>
      </div>
    )
  }
}


export default ChatList