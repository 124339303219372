import * as React from 'react'

export default function DirectMessage(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path d="M20.856 5L11 19.928 8.732 12 3 6.072l15.622-.938h.002L20.856 5zm-5.105 4.102l-4.95 2.858.927 3.236 4.023-6.094zm-1-1.733l-7.29.438 2.339 2.42 4.951-2.858z" transform="translate(-512 -663) translate(41 510) translate(30 125) translate(421 20) translate(20 8)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
