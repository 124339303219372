import * as React from 'react';
import { Messages } from '../config'
import "./ProgressBar.scss"

interface Props {
  percent: number | string
  style?: React.CSSProperties
}

class ProgressBar extends React.Component<Props> {

  public render = (): JSX.Element | null => {
    const { percent } = this.props;
    const barStyle = {
      width: percent + '%'
    };

    if (percent === 0) {
      return null;
    }

    return (
      <div className="container" style={this.props.style}>
        <h6 className="progress-text">{`${this.props.percent}%`}</h6>
        <div className="progress">
          <div className="progress-bar" role="progressbar" style={barStyle} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
        </div>
        <div className="progress-description">{Messages.PROGRESS_BAR_DESC}</div>
      </div>
    )
  }
}

export default ProgressBar;