import * as React from 'react';
import { ReviewInfo, getReviews, getAllVideos, getReviewsByType, deleteVideo, blockVideo } from './Reviews';
import Pagination from '../components/pagination/Pagination';
import { PageState, PageInfo, FilterRequest } from '../components/pagination/Paginations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../modules';
import { actions as reviewActions } from '../modules/review'
import { ApplicationInfo } from '../application/Applications';
import BreadCrumb from '../components/BreadCrumb';
import { NavItem } from '../components/Navigation';
import "./DashboardContent.scss"
import Button from '../components/button/Button';
import ReviewList from './ReviewList';
import Modal from '../components/modal/Modal';
import UploadModalContent from './UploadModalContent';
import Dropdown from '../components/dropdown/Dropdown';
import SearchInput from '../components/input/SearchInput';
import Toast from '../components/Toast'
import { PlayOutlined, UploadIcon } from '../assets/svg';
import { openNewPage } from '../config/browser';

enum ModalStateCode {
  Upload = 1
}

interface Props extends PageState {
  app: ApplicationInfo
  list: ReviewInfo[]
  actions: typeof reviewActions
  pageRequest: FilterRequest
  uploadContent: number
}

interface State {
  stage: number
  totalElementes: number[]
  visibleModal: ModalStateCode
  search: string
  sort: string
}

export const padNumber = (number: number): string => {
  return (number < 10 ? `0${number}` : `${number}`)
}

class VideoContent extends React.Component<Props, State> {
  state: State = {
    stage: 0,
    totalElementes: [0, 0, 0],
    visibleModal: 0,
    search: '',
    sort: "Latest"
  }

  sortOptions = [
    {
      value: 'View',
      onClick: () => {
        this.setState({ sort: "View" })
        this.props.pageRequest.sort = 'viewCount'
        getAllVideos(this.props.app.appKey, this.props.pageRequest, this.setListTotalElements)
      },
    },
    {
      value: 'Latest',
      onClick: () => {
        this.setState({ sort: "Latest" })
        this.props.pageRequest.sort = 'createdAt'
        getAllVideos(this.props.app.appKey, this.props.pageRequest, this.setListTotalElements)
      },
    },
  ]

  initPage() {
    const { pageRequest } = this.props
    pageRequest.page = 0
    pageRequest.direction = 0
    pageRequest.search = ""
    pageRequest.sort = "createdAt"
    
    this.props.actions.setPageRequest(pageRequest)
    this.setState({ stage: 0, totalElementes: [0, 0, 0], visibleModal: 0, search: '', sort: 'Latest' })
  }

  onChangeSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    if (value === ' ') {
      return
    }

    this.setState({ search: value })
  }

  search = () => {
    this.props.pageRequest.search = this.state.search
    getAllVideos(this.props.app.appKey, this.props.pageRequest, this.setListTotalElements)
  }

  toggleModalGenerator = (modalCode: ModalStateCode) => () => {
    if(this.state.visibleModal === modalCode) {
      this.setState({visibleModal: 0})
    } else {
      this.setState({visibleModal: modalCode})
    }
  }

  toggleUpload = this.toggleModalGenerator(ModalStateCode.Upload)

  handlePage = (page: number) => {
    const pageRequest = this.props.pageRequest
    pageRequest.page = page
    pageRequest.search = this.state.search

    this.props.actions.setPageRequest(pageRequest)

    this.handleReload(this.state.stage, pageRequest);
  }

  handleStage = (stage: number) => {
    this.setState({ stage });

    this.handleReload(stage, this.props.pageRequest);
  }

  handleReload = (stage: number, pageRequest: FilterRequest) => {
    switch(stage) {
      case 1:
        getReviewsByType(this.props.app.appKey, 'BROADCASTED', pageRequest, this.setList)
        break
      case 2:
        getReviewsByType(this.props.app.appKey, 'UPLOADED', pageRequest, this.setList)
        break
      default:
        getReviews(this.props.app.appKey, pageRequest, this.setList)  
    }
  }

  setList = (list: ReviewInfo[], page: PageInfo) => {
    this.props.actions.setList({ list, page });

    const { stage, totalElementes } = this.state
    totalElementes[stage] = this.props.pageInfo.totalElementes
    this.setState({ totalElementes })
  }

  setListTotalElements = (list: ReviewInfo[], page: PageInfo, totalElementes: number[]) => {
    this.props.actions.setList({ list, page });
    this.setState({ totalElementes })
  }

  refreshCurrentPage = () => {
    this.handleReload(this.state.stage, this.props.pageRequest);
  }

  onDelete = (videoKey: string) => {
    deleteVideo(this.props.app.appKey, videoKey)
      .then(res => {
        console.log(res)
        Toast.notify('Successfully deleted')

        this.refreshCurrentPage()
      }, err => {
        if (err) {
          Toast.warn('Failed to delete video')
        }
      });
  }
  
  onBlock = (review: ReviewInfo) => {
    blockVideo(this.props.app.appKey, review)
      .then(res => {
        console.log(res)
        Toast.notify('Successfully blocked')
        
        this.refreshCurrentPage()
      }, err => {
        if (err) {
          Toast.warn('Failed to block video')
        }
      });
  }

  onLive = () => {
    openNewPage(`/streaming`);
  }

  closeUploader = () => {
    this.toggleUpload()
  }

  closeUploaderAndRefresh = () => {
    this.closeUploader()
    getAllVideos(this.props.app.appKey, this.props.pageRequest, this.setListTotalElements)
  }

  componentDidMount = () => {
    this.initPage();
    getAllVideos(this.props.app.appKey, this.props.pageRequest, this.setListTotalElements)
  }
  
  shouldComponentUpdate(nextProps: Props): boolean {
    if (nextProps.app.appKey !== this.props.app.appKey) {
      this.initPage()
      getAllVideos(nextProps.app.appKey, nextProps.pageRequest, this.setListTotalElements)
      return true
    }
    return true
  }

  public render() {
    const { uploadContent } = this.props

    return (
      <div className="dashboard-content col-xl-10">
        <BreadCrumb names={['Dashboard', 'Videos']} />
        <h5 className="content-title">Videos</h5>
        <div className="tab-group">
          <ul className="nav nav-pills">
            <NavItem stage={this.state.stage} value={0} label={"All (" + padNumber(this.state.totalElementes[0]) + ")"} handleChange={this.handleStage.bind(this, 0)} />
            <NavItem stage={this.state.stage} value={1} label={"Broadcasted (" + padNumber(this.state.totalElementes[1]) + ")"} handleChange={this.handleStage.bind(this, 1)} />
            <NavItem stage={this.state.stage} value={2} label={"Uploaded (" + padNumber(this.state.totalElementes[2]) + ")"} handleChange={this.handleStage.bind(this, 2)} />
          </ul>
        </div>
        <div className="content-toolbar">
          <Dropdown>
            <Dropdown.Button toggleIcon><span>{this.state.sort}</span></Dropdown.Button>
            <Dropdown.Items options={this.sortOptions} />
          </Dropdown>
          <SearchInput label="Search Video" value={this.state.search} onChange={this.onChangeSearch} onSearch={this.search} maxLength={50} />
          <div className="tool-divider" />
          <div className="content-toolbar-btn-group">
            <Button size="medium" color="upload" onClick={this.onLive}><PlayOutlined /><span>Start Live</span></Button>
            <Button size="medium" color="upload" onClick={this.toggleUpload}><UploadIcon /><span>Upload</span></Button>
          </div>
        </div>
        <div className="row review-group">
          <ReviewList list={this.props.list} onBlock={this.onBlock} onDelete={this.onDelete} />
        </div>
        <div className="mt-50">
          <Pagination page={this.props.pageInfo} handlePage={this.handlePage}/>
        </div>
        <Modal title="Upload"  visible={this.state.visibleModal === ModalStateCode.Upload} setVisible={this.toggleUpload} okButtonTitle="Upload" disabled={uploadContent} render={<UploadModalContent appKey={this.props.app.appKey} appSecret={this.props.app.appSecret} onClose={this.closeUploader} onCloseAndRefresh={this.closeUploaderAndRefresh}/>} />
      </div>
    )
  }
}

export default connect(
	({ application, review, upload } : StoreState) => ({
    app: application.application,
    list: review.list,
    pageInfo: review.page,
    pageRequest: review.pageRequest,
    uploadContent: upload.content
	}),
	(dispatch) => ({
    actions: bindActionCreators(reviewActions, dispatch)
  })
)(VideoContent)