import * as React from 'react'

interface Props {
  className?: string
}
export default function UserNor({ className }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path fillRule="nonzero" d="M0 0H20V20H0z" transform="translate(-400 -339) translate(390 179) translate(0 150) translate(10 10)" />
                <path
                  fill="#C4C4C4"
                  d="M10 3c2.376 0 3.384 1.89 3.283 3.943.18-.07.39-.06.57.045.32.184.429.591.245.91l-.334.578c-.172.298-.54.413-.848.275-.4 1.165-.902 1.973-1.415 2.492L11.5 12h.5c2.689 0 4.882 2.122 4.995 4.783L17 17H3c0-2.761 2.239-5 5-5h.5v-.807c-.476-.52-.95-1.307-1.389-2.407-.282.075-.591-.045-.744-.31l-.334-.578c-.184-.319-.075-.726.244-.91.12-.07.254-.098.383-.088C6.545 4.884 7.62 3 10 3z"
                  transform="translate(-400 -339) translate(390 179) translate(0 150) translate(10 10)"
                  className={className}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
