import React, { useEffect, useState } from 'react';
import { Block, BlackNoContent } from '../assets/svg';
import { Config } from '../config';
import { ViewerInfo } from '../review/Watchers';
import "./ViewerList.scss";

interface ViewerItemProps {
  item: ViewerInfo
  onBlock(id: string): void 
  onUnBlock(id: string): void
  handleReload(): void
}

function ViewerItem ({ item, onBlock, onUnBlock, handleReload } : ViewerItemProps) {
  const handleBlock = () => {
    item.block ? onUnBlock(item.userId) : onBlock(item.userId)
    handleReload()
  }
  
  return (
    <div className="viewer-info-group">
      <div className="user-content">
        <img className="user-avatar" src={item.profilePhotoUrl || Config.DEFAULT_AVATAR} alt="user_avatar" />
        <span>{item.username}</span>
      </div>
      <div className="viewer-button-group">
        <button type="button" onClick={handleBlock}><Block className={item.block ? "block_icon" : "unblock_icon"}/><span className={item.block ? "block_content" : "unblock_content"}>{item.block ? "차단 해제" : "차단"}</span></button>
      </div>
    </div>
  )
}

interface Props {
  list: ViewerInfo[]
  onBlock(id: string): void 
  onUnBlock(id: string): void
  handleReload(): void
}

export default function ViewerList({ list, onBlock, onUnBlock, handleReload }: Props): JSX.Element {
  const [users, setUsers] = useState(list)

  useEffect(() => {
    setUsers(list)
  }, [users, list])

  return (
    <div className="viewer-list-group">
      {list.length > 0 ? list.map((item, i) => 
        <ViewerItem key={`${item.userId}${i}`} item={item} onBlock={onBlock} onUnBlock={onUnBlock} handleReload={handleReload}/>
      ) :
      <div className="no_contents"><BlackNoContent /><span>찾으시는 참여자를 검색해주세요.</span></div>}
    </div>
  )
}