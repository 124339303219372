import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../modules';
import { actions as userActions } from '../modules/user'
import './Webinar.scss'
import TextInput from '../components/input/TextInput';
import { Config } from '../config'
import { FormBullet, CopyIcon } from '../assets/svg';
import Toast from '../components/Toast'


interface Props {
  streamKey: string
}

class ObsSettingContent extends React.Component<Props> {

  onCopyUrl = (e: React.MouseEvent<SVGElement>) => {
    const inputUrl: any = document.getElementById('inputUrl')
    console.log(inputUrl)

    if (inputUrl) {
      inputUrl.select()
      document.execCommand('copy')
      e.currentTarget.focus();
  
      Toast.notify('It has been copied to the clipboard.');
    } else {
      Toast.notify('No rtmp key');
    }
  }

  onCopyStreamKey = (e: React.MouseEvent<SVGElement>) => {
    const inputStream: any = document.getElementById('inputStream')
    console.log(inputStream)

    if (inputStream) {
      inputStream.select()

      document.execCommand('copy')
      e.currentTarget.focus();
  
      Toast.notify('It has been copied to the clipboard.');
    } else {
      Toast.notify('No stream key');
    }
  }

  public render = (): JSX.Element => {
    const { streamKey } = this.props
    return (
      <div className="obs-settings-content">
        <p>OBS 스튜디오에서 이 주소를 사용하세요</p>
        <div className="copy-link-group">
          <span className="label"><FormBullet />RTMP</span>
          <TextInput id="inputUrl" name="appKey" value={Config.RTMP_HOST} readOnly />
          <CopyIcon className="copy-icon" onClick={this.onCopyUrl} />
        </div>
        <div className="copy-link-group">
          <span className="label"><FormBullet />Stream Key</span>
          <TextInput id="inputStream" name="streamKey" value={streamKey} readOnly />
          <CopyIcon className="copy-icon" onClick={this.onCopyStreamKey}/>
        </div>
      </div>
    )
  }
}

export default connect(
	({ application, user } : StoreState) => ({
    app: application.application,
    list: user.list,
    pageInfo: user.page,
    pageRequest: user.pageRequest
	}),
	(dispatch) => ({
    actions: bindActionCreators(userActions, dispatch)
  })
)(ObsSettingContent)