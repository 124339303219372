import * as React from 'react'

export default function UploadIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <defs>
        <filter id="g9d8ljbmaa">
          <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.976471 0 0 0 0 0.388235 0 0 0 0 0.003922 0 0 0 1.000000 0" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g filter="url(#g9d8ljbmaa)" transform="translate(-1437 -232) translate(913 219) translate(496)">
              <g>
                <path d="M0 0H14V14H0z" transform="translate(28 13)" />
                <path fill="#888" d="M7 5c.552 0 1 .448 1 1v2h1l-2 3-2-3h1V6c0-.552.448-1 1-1zm4-3v3.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5V3H4v2.5c0 .276-.224.5-.5.5S3 5.776 3 5.5V2h8z" transform="translate(28 13) rotate(-180 7 6.5)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
