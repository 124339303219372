import React from 'react'

interface Props {
  className?: string
}
export default function Download({ className }: Props): JSX.Element {
  switch (className) {
    case 'white-svg-icon':
      return (
        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="agggg">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
            </filter>
          </defs>
          <g transform="translate(-21 -13)" filter="url(#agggg)" fill="none" fillRule="evenodd">
            <path d="M21 13h14v14H21z" />
            <path d="m28 21-2-3h1v-2a1 1 0 0 1 2 0v2h1l-2 3zm-4 3v-3.5a.5.5 0 1 1 1 0V23h6v-2.5a.5.5 0 0 1 1 0V24h-8z" fill="#888" />
          </g>
        </svg>
      )
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <defs>
            <filter id="1kmxbi2ova">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.976471 0 0 0 0 0.388235 0 0 0 0 0.003922 0 0 0 1.000000 0" />
            </filter>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <g>
                  <g>
                    <g filter="url(#1kmxbi2ova)" transform="translate(-1592 -513) translate(1273 70) translate(30 210) translate(0 210) translate(273 10)">
                      <g>
                        <path d="M0 0H14V14H0z" transform="translate(16 13)" />
                        <path fill="#888" d="M7 5l2 3H8v2c0 .552-.448 1-1 1s-1-.448-1-1V8H5l2-3zm4-3v3.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5V3H4v2.5c0 .276-.224.5-.5.5S3 5.776 3 5.5V2h8z" transform="translate(16 13) rotate(-180 7 6.5)" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      )
  }
}
