import * as React from 'react'

export default function NoContent(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="101" height="101" viewBox="0 0 101 101">
      <defs>
        <linearGradient id="v9u5ujhpoa" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#FFD110" />
          <stop offset="100%" stopColor="#FFBD1F" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H100V100H0z" transform="translate(-1019 -359) translate(610 279) translate(410 80)" />
              <g fill="url(#v9u5ujhpoa)" stroke="#FFF" strokeWidth="2" transform="translate(-1019 -359) translate(610 279) translate(410 80) rotate(45 11.322 53.278)">
                <path
                  d="M36-9c7.732 0 14.732 3.134 19.799 8.201S64 11.268 64 19c0 7.357-2.837 14.05-7.477 19.048-4.365 4.701-10.327 7.9-17.022 8.735l-.503.058L39 61c0 .828-.336 1.578-.879 2.121-.543.543-1.293.879-2.121.879-.828 0-1.578-.336-2.121-.879-.498-.497-.822-1.169-.872-1.916L33 61V46.91c-6.903-.77-13.057-4.03-17.532-8.863C10.833 33.042 8 26.352 8 19c0-7.732 3.134-14.732 8.201-19.799S28.268-9 36-9zm0 6C29.925-3 24.425-.538 20.444 3.444 16.462 7.424 14 12.924 14 19c0 6.075 2.462 11.575 6.444 15.556C24.424 38.538 29.924 41 36 41c6.075 0 11.575-2.462 15.556-6.444C55.538 30.576 58 25.076 58 19c0-6.075-2.462-11.575-6.444-15.556C47.576-.538 42.076-3 36-3z"
                  transform="rotate(-90 36 27.5)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
