import * as React from 'react'
import { useState, useEffect } from 'react';
import { ApplicationInfo } from '../application/Applications';
import MultiLineChart from '../components/chart/MultiLineChart'
import Dropdown from '../components/dropdown/Dropdown';
import "./OverviewLiveStat.scss"
import { dateFilterScheme, StatInfo, getStatistics, makeLabels } from './Statistics';

interface Props {
  app: ApplicationInfo
}

export default function OverviewLiveStat({ app }: Props): JSX.Element {
  const [chartDateFilter, setChartDateFilter] = useState<keyof typeof dateFilterScheme>("lastest 1 week");
  const [chartData, setChartData] = useState<Array<StatInfo>>([])
  const [chartLabels, setChartLabels] = useState<Array<string>>([])
  const [dateFilter, setDateFilter] = useState<string>("day");

  useEffect(() => {
    getStatistics(app.appKey, chartDateFilter, ["dsv", "dvp", "dvm"], ["green", "light-green", "orange"], setChartData)
    
    const dateLabels = makeLabels(7, "MMM Do")

    setChartLabels(dateLabels)
  }, [app.appKey, chartDateFilter])

  const filterOption = [
    {
      value: 'lastest 1 week',
      onClick: () => {
        setChartDateFilter("lastest 1 week")
        setDateFilter("day")
      },
    },
  ]
  
  return (
    <div>
      <div className="col-xl-12 overview live-stat-overview">
        <div className="overview-header">
          <h5 className="card-title">Live 기간별 통계</h5>
        </div>
        <div className="card overview-item">
          <div className="chart-filter">
            <Dropdown>
              <Dropdown.Button toggleIcon>
                <span>{chartDateFilter}</span>
              </Dropdown.Button>
              <Dropdown.Items options={filterOption} />
            </Dropdown>
          </div>
          <div className="card-body overview-body">
            <div className="line-chart-group">
              <MultiLineChart data={chartData} width={900} height={300} labels={chartLabels} />
            </div>
            <div className="chart-label-group">
              <div className="chart-label">
                <div className="circle green" />
                Live 수
              </div>
              <div className="chart-label">
                <div className="circle light-green" />
                Live 참여자 수
              </div>
              <div className="chart-label">
                <div className="circle orange" />
                채팅메시지 수
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
