import React, { Component } from 'react'
import SearchInput from '../components/input/SearchInput'
import { NavItem } from '../components/Navigation'
import './MonitorSubBox.scss'
import { getLiveViewers } from './Video'
import { WatcherInfo } from '../review/Watchers'
import { blockUser, unBlockUser } from '../user/Users'
import WatcherList from './WatcherList'

interface Props {
  appKey: string
  videoKey: string
  setSelectedUser?(watcher: WatcherInfo): void
}

interface State {
  stage: number
  search: string
  watchers: WatcherInfo[]
}

export default class MonitorSubBox extends Component<Props, State> {
  state = {
    stage: 0,
    search: '',
    watchers: [],
  }

  onChangeSearch = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget
    if (value === ' ') {
      return
    }

    this.setState({ search: value })
  }

  handleReload = (): void => {
    getLiveViewers(this.props.appKey, this.props.videoKey, this.state.search, this.setWatchers)
  }

  handleStage = (stage: number) => (): void => {
    this.setState({ stage })
    this.handleReload()
  }

  search = (): void => {
    getLiveViewers(this.props.appKey, this.props.videoKey, this.state.search, this.setWatchers)
  }

  setWatchers = (watchers: WatcherInfo[]): void => {
    this.setState({ watchers })
  }

  onBlock = (id: string): void => {
    blockUser(this.props.appKey, id, this.props.videoKey).then(() => this.handleReload())
  }

  onUnBlock = (id: string): void => {
    unBlockUser(this.props.appKey, id).then(() => this.handleReload())
  }

  render(): JSX.Element {
    return (
      <div className="monitor-subbox-group">
        <div className="tab-group">
          <ul className="nav nav-pills">
            <NavItem stage={this.state.stage} value={0} label={'참여자 검색'} handleChange={this.handleStage(0)} />
          </ul>
        </div>
        <SearchInput label="Search Users" value={this.state.search} onChange={this.onChangeSearch} onSearch={this.search} maxLength={50} />
        <div className="monitor-subbox-content"><WatcherList type="LIVE" list={this.state.watchers} onBlock={this.onBlock} onUnBlock={this.onUnBlock} handleReload={this.handleReload} setSelectedUser={this.props.setSelectedUser} /></div>
      </div>
    )
  }
}
