import * as React from 'react'
import { actions as appContentActions } from '../modules/application'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ApplicationInfo } from '../application/Applications'
import { StoreState } from '../modules'
import TextInput from '../components/input/TextInput'
import Button from '../components/button/Button'
import { createBroadcast, saveStreamKey } from './Webinars'
import TextArea from '../components/input/TextArea'
import SwitchInput from '../components/input/SwitchInput'
import Dropdown from '../components/dropdown/Dropdown';
import './StreamKeyForm.scss'
import Toast from '../components/Toast'
import { MAX_LENGTH } from '../config/Config'


interface Props {
  app: ApplicationInfo
  streamKeys: string[]
  actions: typeof appContentActions
  onClose(): void
  onCancel(): void
}

interface State {
  title: string
  titleError: string
  content: string
  contentError: string
  visibility: boolean
  streamKey: string
}

class BroadcastForm extends React.Component<Props> {
  state: State = {
    title: 'Test webinar streaming',
    titleError: '',
    content: 'Test webinar streaming content',
    contentError: '',
    visibility: false,
    streamKey: this.props.streamKeys.length > 0 ? this.props.streamKeys[0] : ''
  }

  onChangeText = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value})
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { title, content, visibility, streamKey } = this.state

    if (title === '') {
      this.setState({ titleError: 'Please enter title' })
      return
    }
    this.setState({ titleError: '' })

    if (content === '') {
      this.setState({ contentError: 'Please enter content' })
      return
    }
    this.setState({ contentError: '' })
    
    console.log(streamKey);
    
    const { app } = this.props
    
    if (streamKey === '') {
      saveStreamKey({ app_key: app.appKey, nickname: app.appKey }).then(
        (res) => {
          console.log(res.data)
          const streamKey = res.data.stream_key
          console.log(streamKey)

          const request = {
            title,
            content,
            visibility: visibility ? 'PUBLIC' : 'PRIVATE',
            stream_key: streamKey
          }
          
          createBroadcast(app.appKey, request, (v) => {
            Toast.warn('Started a broadcast')
            console.log(v)
            this.props.onClose()
          })
          
        }, (err: any) => {
          console.error(err)
          Toast.warn('Failed to save the Stream Key')
        }
      )
    } else {
      const request = {
        title,
        content,
        visibility: visibility ? 'PUBLIC' : 'PRIVATE',
        stream_key: streamKey
      }
      
      createBroadcast(app.appKey, request, (v) => {
        Toast.warn('Started a broadcast')
        console.log(v)
        this.props.onClose()
      })
    }
        // this.props.refresh()
  }

  onChangeVisiblity = (visibility: boolean): void => {
    console.log(visibility)
    this.setState({ visibility })
  }


  onClickStreamKey = (streamKey: string): void => {
    console.log(streamKey)
    this.setState({ streamKey })
  }

  public render = (): JSX.Element => {
    const { streamKeys } = this.props
    const { streamKey } = this.state

    const options = streamKeys.map((item) => (
      { value: item, onClick: this.onClickStreamKey.bind(this, item) }
    ))

    const streamKeyLabel = streamKey === '' ? 'Create a new stream key' : streamKey

    return (
      <form className="application-form" onSubmit={this.handleSubmit}>
        <div className="input-group">
          <label htmlFor="Stream key">
            <span>Stream key</span>
          </label>
          <Dropdown>
            <Dropdown.Button toggleIcon><span>{streamKeyLabel}</span></Dropdown.Button>
            { streamKeys.length > 0 && <Dropdown.Items options={options} /> }
          </Dropdown>
        </div>
        <TextInput type="text" name="title" value={this.state.title} onChange={this.onChangeText} label="Title" maxLength={MAX_LENGTH.videoTitle} required error={this.state.titleError} />
        <TextArea type="text" name="content" value={this.state.content} onChange={this.onChangeText} label="Content" maxLength={MAX_LENGTH.videoDesc} required error={this.state.contentError} />
        <SwitchInput label="Visibility" current={this.state.visibility} onChange={this.onChangeVisiblity} />
        <div className="button">
          <Button type="button" size="large" color="white" onClick={this.props.onCancel}>Cancel</Button>
          <Button type="submit" size="large" color="red">Ok</Button>
        </div>
      </form>
    )
  }
}

export default connect(
  ({ application }: StoreState) => ({
    app: application.application,
  }),
  (dispatch) => ({
    actions: bindActionCreators(appContentActions, dispatch),
  })
)(BroadcastForm)
