import { Client } from "../config"
import { errorHandler } from '../account/Auth'


type Overview = {
  max_live_count : number
  max_live_chat_count : number
  total_live_count : number
  total_live_limit : number
  total_vod_count : number
  total_vod_limit : number
  storage_used : number
  storage_limit : number
  today_user_count : number
  today_live_count : number
  today_vod_count : number
  monthly_user_count : number
  monthly_live_count : number
  monthly_vod_count : number
}

export class OverviewInfo {
  maxLiveCount : number
  maxLiveChatCount : number
  totalLiveCount : number
  totalLiveLimit : number
  totalVodCount : number
  totalVodLimit : number
  storageUsed : number
  storageLimit : number
  todayUserCount : number
  todayLiveCount : number
  todayVodCount : number
  monthlyUserCount : number
  monthlyLiveCount : number
  monthlyVodCount : number
 
  constructor(json: Overview) {
    this.maxLiveCount = json.max_live_count
    this.maxLiveChatCount = json.max_live_chat_count
    this.totalLiveCount = json.total_live_count
    this.totalLiveLimit = json.total_live_limit
    this.totalVodCount = json.total_vod_count
    this.totalVodLimit = json.total_vod_limit
    this.storageUsed = json.storage_used
    this.storageLimit = json.storage_limit
    this.todayUserCount = json.today_user_count
    this.todayLiveCount = json.today_live_count
    this.todayVodCount = json.today_vod_count
    this.monthlyUserCount = json.monthly_user_count
    this.monthlyLiveCount = json.monthly_live_count
    this.monthlyVodCount = json.monthly_vod_count
  }
}

export const getOverview = (appKey: string, callback: (overview: OverviewInfo) => void): void => {
  new Client(`/api/2/applications/${appKey}/overview`)
    .get()
    .then(response => {
      console.log(response)
      callback(new OverviewInfo(response.data))
    }, errorHandler) 
}
