export class PagerConfig {
  public pagerSize: number = 10;
  public pagergInterval: number = 10;
}

const config = new PagerConfig()

export default {
  size: config.pagerSize,
  interval: config.pagergInterval
}