import * as React from 'react'
import { bindActionCreators } from 'redux';
import { ApplicationInfo } from '../application/Applications'
import { connect } from 'react-redux'
import { actions as applicationActions } from '../modules/application'
import { actions as settingActions, SettingError } from '../modules/setting'
import { StoreState } from '../modules'
import BreadCrumb from '../components/BreadCrumb'
import OverviewApplicationItem from '../review/OverviewApplicationItem'
import AdminInfoItem from './AdminInfoItem'
import { AppUserInfo, CallbackSettingsInfo, getSettings, SettingsInfo, updateAppUser, updateSettings, WebhookSettingInfo } from './Settings'
import ProhibitedWordsItem from './ProhibitedWordsItem'
import Button from '../components/button/Button'
import './Settings.scss'
import Toast from '../components/Toast'
import ThumbnailItem from './ThumbnailItem';
import WebhookItem from './WebhookItem';
import { avatarValidator, callbackValidator, userIdValidator, usernameValidator, webhookValidator } from '../config/Validator';
import CallbackItem from './CallbackItem';
import { MemberInfo } from '../account/Auth';

interface Props {
  app: ApplicationInfo
  appUser: AppUserInfo
  dirtyAppUser: AppUserInfo
  callbacks: CallbackSettingsInfo[],
  liveTimer: number,
  thumbnailInterval: number,
  profanityFilter: number,
  profanity: string,
  webhook: WebhookSettingInfo,
  applicationActions: typeof applicationActions
  settingActions: typeof settingActions
}

class SettingsContent extends React.Component<Props> {
  
  componentDidMount = () => {
    this.loadSettings()
    if(this.props.appUser) {
      this.props.settingActions.setAppUser(this.props.appUser);
    }
  }

  loadSettings = () => {
    const { app } = this.props
    getSettings(app.appKey, this.setSettings)
  }

  setSettings = (settings: SettingsInfo) => {
    console.log(settings)
    this.props.settingActions.setCallbacks([settings.callbackSettgins[0] || new CallbackSettingsInfo(), settings.callbackSettgins[1] || new CallbackSettingsInfo(), settings.callbackSettgins[2] || new CallbackSettingsInfo()]);
    this.props.settingActions.setLiveTimer(settings.liveTimer);
    this.props.settingActions.setProfanity(settings.profanity);
    this.props.settingActions.setProfanityFilter(settings.profanityFilter);
    this.props.settingActions.setThumbnailInterval(settings.thumbnailInterval)
    this.props.settingActions.setWebhook(settings.webhook)
    this.setState({ settings, dirty: settings })
  }

  setAppUser = (appUser: AppUserInfo) => {
    console.log(appUser)
    this.props.applicationActions.setAppUser(appUser);
  }

  onCancel = (): void => {
    this.loadSettings()
  }

  handleValidation = (): string[] => {
    const { dirtyAppUser, callbacks, webhook } = this.props;
    const validator = {
      userId: userIdValidator(dirtyAppUser.userId),
      username: usernameValidator(dirtyAppUser.username),
      profilePhotoUrl: avatarValidator(dirtyAppUser.profilePhotoUrl),
      webhook: webhookValidator(webhook),
      callbacks: callbackValidator(callbacks)
    }

    return Object.values(validator).filter(error => error)
  }

  onSave = (): void => {
    const errorMessage = this.handleValidation()

    if(errorMessage.length > 0) {
      Toast.notify(errorMessage[0])
      return;
    }    

    const { app, dirtyAppUser, webhook, profanity, profanityFilter, thumbnailInterval, callbacks, liveTimer } = this.props
    const webhookOps: any = {
      "type": `${webhook.type}`,
      "key": `${webhook.key}`,
      "level": 2
    }

    if(webhookOps.type === "telegram") {
      webhookOps.room_id = `${webhook.roomId}`
    }

    const request = {
      thumbnail_interval: thumbnailInterval,
      callback_settings: callbacks.filter((cb) => Boolean(cb.url)),
      profanity_filter: profanityFilter,
      profanity,
      demo: false,
      extensions: {
        "webhooks": [webhookOps],
        "live_timer": liveTimer || null
      },
    }

    if(Object.keys(request).length > 0) {
      updateSettings(app.appKey, request, this.setSettings)
    } else {
      console.log('no application settings to update.')
    }

    const appRequest = {
      user_id: dirtyAppUser?.userId,
      username: dirtyAppUser?.username,
      profile_photo_url: dirtyAppUser?.profilePhotoUrl
    }

    if(Object.keys(appRequest).length > 0) {
      updateAppUser(app.appKey, appRequest, this.setAppUser)
    }else {
      console.log('no application user to update.')
    }

    Toast.notify('Application settings has been updated.')
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    if(nextProps.app.appKey !== this.props.app.appKey) {
      getSettings(nextProps.app.appKey, this.setSettings)
      return true
    }
    if(nextProps.appUser !== this.props.appUser) {
      if(nextProps.appUser) {
        nextProps.settingActions.setAppUser(nextProps.appUser);
      }
      return true
    }
    return true
  }

  public render = (): JSX.Element | null => {
    const { app } = this.props

    return (
      <div className="dashboard-content col-xl-10">
        <BreadCrumb names={['Dashboard', 'Settings']} />
        <h3 className="content-title">Settings</h3>
        <div className="content-body setting-content">
          <OverviewApplicationItem app={app} title="General(application info)" />
          <AdminInfoItem />
          <ProhibitedWordsItem />
          <ThumbnailItem />
          <WebhookItem />
          <CallbackItem />
        </div>
        <div className="button-group">
          <Button type="button" size="large" color="white" onClick={this.onCancel}>Cancel</Button>
          <Button type="button" size="large" color="red" onClick={this.onSave}>Save</Button>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ application, setting }: StoreState) => ({
    app: application.application,
    appUser: application.appUser,
    dirtyAppUser: setting.appUser,
    callbacks: setting.callbacks,
    liveTimer: setting.liveTimer,
    thumbnailInterval: setting.thumbnailInterval,
    profanityFilter: setting.profanityFilter,
    profanity: setting.profanity,
    webhook: setting.webhook,
  }),
	(dispatch) => ({
    applicationActions: bindActionCreators(applicationActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
  })
)(SettingsContent)
