import React, { ReactNode } from 'react'
import './Title.scss'

interface Props {
  children: ReactNode
  size?: 'small' | 'medium' | 'large' | 'xlarge'
  color?: 'black'
  [key: string]: any
}

function Title({ className, children, size, color, ...props }: Props): JSX.Element {
  return (
    <h1 className={[className, `common-title`, `${size}-title`, `${color}-title`].join(' ')} {...props}>
      {children}
    </h1>
  )
}

export default React.memo(Title)

Title.defaultProps = {
  size: 'medium',
  color: 'black',
}
