import * as React from 'react'
import { ReactNode } from 'react';
import './Dropdown.scss'

interface OptionType {
  value: string
  description?: string
  onClick?(): void
  disabled?: boolean
  divider?: boolean
}

interface Props {
  children: ReactNode
  [key: string]: any
}

export default function Dropdown({ children }: Props): JSX.Element {
  return (
    <div className="dropdown" >
      {children}
    </div>
  )
}

interface DropdownButtonProps {
  children: string | ReactNode
  toggleIcon?: boolean
  disabled?: boolean
}

Dropdown.Button = function DropdownButton({ children, toggleIcon, disabled }: DropdownButtonProps) {
  return (
    <a href="#" className={"dropdown-button " + (toggleIcon && "dropdown-toggle ") + (disabled ? "disabled" : "")} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {children}
    </a>
  )
}

interface DropdownItemsProps {
  options: Array<OptionType>
}

Dropdown.Items = function DropdownItems ({ options }: DropdownItemsProps) {
  return (
    <div className="dropdown-menu dropdown-menu-left" aria-labelledby="ex2">
      {options.map(option => <button key={option.value} className={option.divider ? "dropdown-item-divider dropdown-item" : "dropdown-item"} type="button" onClick={option.onClick} disabled={option.disabled}>{option.description || option.value}</button>)}
    </div>
  )
}