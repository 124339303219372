import * as React from 'react'

export default function Next(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <path d="M0 0H20V20H0z" transform="translate(-1008 -879) translate(853 874) translate(155 5)"/>
              <path fill="#000" d="M10 7L14 13 6 13z" transform="translate(-1008 -879) translate(853 874) translate(155 5) matrix(0 -1 -1 0 20 20)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}