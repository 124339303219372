import { createAction, handleActions, Action } from 'redux-actions';
import videojs from 'video.js';
import { GoodsInfo } from '../monitor/Goods';
import { VideoInfo } from '../monitor/Video';

const SET_SDK = 'video/SET_SDK'
const SET_PLAYER = 'video/SET_PLAERYER'
const SET_VIDEO = 'streaming/SET_VIDEO'

export const actions = {
  setSdk: createAction<any>(SET_SDK),
  setPlayer: createAction<videojs.Player | null>(SET_PLAYER),
  setVideo: createAction<any>(SET_VIDEO),
}

export interface VideoState {
  sdk: any
  player: videojs.Player | null
  video: VideoInfo | null
}

const initialState = {
  sdk: null,
  player: null,
  video: null,
}

export default handleActions<VideoState, any>({
  [SET_SDK]: (state, action:Action<any>) => ({ sdk: action.payload, player: state.player, video: state.video }),
  [SET_PLAYER]: (state, action:Action<videojs.Player>) => ({ sdk: state.sdk, player: action.payload, video: state.video }),
  [SET_VIDEO]: (state, action:Action<VideoInfo | null>) => ({ sdk: action.payload, player: state.player, video: action.payload }),
}, initialState)
