import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import Button from '../button/Button'
import './Modal.scss'

interface Props {
  visible: boolean | number
  setVisible: (visible: boolean) => void
  onOk?: () => any
  onCancel?: () => void
  message: string
  className?: string
}

export default function Confirm({ visible, setVisible, onOk, onCancel, message, className }: Props): JSX.Element | null {
  const toggle = useCallback(() => {
    setVisible(!visible)
  }, [setVisible, visible])

  const noneEvent = useCallback((e) => e.stopPropagation(), [])

  const escapeKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        toggle()
      }
    },
    [toggle],
  )

  const clickCancel = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    toggle()
    if (onCancel) onCancel()
  }, [toggle, onCancel])

  const clickOk = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    toggle()
    if (onOk) onOk()
  }, [toggle, onOk])

  useEffect(() => {
    if (visible) window.addEventListener('keydown', escapeKey)
    else if (!visible) window.removeEventListener('keydown', escapeKey)
    return () => {
      window.removeEventListener('keydown', escapeKey)
    }
  }, [escapeKey, visible])

  useEffect(() => {
    const root = document.querySelector("#root")

    if(!root) {
      return;
    }
    
    root.classList.remove('modal-showing')
    visible && root.classList.add('modal-showing')
  }, [visible])
  
  return visible
    ? createPortal(
        <>
          <div className="overlay" />
          <div onClick={toggle} className={className + " modal-container"}>
            <div onClick={noneEvent} className="confirm-wrap">
              <div className="confirm-message">{message}</div>
              <div className="button-wrap">
                <Button size="large" color="white" onClick={clickCancel}>
                  Cancel
                </Button>
                <Button size="large" color="red" onClick={clickOk}>
                  Ok
                </Button>
              </div>
            </div>
          </div>
        </>,
        document.body,
      )
    : null
}

Confirm.defaultProps = {
  onOk: () => ({}),
  onCancel: () => ({}),
}
