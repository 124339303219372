import { url } from "inspector";
import { CallbackSettingsInfo, WebhookSettingInfo } from "../settings/Settings";

export const isJson = (str:string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export default class Validator {
  private valid: boolean
  private validationMessages: string
  value: string
  label: string
   
  constructor() {
    this.validationMessages = ''
    this.valid = true
  }
 
  private checkCase = (validationCase: boolean, errorMessage: string) => {
    if(!validationCase) {
      this.valid = false;
      this.validationMessages = errorMessage.replace("*label*", "'" + this.label + "'")
    }   
  }

  setValue = (value?: string): Validator => {
    this.value = value || ""
    this.validationMessages = ''
    return this;
  }

  setLabel = (label: string): Validator => {
    this.label = label
    return this;
  }

  required = (): Validator => {
    this.checkCase(this.value.length !== 0, "*label* is required.")
    return this
  }
  
  minLength = (length: number): Validator => {
    this.checkCase(this.value.length > length, "*label* must have more than " + length + " characters.");
    return this
  }
  
  maxLength = (length: number): Validator => {
    this.checkCase(this.value.length < length, "*label* must have less than " + length + " characters.");
    return this
  }

  email = (): Validator => {
    if(this.value.length === 0) {
      return this
    }
    const emailRegex = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/i
    this.checkCase(!!this.value.match(emailRegex), "*label* must be valid email.");
    return this
  }

  url = (): Validator => {
    if(this.value.length === 0) {
      return this
    }
    const urlRegex = /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/gi
    this.checkCase(!!this.value.match(urlRegex), "*label* must be valid url.");
    return this
  }

  token = (): Validator => {
    if(this.value.length === 0) {
      return this
    }
    const urlRegex = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/
    this.checkCase(!!this.value.match(urlRegex), "*label* must be valid token.");
    return this
  }

  password = (): Validator => {
    if(this.value.length === 0) {
      return this
    }
    // minLength: 8, char: at least 1, number: at least 1, special char: at least 1
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/
    this.checkCase(!!this.value.match(passwordRegex), "*label* must be valid password.");
    return this
  }

  time = (): Validator => {
    if(this.value.length === 0) {
      return this
    }
    // hh:mm:ss
    const timeRegex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/i
    this.checkCase(!!this.value.match(timeRegex), "*label* must be valid password.");
    return this
  }

  isValid = (): boolean => {
    return this.valid
  }

  hasError = (): string => {
    return this.validationMessages
  }

  reset = (): Validator => {
    this.validationMessages = ''
    this.valid = true
    return this;
  }
}

const validator = new Validator()

export const passwordValidator = (value?: string): string => validator.setValue(value).setLabel("password").maxLength(20).hasError()
export const newPasswordValidator = (value?: string): string => validator.setValue(value).setLabel("new password").maxLength(20).hasError()
export const firstnameValidator = (value?: string): string => validator.setValue(value).setLabel("first name").maxLength(30).hasError()
export const lastnameValidator = (value?: string): string => validator.setValue(value).setLabel("last name").maxLength(30).hasError()
export const avatarValidator = (value?: string): string => validator.setValue(value).setLabel("avtar url").url().maxLength(300).hasError()
export const componayValidator = (value?: string): string => validator.setValue(value).setLabel("company").maxLength(100).hasError()

export const callbackTokenValidator = (type?: string, value?: string): string => validator.setValue(value).setLabel(`${type} callback token`).token().maxLength(300).hasError()
export const callbackUrlValidator = (type?: string, value?: string): string => validator.setValue(value).setLabel(`${type} callback url`).url().maxLength(300).hasError()
export const profanityValidator = (value?: string): string => validator.setValue(value).setLabel("profanity").maxLength(300).hasError()
export const userIdValidator = (value?: string): string => validator.setValue(value).setLabel("user id").minLength(2).maxLength(20).hasError()
export const usernameValidator = (value?: string): string => validator.setValue(value).setLabel("user name").minLength(2).maxLength(20).hasError()
export const webhookValidator = (value?: WebhookSettingInfo): string => {
    if(value?.type === "telegram") {
      return validator.setValue(value?.key).setLabel("webhook key").maxLength(50).hasError() || validator.setValue(value?.roomId).setLabel("webhook roomId").required().maxLength(20).hasError()
    } else {
      return validator.setValue(value?.key).setLabel("webhook key").maxLength(50).hasError()
    } 
}
export const callbackValidator = (callbacks?: CallbackSettingsInfo[]): string => {
  let error = '';
  callbacks?.forEach((callback) => {
    if(callbackTokenValidator(callback.method, callback.token) || callbackUrlValidator(callback.type, callback.url)) {
      error = callbackTokenValidator(callback.method, callback.token) || callbackUrlValidator(callback.type, callback.url)
    }
  })
  return error;
}
