import * as React from 'react'
import { NavProp, NavTabProp } from './Navigations';
import "./Navigation.scss"

export const NavItem : React.SFC<NavProp> = ({ stage, value, label, handleChange }) => (
  <li className="dashboard-tab-item">
    <a className={'tab-link' + (stage === value ? ' active' : '')} href="#"  onClick={handleChange}>
      {label}
    </a>
  </li>
)

export const NavTabItem : React.SFC<NavTabProp> = ({ stage, link, value, label, handleChange }) => (
  <li className="dashboard-tab-item">
    <a className={'tab-link' + (stage === value ? ' active' : '')} onClick={handleChange} 
      id={`${link}-tab`} href={`#${link}`} data-toggle="tab" role="tab" aria-controls={link} aria-selected="true">
      {label}
    </a>
  </li>
)

