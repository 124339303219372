import moment from 'moment'
import { errorHandler } from '../account/Auth'
import { Client, Config } from '../config'
import { dateFormat } from "../config/Format"

export enum lineColorScheme {
  green = "#68d099",
  "light-green" = "#c5e46d",
  orange = "#ffac36",
  blue = "#65a3f5",
  red = "#f83345"
}

export type StatVarType = "dsv" | "duv" | "dpv" | "ddv" | "dvp" | "dvm" | "tsv" | "tuv" | "tpv" | "tdv" | "tvv" | "tvp" | "tvm" | "date"

export const dateFilterScheme= {
  "1 day": { start: moment().subtract(1, 'd'), end: moment() },
  "lastest 1 week": { start: moment().subtract(1, 'w'), end: moment() },
  "1 month": { start: moment().subtract(1, 'M'), end: moment() },
  "3 months": { start: moment().subtract(3, 'M'), end: moment() }
}

export class StatInfo {
  colorTitle: keyof typeof lineColorScheme
  color: lineColorScheme
  data: number[]

  constructor(data: number[], colorTitle: keyof typeof lineColorScheme, color: lineColorScheme) {
    this.colorTitle = colorTitle
    this.color = color
    this.data = data
  }
}

export const makeLabels = (count: number, format?: string): Array<string> => Array(count).fill(0).map(
  (_, i: number) => moment().subtract(count-i, 'd').format(format || Config.BASE_DATE_FORMAT)
);

export const getStatistics = (appKey: string, dateFilter: keyof typeof dateFilterScheme, statisticVar: Array<any>, color: Array<keyof typeof lineColorScheme>, callback: (list: any[]) => void): void => {
  const param = { start: dateFormat(dateFilterScheme[dateFilter].start, 'YYYY-MM-DD'), end: dateFormat(dateFilterScheme[dateFilter].end, 'YYYY-MM-DD') } 

  new Client(`/api/2/applications/${appKey}/statistics`)
    .get(param)
    .then(response => {
      console.log("statistics",response.data)

      const dateLabels = makeLabels(7)

      const dateIndexMap: any = {}

      dateLabels.forEach((date, i) => {
        dateIndexMap[date] = i
      })

      const dummyStatData: any = []

      statisticVar?.forEach(() => {
        dummyStatData.push(Array(7).fill(0))
      })

      let statData: any = []

      statData = statisticVar?.map((v, i) => {
        for (const data of response.data) {
          if(dateIndexMap[data.date]) {
            dummyStatData[i][dateIndexMap[data.date]] = data[v] 
            
          }
        }
        return new StatInfo(dummyStatData[i], color[i], lineColorScheme[color[i]])
      })

      callback(statData)
    }, errorHandler)
} 