import { createAction, handleActions, Action } from 'redux-actions'
import { produce } from 'immer';
import { PageInfo, PageRequest } from '../components/pagination/Paginations'
import { ApplicationInfo } from '../application/Applications'
import { Config } from '../config'
import { UserInfo } from '../user/Users'

type AppPayload = ApplicationInfo
type UserPayload = UserInfo

interface ListPayload {
  list: UserInfo[]
  page: PageInfo
}

type PathPayload = string[]
type ModalPayload = number

const SET_APP = 'user/SET_APP'
const SET_LIST = 'user/SET_LIST'
const SET_USER = 'user/SET_USER'
const SET_PATH = 'user/SET_PATH'
const SET_PAGE_REQUEST = 'review/SET_PAGE_REQUEST'
const CHECKED = 'user/CHECKED'
const UNCHECKED = 'user/UNCHECKED'
const CHECKED_ALL = 'user/CHECKED_ALL'
const UNCHECKED_ALL = 'user/UNCHECKED_ALL'
const SET_MODAL = 'user/SET_MODAL'
const CLEAR_CHECKLIST = 'user/CLEAR_CHECKLIST'

export const actions = {
  setAppKey: createAction<AppPayload>(SET_APP),
	setList: createAction<ListPayload>(SET_LIST),
  setPageRequest: createAction<PageRequest>(SET_PAGE_REQUEST),
  setPath: createAction<PathPayload>(SET_PATH),
  setUser: createAction<UserPayload>(SET_USER),

  checked: createAction<UserPayload>(CHECKED),
  unchecked: createAction<UserPayload>(UNCHECKED),
  checkedAll: createAction(CHECKED_ALL),
  uncheckedAll: createAction(UNCHECKED_ALL),
  setModal: createAction<ModalPayload>(SET_MODAL),
  clearCheckList: createAction(CLEAR_CHECKLIST)
}

export interface UserState {
  app: ApplicationInfo | null,
  list: UserInfo[],
  checkList: UserInfo[],
  path: string[],
	page: PageInfo
  pageRequest: PageRequest,
  user: UserInfo | null,
  modalContent: number,
  checkedAll: boolean
}

const initialState: UserState = {
  app: null,
  list: [],
  checkList: [],
  path: ['/'],
  page: new PageInfo(null),
  pageRequest: new PageRequest(0, Config.TABLE_ITEM_COUNT),
  user: null,
  modalContent: 0,
	checkedAll: false
}

export default handleActions<UserState, any>({
  [SET_APP]: (state, action: Action<AppPayload>) => produce(state, draft => { draft.app = action.payload }),
  [SET_LIST]: (state, action: Action<ListPayload>) => produce(state, draft => { draft.list = action.payload.list, draft.page = action.payload.page }),
  [SET_PAGE_REQUEST]: (state, action: Action<PageRequest>) => produce(state, draft => { draft.pageRequest = action.payload }),
  [SET_PATH]: (state, action: Action<PathPayload>) => produce(state, draft => { draft.path = action.payload, draft.user =  null }),
  [SET_USER]: (state, action: Action<UserPayload>) => produce(state, draft => { draft.user = action.payload }),
  [CHECKED]: (state, action: Action<UserPayload>) => produce(state, draft => { draft.checkList = [...state.checkList].concat(action.payload) }),
  [UNCHECKED]: (state, action: Action<UserPayload>) => produce(state, draft => { draft.checkList = [...state.checkList].filter(f => f.userId !== action.payload.userId) }),
  [CHECKED_ALL]: (state) => produce(state, draft => { draft.checkList = [...state.list], draft.checkedAll = true }),
  [UNCHECKED_ALL]: (state) => produce(state, draft => { draft.checkList = [], draft.checkedAll = false }),
  [SET_MODAL]: (state, action: Action<ModalPayload>) => produce(state, draft => { draft.modalContent = action.payload }),
  [CLEAR_CHECKLIST]: (state, action: Action<ModalPayload>) => produce(state, draft => { draft.checkList = [], draft.modalContent = action.payload }),
	}, initialState
)