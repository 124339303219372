import * as React from 'react'
import './CheckBox.scss'


interface Props {
  id: string
  value: string
  label?: string
  checked? : boolean
  onChange: (id: string, checked: boolean) => void 
}

export class CheckBox extends React.Component<Props> {
  onChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.checked

    const { id } = this.props
    this.props.onChange(id, value)
  }

  public render = (): JSX.Element => {
    const { id, value, label, checked } = this.props
    return (
      <div className="form-check">
        <input className="form-check-input" type="checkbox" name="check" id={id} value={value} checked={checked} onChange={this.onChange} />
        <label className="form-check-label" htmlFor={id}>{label}</label>
      </div>
    )
  }
}