import * as React from 'react'
import TermsContent from './TermsContent'
import { TERMS_OF_SERVICE as TOS } from '../config/Terms'
import DashboardHeader from '../components/DashboardHeader';
import Footer from '../components/Footer';


interface Props {
}

class TermsContainer extends React.Component<Props> {
  public render = (): JSX.Element => {
    return (
      <div className="container-fluid terms-container-fluid">
        <DashboardHeader />
        <div className="terms-padding"/>
        <div className="content-container">
          <TermsContent terms={TOS} />
          <Footer />
        </div>
      </div>
    )
  }
}

export default TermsContainer;