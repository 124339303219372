import * as React from 'react';
import { ApplicationInfo, changeApplication, deleteApplication } from './Applications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as appActions } from '../modules/application'
import Modal from '../components/modal/Modal';
import Confirm from '../components/modal/Confirm';
import ChangeNameModalContent from './RenameModalContent';
import Dropdown from '../components/dropdown/Dropdown';
import Badge from '../components/Badge';
import Toast from '../components/Toast'
import { MoreIcon } from '../assets/svg';
import { openNewPage } from '../config/browser';


enum ModalStateCode {
  Delete = 1,
  Rename = 2,
}

interface Props {
  app: ApplicationInfo
  appActions: typeof appActions
  refreshApplication(): void
}

interface State { 
  visibleModal: ModalStateCode
}

class ApplicationItem extends React.Component<Props, State> {
  state = {
    visibleModal: 0,
  }

  toggleConfirmGenerator = (modalCode: ModalStateCode) => () => {
    if(this.state.visibleModal === modalCode) {
      this.setState({visibleModal: 0})
    } else {
      this.setState({visibleModal: modalCode})
    }
  }

  toggleRename = this.toggleConfirmGenerator(ModalStateCode.Rename)
  toggleDelete = this.toggleConfirmGenerator(ModalStateCode.Delete)

  copyToClipboard = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    console.log(this.props.app.accessToken)

    const element = document.createElement('textarea');
    element.value = this.props.app.accessToken;
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy')
    element.remove()

    alert("Copied to clipboard");
  }

  delete = () => {
    if (!this.props.app.appKey) {
      alert('appKey is requried')
      return
    }
    
    deleteApplication(this.props.app.appKey)
      .then(() => {
          Toast.notify('Deleted successfully')
          this.props.refreshApplication()
        }, err => {
          console.error(err)
          alert('Failed to delete application')
        })
  }

  rename = (name: string) => {
    changeApplication(this.props.app.appKey, name)
      .then(() => {
        Toast.notify('Changed successfully')
        this.props.refreshApplication()
      }, err => {
        console.error(err)
        alert('Failed to change application name')
      })
      this.toggleRename()
  }

  onClick = () => {
    const { app } = this.props
    this.props.appActions.setApplication(app)
    localStorage.setItem('app', JSON.stringify(app))
    openNewPage(`/applications/${app.appKey}`);
  }

  public render() {
    const { app } = this.props
    console.log(app)

    const options = [
      {
        value: 'Change App name',
        onClick: this.toggleRename,
      },
      {
        value: 'Delete',
        onClick: this.toggleDelete,
      }
    ]

    return (
      <>
        <tr>
          <td>
            <div className="app-name ml-10 text-ellipsis" data-toggle="tooltip" title={app.name}>
              <a onClick={this.onClick}>{app.name}</a>
            </div>       
          </td>
          <td className="text-center">{app.onLive}</td>
          <td className="text-center">{app.totalLive}</td>
          <td className="text-center">{app.totalVod}</td>
          <td>{app.createdAt}</td>
          <td className="table-badge"><Badge type={app.plan.name} /></td>
          <td className="application-item-menu">
            <Dropdown>
              <Dropdown.Button><MoreIcon /></Dropdown.Button>
              <Dropdown.Items options={options}/>
            </Dropdown>
          </td>
        </tr>
        <Modal title="Rename Application" render={<ChangeNameModalContent onRename={this.rename} onClose={this.toggleRename} />} visible={this.state.visibleModal === ModalStateCode.Rename} setVisible={this.toggleRename} />
        <Confirm message="Do you want to delete this application?" visible={this.state.visibleModal === ModalStateCode.Delete} setVisible={this.toggleDelete} onOk={this.delete} />
      </>
    )
  }
}

export default connect(
	({}) => ({}),
	(dispatch) => ({
    appActions: bindActionCreators(appActions, dispatch),
  })
)(ApplicationItem)