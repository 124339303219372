import React from 'react';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import Button from '../components/button/Button';
import { Config } from '../config'
const GoogleLogo = Config.IMAGE_BASE_URL + 'GoogleLogo.png';

interface Props {
  onSuccess(res: GoogleLoginResponse): void
  onFailure(): void
}

export default function GoogleLoginButton({ onSuccess, onFailure }: Props): JSX.Element {
  return (
    <>
      <GoogleLogin
        clientId={Config.GOOGLE_CLIENT_ID}
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy="single_host_origin"
        buttonText="Login"
        render={renderProps => (
          <>
            <Button
              color="google"
              size="medium"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <img src={GoogleLogo} />
              <span>Continue with Goolge</span>
            </Button>
          </>
        )}
      />
    </>
  );
}