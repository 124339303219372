import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { actions as settingActions } from '../modules/setting'
import { StoreState } from '../modules';
import { FormBullet } from '../assets/svg'
import Dropdown from '../components/dropdown/Dropdown'
import TextInput from '../components/input/TextInput'


interface Props {
  thumbnailInterval: number,
  liveTimer: number,
  settingActions: typeof settingActions
}

class ThumbnailItem extends React.Component<Props> {

  onClick = (interval: number): void => {
    this.props.settingActions.setThumbnailInterval(interval)
  }

  onChange = (e: any): void => {
    this.props.settingActions.setLiveTimer(e.target.value)
  }

  public render = (): JSX.Element => {
    const labelText = '업데이트 주기'
    const options = [
      {
        value: '10 초',
        onClick: this.onClick.bind(this, 10),
      },
      {
        value: '15 초',
        onClick: this.onClick.bind(this, 10),
      },
      {
        value: '30 초',
        onClick: this.onClick.bind(this, 30),
      },
      {
        value: '60 초',
        onClick: this.onClick.bind(this, 60),
      }
    ]

    const label = `${this.props.thumbnailInterval} 초`
    return (
      <div className="settings col-xl-12">
        <div className="settings-header">
          <h5 className="card-title">썸네일 업데이트 주기 설정(default 설정)</h5>
        </div>
        <div className="card settings-item">
          <div className="card-body overview-body">
            <div className="setting-input-group">
              <label htmlFor={labelText}>
                <FormBullet /> <span>{labelText}</span>
              </label>
              <Dropdown>
                <Dropdown.Button toggleIcon><span>{label}</span></Dropdown.Button>
                <Dropdown.Items options={options} />
              </Dropdown>
              <label htmlFor="liveTimer" style={{marginLeft: "120px"}}>
                <FormBullet /> <span>종료 시간</span>
              </label>
              <TextInput type="number" name="liveTimer" value={this.props.liveTimer} onChange={this.onChange} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ setting }: StoreState) => ({
    thumbnailInterval: setting.thumbnailInterval,
    liveTimer: setting.liveTimer,
  }),
	(dispatch) => ({
    settingActions: bindActionCreators(settingActions, dispatch),
  })
)(ThumbnailItem)
