import React from 'react';
import { ArrowDown } from '../assets/svg';
import MultiLineChart from '../components/chart/MultiLineChart';
import { currencyFormat, dateFormat } from '../config/Format';
import { MessageInfo } from '../review/Messages';
import { lineColorScheme, StatInfo } from '../review/Statistics';
import "./MonitorChartBox.scss";
import { WatcherHistoryInfo } from './Video';

interface Props {
  chartData: WatcherHistoryInfo
  messages: MessageInfo[]
}

export default function MonitorChartBox({ chartData, messages }: Props): JSX.Element {
  const difference = chartData.countList.length > 1 ? (chartData.countList[chartData.countList.length - 1] - chartData.countList[chartData.countList.length - 2]) : 0
  const changeRate = chartData.countList.length > 0 ? difference / (chartData.countList[chartData.countList.length - 1] || 1 ) * 100 : 0
  
  return (
    <div className="monitor-chart-group">
      <div className="monitor-chart-header">
        <span className="chart-title">현재 시청자 수</span>
        <div className="chart-content">
          <div className="chart-viewer">{currencyFormat(messages[messages.length - 1]?.participantCount || 0)}</div>
          <div className={"chart-change-rate " + (difference > 0 ? "up-rate" : "down-rate")}>{difference > 0 ? <ArrowDown /> : <ArrowDown />}{`${Math.abs(difference)} (${changeRate.toFixed(2)}%)`}</div>
        </div>
      </div>
      <div className="chart-date">{dateFormat(Date.now(), "YYYY.MM.DD. hh:mm")}</div>
      <MultiLineChart type="time" data={[new StatInfo(chartData.countList, difference > 0 ? "red" : "blue", lineColorScheme[difference > 0 ? "red" : "blue"])]} labels={chartData.timeList} maxTicksLimit={5} />
    </div>
  )
}
