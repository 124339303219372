import * as React from 'react'
import { GoogleLoginResponse } from 'react-google-login'
import { actions as loginActions } from '../modules/login'
import { actions as contentActions } from '../modules/content'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { googleLogin, me, MemberInfo, signUp } from '../account/Auth'
import Button from '../components/button/Button'
import TextInput from '../components/input/TextInput'
import Title from '../components/title/Title'
import './SignForm.scss'
import GoogleLoginButton from './GoogleLoginButton'
import { GoSignIn } from './GoLoginForms'
import { Config, Messages, Store } from '../config'
import { Link } from 'react-router-dom'
import Toast from '../components/Toast'
import { MAX_LENGTH } from '../config/Config'
import { Mail } from '../assets/svg'


interface Props {
  logins: typeof loginActions
  contents: typeof contentActions
}

interface State {
  email: string
  emailError: string
  password: string
  passwordError: string
  confirm: string
  confirmError: string
  firstName: string
  lastName: string
  company: string
  confirmPolicy: boolean,
}

class SignUpForm extends React.Component<Props, State> {
  state: State = {
    email: '',
    password: '',
    confirm: '',
    passwordError: '',
    emailError: '',
    confirmError: '',
    company: '',
    firstName: '',
    lastName: '',
    confirmPolicy: false
  }

  onChangeInput = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  onChangeCheck = (e: React.FormEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.checked
    this.setState({confirmPolicy: value})
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { company, email, password, confirm, confirmPolicy, firstName, lastName } = this.state

    if (email === '') {
      this.setState({ emailError: 'Please enter your Email' })
      return
    }
    this.setState({ emailError: '' })

    if (password === '') {
      this.setState({ passwordError: 'Please enter your Password' })
      return
    } else if (password.trim().length < Config.PASSWORD_MIN_LENGTH) {
      this.setState({ passwordError: Messages.PASSWORD_MIN_LENGTH })
      return
    }
    this.setState({ passwordError: '' })

    if (confirm === '') {
      this.setState({ confirmError: 'Please enter confirm Password' })
      return
    } else if (confirm.trim().length < Config.PASSWORD_MIN_LENGTH) {
      this.setState({ confirmError: Messages.CONFIRM_PASSWORD_MIN_LENGTH })
      return
    }
    this.setState({ confirmError: '' })

    if (password !== confirm) {
      this.setState({ confirmError: 'Your password and confirmation password do not match.' })
      return
    }

    if (!confirmPolicy) {
      Toast.warn('Please check privacy policy confirm')
      return
    }

    const signupRequest = { email, password, company, firstName, lastName }
    signUp(signupRequest, this.afterSignUp)
  }

  afterSignUp = (): void => {
    Toast.warn(
      <div className="toast-warn">
        <Mail />Congratulations. Please complete the email verification in 30 minutes
      </div>
    )
    this.goSignIn()
  }

  loadMember = (): void => {
    me(this.setMember)
  }

  setMember = (member: MemberInfo): void => {
    console.log(member)
    Store.set(Config.MEMBER_KEY, member)

    this.props.logins.setMember(member)
    this.props.contents.setContent({ content: 2 })
  }

  onGoogleLoginSuccess = (res: GoogleLoginResponse): void => { 
    console.log(res);
    googleLogin(res.profileObj, this.loadMember)
  }

  onGoogleLoginFailure = (): void => { 
    Toast.warn('fail to signup');
    // 회원가입 실패 로직
  }

  goSignIn = (): void => {
    this.props.contents.setContent({ content: 0 })
  }

  public render = (): JSX.Element => {  
    return (
      <div>
        <form className="sign-group" onSubmit={this.handleSubmit}>
          <Title size="medium" color="black">
            Create your account
          </Title>
          <TextInput type="email" id="email" name="email" value={this.state.email} onChange={this.onChangeInput} label="Email" error={this.state.emailError} required maxLength={MAX_LENGTH.email}/>
          <TextInput type="password" id="password" name="password" value={this.state.password} onChange={this.onChangeInput} label="Password" error={this.state.passwordError} required maxLength={MAX_LENGTH.password}/>
          <TextInput type="password" id="confirm_password" name="confirm" value={this.state.confirm} onChange={this.onChangeInput} label="Confirm Password" error={this.state.confirmError} required maxLength={MAX_LENGTH.password}/>
          <div className="form-user-name-group">
            <TextInput type="text" id="firstName" name="firstName" value={this.state.firstName} onChange={this.onChangeInput} label="First Name" maxLength={MAX_LENGTH.firstName}/>
            <TextInput type="text" id="lastName" name="lastName" value={this.state.lastName} onChange={this.onChangeInput} label="Last Name" maxLength={MAX_LENGTH.lastName}/>
          </div>
          <TextInput type="text" id="company" name="company" value={this.state.company} onChange={this.onChangeInput} label="Company" maxLength={MAX_LENGTH.company}/>
          <div className="form-policy-group">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="confirmPolicy" checked={this.state.confirmPolicy} onChange={this.onChangeCheck}/>
              <label className="form-check-label" htmlFor="confirmPolicy"></label>
            </div>
            <div className="form-check-message">
              <p>I agree to FLIPFLOP <Link to="/terms_of_service" >Terms of Service</Link> and <Link to="/privacy_policy" >Privacy Policy</Link></p>
              <p>{Messages.SIGNUP_POLICY}</p>
            </div>
          </div>
          <Button color="yellow" size="medium">
            Signup
          </Button>
          <div className="divider">Or</div>
          <GoogleLoginButton onSuccess={this.onGoogleLoginSuccess} onFailure={this.onGoogleLoginFailure} />
        </form>
        <div className="goto_tool">
          <GoSignIn onClick={this.goSignIn} />
        </div>
      </div>
    )
  }
}

export default connect(
  () => ({}),
  (dispatch) => ({
    logins: bindActionCreators(loginActions, dispatch),
    contents: bindActionCreators(contentActions, dispatch),
  }),
)(SignUpForm)
