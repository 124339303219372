import * as React from 'react'

export default function DoubleNext(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <path d="M0 0H20V20H0z" transform="translate(-1037 -879) translate(853 874) translate(184 5)"/>
              <path fill="#000" d="M17 6v8l-6-4 6-4zm-6 0v8l-6-4 6-4z" transform="translate(-1037 -879) translate(853 874) translate(184 5) matrix(-1 0 0 1 22 0)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}