import * as React from 'react'
import { actions as loginActions } from '../modules/login'
import { actions as contentActions } from '../modules/content'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resendVerification } from '../account/Auth'
import Button from '../components/button/Button'
import TextInput from '../components/input/TextInput'
import Title from '../components/title/Title'
import './SignForm.scss'
import { GoBackSignInLink, GoSignUpLink } from './GoLoginForms'
import Toast from '../components/Toast'
import { MAX_LENGTH } from '../config/Config'
import { Mail } from '../assets/svg'

interface Props {
  logins: typeof loginActions
  contents: typeof contentActions
}

interface State {
  email: string
  emailError: string
}

class ResendEmailForm extends React.Component<Props, State> {
  state: State = {
    email: '',
    emailError: '',
  }

  onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { email } = this.state

    if (email === '') {
      this.setState({ emailError: 'Please enter your Email' })
      return
    }
    this.setState({ emailError: '' })

    resendVerification(email, this.success, this.fail)
  }

  success = (): void => {
    Toast.warn(
      <div className="toast-warn">
        <Mail />Verification email sent. Please complete the email verification in 30 minutes
      </div>
    )
    this.props.contents.setContent({ content: 0 })
  } 

  fail = (): void => {
    Toast.notify('This email is not signed up.')
    window.location.href = '/applications'
  }

  goSignUp = (): void => {
    this.props.contents.setContent({ content: 1 })
  }

  public render = (): JSX.Element => {  
    return (
      <div>
      <form className="sign-group resend-email" onSubmit={this.handleSubmit}>
        <Title size="medium" color="black">
          Resend the verification email
        </Title>
        <TextInput type="email" id="email" name="email" value={this.state.email} onChange={this.onChangeInput} label="Email" error={this.state.emailError} required maxLength={MAX_LENGTH.email} />
        <GoBackSignInLink path={'/applications'} />
        <Button color="yellow" size="large">
          Send email
        </Button>
      </form>
      <div className="goto_tool">
        <GoSignUpLink path={'/applications'} />
      </div>
    </div>
    )
  }
}

export default connect(
  () => ({}),
  (dispatch) => ({
    logins: bindActionCreators(loginActions, dispatch),
    contents: bindActionCreators(contentActions, dispatch),
  }),
)(ResendEmailForm)
