import * as React from 'react'

interface Props {
  id: string
  name: string
  value: string
  label?: string
  onChange: (type: string) => void 
  [key: string]: any
}

export default function Radio({id, value, onChange, name, label, ...props}: Props): JSX.Element {
  const onChangeRadio = React.useCallback((e:any): void => {
    const value = e.currentTarget.value
    onChange(value)
  }, [value])

  return (
    <div className="form-check">
      <input className="form-check-input" type="radio" name={name} id={id} value={value} onChange={onChangeRadio} {...props}/>
      <label className="form-check-label" htmlFor={id}>{label}</label>
    </div>
  )
}