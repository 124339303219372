import React from 'react'

export default function GreenArrow(): JSX.Element {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
          <circle fill="#60EB00" cx="10" cy="10" r="10"/>
          <path d="M10 5a1 1 0 0 1 1 1l-.001 5H14l-4 4-4-4h2.999L9 6a1 1 0 0 1 1-1z" fill="#FFF"/>
      </g>
    </svg>
  )
}
