import React, { useEffect, useState } from 'react'
import { Download, FormBullet } from '../assets/svg'
import { currencyFormat, dateFormat } from '../config/Format'
import MonitorInfoItem from './MonitorInfoItem'
import { getLiveStat, LiveStatInfo, VideoInfo } from './Video'

interface Props {
  video: VideoInfo
}

export default function MonitorVideoInfo({ video }: Props): JSX.Element {
  const [vodStat, setVodStat] = useState<LiveStatInfo>(new LiveStatInfo({max_watcher_count: 0, total_message_count: 0, total_watcher_count: 0, avg_live_watch_time: 0}))

  useEffect(() => {
    getLiveStat(video.appKey, video.videoKey, setVodStat)
  }, [setVodStat, video.appKey, video.videoKey])

  return (
    <div className="monitor-box-group half-box">
      <div className="monitor-box-title mb-20">Video info</div>
      <ul className="monitor-info-list-group vod-stat-list-group">
        {video.downloadUrl && 
          <li className="monitor-info-item-group">
            <div className="monitor-info">
              <FormBullet />
              <span>영상 다운로드</span>
            </div>
            <div className="monitor-info"><a className="download-btn" href={video.downloadUrl} download rel="noopener noreferrer" target="_blank"><Download />Download</a></div>
          </li>
        }
        <MonitorInfoItem title="채팅 수" value={currencyFormat(vodStat.totalMessageCount)} /> 
        <MonitorInfoItem title="전체 시청자 수" value={currencyFormat(vodStat.totalWatcherCount)} /> 
        <MonitorInfoItem title="최대 시청자 수" value={currencyFormat(vodStat.maxWatcherCount)} /> 
        <MonitorInfoItem title="라이브 시 평균 방송 시청 시간" value={dateFormat(vodStat.avgLiveWatchTime, "H시간 MM분")} />
        <MonitorInfoItem title="조회수" value={currencyFormat(video.viewCount)} />
      </ul>
    </div>
  )
}