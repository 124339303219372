import React from 'react';
import { PlanName } from '../application/Applications';

import "./Badge.scss";

enum BadgeCode {
  Free = "Free Trial",
  Business = "Business",
  Basic = "Basic",
  Pro = "Pro"
}

interface Props {
  type: PlanName
}

export default function Badge({type}: Props): JSX.Element {
  return (
    <div className={`badge-group ${type.toLowerCase()}-badge`}>
      {BadgeCode[type]}
    </div>
  )
}