import * as React from 'react';
import { DoubleNext, DoublePrev, Next, Prev } from '../../assets/svg';

interface Props {
  direction: string;
  active: boolean;
  page: number;
  handlePage(page: number): void; 
}

class ArrowItem extends React.Component<Props> {

  handlePage = (e: React.FormEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    this.props.handlePage(this.props.page);
  }

  public render = (): JSX.Element => {
    const { direction, active} = this.props;
    const icon = direction === 'Previous' ? <Prev /> : 
      direction === 'Next' ? <Next /> :
      direction === 'PagePrevious' ? <DoublePrev /> : <DoubleNext />;

    return (
      <li className={'page-item' + (!active ? ' disabled' : '')}>
        { active ? 
        <a className="page-link arrow-item" href="#" aria-label={direction} onClick={this.handlePage}>
          <span aria-hidden="true">{icon}</span>
          <span className="sr-only">{direction}</span>
        </a> :
        <button className="page-link arrow-item" aria-label={direction}>
          <span aria-hidden="true">{icon}</span>
          <span className="sr-only">{direction}</span>
        </button> }
      </li>
    );
  }
}

export default ArrowItem;