import * as React from 'react'
import { actions as loginActions } from '../modules/login'
import { actions as contentActions } from '../modules/content'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { passwordReset } from '../account/Auth'
import Button from '../components/button/Button'
import TextInput from '../components/input/TextInput'
import Title from '../components/title/Title'
import './SignForm.scss'
import { GoBackSignIn, GoSignUp } from './GoLoginForms'
import Toast from '../components/Toast'
import { Messages } from '../config'
import { MAX_LENGTH } from '../config/Config'
import { Mail } from '../assets/svg'


interface Props {
  logins: typeof loginActions
  contents: typeof contentActions
}

interface State {
  email: string
  emailError: string
}

class PasswordResetForm extends React.Component<Props, State> {
  state: State = {
    email: '',
    emailError: '',
  }

  onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { email } = this.state

    if (email === '') {
      this.setState({ emailError: Messages.INVALID_PASSWORD_RESET_EMAIL })
      return
    }
    this.setState({ emailError: '' })

    passwordReset(email, this.afterSentEmail)
  }

  afterSentEmail = (): void => {
    Toast.warn(
      <div className="toast-warn">
        <Mail />A password reset email has been sent.
      </div>
    )
    this.props.contents.setContent({ content: 0 })
  }

  goSignUp = (): void => {
    this.props.contents.setContent({ content: 1  })
  }

  goSignIn = (): void => {
    this.props.contents.setContent({ content: 0  })
  }

  public render = (): JSX.Element => {  
    return (
      <div>
      <form className="sign-group" onSubmit={this.handleSubmit}>
        <Title size="medium" color="black">
          Forgot your password?
        </Title>
        <TextInput type="email" id="email" name="email" value={this.state.email} onChange={this.onChangeInput} label="Email" error={this.state.emailError} required maxLength={MAX_LENGTH.email} />
        <GoBackSignIn onClick={this.goSignIn} />
        <Button color="yellow" size="large">Send email</Button>
      </form>
      <div className="goto_tool">
        <GoSignUp onClick={this.goSignUp} />
      </div>
    </div>
    )
  }
}

export default connect(
  () => ({}),
  (dispatch) => ({
    logins: bindActionCreators(loginActions, dispatch),
    contents: bindActionCreators(contentActions, dispatch),
  }),
)(PasswordResetForm)
