import * as React from 'react'
import { Doughnut } from 'react-chartjs-2'
import './QuotaChart.scss'

interface QuotaType {
  title: string
  max: number
  current: number
  unit?: string
}

interface Props {
  data: QuotaType
}

export default function QuotaChart({ data }: Props): JSX.Element {
  const chartData = {
    datasets: [
      {
        data: data.max < data.current ? [0, 100] : [(1 - data.current / data.max) * 100 || (data.max === 0 && 1), (data.current / data.max) * 100],
        borderWidth: 0,
        backgroundColor: ['#eceff1', '#fe5c00'],
        radius: '90%',
        innerRadius: '70%',
      },
    ],
  }

  const chartOptions = {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    cutoutPercentage: 94,
  }

  return (
    <div className="quota-group">
      <Doughnut width={180} height={180} data={chartData} options={chartOptions} />
      <div className="quota-text">
        <p className="quota-title">{data.title}</p>
        <p className="quota-content">
          <span>
            {data.current}
            {data.unit || ''}
          </span>
          <span>{` / ${data.max}`}</span>
        </p>
      </div>
    </div>
  )
}
