import * as React from 'react'

interface Props {
  className?: string
}

export default function Search({className}: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-577 -595) translate(41 510) translate(40 75) translate(496 10)">
                <path fillRule="nonzero" d="M0 0H20V20H0z" />
                <circle cx="9.5" cy="9.5" r="5.5" stroke="#DDD" strokeWidth="2" className={className} />
                <path stroke="#DDD" strokeWidth="2" d="M17 17L13 13" className={className} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
