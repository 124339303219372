import * as React from 'react';
import { PageState, PageInfo, FilterRequest } from '../components/pagination/Paginations';
import { ReviewInfo, getDeletedVideos, blockVideo } from './Reviews';
import { ApplicationInfo } from '../application/Applications';
import { bindActionCreators } from 'redux';
import { StoreState } from '../modules';
import { actions as reviewActions } from '../modules/review'
import { connect } from 'react-redux';
import Pagination from '../components/pagination/Pagination';
import BreadCrumb from '../components/BreadCrumb';
import { NavItem } from '../components/Navigation';
import ReviewList from './ReviewList';
import { padNumber } from './VideoContent';
import Toast from '../components/Toast'


interface Props extends PageState {
  app: ApplicationInfo
  list: ReviewInfo[]
  actions: typeof reviewActions
  pageRequest: FilterRequest
}

interface State {
}

class DeletedVideoContent extends React.Component<Props, State> {
  initPage = () => {
    const { pageRequest } = this.props
    pageRequest.page = 0
    this.props.actions.setPageRequest(pageRequest)
  }

  handlePage = (page: number) => {
    const pageRequest = this.props.pageRequest
    pageRequest.page = page
    this.props.actions.setPageRequest(pageRequest)

    this.handleReload(pageRequest);
  }

  handleReload = (pageRequest: FilterRequest) => {
    getDeletedVideos(this.props.app.appKey, pageRequest, this.setList)
  }

  setList = (list: ReviewInfo[], page: PageInfo) => {
    this.props.actions.setList({ list, page });
  }

  onBlock = (review: ReviewInfo) => {
    blockVideo(this.props.app.appKey, review)
      .then(res => {
        console.log(res)
        Toast.notify('Successfully blocked') 
        this.handleReload(this.props.pageRequest);
      }, err => {
        if (err) {
          Toast.warn('Failed to blocke video');
        }
      });
  }

  onDelete = (videoKey: string) => {
    console.log(`onDelete - ${videoKey}`)
  }

  componentDidMount = () => {
    this.initPage()
    getDeletedVideos(this.props.app.appKey, this.props.pageRequest, this.setList)
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    if (nextProps.app.appKey !== this.props.app.appKey) {
      this.initPage()
      getDeletedVideos(nextProps.app.appKey, nextProps.pageRequest, this.setList)
      return true
    }
    return true
  }

  public render() {

    return (
      <div className="dashboard-content col-xl-10">
        <BreadCrumb names={['Dashboard', 'Deleted Videos']} />
        <h5 className="content-title">Deleted Videos</h5>
        <div className="tab-group">
          <ul className="nav nav-pills justify-content-center">
            <NavItem stage={0} value={0} label={'All (' + padNumber(this.props.pageInfo.totalElementes) + ')'} handleChange={this.handlePage.bind(this, 0)} />
          </ul>
        </div>
        <div className="row review-group">
          <ReviewList list={this.props.list} onBlock={this.onBlock} onDelete={this.onDelete} />
        </div>
        <div className="mt-50">
          <Pagination page={this.props.pageInfo} handlePage={this.handlePage}/>
        </div>
      </div>
    )
  }
}


export default connect(
	({ application, review } : StoreState) => ({
    app: application.application,
    list: review.list,
    pageInfo: review.page,
    pageRequest: review.pageRequest
	}),
	(dispatch) => ({
    actions: bindActionCreators(reviewActions, dispatch)
  })
)(DeletedVideoContent)