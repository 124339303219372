import * as React from 'react'
import Switch from './Switch'
import './Switch.scss'

interface Props {
  label: string
  current: boolean
  onChange(value: boolean): void
}

interface State {
}

class SwitchInput extends React.Component<Props, State> {
  state: State = {
  }

  onChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { checked } = e.currentTarget
    this.props.onChange(checked)
  }

  public render = (): JSX.Element => {
    const { current } = this.props
    const { label } = this.props

    return (
      <div className="input-group switch-input-group">
        <label className="switch-label" htmlFor={label}>
          <span>{label}</span>
        </label>
        <Switch checked={current} onChange={this.onChange} />
      </div>
    )
  }
}

export default SwitchInput