export const TERMS_OF_SERVICE: Terms = {
  title: 'Terms of Service',
  desc: `사용자는 아래 기재된 사용자 약관을 주의깊게 읽어주시기를 바랍니다. (주)요쿠스가 제공하는 SaaS 형 소프트웨어 FLIPFLOP 서비스를 사용하거나 회원으로 가입을 하게되면, 회원 및 사용자는 요쿠스가 제공한 아래의 사용자 (서비스)약관의 내용에 동의하는 것으로 간주됩니다. 만약, 아래의 내용에 동의하지 않으시면, (주)요쿠스가 제공하는 서비스를 이용하지 않으셔야 함을 알려 드립니다.`,
  articles: [
    {
      id: 1,
      title: `약관에 대한 확인 및 허용`,
      clauses: [
        { id: 'A',
          title: `(주)요쿠스 (이하, “요쿠스”)의 “FLIPFLOP” 소프트웨어, 웹사이트 혹은 요쿠스의 서비스, 소프트웨어, 데이터피드 (어플리케이션 및 웹사이트를 통한 폼) 등에 접근 혹은 방문하게 되면, 방문자 
        혹은 사용자는 (1) 서비스약관과 (2) "FLIPFLOP"의 개인정보 보호 정책에 포함되는 모든 내용에 동의하는 것으로 간주합니다.`},
        { id: 'B',
          title: `본 약관에 중요한 변경이 있는 경우에는 요쿠스의 재량에 따라 그 내용을 변경하거나 언제든지 정책을 수정할 수 있으며, 변경내용에 대한 통지를 메일과 "FLIPFLOP 서비스 약관" 을 통해서 통지하며, 방문자와 사용자도 주기적으로 최신 버전을 약관 페이지를 통해 검토 하여야 합니다. 이는 제 3 사업자의 정책과는 무관하며, 이에 대한 요쿠스의 책임은 없을 수 있습니다.`},
        { id: 'C',
          title: `요쿠스의 서비스 방문자와 사용자는 중요한 정책변경에 의한 동의가 필요한 경우에는 서비스에서 동의를 확인하도록 합니다. 또한, 성능에 대한 모니터링, 기능점검 및 다른 목적의 벤치마킹에 의해 서비스가 제한될 수 있음을 인지하여야 합니다.`},
      ] 
    },
    {
      id: 2,
      title: `서비스`,
      clauses: [
        { 
          id: 'A',
          title: `본 약관은 본 서비스의 모든 이용자에게 적용됩니다. “콘텐츠”에는 사용자가 본 서비스 상으로 보거나, 본 서비스를 통하여 접속하거나 또는 본 서비스에 기고할 수 있는 텍스트, 사진, 사운드, 음악, 비디오, 시청각 혼한물, 상호작용 내용 및 기타자료가 포함됩니다. 본 서비스에는 소프트웨어 “FLIPFLOP”의 모든 제품, 소프트웨어 및 서비스를 포함한 모든 항목이 포함됩니다.`},
        { id: 'B',
          title: `본 서비스는 요쿠스와는 별개의 사업자 혹은 개인 및 법인이 소유하는 제 3자 웹사이트 및 어플리케이션등 서비스와의 링크도 포함될 수 있습니다. 요쿠스는 제3자 서비스 (웹사이트 및 어플리케이션 등)의 콘텐츠, 개인정보 취급방침 및 행위에 관련이 없으며 이에 대한 통제 및 관련하여 어떠한 책임도 지지 않습니다. 또한, 요쿠스는 제 3자 웹사이트의 콘텐츠를 검열하거나 편집하지 아니하며, 이와 같이 할 수도 없습니다. 요쿠스의 서비스를 이용하면 사용자는 명시적으로 사용자의 제3자 서비스 이용으로 인하여 발생하는 모든 책임으로부터 요쿠스를 면제시켜야 합니다.`},
        { id: 'C',
          title: `이에 따라, 당사는 사용자에게, 본 서비스를 떠나는 경우 이를 인식하고 사용자가 방문한 기타 각 서비스의 약관 및 개인정보 취급방침을 확인 및 검토할 것을 권장합니다.`},
      ] 
    },
    {
      id: 3,
      title: `FLIPFLOP 계정`,
      clauses: [
        { 
          id: 'A',
          title: `본 서비스의 특정 내용에 접속하기 위하여, 사용자는 FLIPFLOP 계정을 전자메일 (EMAIL)형식으로 개설하여야 합니다. 사용자는 허락없이 타인의 계정을 이용할 수 없습니다. 계정 개설시 사용는 정확하고 완전한 정보를 제공하여야 합니다. 사용자는 사용자의 계정으로 행한 활동에 대하여 단독 책임을 지며, 계정 비밀번호의 보안을 유지하여야 합니다. 사용자는 사용자 계정의 보안 침해나 무단 이용시 즉시 그러한 사실을 "FLIPFLOP"을 사용하는 요쿠스에 "support@flipflop.tv" 전자메일 계정을 통해 통지하여야 합니다.`},
        { id: 'B',
          title: `요쿠스는 사용자 계정의 무단 이용으로 인하여 사용자가 입은 소실에 대하여 책임을 지지는 않지만, 사용자는 그러한 무단 이용으로 인하여 요쿠스 또는 타인이 입은 소실에 대하여 책임을 질 수 있습니다.`},
        { id: 'C',
          title: `요쿠스 사용자의 계정은 사용자의 서비스 형태와 서비스 방법에 따라 해당 플랫폼 서비스, 인스타그램, 트위터, 페이스북, 텀블러, 카카오톡 (카카오의 모든 서비스 플랫폼), 네이버 (네이버의 모든 서비스 플랫폼) 등과 같은 소셜네트워크 서비스의 계정과의 연결이 될 수 있습니다. 만약 소셜네트워크 서비스 계정과 연결이 되었을 경우, 사용자는 해당 서비스에서 사용자의 정보 (사용자 이름, 사진, 비디오, 코멘트 아바타 등)를 획득하여 저장할 수 있음을 인지해야 합니다. 또한, 정보들을 제공받는데 있어서, 사용자는 대화내용, 공유링크 등의 정보가 소셜네트워크 서비스로 연결되어 사용자의 권한제공 확인을 통해 제공되거나 받을 수 있음을 인지하여야 합니다.`},
      ] 
    },
    {
      id: 4,
      title: `일반적인 서비스 이용 – 허용사항 및 제한사항`,
      desc: `요쿠스는 아래에 기재된 내용을 조건으로 하여 방문자와 사용자의 본 서비스 이용을 허용합니다.`,
      clauses: [
        { 
          id: 'A',
          title: `사용자는 요쿠스의 사전 서면 승인없이는 본 서비스 또는 콘텐츠 어느 부분이라도 사용자의 플랫폼을 제외한 어떤 제 3의 매체 (플랫폼 등)로도 배포하지 않을 것에 동의하는 것으로 합니다. 
          단, 요쿠스가 본 서비스에서 제공한 기능을 통하여 그러한 배포를 우한 수단을 제공하는 경우에는 예외로 합니다.`
        },
        { 
          id: 'B',
          title: `사용자는 본 서비스의 일부라도 변경하거나 수정하지 않을 것에 동의하는 것으로 합니다.`
        },
        { 
          id: 'C',
          title: `사용자는 본 서비스 자체의 비디오 플레이 페이지 및 기타 요쿠스가 지정하는 명시적으로 인정된 수단 이외의 기술이나 수단을 통하여 콘텐츠에 접속하지 않을 것에 동의하는 것으로 합니다.`
        },
        { 
          id: 'D',
          title: `사용자는 요쿠스의 사전 서면 승인을 얻은 경우를 제외하고 아래에 기재된 상업적 목적을 위하여 본 서비스를 이용하지 않을 것에 동의하는 것으로 합니다.`,
          details: [
            { 
              id: '1)',
              title: `"FLIPFLOP" 서비스에서의 접속 판매`
            },
            {
              id: '2)',
              title: `"FLIPFLOP" 서비스에서의 광고, 후원 또는 판매를 목적으로 하는 모든 상업적 내용`
            },
            {
              id: '3)',
              title: `"FLIPFLOP" 서비스에서의 광고를 포함하는 모든 콘텐츠 및 그 내용을 포함한 블로그, 웹사이트의 광고, 후원 또는 판매를 목적으로 하는 모든 상업적 내용.`
            }
          ]},
          { 
            id: 'E',
            title: `아래 기재된 내용은 금지된 상업적인 목적에 포함되지 않습니다.`,
            details: [
              { 
                id: '1)',
                title: `사용자가 제공하는 서비스의 목적에 상응하는 콘텐츠`
              },
              {
                id: '2)',
                title: `영상에서의 필연적으로 판단되는 내용으로서 광고 및 상업적인 목적으로 볼 수 없는 경우`
              }
            ]
          },
          { 
            id: 'F',
            title: `사용자는 요쿠스가 제공하는 "FLIPFLOP" 소프트웨어 및 서비스의 업데이트를 자동적으로 다운로드하여 설치할 수 있음에 동의하는 것으로 합니다. 이러한 업데이트는 "FLIPFLOP"의 모든 서비스의 향상 및 추가 개발하기 위하여 설계된 것으로, 버그 수정, 향상된 기능, 신규 소프트웨어 모듈 및 완전히 새로운  버전의 형태를 가질 수 있습니다. 사용자는 "FLIPFLOP"의 서비스 이용의 일부로서 이러한 업데이트를 수신하는데 동의 하는 것으로 합니다.`
          },
          { 
            id: 'G',
            title: `사용자는 인간이 전통적인 온라인 웹 브라우저를 이용하여 일정 기간 동안 합리적으로 생성할 수 있는 것보다 많은 요청 메시지를 동일한 기간 내에 "FLIPFLOP" 서버에 전송하는 방식으로 본 서비스에 접속하는 “robots,” “spiders”, 또는 “offline readers”를 비롯한 자동화 시스템을 이용하거나 실행(launch)하지 않을 것에 동의합니다. 전술한 내용에도 불구하고, "FLIPFLOP" 은 공개적으로 이용가능한 검색가능 자료 색인(그러한 자료의 캐시(caches) 또는 보관이 아님)을 작성하기 위해서만 그리고 이에 필요한 범위 내에서만 공개적 검색엔진 사업자에게 자료를 복사할 스파이더(spider)를 이용할 수 있도록 허용합니다. "FLIPFLOP"는 일반적으로 또는 특별한 경우 이러한 예외인정을 취소할 권리를 보유합니다. 사용는 본 서비스로부터 계정 명칭을 포함한 개인식별정보를 수집하거나 수확하지 아니하며 또한 상업적인 거래유인 목적을 위하여 본 서비스가 제공한 통신시스템(예컨데, 의견 및 이메일)을 이용하지 않을 것에 동의합니다. 사용은 상업적인 목적을 위하여 본 서비스의 이용자로부터 그 이용자의 콘텐츠와 관련하여 거래를 유인하지 않을 것에 동의합니다.`
          },
          { 
            id: 'H',
            title: `"FLIPFLOP"의 모든 서비스 이용시 사용는 모든 관련 법률을 준수합니다.`
          },
          { 
            id: 'I',
            title: `"FLIPFLOP"은 언제든지 본 서비스의 특정 부분을 중단할 권리를 보유합니다.`
          },
          { 
            id: 'J',
            title: `사용자는 사용자의 정보를 "FLIPFLOP"에 접속일로부터 30일의 기간동안 정상적인 접근이 "FLIPFLOP"의 서비스에 이루어졌을 경우 제공하는 것을 동의하는 것으로 간주합니다.`
          },
          { 
            id: 'K',
            title: `사용자는 "FLIPFLOP"을 제공하는 요쿠스의 자산 및 요쿠스의 합병, 인수, 기업구조 조정, 또는 판매의 경우가 발생하더라도 어떠한 권리를 가질 수 없음에 동의합니다.`
          },
          { 
            id: 'L',
            title: `사용자는 "FLIPFLOP"에서 정하는 가격정책에 의해 "FLIPFLOP" 비용을 비용지불일에 제공하여야 하며, 사용자는 "FLIPFLOP"에 사용비용에 대한 대한민국이 정하는 세법에 의해 세금계산서 및 요금청구서를 서면으로 요청할 수 있는 권한을 보유합니다. 이에, "FLIPFLOP"을 운영하는 요쿠스는 해당 사용자의 요청을 수락하고, 비용에 대한 보고서를 제공하고, 대한민국 법률이 정하는 세금계산서 발급 방식을 통해 사용자에게 제공하여야 합니다.`
          },
          { 
            id: 'M',
            title: `사용자는 "FLIPFLOP"에서 제시된 세금계산서에 따라 "FLIPFLOP"이 정하는 기간내에 소프트웨어 사용에 따르는 서비스 이용대금을 "FLIPFLOP"를 운영하는 요쿠스에 제공하여야 하며, "FLIPFLOP"이 제시한 비용지급 기한을 지키지 못할 경우에는 "FLIPFLOP"을 운영하는 요쿠스가 "FLIPFLOP"의 사용권한을 정지 시킬 수 있음에 동의합니다.`
          },
      ] 
    },
    {
      id: 5,
      title: `사용자의 콘텐츠 이용`,
      desc: `일반적인 제한에 추가하여, 콘텐츠 이용시 사용자는 특히 아래에 기재된 제한 및 조건에 따라야 합니다.`,
      clauses: [
        { 
          id: 'A',
          title: `본 서비스상의 콘텐츠와 본 서비스상의 상표, 서비스표 및 로고는 법률에 규정된 저작권 및 기타 지적재산권에 따라 "FLIPFLOP"이 소유하거나 "FLIPFLOP"에게 라이센스가 부여됩니다.`
        },
        { id: 'B',
          title: `콘텐츠는 사용자에 의해 제공된 그대로 사용자들에게 제공됩니다. 사용자는 본 서비스의 제공된 기능을 통하여 의도한 대로, 그리고 본 약관 동의의사 의해 허용된 대로 참고 목적 또는 개인적인 목적 혹은 법인의 목적에 한하여 콘텐츠에 접속할 수 있습니다. 사용자는 해당 콘텐츠에 대하여 본 서비스에서 "FLIPFLOP"가 표시한 “다운로드” 또는 그와 비슷한 유사한 링크를 발견하지 않는 한 콘텐츠를 다운로드 하면 안 됩니다. 사용자는 요쿠스 또는 각 콘텐츠의 라이센서의 사전 서면 동의 없이는 어떠한 목적으로도 콘텐츠를 복사, 복제, 온라인으로 제공하거나 전자적인 방법으로 전송하거나, 발표, 각색, 배포, 전송, 방송, 전시, 판매, 라이센스 하여야 하거나 이와 유사하게 이용하여서는 안 됩니다. "FLIPFLOP"와 라이센서들은 본 서비스 및 콘텐츠에서 명시적으로 허가 되어지지 않은 모든 권리를 보유합니다.`
        },
        { 
          id: 'C',
          title: `사용자는 본 서비스의 보안관련 기능 또는 콘텐츠의 이용 또는 복사를 방지 또는 제한하거나, 본 서비스 또는 본 서비스에 포함된 콘텐츠의 이용에 대한 제한을 실행하는 기능을 회피하거나, 비 활성화하거나 달리 방해하지 않을것에 동의하는 것으로 합니다.`
        },
        { 
          id: 'D',
          title: `사용자는 본 서비스 이용시 사용자의 여러 출처에서 나온 콘텐츠에 노출되며, "FLIPFLOP"는 이러한 콘텐츠 및 관련된 정확성, 유용성, 안정 또는 지적재산권에 대하여 책임을 지지 않음을 알고 있는 것으로 동의 하게 됩니다. 사용자는 또한 부정확하거나, 불쾌감을 주거나, 무례하거나, 논란의 여지가 있는 콘텐츠에 노출될 수 있음을 이해하고 이를 인정하며, 사용자는 그와 관련하여 "FLIPFLOP"에 대하여 이를 법률상 또는 형법상 권리 또는 구제수단을 포기할 것에 동의하고 이를 관련 법률이 허용하는 범위 내에서 사용자의 본 서비스 이용과 관련된 모든 사안에 관하여 법률이 허용하는 최대 범위까지 "FLIPFLOP"와 그 소유자, 운영자, 라이센서 및 라이센시를 면책하고 손해를 입지 않도록 하는 것에 동의하는 것으로 간주합니다.`
        },
      ] 
    },
    {
      id: 6,
      title: `사용자의 콘텐츠와 관련 활동`,
      clauses: [
        { 
          id: 'A',
          title: `사용자는 "FLIPFLOP"의 계정 보유자 자격으로 본 서비스에 비디오, 비디오 제목 및 설명정보, 비디오에 대한 의견 및 리뷰등을 포함한 콘텐츠를 사용할 수 있습니다. 또한, 사용자는 "FLIPFLOP"가 사용자가 제출한 콘텐츠의 기밀성을 보장하지 않고 있음을 알고 있는 것으로 간주합니다.`
        },
        { id: 'B',
          title: `사용자 자신은 콘텐츠에 대해서 사용자 만이 책임을 집니다. 사용자는 사용자가 필요한 라이센스, 권리, 동의 및 승인을 소유하거나 보유하고 있으며, 사용자는 본 약관 동의의사에 따라 본 서비스를 통하여 발생한 콘텐츠에 대한 모든 특허, 상표, 영업비밀, 저작권 또는 기타 전유적 권리를 "FLIPFLOP"에 라이센트 하였음을 확인, 진술 및 보증합니다. 하지만, 사용자가 이용한 저작권이 있는 콘텐츠에 대한 모든 책임은 사용자에게 있으며, 이에 대한 "FLIPFLOP"의 책임과는 관련이 없음에 동의하는 것으로 간주합니다.`
        },
        { 
          id: 'C',
          title: `사용자는 사용자의 콘텐츠에 대한 소유권을 전부 보유함을 명확히 합니다. 그러나 "FLIPFLOP"에 콘텐츠를 제출함으로써, 사용자는 본 서비스의 일부 또는 전부를, 어떠한 미디어 포맷으로 어떠한 미디어 채널을 통하여 광고하고 재배포하는 것을 비롯하여, 본 서비스 및 "FLIPFLOP"의 사업과 관련하여 콘텐츠를 이용, 복제, 배포, 2차적 저작물을 작성하거나, 전시, 발표, 각색, 온라인에 제공하거나 전자적인 방법으로 전송하고, 세계적이고, 비독점적이고, 무상으로 제공되고, 양도 가능하며, 서브라이센스를 허락할 수 있는 라이센스를 요쿠스에 허락하는 것으로 간주합니다. 사용자가 본 서비스에 제출한 비디오 콘텐츠에 대하여 사용자가 동의한 라이센스는 사용자가 본 서비스에서 사용자의 비디오를 제거하거나 삭제한 후 상업적으로 합리적인 기간내에 효력을 상실하게 됩니다. 사용자는 "FLIPFLOP"가 제거되거나 삭제된 사용자의 비디오의 서버 사본을 보유할 수 있으며, 이를 전시, 배포하거나 공연할 수 없음을 알고 있고 이에 동의하는 것으로 합니다. 사용자가 제출한 이용자 의견에 대해 사용자가 허락한 상기 라이센스는 영구적이며 취소할 수 없습니다.`
        },
        { 
          id: 'D',
          title: `사용자가 본 서비스에 제출한 콘텐츠는, 사용자가 이를 게시하고 또한 본 약관에 정해진 라이센스 권리 전부를 "FLIPFLOP"에 전가할 수 있도록, 사용자가 적법한 소유권자로부터 승인을 받았거나 달리 법적으로 그러한 권한이 있는 경우를 제외 하고는, 제3자가 저작권을 보유한 자료나 기타 제3자가 전유적 권리를 가진 자료를 포함하지 않을 것에 동의합니다.`
        },
        { 
          id: 'E',
          title: `사용자는 대한민국의 정서 및 법률적으로 상반되거나 관련 현지, 국내 및 국제 법률 및 규정에 상반되는 콘텐츠나 기타 자료를 본 서비스에 제출하지 않을 것에 동의합니다.`
        },
        { 
          id: 'F',
          title: `"FLIPFLOP"은 이용자 또는 기타 라이센서가 본 서비스에 제출한 콘텐츠나 본 서비스에 게시된 의견, 권고 또는 조언을 보증하지 아니하며, "FLIPFLOP"은 콘텐츠와 관련된 일체의 책임을 명시적으로 부인합니다. "FLIPFLOP"은 본 서비스를 통한 저작권 침해 행위나 지적재산권 침해를 허용하지 아니하며, 콘텐츠가 타인의 지적재산권을 침해한다는 사실을 적절하게 고지 받는 경우 그 콘텐츠 일체를 제거합니다. "FLIPFLOP"은 사전 통지 없이 콘텐츠를 제거할 권리를 보유합니다.`
        },
        { 
          id: 'G',
          title: `14세 미만의 사용자로부터 제공된 비디오 컨텐츠는 부모 및 보호자의 동의를 취득하고 제공된 것으로 간주하며, 부모 및 보호자의 동의없이 제공된 비디오 콘텐츠에 대한 사용자의 보호자 요청이 "FLIPFLOP"에게 전달될 경우에는 보호자에게 그 내용을 전달하지 않고 콘텐츠를 즉시 삭제 및 제거 조치를 취하는것에 동의합니다.`
        },
        { 
          id: 'H',
          title: `사용자는 사용자의 개인정보 및 개인정보와 관련된 내용 및 데이터를 서비스를 통해 배포할 수 없으며, 이는 대한민국 법률에서 제시한 개인정보 보호정책을 준수하기 위한 내용으로 "FLIPFLOP"은 이를 제한, 변경 및 삭제처리가 즉시 가능하며 사용자에게 직접 수정 요청을 취할 수 있다. 위사항에 대해서 사용자는 이를 인지하고 동의하는 것으로 한다.`
        },
      ] 
    },
    {
      id: 7,
      title: `계정 해지 정책`,
      clauses: [
        { 
          id: 'A',
          title: `합당한 상황에 비추어서 이용자가 상습 침해자로 확정되는 경우 "FLIPFLOP"은 본 서비스에 대한 해당 사용자의 접속권한을 종료시킬 수 있습니다.`
        },
        { id: 'B',
          title: `"FLIPFLOP"은 음란물, 폭력물, 외설 컨텐츠를 포함하는 내용 또는 "FLIPFLOP"가 정하는 크기의 용량을 벗어나는 과대용량을 비롯하여 콘텐츠가 저작권 침해 이외의 사유로 본 약관에 위배되는지 여부를 결정할 권한을 가집니다. "FLIPFLOP"은 언제라도 사전 통지 없이 "FLIPFLOP"의 단독의 재량 및 사용자들에 의한 신고기능을 이용하여, 본 약관에 위배되는 자료의 제출시, 해당 콘텐츠를 제거하거나 이용자의 계정을 종료시킬 수 있습니다.`
        },
      ] 
    },
    {
      id: 8,
      title: `저작권 정책`,
      clauses: [
        { 
          id: 'A',
          title: `"FLIPFLOP"은 제3자의 저작권을 침해했다고 주장된 모든 사용자에 의해 제출된 비디오에 대하여 분명한 저작권 정책을 적용합니다. 또한, 사용자가 저작권에 대한 소유권을 가지고 있다고 판단될 때에는 "FLIPFLOP"의 관련 서류와 주장을 뒷받침할 수 있는 내용을 증명하여 아래의 경우에 대처하여야 합니다.`,
          details: [
            { 
              id: '1)',
              title: `저작권 소유자로부터의 저작권 침해에 대한 신고가 접수된 경우`
            },
            {
              id: '2)',
              title: `관계 기관 및 국가기관, 사정기관으로부터의 요청이 접수된 경우`
            },
            {
              id: '3)',
              title: `사용자에 의한 신고접수로 인해 "FLIPFLOP" 운영요원의 확인으로 도덕적, 법률적 문제가 있다고 판단되는 경우`
            },
            {
              id: '4)',
              title: `법률 전문가의 조언을 통한 저작권 문제가 있다고 자체 판단한 경우`
            },
            {
              id: '5)',
              title: `위의 사항을 토대로 "FLIPFLOP"에서는 저작권 문제를 지속적으로 확인 및 조치하는 것을 사용자가 동의하는 것으로 합니다. "FLIPFLOP"의 저작권 정책의 일환으로, 이용자가 반복 위반자로 결정된 경우, "FLIPFLOP"은 해당 이용자의 "FLIPFLOP" 서비스에 대한 접속을 해지 할 수 있습니다. 반복 위반자란 2회 이상의 침해행위가 통보된 이용자를 뜻합니다. 위반사례에 대한 주장 혹은 대응은 요쿠스의 저작권관련 담당자 연락처로 연락을 취할 수 있습니다. (이메일 : privacy@flipflop.tv, 전화 : +82-70-4827-0981, 주소 : 서울특별시 마포구 양화로8길 16-12, 2층).`
            },
          ]
        },
        { 
          id: 'B',
          title: `사용자는 해당 컨텐츠가 접근이 되지 않거나 삭제되었음을 인지하였으며, 저작권에 대한 대응이 필요하다고 판단될 경우 본인 혹은 대리인을 통해 "FLIPFLOP"에 아래의 내용을 통해 증명할 수 있습니다.`,
          details: [
            { 
              id: '1)',
              title: `실제 혹은 전자 문서`
            },
            {
              id: '2)',
              title: `삭제 혹은 사용이 불가능해진 컨텐츠 원본 내용 (영상) 및 정보`
            },
            {
              id: '3)',
              title: `저작권을 소유한 컨텐츠 내용 및 정보에 대한 사유서 (자유 형식 사유서 가능)`
            },
            {
              id: '4)',
              title: `개인정보 (이름, 주소, 연락처 및 이메일 연락처)이 기재되어있는 개인을 증명할 수 있는 국가기관의 개인증명서 (주민등록 등(초)본 등)`
            },
          ]
        },
        { 
          id: 'C',
          title: `"FLIPFLOP"은 본 서비스, 하이퍼링크된 서비스 또는 배너 등의 광고를 통하여 제3자가 광고하거나 제공하는 제품이나 서비스에 대하여 어떠한 보증, 승인, 보장 또는 그에 대한 책임을 부담하지 않으며, 요쿠스는 제품 또는 서비스를 위한 사용와 제3자와의 거래의 당사자가 아니며, 어떠한 방식으로도 그러한 거래를 모니터링할 책임을 부담하지 않습니다. 어떠한 매체를 통해서건 또는 어떠한 상황에서건 제품이나 서비스를 구입하는 것과 관련하여, 적절한 경우, 사용자는 최선의 판단력으로서 주의를 기울여야 합니다.`,
        },
      ] 
    },
    {
      id: 9,
      title: `보증 거부`,
      clauses: [
        { 
          id: 'A',
          title: `사용자는 사용의 본 서비스 사용과 관련하여 사용자 단독으로 위험을 부담하기로 동의합니다. 법률이 허용하는 최대 범위 내에서, "FLIPFLOP"의 임직원, 이사 및 대리인은 본 서비스 및 사용자의 본 서비스 이용과 관련하여 명시적이거나 묵시적인 모든 보증을 배제합니다. 법률이 허용하는 최대 범위 내에서, "FLIPFLOP"은 본 사이트의 콘텐츠 또는 본 사이트와 링크된 사이트의 콘텐츠의 정확성이나 완전성에 대하여 모든 보증, 조건이나 진술을 배제하며,`,
          details: [
            { 
              id: '1)',
              title: `콘텐츠의 오류, 과실이나 부정확성,`
            },
            {
              id: '2)',
              title: `당사의 서비스에 대한 사용의 접속 및 사용으로 인하여 발생하는 여하한 성질의 상해(personal injury) 또는 재산적 손해(property damage),`
            },
            {
              id: '3)',
              title: `당사의 보안서버 및/또는 그에 저장된 모든 개인정보 및/또는 금융정보에 대한 무단 접속 또는 무단 사용,`
            },
            {
              id: '4)',
              title: `당사 서비스로 또는 당사 서비스로부터의 전송 장애 또는 중단,`
            },
            {
              id: '5)',
              title: `제3자가 당사 서비스로 또는 당사 서비스를 통하여 전송하는 버그, 바이러스, 트로이 목마 및 유사한 것, 및/또는`
            },
            {
              id: '6)',
              title: `콘텐츠의 오류 또는 누락이나 본 서비스에 게재, 이메일 송부, 전송되거나 달리 본 서비스를 통하여 제공된 콘텐츠의 사용으로 인하여 발생하는 여하한 종류의 손실이나 손해에 대하여 어떠한 책임도 부담하지 않습니다.`
            },
          ]
        },
        { 
          id: 'B',
          title: `"FLIPFLOP"은 본 서비스, 하이퍼링크된 서비스 또는 배너 등의 광고를 통하여 제3자가 광고하거나 제공하는 제품이나 서비스에 대하여 어떠한 보증, 승인, 보장 또는 그에 대한 책임을 부담하지 않으며, 요쿠스는 제품 또는 서비스를 위한 사용와 제3자와의 거래의 당사자가 아니며, 어떠한 방식으로도 그러한 거래를 모니터링할 책임을 부담하지 않습니다. 어떠한 매체를 통해서건 또는 어떠한 상황에서건 제품이나 서비스를 구입하는 것과 관련하여, 적절한 경우, 사용자는 최선의 판단력으로서 주의를 기울여야 합니다.`,
        },
      ] 
    },
    {
      id: 10,
      title: `책임의 제한`,
      clauses: [
        { 
          id: 'A',
          title: `법률이 허용하는 최대 범위 내에서, "FLIPFLOP"의 임직원, 이사나 대리인은 보증, 계약, 불법행위나 기타 법리에 근거한 것인지 여부에 관계없이, 또한 회사가 그러한 손해의 가능성에 대하여 고지받았는지 여부와 관계없이 어떠한 경우에도`,
          details: [
            { 
              id: '1)',
              title: `콘텐츠의 오류, 과실이나 부정확성,`
            },
            {
              id: '2)',
              title: `당사의 서비스에 대한 사용의 접속 및 사용으로 인하여 발생하는 여하한 성질의 상해(personal injury) 또는 재산적 손해(property damage),`
            },
            {
              id: '3)',
              title: `당사의 보안서버 및/또는 그에 저장된 모든 개인정보 및/또는 금융정보에 대한 무단 접속 또는 무단 사용,`
            },
            {
              id: '4)',
              title: `당사 서비스로 또는 당사 서비스로부터의 전송 장애 또는 중단,`
            },
            {
              id: '5)',
              title: `제3자가 당사 서비스로 또는 당사 서비스를 통하여 전송하는 버그, 바이러스, 트로이 목마 및 유사한 것, 및/또는`
            },
            {
              id: '6)',
              title: `콘텐츠의 오류 또는 누락이나 본 서비스에 게재, 이메일 송부, 전송되거나 달리 본 서비스를 통하여 제공된 콘텐츠의 사용으로 인하여 발생하는 여하한 종류의 손실이나 손해에 대하여 어떠한 책임도 부담하지 않습니다.`
            },
          ]
        },
        { 
          id: 'B',
          title: `당사는 일부 관할권에서는 법적으로 배제될 수 없는 보증, 포기 및 조건이 적용될 수 있다는 점을 이해합니다. 사용자의 관할권이 이에 해당하는 경우, 법률이 허용하는 범위 내에서 "FLIPFLOP"은 그러한 보증이나 조건에 근거한 청구에 대한 "FLIPFLOP"의 책임을 사용자에게 본 서비스를 다시 제공하는 것 또는 본 서비스를 사용에게 다시 제공하는데 소요되는 비용으로 한정합니다.
          사용자는 특별히 "FLIPFLOP"은 제3자의 콘텐츠 또는 비방, 공격적이거나 불법적인 행위에 대하여 책임지지 아니하며, 그러한 콘텐츠나 행위로 인한 위해 또는 손해의 위험을 전적으로 사용자가 부담한다는 사실을 인정합니다.`,
        },
        { 
          id: 'C',
          title: `"FLIPFLOP"은 본 서비스가 타지역에서의 사용에 적절하다거나 가능하다고 진술하지 않습니다. 다른 관할지역에서 본 서비스에 접속하거나 이용하는 이용자들은 스스로의 결단으로서 본 서비스에 접속하거나 이용하는 것이고, 현지 법률을 준수할 책임이 있습니다.`,
        },
      ] 
    },
    {
      id: 11,
      title: `면책`,
      clauses: [
        { 
          id: 'A',
          title: `법률이 허용하는 범위 내에서, 사용은`,
          details: [
            { 
              id: '1)',
              title: `사용자의 본 서비스 이용 및 접속`
            },
            {
              id: '2)',
              title: `사용자의 본 약관 규정의 위반,`
            },
            {
              id: '3)',
              title: `사용자의 제3자의 저작권, 재산권 내지 프라이버시권 등 권리침해,`
            },
            {
              id: '4)',
              title: `사용자에 의한 와전된 내용으로 인한 피해`
            },
            {
              id: '5)',
              title: `제 3사업자의 저작권 및 여러 권리에 의거한 사용자의 위반행위`
            },
            {
              id: '6)',
              title: `사용자의 콘텐츠가 제3자에게 손해를 야기했다는 주장으로 인한 일체의 청구, 손해, 의무, 손실, 책임, 비용 및 부채와 경비(변호사 비용을 포함하나 이에 한정되지 않음)로부터 "FLIPFLOP"의 임직원, 이사 및 대리인을 보호하고 면책하며 이로 인하여 손해를 입지 않도록 하기로 동의합니다. 이러한 방어 및 면책 의무는 본 약관이나 사용자의 본 서비스 이용이 종료된 이후에도 유효하게 존속합니다.`
            },
          ]
        },
      ],
    },
    {
      id: 12,
      title: `본 약관의 수락능력`,
      clauses: [
        { 
          id: 'A',
          title: `사용자는 사용자가 18세 이상이거나, 미성년이지만 성인으로서의 권한을 가지거나, 법적인 부모 또는 후견인의 동의를 받았으며, 본 약관에 규정된 조건, 의무, 확인, 진술 및 보증에 합의하고 본 약관을 준수할 수 있는 완전한 권한이 있음을 확인합니다. 본 서비스는 14세 미만의 아동을 대상으로 한 서비스가 아니므로 어떠한 경우라도 사용자는 사용자가 14세를 넘었음을 확인합니다. 사용자가 14세 미만인 경우에는 본 서비스를 이용하실 수 없습니다. 사용자에게 적합한 다양한 웹사이트가 있으므로, 사용자에게 적절한 사이트에 대하여 부모님과 상의하시기 바랍니다. 만약 14세 이하의 사용자가 가입 및 비디오 콘텐츠를 제공했을 경우에는 보호자의 동의하에 보호자와 함께 콘텐츠를 제공한것으로 본 약관을 근거로 인지합니다.
          당사는 사용자의 본 서비스 이용에 어떠한 조건이 적용되는지 사용자가 알 수 있도록 본 서비스와 함께 본 약관을 제공합니다. 사용자는 당사자 본 약관을 검토할 수 있는 합리적인 기회를 사용자에게 제공 하였으며 사용자가 이에 동의 하였음을 인정합니다.`,
        },
      ]
    },
    {
      id: 13,
      title: `양도`,
      clauses: [
        { 
          id: 'A',
          title: `사용자는 사용자가 18세 이상이거나, 미성년이지만 성인으로서의 권한을 가지거나, 법적인 부모 또는 후견인의 동의를 받았으며, 본 약관에 규정된 조건, 의무, 확인, 진술 및 보증에 합의하고 본 약관을 준수할 수 있는 완전한 권한이 있음을 확인합니다. 본 서비스는 14세 미만의 아동을 대상으로 한 서비스가 아니므로 어떠한 경우라도 사용자는 사용자가 14세를 넘었음을 확인합니다. 사용자가 14세 미만인 경우에는 본 서비스를 이용하실 수 없습니다. 사용자에게 적합한 다양한 웹사이트가 있으므로, 사용자에게 적절한 사이트에 대하여 부모님과 상의하시기 바랍니다. 만약 14세 이하의 사용자가 가입 및 비디오 콘텐츠를 제공했을 경우에는 보호자의 동의하에 보호자와 함께 콘텐츠를 제공한것으로 본 약관을 근거로 인지합니다.
          당사는 사용자의 본 서비스 이용에 어떠한 조건이 적용되는지 사용자가 알 수 있도록 본 서비스와 함께 본 약관을 제공합니다. 사용자는 당사자 본 약관을 검토할 수 있는 합리적인 기회를 사용자에게 제공 하였으며 사용자가 이에 동의 하였음을 인정합니다.`,
        },
      ]
    },
    {
      id: 14,
      title: `일반사항`,
      footer: '2021년 1월 1일',
      clauses: [
        { 
          id: 'A',
          title: `사용자는 본서비스가 대한민국에만 근거를 두고 있는것으로 간주되며, 본서비스가 대한민국이외의 관할권에서는 특별 또는 일반을 불문하고 "FLIPFLOP"에 대하여 대인관할을 야기하지 않는 수동적인 수동적인 웹사이트로 간주된다는 점에 동의합니다. 사용자는 본 약관에 대한민국의 국제사법 규정 이외의 대한민국 법률이 적용된다는 점에 동의합니다. 또한, 본 서비스로 인하여 혹은 관련하여 발생하는 분쟁의 경우, 당사자들은 대한민국 법원의 대인관할 및 전속 법정지에 동의합니다. 본약관은 개인정보 취급방침 및 기타 본 서비스에서 "FLIPFLOP"가 공시하는 법적고지와 함께 본 서비스에 관한 사용자와 "FLIPFLOP"간의 완전한 합의를 구성합니다. 특정 조항이 집행 불가능한 것으로 판명될 경우, 이는 다른 조항들에 영향을 미치지 않아야 합니다. 본 약관의 일부 조항에 대한 포기는 해당 조항에 대한 이후의 추가적인 포기나 다른 조항에 대한 포기로 간주되지 않으며, 본 약관상의 권리 또는 규정을 "FLIPFLOP"가 주장하지 아니하는 경우에도 이는 해당 권리 또는 규정에 대한 포기로 간주되지 않습니다. 사용자와 "FLIPFLOP"은 본 서비스로 인하여 또는 본 서비스와 관련하여 청구원인이 발생하는 경우, 발생일로부터 1년 이내에 개시되어야 하기로 합의합니다. 1년이 경과하면 해당 청구 원인은 영구적으로 청구할 수 없습니다.`,
        },
      ]
    },
  ]
}


export type Terms = {
  title: string
  desc: string
  articles: Article[]
}

type Article = {
  id: number
  title: string
  desc?: string | null
  clauses: Clause[]
  footer?: string | null
}

type Clause = {
  id: string
  title: string
  details?: Detail[]
}

type Detail = {
  id: string
  title: string
}