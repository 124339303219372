import React, { ReactNode } from 'react';
import { FormBullet } from '../assets/svg';

interface ApplicationInfoCardProps {
  title: string | ReactNode;
  value: string | number
  subValue?: string | number
  subTitle?: string
  moreButton?: ReactNode
}

function ApplicationInfoCard({title, subTitle, value, subValue, moreButton}: ApplicationInfoCardProps) {
  return (
    <div className="info-item-group">
      <div className="info-item-header-wrap">
        <div className="info-item-header"><FormBullet /><span className="info-title">{title}</span>{subTitle && <small className="info-subtitle">{subTitle}</small>}</div>
        {moreButton}
      </div>
      <div className="info-item-content"><span>{value}</span>{subValue && <span>{subValue}</span>}</div>
    </div>
  )
}

interface Props {
  title: string | ReactNode
  appInfoList : ApplicationInfoCardProps[]
}

export default function OverviewApplicationInfo({title, appInfoList}: Props): JSX.Element {
  return (
    <div className="overview application-info-overview">
      <div className="overview-header">
        <h5 className="card-title">{title}</h5>
      </div>
      <div className="card overview-item application-info-item">
        {appInfoList.map((info, i) => <ApplicationInfoCard key={i} title={info.title} subTitle={info.subTitle} value={info.value} subValue={info.subValue} moreButton={info.moreButton}/>)}
      </div>
    </div>
  )
}