import * as React from 'react'
import { Messages } from '../config'
import BreadCrumb from '../components/BreadCrumb'
import MemberInfoItem from './MemberInfoItem'
import PasswordItem from './PasswordItem'
import { MemberInfo, updateMember } from './Auth'
import Button from '../components/button/Button'
import Toast from '../components/Toast'
import { avatarValidator, firstnameValidator, lastnameValidator, newPasswordValidator, passwordValidator, componayValidator} from '../config/Validator'
import { Link } from 'react-router-dom'


interface Props {
  member: MemberInfo
  onUpdate(member: MemberInfo): void
}

interface State {
  profile: {}
}

class ProfileContent extends React.Component<Props, State> {  
  state: State = {
    profile: {},
  }

  initProfile = (): void => {
    const profile = this.getProfile()
    this.setState({ profile })
  }

  getProfile = (): {} => {
    const { member } = this.props
    const profile = {
      email: member.email,
      avatar: member.avatarUrl,
      firstname: member.firstName,
      lastname: member.lastName,
      company: member.company
    }

    console.log(profile)
    return profile
  }

  onChange = (profile: {}): void => {
    console.log(profile)
    this.setState({ profile })
  }

  onSave = (): void => {
    const errorMessage = this.handleValidation()
    console.log("errorMessage", errorMessage)

    if(errorMessage.length > 0) {
      Toast.notify(errorMessage[0])
      return;
    }

    const { profile }: any = this.state
    console.log(profile)

    const request = {
      prev_password: profile.prePassword,
      new_password: profile.newPassword,
      first_name: profile.firstname,
      last_name: profile.lastname,
      profile_photo_url: profile.avatar,
      company: profile.company,
    }

    profile.prePassword = ''
    profile.newPassword = ''
    this.setState({ profile })

    updateMember(request, (member) => {
      Toast.notify('Your profile has been updated.');
      this.props.onUpdate(member)
    })
  }

  handleValidation = (): string[] => {
    const { profile }: any = this.state

    const validator = {
      password: passwordValidator(profile?.password),
      newPassword: newPasswordValidator(profile?.newPassword),
      firstname: firstnameValidator(profile?.firstname),
      lastname: lastnameValidator(profile?.lastname),
      avatar: avatarValidator(profile?.avatar),
      company: componayValidator(profile?.company)
    }

    console.log("validator", validator)

    return Object.values(validator).filter(error => error)
  }

  componentDidMount (): void {
    this.initProfile()
  }

  public render = (): JSX.Element | null => {
    const { profile } = this.state

    const member = Object.keys(profile).length === 0 ? 
      this.getProfile() : profile

    return (
      <div className="dashboard-content col-xl-10">
        <BreadCrumb names={['Account Settings']} />
        <h5 className="content-title">{Messages.PROFILE_TITLE}</h5>
        <div className="content-body profile-body">
          <MemberInfoItem member={member} onChange={this.onChange} />
          {this.props.member?.email.split('@')[1] !== 'gmail.com' && <PasswordItem member={member} onChange={this.onChange} />}
        </div>
        <div className="button-group">
          <Link to="/dashboard"><Button type="button" size="large" color="white" >Cancel</Button></Link>
          <Button type="submit" size="large" color="red" onClick={this.onSave}>Save</Button>
        </div>
      </div>
    )
  }
}

export default ProfileContent