import * as React from 'react'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { StoreState } from '../modules'
import { actions as loginActions } from '../modules/login'
import './LoginContainer.scss'
import PasswordResetForm from './PasswordResetForm'

interface Props {
  content: number
  actions: typeof loginActions
}

class LoginContainer extends React.Component<Props> {
  public render(): JSX.Element {
    return <div className="login-container">{
      this.props.content === 91 ? <PasswordResetForm /> : 
      this.props.content === 1 ? <SignUpForm /> : 
      <SignInForm />}</div>
  }
}

export default connect(
  ({ content }: StoreState) => ({
    content: content.content,
  }),
  (dispatch) => ({
    actions: bindActionCreators(loginActions, dispatch),
  }),
)(LoginContainer)
