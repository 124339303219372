import * as React from 'react';
import { ApplicationInfo } from './Applications';
import ApplicationItem from './ApplicationItem';
import { actions as appContentActions } from '../modules/application'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '../components/button/Button';
import Title from '../components/title/Title';
import Messages from '../config/Messages';
import SearchInput from '../components/input/SearchInput';
import { Config } from '../config';
import { Add, NoContent } from '../assets/svg';
import { MemberInfo } from '../account/Auth';
import { Link } from 'react-router-dom';

const AppUserImage = Config.IMAGE_BASE_URL + 'AppUserImage.png';

interface Props {
  applications: ApplicationInfo[]
  member: MemberInfo
  actions: typeof appContentActions
  refreshApplication(): void
  searchApplication(search: string): void
}

interface State {
  search: string
}

class ApplicationTable extends React.Component<Props, State> {
  state = {
    search: ''
  }

  onChangeSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    if (value === ' ') {
      return
    }

    this.setState({ search: value })
  }

  createApplication = () => {
    this.props.actions.setContent({ content: 1 })
  }

  search = () => {
    this.props.searchApplication(this.state.search)
  }

  public render() {
    const { member } = this.props;

    if (!this.props.applications) {
      return null
    }

    const applications = this.props.applications.map((app, index) => (
      <ApplicationItem key={index} app={app} refreshApplication={this.props.refreshApplication} />
    ))

    console.log("member", member);
    
    return (
      <div className="application-content text-left">
        <div className="application-user-content-group">
          <Link to="/profile"><img src={member?.avatarUrl || AppUserImage} width="72" height="72"/></Link>
          <div className="title-group">
            <Title size="small" color="black">{`${member?.lastName || ""} ${member?.firstName || ""}`}</Title>
            <p className="desc">{`${applications.length} ${applications.length > 1 ? 'applications' : 'application'}`}</p>
          </div>
        </div>
        <div className="title-bar">
          <div className="title-group">
            <p className="title">{Messages.APP_CONTENT_TITLE}</p>
            <p className="desc">{`${Messages.APP_CONTENT_DESC} ${applications.length} ${applications.length > 1 ? 'applications' : 'application'}`}</p>
          </div>
          <div className="tool-bar">
            <SearchInput label="Search applications" value={this.state.search} onChange={this.onChangeSearch} onSearch={this.search} maxLength={50} />
            <div className="tool-divider" />
            <Button size="create" color="red" disabled={this.props.applications.length > Config.MAX_OWN_APPLICATIONS - 1} onClick={this.createApplication}>
              <span>Create</span> <Add />
            </Button>
          </div>
        </div>
        <table className="table table-hover application-table">
          <thead>
            <tr>
              <th scope="col" className="w-30">Name</th>
              <th scope="col" className="w-10 text-center">On Air</th>
              <th scope="col" className="w-10 text-center">Total Live</th>
              <th scope="col" className="w-12 text-center">Total VOD</th>
              <th scope="col" className="w-15">Created</th>
              <th scope="col" className="w-10">Plan</th>
              <th scope="col" className="w-10"></th>
            </tr>
          </thead>
          <tbody>
            { applications }
          </tbody>
        </table>
        { applications.length === 0 ?
          <div className="no-content">
            <NoContent />
            <span>{Messages.NO_APPLICATION}</span>
          </div> : ''}
      </div>
    )
  }
}

export default connect(
	(state) => ({
    member: state.login.member
  }),
	(dispatch) => ({
    actions: bindActionCreators(appContentActions, dispatch)
  })
)(ApplicationTable)