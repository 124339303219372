import * as React from 'react'
import { FormBullet } from '../assets/svg'
import './OverviewItem.scss'


interface Props {
  itemList: Array<any>
}

interface ItemProps {
  label: string
  value: any
}

const TextItem: React.SFC<ItemProps> = ({ label, value }) => (
  <li className="list-group-item">
    <label>
      <FormBullet />
      <span>{label}</span>
    </label>
    <span className="item-value">{value}</span>
  </li>
)

class OverviewItem extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { itemList } = this.props

    return (
      <div className="card overview-item">
        <div className="card-body overview-body">
          <ul className="list-group list-group-flush">
            {itemList.map(item => 
              <TextItem key={item.key} label={`${item.key}`} value={item.value} />
            )}
          </ul>
        </div>
      </div>
    )
  }
}

export default OverviewItem
