import * as React from 'react';
import { Config, Store } from '../config'
import { actions as loginActions } from '../modules/login'
import { actions as contentActions } from '../modules/content'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../modules';
import HomeRouter from './HomeRouter';
import LoginContainer from './LoginContainer';
import { Location } from 'history';
import Footer from '../components/Footer';
import DashboardHeader from '../components/DashboardHeader';

interface Props {
  content: number
  isLogin: boolean
  logins: typeof loginActions
  contents: typeof contentActions
  location: Location
}

class Home extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props): boolean {
    // 로그인, 로그아웃, 혹은 url 변경시
    if(nextProps.location.pathname !== this.props.location.pathname || nextProps.content !== this.props.content || nextProps.isLogin !== this.props.isLogin) {
      window.scrollTo({top: 0})
      return true
    }
    return false
  }

  public render() {
    const { isLogin } = this.props
    return (
      <div className="container-fluid">
        <DashboardHeader />
        <div className="app-body">
          { !isLogin ? 
            <LoginContainer /> : 
            <HomeRouter hasFooter={isLogin} /> }
        </div>
        { isLogin && <Footer />}
      </div>
    )
  }
}

export default connect(
	({ content, login } : StoreState ) => ({
    content: content.content,
    isLogin: login.isLogin,
	}),
	(dispatch) => ({
    logins: bindActionCreators(loginActions, dispatch),
    contents: bindActionCreators(contentActions, dispatch)
  })
)(Home)