import * as React from 'react'

export default function Play(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <defs>
          <filter id="sz37azk6oa">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.996078 0 0 0 0 0.360784 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
          </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
          <g>
              <g filter="url(#sz37azk6oa)" transform="translate(-1749 -39) translate(1709 24)">
                  <g>
                      <path d="M0 0H20V20H0z" transform="translate(40 15)"/>
                      <path fill="#FFF" fillRule="nonzero" d="M10 4l6.123 10.496c.278.477.117 1.09-.36 1.368-.153.089-.327.136-.504.136H4.741c-.552 0-1-.448-1-1 0-.177.047-.35.136-.504L10 4z" transform="translate(40 15) rotate(90 10 10)"/>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  )
}
