import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DashboardHeader from '../components/DashboardHeader'
import { StoreState } from '../modules'
import { actions as loginActions } from '../modules/login'
import EmailVerifiactionForm from './EmailVerifiactionForm'
import './LoginContainer.scss'

interface Props {
  content: number
  actions: typeof loginActions
}

class EmailVerificationContainer extends React.Component<Props> {
  public render() {
    console.log(this.props.content)

    return (
      <div className="container-fluid">
        <DashboardHeader />
        <div className="login-container">
          <EmailVerifiactionForm />
        </div>
      </div>
    )
  }
}

export default connect(
  ({ content }: StoreState) => ({
    content: content.content,
  }),
  (dispatch) => ({
    actions: bindActionCreators(loginActions, dispatch),
  }),
)(EmailVerificationContainer)
