import * as React from 'react'

interface Props {
  className?: string
}
export default function OverviewNor({ className }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path fillRule="nonzero" d="M0 0H20V20H0z" transform="translate(-400 -189) translate(390 179) translate(10 10)" />
              <path fill="#C4C4C4" d="M6 3h8c1.657 0 3 1.343 3 3v8c0 1.657-1.343 3-3 3H6c-1.657 0-3-1.343-3-3V6c0-1.657 1.343-3 3-3z" transform="translate(-400 -189) translate(390 179) translate(10 10)" className={className} />
              <path fill="#FFF" d="M7.5 6h5c.276 0 .5.224.5.5s-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zM7.5 9h5c.276 0 .5.224.5.5s-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zM7.5 12h5c.276 0 .5.224.5.5s-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5z" transform="translate(-400 -189) translate(390 179) translate(10 10)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
