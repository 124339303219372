import * as React from 'react';
import "./UploadVideoPlayer.scss";

const playerStyle = {
  backgroundColor: '#F5F5F5'
};

const PlayerPoster: React.FunctionComponent<Props> = ({ src, poster }) => {
  console.log(poster)
  const posterStyle = {
    background: `url(${poster ? poster : ''})  center center / contain #000 no-repeat`
  }
  return (
    <div className="player-container">
      <div>
        <video id="video-player" className="embed-responsive-item" controls={true} autoPlay muted x-webkit-ariplay="allow" webkit-playsinline="allow" preload="auto" playsInline={true} style={playerStyle} src={src}/>
      </div>
      <div style={posterStyle}/>
    </div>
  );
}

interface Props {
  src: string,
  poster: string
}

class UploadVideoPlayer extends React.Component<Props> {

  public render = (): JSX.Element | null => {
    const { src, poster } = this.props;

    if (!src) {
      return null;
    }

    return (
      <div id="video-preview-form-group" className="form-group">
        <PlayerPoster src={src} poster={poster} />
      </div>
    );
  }
}

export default UploadVideoPlayer;