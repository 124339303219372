import * as React from 'react';
import { CheckBox } from '../components/CheckBox';
import { actions as userActions } from '../modules/user'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../modules';
import UserItem from './UserItem';
import { UserInfo } from './Users';
import { Messages } from '../config';
import { NoContent } from '../assets/svg';


interface Props {
  list: UserInfo[]
  checkList: UserInfo[]
  checkedAll: boolean
  actions: typeof userActions
  onBlock(user: UserInfo): void
  onUnBlock(user: UserInfo): void
}

interface State {
}

class UserList extends React.Component<Props, State> {

  state: State = {
  }

  checkedAll = (): void => {
    const { checkedAll, actions } = this.props
    console.log(`${checkedAll}`)

    if (!checkedAll) {
      actions.checkedAll()
    } else {
      actions.uncheckedAll()
    }
  }

  public render = (): JSX.Element => {
    const { list, checkList, checkedAll } = this.props
    console.log(checkList)

    console.log(`checkedAll: ${checkedAll}`)
    const users = list.map((u, index) => (
      <UserItem key={index} user={u} checked={checkList.findIndex(c => c.userId === u.userId) >= 0} onBlock={() => this.props.onBlock(u)} onUnBlock={() => this.props.onUnBlock(u)}/>
    ))

    return (
      <>
        <table className="table table-hover user-table">
          <thead>
            <tr>
              <th scope="col" className="w-5">
                <CheckBox id={'all'} value={'all'} onChange={this.checkedAll} checked={checkedAll} />
              </th>
              <th scope="col" className="w-25">ID</th>
              <th scope="col" className="w-20">Username</th>
              <th scope="col" className="w-20">Created</th>
              <th scope="col" className="w-20">Updated</th>
              <th scope="col" className="w-10"></th>
            </tr>
          </thead>
          <tbody>
          { users }
          </tbody>
        </table>
        { users.length === 0 ?
          <div className="no-content">
            <NoContent />
            <span>{Messages.NO_USER}</span>
          </div> : ''}
      </>
    )
  }
} 

export default connect(
	({ user } : StoreState) => ({
    list: user.list,
    checkList: user.checkList,
    checkedAll: user.checkedAll,
    page: user.page,
    pageRequest: user.pageRequest
	}),
	(dispatch) => ({
    actions: bindActionCreators(userActions, dispatch)
  })
)(UserList)
