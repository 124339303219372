import * as React from 'react'
import { actions as appContentActions } from '../modules/application'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ApplicationInfo } from '../application/Applications'
import { StoreState } from '../modules'
import TextInput from '../components/input/TextInput'
import Button from '../components/button/Button'
import './StreamKeyForm.scss'
import { saveStreamKey } from './Webinars'
import Toast from '../components/Toast'
import { MAX_LENGTH } from '../config/Config'


interface Props {
  app: ApplicationInfo
  actions: typeof appContentActions
  onCancel(): void
  refresh(): void
}

interface State {
  nickname: string
  nameError: string
}

class ApplicationForm extends React.Component<Props> {
  state: State = {
    nickname: '',
    nameError: ''
  }

  onChangeNickname = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget

    if (value === '') {
      this.setState({ nickname: value, nameError: 'Please enter your Application name' })
    } else {
      this.setState({ nickname: value, nameError: '' })
    }
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { nickname } = this.state

    if (nickname === '') {
      this.setState({ nameError: 'Please enter nickname' })
      return
    }
    this.setState({ nameError: '' })

    const request = {
      nickname,
      app_key: this.props.app.appKey,
    }

    saveStreamKey(request).then(
      () => {
        Toast.notify('Saved successfully')
        this.props.refresh()
      }, (err: any) => {
        console.error(err)
        Toast.warn('Failed to save the Stream Key')
      },
    )
  }

  public render = (): JSX.Element => {
    return (
      <form className="application-form" onSubmit={this.handleSubmit}>
        <TextInput type="text" name="Nickname" value={this.state.nickname} onChange={this.onChangeNickname} label="Nickname" maxLength={MAX_LENGTH.streamKey} required error={this.state.nameError} />
        <div className="button">
          <Button type="button" size="large" color="white" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button type="submit" size="large" color="red">
            Ok
          </Button>
        </div>
      </form>
    )
  }
}

export default connect(
  ({ application }: StoreState) => ({
    app: application.application,
  }),
  (dispatch) => ({
    actions: bindActionCreators(appContentActions, dispatch),
  })
)(ApplicationForm)
