import * as React from 'react'

export default function DoublePrev(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" opacity=".3">
        <g fillRule="nonzero">
          <g>
            <g>
              <path d="M0 0H20V20H0z" transform="translate(-863 -879) translate(853 874) translate(10 5)"/>
              <path fill="#000" d="M15 6v8l-6-4 6-4zM9 6v8l-6-4 6-4z" transform="translate(-863 -879) translate(853 874) translate(10 5)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}