import React, { Component } from 'react';
import ChatList from './ChatList';
import "./MonitorChatBox.scss";
import { VideoInfo } from './Video';

interface Props {
  video: VideoInfo
  messages: any[]
  loadMoreChat(): void 
}

export default class VodMonitorChatBox extends Component<Props> {
  render (): JSX.Element {
    return (
      <div className="monitor-chatbox-group vod-monitor-chatbox">
        <div className="monitor-box-title">채팅 메시지</div>
        <div className="monitor-chatbox-content vod-monitor-chatbox-content">
          <ChatList messages={this.props.messages} loadMoreChat={this.props.loadMoreChat}/>
        </div>
      </div>
    )
  }
}