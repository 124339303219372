import * as React from 'react'
import './TextInput.scss'

interface Props {
  label?: string
  id?: string
  type?: string
  value: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  description?: string | React.ReactNode
  minLength?: number
  maxLength?: number
  placeholder?: string
  error?: string
  [key: string]: any
}

export default function TextInput({ type, label, value, id, error, required, onChange, description, minLength, maxLength, placeholder, ...props }: Props): JSX.Element {
  return (
    <div className="input-group">
      {label && <label htmlFor={id || label}>
        <span>{`${label} `}</span>
        {required && <span className="input-required">*</span>}
      </label>}
      <input type={type || "text"} value={value} id={id || label} onChange={onChange} minLength={minLength} maxLength={maxLength} placeholder={placeholder} {...props} />
      {error ? <span className="input-error-message">{`* ${error}`}</span> : description && <div className="input-text">{description}</div>}
    </div>
  )
}
