import * as React from 'react'
import QuotaChart from '../components/chart/QuotaChart'
import { OverviewInfo } from './Overview';
import "./OverviewQuota.scss"

interface Props {
  overview: OverviewInfo
}

export default function OverviewQuota({ overview }: Props): JSX.Element {
  const quotaData = [
    {title: 'Live', max: overview.totalLiveLimit < 0 ? 0 : overview.totalLiveLimit , current: overview.totalLiveCount},
    {title: 'VOD', max: overview.totalVodLimit < 0 ? 0 : overview.totalVodLimit, current: overview.totalVodCount},
    // FIXME: add app s3 storage
    {title: 'S3 storage', max: overview.storageLimit, current: overview.storageUsed, unit: "G"},
  ];

  return (
    <>
      <div className="col-xl-12 overview quota-overview">
        <div className="overview-header">
          <h5 className="card-title">Quota</h5>
        </div>
        <div className="card overview-item">
          <div className="card-body overview-body">
            <div className="quota-chart-group">
              {quotaData.map(data => <QuotaChart key={data.title} data={data} />)}
            </div>
            <div className="chart-label-group">
              <div className="chart-label">
                <div className="circle red" />
                Current application
              </div>
              <div className="chart-label">
                <div className="circle white" />
                Remaining
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
