import { Config } from '.'
const FlipflopLite = Config.IMAGE_BASE_URL +'FlipflopLite.png'
const FlipflopLive = Config.IMAGE_BASE_URL +'FlipflopLive.png'
const FlipflopWebinar = Config.IMAGE_BASE_URL +'FlipflopWebinar.png'
const Live = Config.IMAGE_BASE_URL +'Live.png'
const Transcoder = Config.IMAGE_BASE_URL +'Transcoder.png'
const Webinar = Config.IMAGE_BASE_URL +'Webinar.png'

const planTitle = `FLIPFLOP Plan`
const planContent = `FLIPFLOP is providing price plans in terms of your business scope and usage.  
Each plan has different features of functions. Please check out FLIPFLOP price plan carefully and choose one.`

const appTitle = `FLIPFLOP`
const appContent = `FlipFlop is a SaaS suite for embracing video technology into your product.
Starting from simply transcoding videos to live streaming for various purposes for you to
incorporate video technology for your own needs.`

const appContentTitle = `Applications`
const appContentDesc = `The organization can have up to ${Config.MAX_OWN_APPLICATIONS} applications and currently has`
const noApplication = `There is no application.`
const noUser = `There is no user.`
const noVideo = `There is no video.`
const noStreamKeys = `There is no stream keys.`

const streamKeyTitle = `Stream Key`
const streamKeyDesc = `The organization can have up to ${Config.MAX_OWN_STREAMKEY} stream keys and currently has`

const progressBarDesc = `Please do not close this window until upload completes.`
const videojsNoJS = `To view this video please enable JavaScript, and consider upgrading to a web browser that`

const signupPolicy = `which includes my consent to receive marketing
information from FLIPFLOP. I can unsubscribe from
marketing communications at any time.`

const whatsWebinar = ['웨비나란 OBS Studio를 사용하여 방송하는 것을 의미합니다. 먼저 OBS Studio를 준비해 주세요.']

const howToUseWebinar = [
  '방송을 테스트해 보려면, 방송을 하나 생성해 주세요',
  '스트림 키는 방송의 주소입니다. 스트림 키가 없으면 자동으로 생성되고, 있으면 원하는 스트림 키를 선택해 주세요.',
  '방송이 시작되면, RTMP 주소와 스트림 키가 생성됩니다.',
  'OBS에 아래의 RTMP 주소와 스트림 키를 사용해서 설정해 주세요.',
  'OBS에서 방송을 시작하면 아래의 Preview 페이지에서 방송이 나오기 시작합니다.',
  'OBS Studio에서 방송을 끄면 방송이 종료됩니다.',
]

const demoContents = {
  resetChangeDesc: `* 채널이 같은 영상만 볼 수 있습니다.`,
  resetChangeAlert: `* 주의) 데모는 최근 1일의 영상만 다시 볼 수 있고, 시간이 지난 데모 영상은 자동 폐기됩니다.`,
  liveDescription: ['* 라이브는 최대 5분까지 진행되며 5분 경과 후 자동 종료 됩니다.', '* 라이브를 공유하여 다른 사용자와 공유할 수 있습니다.'],
  description: `If you log-in to the flipflop service, you can see more information at once. Log-in right now.`,
  sampleDescription: [
    `라이브 방송 관리자 데모
관리자 데모페이지 에서는 채팅메세지와 다이텍트 메세지를 활용 할 수 있습니다.`,
    `또한 현재 시청자수, 동시 시청자수,  전체 메세지수의 통계를 실시간 데이터로 볼 수 있습니다.`,
  ],
  endDesciption: `라이브가 종료되었습니다. 다시 라이브를 시작해 보세요.
라이브 데모는 최대 5분동안 사용 하실 수 있습니다.`,
}

const landingContents = {
  title: `The next
video standard for all
contents and devices.`,
  content: `Most efficient solution for video transcoding, 
live streaming, and webinars.`,
  palnDesc: `FLIPFLOP is providing price plans in terms of your business scope and usage.  Each plan has different features of functions. 
Please check out FLIPFLOP price plan carefully and choose one.`,
  demoDesc: `Build your first video experiences for your business.`,
  demoContent: `a. If you choose FF Lite, you can upload video file by 10, 2 videos at the same time
b. In case of Flipflop Live, 3 live videos per day.`,
  contactDesc: `Contact us to experience FLIPFLOP’s unique live streaming and video playback features.`,

  mobileTitle: `The next
video standard for
all contents and
devices.`,
  mobileContent: `Most efficient solution for video transcoding, live streaming,
and webinars.`,

  productContents: [
    {
      title: `FLIPFLOP Lite`,
      description: `Best way to provide features for online video ecosystem for your users to your platform.`,
      content: [`a. API and SDK for easy integration to your platform.`, `b. Unmatched processing capability for massive amount of video uploads.`, `c. Fine control video accessibility for different groups of audience.`],
      image: FlipflopLite,
    },
    {
      title: `FLIPFLOP Live`,
      description: `Optimized for various live streaming protocols.`,
      content: [`a. Use RTMP/WebRTC/HLS according to your needs.`, `b. API and SDK for easy implementation of live streaming applications. (chat & ecommerce)`, `c. Easy to use dashboard for controlling features and viewer analysis.`],
      image: FlipflopLive,
    },
    {
      title: `FLIPFLOP Webinar`,
      description: `Webinar solution for massive audience around the world.`,
      content: [`a. Proven solution that works.`, `b. Live stream ingestion with 3rd party software. (OBS, vMix)`, `c. Live stream high quality video world wide.`, `d. Bringing the streamer and the audience closer. (live chat and QnA)`],
      image: FlipflopWebinar,
    },
  ],

  planContents: [
    {
      title: `Live`,
      content: `Live (Commerce) Streaming.`,
      image: Live,
    },
    {
      title: `Transcoder`,
      content: `Live Webinar (1:1, 1:Many, Many:Many, Screen transferring / Video Conference screen transferring)`,
      image: Transcoder,
    },
    {
      title: `Webinar`,
      content: `Video Transcoding 
(Video transcoding / Compression)`,
      image: Webinar,
    },
  ],

  clientLogos: [
    {
      Key: 'clients/logo01-228-x-100-hyd-home.png',
    },
    {
      Key: 'clients/logo02-228-x-100-hmall.png',
    },
    {
      Key: 'clients/logo03-228-x-100-shin-inc.png',
    },
    {
      Key: 'clients/logo04-228-x-100-si-village.png',
    },
    {
      Key: 'clients/logo05-228-x-100-mbc.png',
    },
    {
      Key: 'clients/logo06-228-x-100-skt.png',
    },
    {
      Key: 'clients/logo07-228-x-100-amore.png',
    },
    {
      Key: 'clients/logo08-228-x-100-amazer.png',
    },
    {
      Key: 'clients/logo09-228-x-100-snow.png',
    },
    {
      Key: 'clients/logo10-228-x-100-herald.png',
    },
  ],
}

export default {
  PLAN_TITLE: planTitle,
  PLAN_CONTENT: planContent,
  APP_TITLE: appTitle,
  APP_CONTENT: appContent,
  APP_CONTENT_TITLE: appContentTitle,
  APP_CONTENT_DESC: appContentDesc,
  LANDING_CONTENTS: landingContents,
  DEMO_CONTENTS: demoContents,
  STREAMKEY_TITLE: streamKeyTitle,
  STREAMKEY_DESC: streamKeyDesc,
  TERMS_OF_SERVICE: 'Terms of Service',
  PRIVACY_POLICY: 'Privacy Policy',
  COMPANY_INFO_URL: 'https://static.jocoos.com/home/companyInfo.json',
  PHONE: '+82-70-4827-0981',
  EMAIL: 'support@jocoos.com',
  ADDRESS: '2F, 16-12 Yanghwa-ro 8-gil, mapo-gu, Seoul, 04044, Republic of Korea',
  NO_APPLICATION: noApplication,
  NO_USER: noUser,
  NO_VIDEO: noVideo,
  NO_STREAM_KEYS: noStreamKeys,
  PROGRESS_BAR_DESC: progressBarDesc,
  VIDEOJS_NO_JS: videojsNoJS,
  PROFILE_TITLE: 'Profile',
  SIGNUP_POLICY: signupPolicy,
  WHATS_WEBINAR: whatsWebinar,
  HOW_TO_USE_WEBINAR: howToUseWebinar,
  INVALID_PASSWORD_RESET_EMAIL: 'Your email address is invalid. Please enter a valid address.',
  SESSION_EXPIRED: 'Your session is expired. please login again.',
  PASSWORD_MIN_LENGTH: 'Please enter password at least 6 characters.',
  CONFIRM_PASSWORD_MIN_LENGTH: 'Please enter confirm password at least 6 characters.',
}
