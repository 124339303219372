import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as webinarActions } from '../modules/webinar'
import { CheckBox } from '../components/CheckBox';
import { deleteStreamKey, StreamKeyInfo } from './Webinars';
import { MoreIcon } from '../assets/svg';
import Confirm from '../components/modal/Confirm';
import Dropdown from '../components/dropdown/Dropdown';
import Toast from '../components/Toast'


enum ModalStateCode {
  Delete = 1,
}

interface Props {
  streamKey: StreamKeyInfo
  checked: boolean
  actions: typeof webinarActions
  refresh(): void
}

interface State { 
  visibleModal: ModalStateCode
}

class StreamKeyItem extends React.Component<Props, State> {
  state = {
    visibleModal: 0,
  }

  toggleConfirmGenerator = (modalCode: ModalStateCode) => (): void => {
    if(this.state.visibleModal === modalCode) {
      this.setState({visibleModal: 0})
    } else {
      this.setState({visibleModal: modalCode})
    }
  }

  onChange = (id: string, checked: boolean): void => {
    const { streamKey, actions } = this.props
    if (checked) {
      actions.checked(streamKey)
    } else {
      actions.unchecked(streamKey)
    }
  }

  toggleDelete = this.toggleConfirmGenerator(ModalStateCode.Delete)

  onDelete = (): void => {
    deleteStreamKey(this.props.streamKey.id)
      .then(res => {
        console.log(res)
        Toast.notify('Deleted successfully')
        this.props.refresh()
      })
  }

  public render() {
    const { streamKey } = this.props
    const options = [
      {
        value: 'Delete',
        onClick: this.toggleDelete,
      }
    ]

    return (
      <>
      <tr>
        <td className="w-5">
          <CheckBox id={'' + streamKey.id} value={'' + streamKey.id} checked={this.props.checked} onChange={this.onChange}/>
        </td>
        <td>{streamKey.nickname}</td>
        <td>{streamKey.streamKey}</td>
        <td>{streamKey.stateAsString}</td>
        <td>{streamKey.createdAt}</td>
        <td className="menu-group">
          <Dropdown>
            <Dropdown.Button><MoreIcon /></Dropdown.Button>
            <Dropdown.Items options={options} />
          </Dropdown>
        </td>
      </tr>
      <Confirm message="Do you want to delete this stream key?" visible={this.state.visibleModal === ModalStateCode.Delete} setVisible={this.toggleDelete} onOk={this.onDelete} />
      </>
    )}
} 

export default connect(
	() => ({}),
	(dispatch) => ({
    actions: bindActionCreators(webinarActions, dispatch)
  })
)(StreamKeyItem)