import * as React from 'react'
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as settingActions } from '../modules/setting'
import SettingInputText from '../components/input/SettingTextInput';
import Radio from '../components/Radio';
import { FormBullet } from '../assets/svg';

export default function WebhookItem(): JSX.Element {
  const { webhook } = useSelector((state) => ({ 
    webhook: state.setting.webhook,
  }));
  
  const dispatch = useDispatch();
  
  const onChangeInput = useCallback((e) => {
    console.log("e.target.value", e.target.value)
    if(e.target.name === 'key') {
      webhook.key = e.target.value;
    } else {
      webhook.roomId = e.target.value;
    }
    dispatch(settingActions.setWebhook(webhook))
  }, [webhook?.key, webhook?.roomId])

  const onChangeRadio = useCallback((type: string) => {
    webhook.type = type;
    dispatch(settingActions.setWebhook(webhook))
  }, [webhook?.type])

  return (
    <div className="settings col-xl-12">
      <div className="settings-header">
        <h5 className="card-title">Webhook</h5>
      </div>
      <div className="card settings-item">
        <div className="card-body overview-body">
          <div className="setting-input-group webhook-type-item">
            <label htmlFor="Type">
              <FormBullet /> <span>Type</span>
            </label>
            <Radio type="radio" checked={webhook?.type === "slack"} id="type1" name="type" value="slack" label="slack" onChange={onChangeRadio}/>
            <Radio type="radio" checked={webhook?.type === "telegram"} id="type2" name="type" value="telegram" label="telegram" onChange={onChangeRadio}/>
          </div>
          <SettingInputText id="key" name="key" value={webhook?.key || ""} onChange={onChangeInput} label="Key" />
          {webhook?.type === "telegram" && <SettingInputText id="roomId" name="roomId" value={webhook?.roomId || ""} onChange={onChangeInput} label="Room Id" />}
        </div>
      </div>
    </div>
  )
}