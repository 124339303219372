import * as React from 'react'
import { PageInfo } from './Paginations'
import PageItem from './PageItem'
import ArrowItem from './ArrowItem'
import PagerConfig from './PagerConfig'
import './Pagination.scss'


interface Props {
  page: PageInfo;
  noItemMessage?: string
  handlePage(page: number): void; 
}

interface State {
  current : number;
}

class Pagination extends React.Component<Props, State> {
  state: State = {
    current: 0
  }

  handlePage = (current: number): void => {
    this.setState({ current });
    this.props.handlePage(current);
    window.scrollTo({top: 0})
  }

  public render = (): JSX.Element => {
    const { page } = this.props;    
    const { handlePage } = this;
    const pages = [];
    const prevPage = (this.state.current >= 1 ? this.state.current - 1 : -1);    
    const nextPage = (page.totalElementes - (page.number + 1) * page.size) > 0 ? page.number + 1 : -1;
    const pagePrevPage = (this.state.current >= 1 ? Math.max(this.state.current - PagerConfig.size, 0) : -1);    
    const pageNextPage = (page.totalElementes - (page.number + 1) * page.size) > 0 ? Math.min(page.number + PagerConfig.size, (page.totalPages - 1)) : -1;


    const prev = <ArrowItem direction='Previous' active={page.prevActived} page={prevPage} handlePage={handlePage}/>;
    const next = <ArrowItem direction='Next' active={page.nextActived} page={nextPage} handlePage={handlePage}/>;
    const pagePrev = <ArrowItem direction='PagePrevious' active={page.pagePrevActived} page={pagePrevPage} handlePage={handlePage}/>;
    const pageNext = <ArrowItem direction='PageNext' active={page.pageNextActived} page={pageNextPage} handlePage={handlePage}/>;


    if (page) {
      let start = 0;
      let end = 0;

      if(page.totalPages <= PagerConfig.size) {
        start = 1;
        end = page.totalPages;
      } else {
        if(this.state.current <= (PagerConfig.size / 2)) {
          start = 1;
          end = 10;
        } else if(this.state.current + (PagerConfig.size / 2) >= page.totalPages) {
          start = page.totalPages - (PagerConfig.size - 1);
          end = page.totalPages
        } else {
          start = this.state.current - (PagerConfig.size / 2 - 1);
          end = this.state.current + (PagerConfig.size / 2);
        }
      }
      
      for (let i = start-1 ; i < end; i++) {
        pages.push(<PageItem key={i} index={i + 1} actived={i === page.number} handlePage={handlePage}/>)
      } 
    }


    return (
      <nav className="pagination" aria-label="Page navigation">
        { pages.length > 0 ?
        <ul className="pagination pagination-sm">
          { pagePrev }
          { prev }
          { pages }
          { next }
          { pageNext }
        </ul>
        : <p className="text-center">{this.props.noItemMessage && this.props.noItemMessage}</p> }
      </nav>
    );
  } 
}

export default Pagination;