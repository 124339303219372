import * as React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../modules';
import { actions as uploadActions } from '../modules/upload'
import { Config } from '../config'
import ProgressBar from '../upload/ProgressBar'
import { uploadVideo } from '../upload/upload'
import UploadForm from './UploadForm'
import { SubmitRequest } from '../upload/Api'
import "./UploadModalContent.scss"
import { padNumber } from './VideoContent'
import Button from '../components/button/Button'
import { Spinner } from '../components/Spinner'
import Toast from '../components/Toast'


interface Props {
  appKey: string,
  appSecret: string
  uploadContent: number
  actions: typeof uploadActions
  onClose(): void
  onCloseAndRefresh(): void
}

interface State {
  stage: number,
  percent: number,
  filename: string,
  url: string,
  thumbnail: string,
}

class UploadModalContent extends React.Component<Props, State> {
  state: State = {
    stage: 0,
    percent: 0,
    filename: '',
    url: '',
    thumbnail: '',
  };
  
  onCloseAndRefresh = (): void => {
    this.handleClear()
    this.props.onCloseAndRefresh()
  }

  handleClose = (): void => {
    this.handleClear()
    this.props.onClose()
  }

  handleClear = (): void => {
    this.setState({
      stage: 0,
      percent: 0,
      filename: '',
      url: '',
      thumbnail: ''
    });
  }

  setProgress = (progress: number): void => {
    console.log('progress: ' + progress);
    if (Config.INIT_PROGRESS >= progress) {
      return;
    }

    this.setState({
      percent: progress
    });
  }

  handleUpload = async (data: SubmitRequest): Promise<any> => {
    if (!data.video.src) {
      return
    }

    const title = data.title
    const content = data.content
    const videoFile = data.video.file
    const videoName = data.video.name
    const thumbnailFile = data.thumbnail.file
    const seek = data.seek
    const duration = data.duration
    const visiblity = data.visiblity

    // show progressbar more fast
    this.setState({
      percent: Config.INIT_PROGRESS,
      filename: videoName
    });

    const formData = new FormData()
    formData.append('file', videoFile)
    formData.append('title', title)
    formData.append('content', content)
    formData.append('preview_start', seek)
    formData.append('preview_duration', duration)
    formData.append('visibility', visiblity.toUpperCase())
    formData.append('type', 'UPLOADED')
    if (thumbnailFile) {
      formData.append('thumbnail', thumbnailFile)
    }

    console.log(formData)

    this.props.actions.setContent({content: 1})

    const token = btoa(`${this.props.appKey}:${this.props.appSecret}`)
    console.log(token)
    
    try {
      await uploadVideo(`${this.props.appKey}`, formData, this.setProgress)
      
      this.props.actions.setContent({content: 2})

      setTimeout(() => {
        this.onCloseAndRefresh();
        Toast.notify('Successfully uploaded')
      }, 2000)
    } catch(e) {
      Toast.warn('Failed to upload video')
      this.onCloseAndRefresh();
    } finally {
      setTimeout(() => {
        this.props.actions.setContent({content: 0})
      }, 2000)
    }
  }

  public render = (): JSX.Element | null => {
    const { appKey } = this.props

    if (appKey === '') {
      Toast.warn('Inappropriate approach')
      return null
    }
    
    return (
      <div className="upload-modal-container">
        { this.props.uploadContent === 0 ?
        <UploadForm onClear={this.handleClose} onSubmit={this.handleUpload} percent={this.state.percent} />  : 
          <div>
            { this.props.uploadContent === 1 ? 
              <div className="progress-container">
                <ProgressBar percent={padNumber(this.state.percent)} />
              </div> :
              this.props.uploadContent === 2 ? 
              <div className="spinner-container">
                <Spinner progress={this.props.uploadContent} />
              </div> : " "
            }
            <div className="upload-button-group">
              <Button type="button" size="large" color="white" onClick={this.handleClose} disabled={this.props.uploadContent}>
                Cancel
              </Button>
              <Button type="submit" size="large" color="red" onClick={this.handleClose} disabled={this.props.uploadContent}>
                Upload
              </Button>
            </div>
          </div> }
      </div>
    );
  }
}

export default connect(
	({ upload } : StoreState) => ({
    uploadContent: upload.content
	}),
	(dispatch) => ({
    actions: bindActionCreators(uploadActions, dispatch)
  })
)(UploadModalContent)