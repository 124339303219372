import * as React from 'react'

interface Props {
  className?: string
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

export default function DeleteGray({className, onClick}: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <g transform="translate(-972 -432) translate(595 288) translate(40 109) translate(337 35)">
                <circle cx="8" cy="8" r="8" fill="#CCC" />
                <g fill="#FFF" transform="rotate(45 2.879 10.121)">
                  <rect width="2" height="10" x="4" rx="1" />
                  <rect width="10" height="2" y="4" rx="1" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
