
import React, { useEffect, useState } from 'react'
import './Header.scss';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '../modules/application'
import Dropdown from './dropdown/Dropdown';
import { ApplicationInfo, getApplications } from '../application/Applications';
import { SearchRequest } from './pagination/Paginations';
import { Config, Store } from '../config';
import { actions as loginActions } from '../modules/login'
import { actions as contentActions } from '../modules/content'
import Confirm from './modal/Confirm';
import { FlipFlopLogo } from '../assets/svg';
import { me, MemberInfo } from '../account/Auth';
import Button from './button/Button';

export default function DashboardHeader(): JSX.Element {
  const dispatch = useDispatch();
  const [appList, setAppList] = useState<ApplicationInfo[]>([])
  const [confirm, setConfirm] = useState(false)

  const route = useHistory();

  const { app, member, isLogin } = useSelector(state => ({app: state.application.application, member: state.login.member, isLogin: state.login.isLogin }));

  const appOptions = appList.map(app => ({
    value: app.name,
    onClick: () => {
      dispatch(appActions.setApplication(app))
      localStorage.setItem('app', JSON.stringify(app))
      route.push(`/applications/${app.appKey}`)
    }
  }))

  const accountOptions = [
    {
      value: 'Profile',
      onClick: () => {route.push("/profile")},
      divider: true
    },
    {
      value: 'Application',
      onClick: () => {route.push("/applications")},
    },
    {
      value: 'Logout',
      onClick: () => {setConfirm(true)},
    },
  ]

  const logout = (): void => {
    dispatch(loginActions.setLogOut())
    dispatch(contentActions.setContent({ content: 0 }))
    Store.logout();
    route.push("/")
  }

  const goLogin = () => {
    if(window.location.pathname !== "/") {
      route.push("/")
    }
    dispatch(contentActions.setContent({ content: 0 }))
  }

  const setMember = (member: MemberInfo): void => {
    console.log(member)
    Store.set(Config.MEMBER_KEY, member)
    dispatch(loginActions.setMember(member))
  }

  useEffect(() => {
    me(setMember)
    getApplications(new SearchRequest(0, Config.PAGE_SIZE, ""), setAppList)
  }, [])

  return (
    <div className="dashboard-header fixed-top">
      <nav className="navbar navbar-expand-lg">
        <NavLink to="/" className="brand-title">
          <FlipFlopLogo />
        </NavLink>
        {isLogin ? <ul className="account-group">
          <li className="nav-item">
            <Dropdown>
              <Dropdown.Button toggleIcon><span className="text-ellipsis">{app?.name || "Application name"}</span></Dropdown.Button>
              <Dropdown.Items options={appOptions} />
            </Dropdown>
          </li>
          <div className="nav-divide" />
          <li className="account-group">
            <Dropdown>
              <Dropdown.Button><span className="text-ellipsis">{member?.email}</span ></Dropdown.Button>
              <Dropdown.Items options={accountOptions} />
            </Dropdown>
          </li>
        </ul> : <Button size="small" color="transparent" style={{width: "92px"}} onClick={goLogin}>Login</Button> }
        <Confirm
          visible={confirm}
          setVisible={() => setConfirm(false)}
          onOk={logout}
          message="Do you want to log out?"
        />
      </nav>
    </div>
  )
}