import { Client } from '../config'
import { PageInfo, FilterRequest } from '../components/pagination/Paginations'
import { dateFormat } from '../config/Format'
import { errorHandler } from '../account/Auth'
import { AxiosPromise } from 'axios'

type User = {
  id: string
  user_id: string
  username: string
  profile_photo_url: string
  block: boolean
  created_at: number
  updated_at: number
}

export class UserInfo {
  id: string
  userId: string
  block: boolean
  userName: string
  userAvatarUrl: string
  createdAt: string
  updatedAt: string

  constructor(json: User) {
    this.id = json.id
    this.userId = json.user_id
    this.block = json.block
    this.userName = json.username
    this.userAvatarUrl = json.profile_photo_url
    this.createdAt = dateFormat(json.created_at)
    this.updatedAt = json.updated_at ? dateFormat(json.updated_at) : ''
  }
}

export const getUsers = (appKey: string, page: FilterRequest, callback: (list: UserInfo[], pageInfo: PageInfo) => void): void => {
  const param: {} = { page: page.page, size: page.size, direction: page.direction || 1, sort: page.sort || 'id', search: page.search || '' }

  new Client(`/api/2/users/${appKey}`).get(param).then((response) => {
    console.log(response)

    const users: UserInfo[] = []
    const pageInfo: PageInfo = new PageInfo(response.data)

    for (const u of response.data.content) {
      users.push(new UserInfo(u))
    }

    callback(users, pageInfo)
  }, errorHandler)
}

export const getBlockedUsers = (appKey: string, page: FilterRequest, callback: (list: UserInfo[], pageInfo: PageInfo) => void): void => {
  const param: {} = { page: page.page, size: page.size, direction: page.direction || 1, sort: page.sort || 'id', search: page.search || '' }

  new Client(`/api/2/users/block/${appKey}`).get(param).then((response) => {
    console.log(response)

    const users: UserInfo[] = []
    const pageInfo: PageInfo = new PageInfo(response.data)

    for (const u of response.data.content) {
      users.push(new UserInfo(u))
    }

    callback(users, pageInfo)
  }, errorHandler)
}

export const blockUser = (appKey: string, userId: string, videoKey?: string): AxiosPromise => {
  const param: {[key: string]: string} = { app_key: appKey, user_id: userId }
  if(videoKey) {
    param['video_key'] = videoKey
  }

  return new Client(`/api/2/applications/user_block`).post(param)
}

export const unBlockUser = (appKey: string, userId: string): AxiosPromise => {
  const param: {} = { app_key: appKey, user_id: userId }

  return new Client(`/api/2/applications/user_unblock`).post(param)
}
