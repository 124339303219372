import * as React from 'react'
import Button from '../components/button/Button'
import TextInput from '../components/input/TextInput'
import { Config, Messages } from '../config'
import { MAX_LENGTH } from '../config/Config'


interface Props {
  member: any
  onOk(profile: any): void
  onCancel(): void
}

interface State {
  password: string
  passwordError: string
  newPassword: string
  newPasswordError: string
  confirm: string
  confirmError: string
}

class ChangePasswordForm extends React.Component<Props, State> {
  state: State = {
    password: '',
    passwordError: '',
    newPassword: '',
    newPasswordError: '',
    confirm: '',
    confirmError: ''
  }

  onChangeInput = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { password, newPassword, confirm } = this.state

    if (password === '') {
      this.setState({ passwordError: 'Please enter your Password' })
      return
    } else if (password.trim().length < Config.PASSWORD_MIN_LENGTH) {
      this.setState({ passwordError: Messages.PASSWORD_MIN_LENGTH })
      return
    }

    this.setState({ passwordError: '' })

    if (newPassword === '') {
      this.setState({ newPasswordError: 'Please enter your Password' })
      return
    } else if (newPassword.trim().length < Config.PASSWORD_MIN_LENGTH) {
      this.setState({ newPasswordError: Messages.CONFIRM_PASSWORD_MIN_LENGTH })
      return
    }

    this.setState({ newPasswordError: '' })

    if (confirm === '') {
      this.setState({ confirmError: 'Please enter confirm Password' })
      return
    } else if (confirm.trim().length < Config.PASSWORD_MIN_LENGTH) {
      this.setState({ confirmError: Messages.CONFIRM_PASSWORD_MIN_LENGTH })
      return
    }

    this.setState({ confirmError: '' })

    if (newPassword !== confirm) {
      this.setState({ confirmError: 'Your password and confirmation password do not match.' })
      return
    }

    const { member } = this.props
    member['prePassword'] = password
    member['newPassword'] = newPassword

    this.props.onOk(member)
  }
  
  public render(): JSX.Element {
    return (<form className="application-modal-form" onSubmit={this.handleSubmit}>
        <TextInput type="password" id="password" name="password" value={this.state.password} onChange={this.onChangeInput} label="Current password" error={this.state.passwordError} required maxLength={MAX_LENGTH.password}/>
        <TextInput type="password" id="newPassword" name="newPassword" value={this.state.newPassword} onChange={this.onChangeInput} label="New password" error={this.state.newPasswordError} required maxLength={MAX_LENGTH.password}/>
        <TextInput type="password" id="confirm" name="confirm" value={this.state.confirm} onChange={this.onChangeInput} label="Confirm new password" error={this.state.confirmError} required maxLength={MAX_LENGTH.password} />
        <div className="button">
          <Button type="button" size="large" color="white" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button type="submit" size="large" color="red">
            Ok
          </Button>
        </div>
      </form>
    )
  }
}

export default ChangePasswordForm