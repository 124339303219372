import * as React from 'react'
import './LoginContainer.scss'
import PasswordConfirmForm from './PasswordConfirmForm'
import DashboardHeader from '../components/DashboardHeader';

class PasswordConfirmContainer extends React.Component {
  public render = (): JSX.Element => {  
    return (
      <div className="container-fluid">
        <DashboardHeader />
        <div className="login-container">
          <PasswordConfirmForm />
        </div>
      </div>
    )
  }
}

export default PasswordConfirmContainer
