import React, { Component } from 'react';
import { NavItem } from '../components/Navigation';
import { DirectMessageRoom } from '../review/Messages';
import ChatForm from './ChatForm'
import "./MonitorChatBox.scss";

interface Props {
  stage: number
  messages: any[]
  dmRooms?: DirectMessageRoom[]
  sendMessage?(m: string): void
  sendDirectMessage?(m: string, u: string): void
  handleStage?(stage: number): void
}

export default class MonitorChatBox extends Component<Props> {

  render (): JSX.Element {
    const { stage } = this.props

    return (
      <div className="monitor-chatbox-group">
        <div className="tab-group">
          <ul className="nav nav-pills">
            <NavItem stage={1} value={0} label={"채팅 메시지"} handleChange={this.props.handleStage?.bind(this, 0)} />
            {/* FIXME: ADD DM ROOM */}
            {/* <NavItem stage={stage} value={1} label={"다이렉트 메시지"} handleChange={this.props.handleStage.bind(this, 1)} /> */}
          </ul>
        </div>
        <div className="monitor-chatbox-content">
          { stage === 0 ? 
            this.props.sendMessage && <ChatForm messages={this.props.messages} sendMessage={this.props.sendMessage} /> :
            // FIXME: ADD DM ROOM
            // stage === 1 ?
            // <DmChatForm dmRooms={this.props.dmRooms} sendDirectMessage={this.props.sendDirectMessage} /> :
            ""
          }
        </div>
      </div>
    )
  }
}