import * as React from 'react'
import { Config } from '../config'
import { WatcherInfo } from './Watchers'

interface Props {
  watcher: WatcherInfo
}

class WatcherItem extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { watcher } = this.props
    return <div className="list-group-item">
        <div className="bmd-list-group-col">
          <div className="list-group-item-text">
            <div className="message-item">
              <img className="avatar-thumbnail" src={watcher.profilePhotoUrl || Config.DEFAULT_AVATAR} />
              {watcher.userId ? 
              <div className="message-user text-ellipsis">{watcher.username }</div> : 
              <div className="message-user">unknown id</div> }
            </div>
          </div>
        </div>
      </div>
  }
}


export default WatcherItem