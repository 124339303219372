import * as React from 'react'
import { Link } from 'react-router-dom'
import "./BreadCrumb.scss"


interface Props {
  names: string[];
}

interface ItemProps {
  link: string 
  name: string
}

const BreadCrumbItem = ({ link, name }: ItemProps): JSX.Element => (
  <li className="breadcrumb-item">
    <Link to={link === '/home' ? '/applications' : link}>{name}</Link>
  </li>
)

class BreadCrumb extends React.Component<Props> {

  public render = (): JSX.Element => {
    const { names } = this.props
    const last = names.pop()
    const prev = names.map((name, key) => (
      <BreadCrumbItem key={key} link={`/${name.toLowerCase()}`} name={name} />
    ))

    return <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      { prev }
      <li className="breadcrumb-item active" aria-current="page">{last}</li>
    </ol>
  </nav>
  }
}


export default BreadCrumb;