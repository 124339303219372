import * as React from 'react'
import { PlanInfo } from '../application/Applications'
import { CheckGreen } from '../assets/svg'
import "./PlanModal.scss"

interface Props {
  plan: PlanInfo
}

interface PlanTextProps {
  label?: string
  value: number | string
}

interface PlanCostProps {
  plan: PlanInfo
}

const PlanItemText = ({ label, value }: PlanTextProps): JSX.Element => (
  <p className="card-text">
    <CheckGreen />
    <span>
      {label} <b>{value === 0 ? 'Not supported' : value === -1 ? 'Unlimited' : value}</b>
    </span>
  </p>
)

const PlanItemTitle = ({ plan }: PlanCostProps): JSX.Element => (
  <div className="modal-header card-header">
    <p className="cost">{`${plan.name} Trial`}</p>
    <p className="card-title">{plan.cost > 0 ? plan.cost : 'Free'}</p>
    <p className="cost-helper">{plan.cost > 0 ? '/month' : '-'}</p>
  </div>
)
class PlanModal extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { plan } = this.props
    console.log(plan)
    return (
      <div className="plan-item plan-modal">
        <PlanItemTitle plan={plan} />
        <div className="modal-body card-body">
            <PlanItemText label={'Simultaneous channel: '} value={plan.channelCount} />
            <PlanItemText label={'Max number of participants per channel: '} value={plan.chatParticipants} />
            <PlanItemText label={'Video retention period: '} value={plan.retentionPeriod === 0 ? 'Not supported' : `${plan.retentionPeriod} month`} />
            <PlanItemText label={'Max number of Uploaded Video (and Compressed Video): '} value={plan.vodCount === 0 ? 'Not supported' : plan.vodCount} />
            <PlanItemText value={plan.support ? 'Yes' : 'Not supported'} />
        </div>
      </div>
    )
  }
}

export default PlanModal
