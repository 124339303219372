import * as React from 'react'

interface Props {
  text: string
  maxLength: number
}

export const TextLength: React.SFC<Props> = ({ text, maxLength }) => (
  <span>(<b>{text !== '' ? text.length : 0}/{maxLength})</b></span>
)

