import { combineReducers } from 'redux';
import login, { LoginState } from './login'
import content, { ContentState } from './content'
import application, { AppContentState } from './application'
import review, { ReviewState } from './review'
import user, { UserState } from './user'
import webinar, { WebinarState } from './webinar'
import video, { VideoState } from './video';
import monitor, { MonitorState } from './monitor';
import upload, { UploadState } from './upload';
import streaming, { StreamingState } from './streaming';
import setting, { SettingState } from './setting';

const reducers = combineReducers ({
	login,
  content,
  application,
  review,
  user,
  webinar,
  video,
  monitor,
  upload,
  streaming,
  setting
})

export interface StoreState {
  login: LoginState,
  content: ContentState,
  application: AppContentState,
  review: ReviewState,
  user: UserState,
  webinar: WebinarState,
  video: VideoState,
  monitor: MonitorState
  upload: UploadState
  streaming: StreamingState
  setting: SettingState
}

export default reducers;
export type AppState = ReturnType<typeof reducers>;
