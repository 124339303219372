import React from 'react';
import videojs from 'video.js';
import { bindActionCreators } from 'redux';
import { FlipFlop } from 'flipflop-sdk-javascript/dist/flipflop'
import { connect } from 'react-redux';
import { actions as monitorActions } from '../modules/monitor'
import { actions as applicationActions } from '../modules/application'
import { actions as videoActions } from '../modules/video'
import './MonitorContainer.scss';
import MonitorSubBox from './MonitorSubBox';
import MonitorChatBox from './MonitorChatBox';
import MonitorChartBox from './MonitorChartBox';
import MonitorLiveStat from './MonitorLiveStat';
import { getLiveStat, getVideo, getWatchersHistory, LiveStatInfo, terminateLive, VideoInfo, WatcherHistoryInfo } from './Video';
import moment from 'moment'
import VideoPlayer from '../review/VideoPlayer';
import { DirectMessageRoom, Message, MessageInfo } from '../review/Messages';
import { GoodsInfo } from './Goods';
import { WatcherInfo } from '../review/Watchers';
import Toast from '../components/Toast'
import { AppUserInfo, getAppUser } from '../settings/Settings';
import { StoreState } from '../modules';
import { ApplicationInfo } from '../application/Applications';
import { Config } from '../config';
import Button from '../components/button/Button';
import Confirm from '../components/modal/Confirm';

enum ModalStateCode {
  Terminate = 1,
}

interface Props {
  match: any
  app: ApplicationInfo
  appUser: AppUserInfo
  player: videojs.Player
  monitors: typeof monitorActions
  actions: typeof applicationActions
  videos: typeof videoActions
}

interface State {
  video: VideoInfo | null
  selectedUser: WatcherInfo | null
  goods: GoodsInfo[]
  liveStat: LiveStatInfo
  chartData: WatcherHistoryInfo
  messages: MessageInfo[]
  dmRooms: DirectMessageRoom[]
  chatStage: number
  visibleModal: ModalStateCode
}

class LiveMonitorContainer extends React.Component<Props, State> {
  dmRoomMap: Set<DirectMessageRoom> = new Set()

  state: State = {
    video: null,
    selectedUser: null,
    goods: [],
    liveStat: new LiveStatInfo({max_watcher_count: 0, total_message_count: 0, total_watcher_count: 0, avg_live_watch_time: 0}),
    chartData: new WatcherHistoryInfo({count_list: [], time_list: []}),
    messages: [],
    dmRooms: [],
    chatStage: 0,
    visibleModal: 0
  }
  player: any
  gossip: any
  sdk: any

  loadApplication = (): void => {
    const loaded = localStorage.getItem('app')
    const videoKey = this.props.match.params.id

    if (loaded) {
      const app: ApplicationInfo = JSON.parse(loaded)
      this.props.actions.setApplication(app)
      getAppUser(app.appKey, (appUser: AppUserInfo) => {
        this.props.actions.setAppUser(appUser)
        this.loadVideo(app.appKey, videoKey);
      })
      this.loadStatData(app.appKey, videoKey)
    } else {
      window.location.href = '/applications'
    }
  }

  loadStatData = (appKey: string, videoKey: string) => {
    getLiveStat(appKey, videoKey, this.setLiveStat)
    getWatchersHistory(videoKey, this.setChartData);
  }

  initToken = (video: VideoInfo): void => {
    console.log(video)
    this.initMonitor(video)
  }

  toggleModalGenerator = (modalCode: ModalStateCode) => () => {
    if(this.state.visibleModal === modalCode) {
      this.setState({visibleModal: 0})
    } else {
      this.setState({visibleModal: modalCode})
    }
  }

  toggleTerminate = this.toggleModalGenerator(ModalStateCode.Terminate)

  initMonitor = async (video: VideoInfo): Promise<void> => {
    const { app, appUser } = this.props;

    const appKey = app.appKey
    const appSecret = app.appSecret

    if (!appKey || !appSecret) {
      Toast.warn('appKey and appSecret parameters are required');
      return;
    }

    if (!appUser) {
      Toast.warn('Failed to load admin user.');
      return
    }

    const userId = appUser.userId
    const userName = appUser.username
    const profilePhoto = appUser.profilePhotoUrl

    console.log(`appKey: ${appKey}, appSecret: ${appSecret}`);
    console.log(`userId: ${userId}, userName: ${userName}, profilePhoto - ${profilePhoto}`);

    FlipFlop.initialize(appKey, appSecret);
    this.sdk = await FlipFlop.authentication(userId, userName, profilePhoto);
    
    this.player = this.sdk.getPlayer(video.videoKey);
    this.player.prepare(this);

    const params = {
      timestamp: video.createdAt,
      message_types: "MESSAGE, ADMIN",
      prev_size: Config.MESSAGE_COUNT,
      is_inclusive: 0,
    }

    if (this.player) {
      const video = this.player.start(true)
      
      console.log("get video", video)

      this.player.getChatMessages(params)
    }
  }

  setLiveStat = (liveStat: LiveStatInfo) => {
    this.setState({ liveStat })
  }

  setChartData = (chartData: WatcherHistoryInfo) => {
    this.setState({ chartData })
  }

  setVideo = (video: VideoInfo, goods: GoodsInfo[]): void => {
    if(video.state === "VOD") {
      window.location.href = `/vod/${video.videoKey}`;
      return;
    }

    this.setState({ video, goods })

    if(video) {
      this.initToken(video)
    }
  }

  setMessages = (messages: MessageInfo[]): void => {
    this.setState({ messages })
  }

  setDmUser = (watcher: WatcherInfo): void => {
    this.setState({ selectedUser: watcher, chatStage: 1 })
    
    const { dmRooms } = this.state
    const i = dmRooms.findIndex(r => r.id === watcher?.userId)

    console.log(dmRooms, watcher)

    if(i > -1) {
      const room = dmRooms[i]
      this.props.monitors.setRoom({ userId: watcher.userId, username: watcher.username, messages: room.list})
    } else {
      this.props.monitors.setRoom({ userId: watcher.userId, username: watcher.username, messages: []})
    }
  }

  loadVideo = (appKey: string, videoKey: string): void => {
    getVideo(appKey, videoKey, this.setVideo)
  }

  endChat = () => {
    if (this.gossip) {
      this.gossip.disconnect()
    }
  }

  endVideo = () => {
    const { player } = this.props
    if(player) {
      player.reset();
    }
  }

  onPrepare = (): void => {
    console.log('onPrepare')
  }

  onStart = (): void => {
    console.log('onStart')
  }

  onStop = (): void => {
    console.log(`onStopped`)
  }

  onError = (error: string): void => {
    if (error){
      console.error(error)
    }
  }

  onChatReceived = (message: Message): void => {
    console.log("onChatReceived", message)
    message.elapsedTime = Date.now() - message.created_at
    
    if (message.message_type === 'DM') {
      this.receiveDmMessage(message)
    }

    if (message.message_type ==="DM" || message.message_type ==="MESSAGE" || (message.message_type ==="ADMIN" && message.custom_type ==="")) {
      const { liveStat } = this.state;
      liveStat.totalMessageCount++;
      this.setState({liveStat})
    }
    
    const { messages } = this.state;
    console.log("message.message_type", message.message_type)
    if(message.message_type === "JOIN" || message.message_type === "LEAVE") {
      this.state.video && this.loadStatData(this.props.app.appKey, this.state.video.videoKey)
      this.setState({ messages: messages.concat(new MessageInfo(message))});
    } else {
      if(message.custom_type === "CLOSE") {
        this.endChat();
        this.endVideo();
      }
      this.setState({ messages: messages.concat(new MessageInfo(message))});
    }
  }

  receiveDmMessage = (message: Message): void => {
    const { dmRooms } = this.state
    const dm = new MessageInfo(message)
    // FIXME: change receiver id
    const i = dmRooms.findIndex(r => r.id === "12345")

    if (i > -1) {
      // sort by recent message
      const room = dmRooms[i]

      room.append(dm)
      dmRooms.splice(i , 1);
      dmRooms.unshift(room)

      console.log("dmRooms", dmRooms)
    } else {
      dmRooms.unshift(new DirectMessageRoom(dm))
    }

    this.setState({ dmRooms: dmRooms });
  }

  sendMessage = (message: string): void => {
    if (this.player) {
      this.player.sendMessage(message)
    }
  }

  sendDirectMessage = (message: string, userId: string) => {
    if (this.player) {
      this.player.sendDirectMessage(message, userId)
    }
  }

  terminate = () => {
    const { app } = this.props;
    const { video } = this.state

    if(!video) {
      return;
    }

    terminateLive(app.appKey, video.videoKey)
  }

  onChatMessgeReceived = (messages: Message[]): void => {
    const { video } = this.state
    if (!video) {
      return
    }

    const messageInfoList = messages?.map((m: Message) => {
      if (m.message_type === 'DM') {
        this.receiveDmMessage(m)
      }

      m.elapsed = (m.created_at - video.createdAt) / 1000
      const messageInfo = new MessageInfo(m);
      messageInfo.fromNow = moment(m.created_at).from(video.createdAt)
      messageInfo.createdAtFormat = moment(m.created_at).format('HH:mm:ss')
      return messageInfo;
    })
  
    this.setState(prev => ({ messages: prev.messages.concat(messageInfoList)}));
  }

  componentDidMount = (): void => { 
    this.loadApplication()
  }

  componentWillUnmount = (): void => {
    this.endChat();
    this.endVideo();
  }

  handleChatStage = (chatStage: number): void => {
    this.setState({ chatStage })
  }

  render(): JSX.Element | null {
    const { messages, video, chatStage, chartData, liveStat } = this.state
    const { app } = this.props

    if(!video) {
      return null;
    }

    return (
      <div className="monitor-container">
        <div className="monitor-content">
          <div className="monitor-header">
            <div>
              <div className="monitor-title-group">
                <h3 className="monitor-title">{video?.title || "Untitled"}</h3>
              </div>
              <div className="monitor-desc">{video?.content || "No Content"}</div>
            </div>
            <Button size="large" color="upload" onClick={this.toggleTerminate}><span>Terminate Live</span></Button>
          </div>
          <div className="monitor-item-list">
            <div className="monitor-item">
              {video?.url && <VideoPlayer src={video?.url || ""} autoPlay={true} controls={false} retryable/>}
              <MonitorSubBox appKey={app.appKey} videoKey={video?.videoKey} setSelectedUser={this.setDmUser} />
            </div>
            <div className="monitor-item">
              {video && <MonitorChatBox sendMessage={this.sendMessage} messages={messages} dmRooms={this.state.dmRooms} sendDirectMessage={this.sendDirectMessage} stage={chatStage} handleStage={this.handleChatStage} />}
            </div>
            <div className="monitor-item">
              <MonitorChartBox chartData={chartData} messages={messages} />
              <MonitorLiveStat liveStat={liveStat}/>
            </div>
          </div>
        </div>
        <Confirm visible={this.state.visibleModal} setVisible={this.toggleTerminate} onOk={this.terminate} message="Do you want to terminate live?" />
      </div>
    )
  }
}

export default connect(
	({ application, video } : StoreState) => ({
    app: application.application,
    appUser: application.appUser,
    player: video.player
  }),
	(dispatch) => ({
    actions: bindActionCreators(applicationActions, dispatch),
    monitors: bindActionCreators(monitorActions, dispatch),
    videos: bindActionCreators(videoActions, dispatch),
  })
)(LiveMonitorContainer)
