import * as React from 'react'

export default function PlayOutlined(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <defs>
        <filter id="4j04vre6va">
          <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.976471 0 0 0 0 0.388235 0 0 0 0 0.003922 0 0 0 1.000000 0" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g filter="url(#4j04vre6va)" transform="translate(-1244 -193) translate(1216 180)">
            <g>
              <path d="M0 0H14V14H0z" transform="translate(28 13)" />
              <path stroke="#888" d="M7.5 4.508L10.638 10H4.362L7.5 4.508z" transform="translate(28 13) rotate(90 7.5 7)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
