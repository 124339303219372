import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { Config, Store } from '../config'

class Client {
  url: string
  accessToken?: string | null
  contentType: string
  paramteters: { [key: string]: number | string | string[] }

  constructor(url: string, accessToken?: string) {
    this.accessToken = accessToken ? accessToken : Store.get(Config.ACCESS_TOKEN_KEY)

    if (!this.accessToken) {
      console.error('access token is null')
      this.accessToken = Config.GUEST_TOKEN
    }

    this.url = Config.API + url
  }

  post = (body: {}, contentType: string = 'application/json'): AxiosPromise<any> => {
    let jsonBody

    if (contentType === 'application/x-www-form-urlencoded') {
      jsonBody = new URLSearchParams(body)
    } else {
      jsonBody = body
    }

    const request: AxiosRequestConfig = {
      url: this.url,
      method: 'POST',
      headers: {
        'Content-Type': contentType,
        Authorization: this.accessToken,
        Accept: 'application/json',
      },
      data: jsonBody,
    }

    return axios(request)
  }

  upload = (body: {}, callback: (progress: number) => void): AxiosPromise<any> => {
    const request: AxiosRequestConfig = {
      url: this.url,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: this.accessToken,
        Accept: 'application/json',
      },
      data: body,
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        callback(percentCompleted)
      },
    }

    return axios(request)
  }

  get = (paramteters?: { [key: string]: number | string | string[] }): AxiosPromise<any> => {
    if (paramteters) {
      this.paramteters = paramteters
      this.url += '?' + this.encodeQueryData(paramteters)
    }

    const request: AxiosRequestConfig = {
      url: this.url,
      method: 'GET',
      headers: {
        Authorization: this.accessToken,
        Accept: 'application/json',
      },
    }

    return axios(request)
  }

  patch = (id: string, body: {}): AxiosPromise<any> => {
    const request: AxiosRequestConfig = {
      url: this.url + (id !== '' ? '/' + id : ''),
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.accessToken,
        Accept: 'application/json',
      },
      data: body,
    }

    return axios(request)
  }

  delete = (id: string): AxiosPromise<any> => {
    const request: AxiosRequestConfig = {
      url: this.url + '/' + id,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.accessToken,
        Accept: 'application/json',
      },
    }

    return axios(request)
  }

  encodeQueryData = (paramteters: { [index: string]: any }): string => {
    return Object.keys(paramteters)
      .map((key) => {
        return [key, paramteters[key]].map(encodeURIComponent).join('=')
      })
      .join('&')
  }
}

export default Client
