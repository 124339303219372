import { createAction, handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import { PageInfo, FilterRequest } from '../components/pagination/Paginations';
import { Config } from '../config';
import { MessageInfo } from '../review/Messages';
import { ViewerInfo, WatcherInfo } from '../review/Watchers';

const SET_ROOM = 'monitor/SET_ROOM'
const CLOSE_ROOM = 'monitor/CLOSE_ROOM'
const SET_WATCHERS = 'monitor/SET_WATCHERS'
const SET_VIEWERS = 'monitor/SET_VIEWERS'
const SET_PAGE_REQUEST = 'monitor/SET_PAGE_REQUEST'

type RoomPayload = {
  userId: string
  username: string
  messages: MessageInfo[]
}

interface WatcherPayload {
  list: WatcherInfo[]
  page: PageInfo
}

interface ViewerPayload {
  list: ViewerInfo[]
  page: PageInfo
}

export const actions = {
  setRoom: createAction<RoomPayload>(SET_ROOM),
  closeRoom: createAction(CLOSE_ROOM),
  setPageRequest: createAction<FilterRequest>(SET_PAGE_REQUEST),
  setViewers: createAction<ViewerPayload>(SET_VIEWERS),
  setWatchers: createAction<WatcherPayload>(SET_WATCHERS),
}

export interface MonitorState {
  userId: string
  username: string
  messages: MessageInfo[]
  page: PageInfo
  pageRequest: FilterRequest
  watchers: WatcherInfo[],
  viewers: ViewerInfo[],
}

const initialState = {
  userId: '',
  username: '',
  messages: [],
  page: new PageInfo(null),
  pageRequest: new FilterRequest(0, Config.VIEWER_PAGE_COUNT, "", 0, ""),
  watchers: [],
  viewers: [],
}

export default handleActions<MonitorState, any>({
  [SET_ROOM]: (state, action:Action<RoomPayload>) => produce(state, draft => { draft.userId = action.payload.userId, draft.username = action.payload.username, draft.messages = action.payload.messages }),
  [CLOSE_ROOM]: (state) => produce(state, draft => { draft.userId = '', draft.username = '', draft.messages = [] }),
  [SET_PAGE_REQUEST]: (state, action: Action<FilterRequest>) => produce(state, draft => { draft.pageRequest = action.payload }),
  [SET_WATCHERS]: (state, action: Action<WatcherPayload>) => produce(state, draft => { draft.page = action.payload.page, draft.watchers = action.payload.list }),
  [SET_VIEWERS]: (state, action: Action<ViewerPayload>) => produce(state, draft => { draft.page = action.payload.page, draft.viewers = action.payload.list }),
}, initialState)
