import * as React from 'react';
import { PageState, PageInfo, FilterRequest } from '../components/pagination/Paginations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../modules';
import { actions as webinarActions } from '../modules/webinar'
import { ApplicationInfo } from '../application/Applications';
import BreadCrumb from '../components/BreadCrumb';
import './Webinar.scss'
import ObsSettingContent from './ObsSettingContent';
import { getStreamKeys, getVideoByStream, endBroadcast, StreamKeyInfo, updateBroadcast } from './Webinars';
import Button from '../components/button/Button';
import { PlayWhite, Stop } from '../assets/svg'
import BroadcastForm from './BroadcastForm';
import Modal from '../components/modal/Modal';
import VideoPlayer from '../review/VideoPlayer';
import UpdateBroadcastForm from './UpdateBroadcastForm';
import { Messages } from '../config'
import Confirm from '../components/modal/Confirm';
import Toast from '../components/Toast';
import { Config } from '../config';
import { VideoInfo } from '../monitor/Video';


interface OverviewProps {
  title: string
  content: React.ReactNode
}

const OverviewItem = ({ title, content }: OverviewProps) => (
  <div className="overview col-xl-12">
    <div className="overview-header card-title">{title}</div>
    <div className="card overview-item">
      <div className="card-body overview-body">
        { content }
      </div>
    </div>
  </div>
)

const WebinarContent = () => (
  <div className="webinar-content-list">
    {Messages.WHATS_WEBINAR}
  </div>
)

const HowToUseContent = () => (
  <ol className="webinar-content-list">
    { Messages.HOW_TO_USE_WEBINAR.map((item, i) => (
      <li key={i}>{item}</li>  
    )) }
  </ol>
)

interface Props extends PageState {
  app: ApplicationInfo
  list: StreamKeyInfo[]
  actions: typeof webinarActions
  pageRequest: FilterRequest
}

interface State {
  stage: number
  content: number
  lives: VideoInfo[]
  page: PageInfo
  stopKey: string
}

class WebinarOverviewContent extends React.Component<Props, State> {
  timers: Map<string, any> = new Map()
  state: State = {
    stage: 0,
    content: 0,
    lives: [],
    page: new PageInfo(null),
    stopKey: ''
  }

  componentDidMount = (): void => {
    this.loadStreamKeys()
  }

  componentWillUnmount = (): void => {
    if(this.timers) {
      this.timers.forEach((v) => {
        clearInterval(v)
      })
    }
  }

  loadStreamKeys = () => {
    getStreamKeys(this.props.app.appKey, this.props.pageRequest, this.setList)
  }

  setList = (list: StreamKeyInfo[], page: PageInfo): void => {    
    this.props.actions.setList({ list, page });

    for (const k of list) {
      const params = { stream_key: k.streamKey }
      getVideoByStream(this.props.app.appKey, params, (v) => {
        this.setLive(v, k.streamKey)
      })
    }
  }

  setLive = (live: VideoInfo, streamKey: string) => {
    const { lives } = this.state
    console.log(live)

    live.streamKey = streamKey
    if (live.state === 'LIVE' || live.state === 'CREATED') {
      lives.push(live)
      this.setState({ lives })

      if (!this.timers.get(streamKey)) {
        const timer = setInterval(() => this.loadVideoStream(streamKey), Config.WEBINAR_RETRY_INTERVAL)
        this.timers.set(streamKey, timer)
      }
    }
  }

  loadVideoStream = (streamKey: string) => {
    const params = { stream_key: streamKey }
    getVideoByStream(this.props.app.appKey, params, (v) => {
      this.startBroadcast(v, streamKey)
    })
  }

  startBroadcast = (live: VideoInfo, streamKey: string) => {
    if (live.state === 'VOD') {
      clearInterval(this.timers.get(streamKey))
    }

    const { lives } = this.state
    const i = lives.findIndex(v => v.streamKey === streamKey)
    console.log(streamKey)
    console.log(i)

    if (i > -1) {
      lives[i].state = live.state
      this.setState({ lives })
    }
  }

  openStopBroadcastConfirm = (video: VideoInfo) => {
    
    this.setState({ stopKey: video.videoKey, content: 3 })
  }

  openUploadBroadcastModal = (video: VideoInfo) => {
    this.props.actions.setLive(video)
    this.setContent(2)
  }

  openBroadcastModal = () => {
    this.setContent(1)
  }

  refreshAndClose = () => {
    this.setContent(0)
    this.setState({lives: []})
    this.loadStreamKeys()
  }

  setContent = (content: number) => {
    this.setState({ content })
  }
  
  onCancel = () => {
    this.setContent(0)
  }
  
  stopBroadcast = () => {
    console.log('stop broadcast')

    const request = {
      state: 'TERMINATED'
    }

    console.log(`app key: ${this.props.app.appKey}, stop key: ${this.state.stopKey}`)
    updateBroadcast(this.props.app.appKey, this.state.stopKey, request,
      (video) => {
        console.log(video)
        Toast.warn('Stopped the Broadcast')
        this.setState({ lives: [], stopKey: '' })
        this.loadStreamKeys()
      })
  }

  closeConfirm = () => {
    this.setContent(0)
  }

  openCloseBroadcastConfirm = (video: VideoInfo) => {
    this.setState({ stopKey: video.videoKey, content: 4 })
  }

  closeBroadcast = () => {
    const { lives, stopKey } = this.state
    
    endBroadcast(this.props.app.appKey, this.state.stopKey, {}, () => {
      const i = lives.findIndex(v => v.videoKey === stopKey)
      if (i > -1) {
        lives.splice(i, 1);
        this.setState({ lives, stopKey: '' })
      }
    })
    
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    if (nextProps.app.appKey !== this.props.app.appKey) {
      getStreamKeys(nextProps.app.appKey, this.props.pageRequest, this.setList)
      return true
    }
    return true
  }

  public render = (): JSX.Element => {
    const { list } = this.props
    const { content, lives } = this.state

    console.log("lives", lives)
    
    const streamKeys = list
      .filter((s) => !(lives.map(live => live?.streamId)).includes(s.id))
      .map((s) => s.streamKey) || []
    const streamMap = new Map<number, string>()
    list.forEach((s) => {
      streamMap.set(s.id, s.streamKey)
    })
    
    return (
      <div className="dashboard-content webinar-overview-content col-xl-10">
        <BreadCrumb names={['Dashboard', 'Webinar', 'Overview']} />
        <h5 className="content-title">Webinar Overview</h5>
        <div className="content-body">
          <OverviewItem title={`What's Webinar?`} content={<WebinarContent />}/>
          <OverviewItem title={`How to use?`} content={<HowToUseContent />}/>
        </div>
        { lives.map((b, key) => (
          <div key={key}>
            <OverviewItem title={`OBS Settings`} content={<ObsSettingContent streamKey={streamMap.get(b.streamId) || '' } />} />
            <div key={key} className="overview col-xl-12 preview-group">
              <div className="overview-header card-title">{b.title}</div>
              <div className="card overview-item">
                <VideoPlayer src={b.url} autoPlay={true} retryable={true} />
                <div className="button-group">
                  { b.state === 'CREATED' &&
                    <Button size="large" color="red-outline" onClick={this.openStopBroadcastConfirm.bind(this, b)}>
                      <Stop />Stop Broadcast  
                    </Button>
                  }
                  { b.state === 'VOD' || b.state === 'LIVE' &&
                    <Button size="large" color="red-outline" onClick={this.openCloseBroadcastConfirm.bind(this, b)}>
                      <Stop />Close Broadcast  
                    </Button>
                  }
                  <Button size="large" color="red" onClick={this.openUploadBroadcastModal.bind(this, b)}>
                    <PlayWhite />Update Broadcast  
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="button-group">
          <Button size="large" color="red" onClick={this.openBroadcastModal}>
            <PlayWhite />Start Broadcast  
          </Button>
        </div>
        <Modal
          visible={content === 1}
          setVisible={() => this.setContent(0)}
          render={<BroadcastForm streamKeys={streamKeys} onClose={this.refreshAndClose} onCancel={this.onCancel}/>}
          title="Start Broadcast"
        />
        <Modal
          visible={content === 2}
          setVisible={() => this.setContent(0)}
          render={<UpdateBroadcastForm onClose={this.refreshAndClose} onCancel={this.onCancel}/>}
          title="Update Broadcast"
        />
        <Confirm message="Do you want to stop this broadcast?" visible={content === 3} setVisible={this.closeConfirm} onOk={this.stopBroadcast} />
        <Confirm message="Do you want to close this broadcast?" visible={content === 4} setVisible={this.closeConfirm} onOk={this.closeBroadcast} />
      </div>
    )
  }
}

export default connect(
	({ application, webinar } : StoreState) => ({
    app: application.application,
    list: webinar.list,
    pageInfo: webinar.page,
    pageRequest: webinar.pageRequest
	}),
	(dispatch) => ({
    actions: bindActionCreators(webinarActions, dispatch)
  })
)(WebinarOverviewContent)