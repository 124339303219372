import * as React from 'react'
import { useCallback } from 'react'
import SettingInputText from '../components/input/SettingTextInput'
import { FormBullet } from '../assets/svg'
import Dropdown from '../components/dropdown/Dropdown'
import { actions as settingActions } from '../modules/setting'
import { CallbackSettingsInfo } from './Settings'
import { MAX_LENGTH } from '../config/Config'
import TextInput from '../components/input/TextInput'
import { useDispatch, useSelector } from 'react-redux'

interface CallbackFormProps {
  method: 'CREATE' | 'UPDATE' | 'END'
  index: number;
  callbacks: CallbackSettingsInfo[]
}


function CallbackForm ({ method, callbacks, index }: CallbackFormProps) {
  const dispatch = useDispatch();
  const callback = new CallbackSettingsInfo(callbacks[index]);

  const setCallbacks = (callback: CallbackSettingsInfo) => {
    const newCallbacks = callbacks.map((item, i) => {
      if(i == index) {
        return Object.assign({}, callback)
      }
      return item
    });

    console.log("newCallbacks", newCallbacks)

    dispatch(settingActions.setCallbacks(newCallbacks))
  }

  const onChangeInput = useCallback((e) => {
    
    if(e.target.name === 'token') {
      callback.token = e.target.value;
    } else if(e.target.name === 'url') {
      callback.url = e.target.value;
    } else {
      callback.delay = e.target.value;
    }

    console.log("callbacks", callbacks)

    setCallbacks(callback)
  }, [callback])

  const onClickMethod = useCallback((value: string) => {
    callback.type = value;

    setCallbacks(callback)
  }, [callback])

  const methodOptions = [
    {
      value: 'GET',
      onClick: () => onClickMethod('GET'),
    },
    {
      value: 'POST',
      onClick: () => onClickMethod('POST'),
    },
    {
      value: 'PATCH',
      onClick: () => onClickMethod('PATCH'),
    }
  ]

  return (
    <div className="card settings-item">
      <div className="card-body overview-body">
        <SettingInputText id="callback" name="url" className={callback?.url?.length > 0 ? "underline" : ""} value={callback?.url || ""} onChange={onChangeInput} placeholder="https://example.com/api/3/callback" label="Callback URL" maxLength={MAX_LENGTH.url}/>
        <div className="setting-input-group">
          <label htmlFor="Type">
            <FormBullet /> <span>Type</span>
          </label>
          <TextInput type="text" name="type" value={method} disabled />
          <label htmlFor="Method" style={{marginLeft: '60px'}}>
            <FormBullet /> <span>Method</span>
          </label>
          <Dropdown>
            <Dropdown.Button toggleIcon><span>{callback?.type}</span></Dropdown.Button>
            <Dropdown.Items options={methodOptions} />
          </Dropdown>
          {method === "UPDATE" && <>
            <label htmlFor="Interval" style={{marginLeft: '60px'}}>
              <FormBullet /> <span>Interval</span>
            </label>
            <TextInput type="number" name="liveTimer" value={callback?.delay || ""} onChange={onChangeInput} />
          </>}
        </div>
        <SettingInputText id="token" name="token" value={callback?.token || ""} onChange={onChangeInput} placeholder="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" label="Token" maxLength={MAX_LENGTH.token} />
      </div>
    </div>
  )
}

export default function CallbackItem(): JSX.Element {
  const { callbacks } = useSelector((state) => ({ 
    callbacks: state.setting.callbacks,
  }))

  return (
    <div className="settings col-xl-12">
      <div className="settings-header">
        <h5 className="card-title">콜백 설정</h5>
      </div>
      <CallbackForm method="CREATE" callbacks={callbacks} index={0}/>
      <CallbackForm method="UPDATE" callbacks={callbacks} index={1}/>
      <CallbackForm method="END" callbacks={callbacks} index={2}/>
    </div>
  )
}
