import * as React from 'react'
import { useCallback } from 'react'
import { Search } from '../../assets/svg';
import './SearchInput.scss'

interface Props {
  label?: string
  id?: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSearch(): void
  [key: string]: any
}

export default function SearchInput({ label, value, id, onChange, onSearch, ...props }: Props): JSX.Element {
  const keyDownEnter = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch();
    }
  }, [onSearch]);

  return (
    <div className="search-group">
      <input className="search-input" type="text" value={value} id={id || label} onChange={onChange} onKeyPress={keyDownEnter} {...props} />
      <label htmlFor={id || label}>{value.length > 0 ? "" : label}</label>
      <div className="search-icon" onClick={onSearch}>
        <Search className={value.length > 0 ? "active" : ""}/>
      </div>
    </div>
  )
}
