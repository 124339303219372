import React from 'react';
import { bindActionCreators } from 'redux';
import { FlipFlop } from 'flipflop-sdk-javascript/dist/flipflop'
import { actions as applicationActions } from '../modules/application'
import { getVideo, VideoInfo } from './Video';
import moment from 'moment'
import VideoPlayer from '../review/VideoPlayer';
import { MessageInfo } from '../review/Messages';
import { GoodsInfo } from './Goods';
import { WatcherInfo } from '../review/Watchers';
import Toast from '../components/Toast'
import { AppUserInfo, getAppUser } from '../settings/Settings';
import VodMonitorChatBox from './VodMonitorChatBox';
import VodMonitorSubBox from './VodMonitorSubBox'
import MonitorUploaderProfile from './MonitorUploaderProfile';
import MonitorVideoInfo from './MonitorVideoInfo';
import { connect } from 'react-redux';
import { StoreState } from '../modules';
import { ApplicationInfo } from '../application/Applications';
import { Config } from '../config';

interface LoadMessagesOps {
  scrollLock: boolean
  messageLoading: boolean
  isEndMessage: boolean
}

interface Props {
  match: any
  app: ApplicationInfo
  appUser: AppUserInfo
  actions: typeof applicationActions
}

interface State {
  video: VideoInfo | null
  selectedUser: WatcherInfo | null
  goods: GoodsInfo[]
  messages: MessageInfo[]
  loadMessagesOps: LoadMessagesOps
  shown: MessageInfo[],
  cursor: number
}

class VodMonitorContainer extends React.Component<Props, State> {

  state: State = {
    video: null,
    selectedUser: null,
    goods: [],
    messages: [],
    loadMessagesOps: {
      isEndMessage: false,
      messageLoading: false,
      scrollLock: false
    },
    shown: [],
    cursor: 0,
  }

  player: any
  gossip: any
  sdk: any
  videoNode: any

  loadApplication = (): void => {
    const loaded = localStorage.getItem('app')
    const videoKey = this.props.match.params.id

    if (loaded) {
      const app: ApplicationInfo = JSON.parse(loaded)
      this.props.actions.setApplication(app)
      getAppUser(app.appKey, (appUser: AppUserInfo) => {
        this.props.actions.setAppUser(appUser)
        this.loadVideo(app.appKey, videoKey);
      })
    } else {
      window.location.href = '/applications'
    }
  }

  initToken = (video: VideoInfo): void => {
    console.log(video)
    this.initMonitor(video)
  }

  initMonitor = async (video: VideoInfo): Promise<void> => {
    const { app, appUser } = this.props;

    const appKey = app.appKey
    const appSecret = app.appSecret

    if (!appKey || !appSecret) {
      Toast.warn('appKey and appSecret parameters are required');
      return;
    }

    if (!appUser) {
      Toast.warn('Failed to load admin user.');
      return
    }

    const userId = appUser.userId
    const userName = appUser.username
    const profilePhoto = appUser.profilePhotoUrl

    console.log(`appKey: ${appKey}, appSecret: ${appSecret}`);
    console.log(`userId: ${userId}, userName: ${userName}, profilePhoto - ${profilePhoto}`);

    FlipFlop.initialize(appKey, appSecret);
    this.sdk = await FlipFlop.authentication(userId, userName, profilePhoto);
    
    this.player = this.sdk.getPlayer(video.videoKey);
    this.player.prepare(this);

    const params = {
      timestamp: video.createdAt,
      message_types: "MESSAGE, ADMIN",
      prev_size: 0,
      next_size: Config.MESSAGE_COUNT,
      is_inclusive: 0,
    }

    if (this.player) {
      const video = await this.player.start(true)
      console.log("get Video", video);
      this.player.getChatMessages(params)
    }
  }

  setVideo = (video: VideoInfo, goods: GoodsInfo[]): void => {
    this.setState({ video, goods })

    if(video) {
      this.initToken(video)
    }
  }

  setMessages = (messages: MessageInfo[]): void => {
    this.setState({ messages })
  }

  loadVideo = (appKey: string, videoKey: string): void => {
    getVideo(appKey, videoKey, this.setVideo)
  }

  loadNextMessages = (cursor: number) => {
    const { shown, loadMessagesOps, video } = this.state

    if (!loadMessagesOps.isEndMessage && !loadMessagesOps.messageLoading) {
      const params: any = {
        next_size: Config.MESSAGE_COUNT,
        prev_size: 0,
        message_types: "MESSAGE, ADMIN",
        is_inclusive: 0,
      };

      if(shown.length > 0) {
        params.message_id = shown[shown.length - 1].messageId
      } else {
        params.timestamp = moment(video?.createdAt).add(Math.round(cursor), "seconds").unix() * 1000
      }
      if(this.player) {
        this.player.getChatMessages(params);
      }
      
    }
  }

  onTimeUpdate = (e: any): void => {
    const cursor = e.target.currentTime

    this.setState({ cursor })
    const { messages } = this.state

    if (messages.length > 0) {
      this.switchMessage(cursor) 
    } else { 
      this.loadNextMessages(cursor);
    }
  }

  onSeeked = (e: any): void => {
    const { video, loadMessagesOps, cursor } = this.state;
    
    if(!video) {
      return;
    }

    loadMessagesOps.isEndMessage = false;
    this.setState({ messages: [], shown: [], loadMessagesOps, cursor })
    if (this.player) {
      const params = {
        timestamp: moment(video.createdAt).add(Math.round(cursor), "seconds").unix() * 1000,
        next_size: Config.MESSAGE_COUNT,
        prev_size: Config.MESSAGE_COUNT,
        message_types: "MESSAGE, ADMIN",
        is_inclusive: 0,
      };
      this.player.getChatMessages(params);
    }
    
  }

  onPrepare = (): void => {
    console.log('onPrepare')
  }

  onStart = (): void => {
    console.log('onStart')
  }

  onStopped = (): void => {
    console.log(`onStopped`)
  }

  onError = (error: string): void => {
    if (error){
      console.error(error)
    }
  }

  switchMessage = (now: number): void => {
    const { cursor, shown, messages } = this.state
    
    if (now < cursor) {
      this.setState({
        shown: [],
        messages: [...shown].concat(messages)
      })
      return
    }
    
    let index = -1;
    if(messages[messages.length - 1].elapsed < now) {
      index = messages.length
    } else if (messages[0].elapsed < now) {
      index = messages.findIndex(m => (m.elapsed > now));
    }
    console.log(`${messages[0]?.elapsed} cursor: ${now}, index: ${index}`)

    if (index > 0) {
      const toSwitch = messages.splice(0, index);
      
      if (toSwitch.length > 0) {
        const toShow = shown.concat(toSwitch)
        console.log(toShow, messages)

        this.setState({
          messages,
          shown: toShow
        })
      }
    }
  }

  onChatMessgeReceived = (messages: any[]): void => {
    console.log("messages", messages)

    const { video, cursor, shown } = this.state
    if (!video) {
      return
    }

    const messagesArray: MessageInfo[] = []
    const loadMessagesOps = this.state.loadMessagesOps;
    loadMessagesOps.messageLoading = false;
    loadMessagesOps.scrollLock = false;

    if(messages.length < 1) {
      loadMessagesOps.isEndMessage = true
    }

    if (messages && messages.length > 0) {
      for (const m of messages) {
        m.elapsed = (m.created_at - video.createdAt) / 1000
        m.fromNow = moment(m.created_at).from(video.createdAt)
        m.createdAtFormat = moment(m.created_at).format('HH:mm:ss')
        messagesArray.push(new MessageInfo(m))
      }
  
      console.log("messages format", messages)

      // 스크롤 상단 이동시
      if (messages[messages.length - 1].elapsed < cursor) {
        this.setState({ loadMessagesOps, shown: messagesArray.concat([...shown]) })  
      } else {
        this.setState({ messages: messagesArray, loadMessagesOps })
      }
    }
  }

  loadMoreChat = () => {
    const { loadMessagesOps, shown } = this.state;
    const { scrollLock, messageLoading } = loadMessagesOps

    if (!scrollLock && !messageLoading && shown.length>0) {
      loadMessagesOps.scrollLock = true
      loadMessagesOps.messageLoading = true
      this.setState({ loadMessagesOps })

      const params = {
        message_id: shown[0].messageId,
        next_size: 0,
        prev_size: Config.MESSAGE_COUNT,
        message_types: "MESSAGE, ADMIN",
        is_inclusive: 0,
      };

      if(this.player) {
        this.player.getChatMessages(params);
      }
    } else {
      console.log('scrollLock: ' + scrollLock, messageLoading)
    }
  }

  componentDidMount(): void {
    this.loadApplication()
  }

  componentWillUnmount = (): void => {
    if (this.videoNode) {
      this.videoNode.dispose()
    }
  }

  render(): JSX.Element | null {
    const { video, shown } = this.state
    const { app } = this.props
    // console.log('shown', shown, video)

    if(!video) {
      return null;
    }

    return (
      <div className="monitor-container">
        <div className="monitor-content">
          <h3 className="monitor-title">{video?.title || "Untitled"}</h3>
          <div className="monitor-item-list">
            <div className="monitor-item">                
              {video?.url && <VideoPlayer src={video?.url || ""} autoPlay={true} onTimeUpdate={this.onTimeUpdate} onSeeked={this.onSeeked} />}
              <VodMonitorSubBox appKey={app.appKey} videoKey={video.videoKey} />
            </div>
            <div className="monitor-item">
              {video && <VodMonitorChatBox video={video} messages={shown} loadMoreChat={this.loadMoreChat}/>}
            </div>
            <div className="monitor-item">
              {video && 
                <>
                  <MonitorUploaderProfile video={video} />
                  <MonitorVideoInfo video={video} />
                </> }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
	({ application } : StoreState) => ({
    app: application.application,
    appUser: application.appUser
  }),
	(dispatch) => ({
    actions: bindActionCreators(applicationActions, dispatch),
  })
)(VodMonitorContainer)
