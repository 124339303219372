import { createAction, handleActions, Action } from 'redux-actions'
import { produce } from 'immer';

const SET_CONTENT = 'upload/SET_CONTENT'

interface UploadPayload {
  content: number
}

export const actions = {
  setContent: createAction<UploadPayload>(SET_CONTENT)
}

export interface UploadState {
  content: number
}

const initialState: UploadState = {
  content: 0
}

export default handleActions<UploadState, unknown> ({
  [SET_CONTENT]: (state, action: Action<UploadPayload>) => produce(state, draft => { draft.content = action.payload.content })
}, initialState)