import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as reviewActions } from '../modules/user'
import { CheckBox } from '../components/CheckBox';
import { UserInfo } from './Users';
import Dropdown from '../components/dropdown/Dropdown';
import { MoreIcon } from '../assets/svg';
import Confirm from '../components/modal/Confirm';
import { StoreState } from '../modules';
import { AppUserInfo } from '../settings/Settings';

interface Props {
  user: UserInfo
  checked: boolean
  onBlock(): void
  onUnBlock(): void
  actions: typeof reviewActions
  appUser: AppUserInfo
}

enum ModalStateCode {
  Block = 1,
}

interface State {
  visibleModal: ModalStateCode
}

class UserItem extends React.Component<Props, State> {
  state = {
    visibleModal: 0,
  }

  toggleModalGenerator = (modalCode: ModalStateCode) => () => {
    if(this.state.visibleModal === modalCode) {
      this.setState({visibleModal: 0})
    } else {
      this.setState({visibleModal: modalCode})
    }
  }

  toggleBlock = this.toggleModalGenerator(ModalStateCode.Block)
  
  onChange = (id: string, checked: boolean) => {
    const { user, actions } = this.props
    if (checked) {
      actions.checked(user)
    } else {
      actions.unchecked(user)
    }
  }

  public render = (): JSX.Element | null => {
    const { user, appUser } = this.props

    const options = [
      {
        value: user.block ? 'UnBlock' : 'Block',
        onClick: this.toggleBlock,
        disabled: user.userId === appUser.userId
      }
    ]
    
    if (!user) {
      return null
    }

    return (
      <tr>
        <td className="w-5">
          <CheckBox id={user.userId} value={user.userId ? user.userId.toString() : ''} checked={this.props.checked} onChange={this.onChange}/>
        </td>
        <td>{user.userId}</td>
        <td>{user.userName}</td>
        <td>{user.createdAt}</td>
        <td>{user.updatedAt}</td>
        <td className="application-item-menu">
          <Dropdown>
            <Dropdown.Button><MoreIcon /></Dropdown.Button>
            <Dropdown.Items options={options}/>
          </Dropdown>
        </td>
        <Confirm message={`Do you want to ${user.block ? "unblock" : "block"} this user?`} visible={this.state.visibleModal === ModalStateCode.Block} setVisible={this.toggleBlock} onOk={user.block ? this.props.onUnBlock : this.props.onBlock} />
      </tr>
    )}
} 

export default connect(
  ({application}: StoreState) => ({
    appUser: application.appUser,
  }),
  (dispatch) => ({
    actions: bindActionCreators(reviewActions, dispatch)
  })
)(UserItem)