import * as React from 'react'
import Button from '../components/button/Button'
import Dropdown from '../components/dropdown/Dropdown'
import FileInput from '../components/input/FileInput'
import TextArea from '../components/input/TextArea'
import TextInput from '../components/input/TextInput'
import UploadVideoPlayer from '../upload/UploadVideoPlayer'
import Confirm from '../components/modal/Confirm'
import "./UploadForm.scss"
import Toast from '../components/Toast'
import { MAX_LENGTH } from '../config/Config'

const getRequestId = (url: string) => {
  if (url === '') {
    return '';
  }

  return url.split('/').pop()?.substring(0, 8);
}

interface Props {
  onClear(): void
  onSubmit(state: any): void
  percent: number
}

interface State {
  title: string,
  content: string,
  video: {[index: string]: any},
  thumbnail: {[index: string]: any}
  videoError: string
  seek: string
  visiblity: string
  duration: string
  confirm: boolean
}

class UploadForm extends React.Component<Props, State> {
  
  state: State = {
    title: '',
    content: '',
    seek: '',
    duration: '',
    visiblity: 'Public',
    video: {
      src: '',
      isSelected: false
    },
    thumbnail: {
      src: '',
      isSelected: false
    },
    videoError: "",
    confirm: false
  }

  setConfirm = (confirm: boolean) => (): void => {
    this.setState({ confirm })
  }

  handleClear = (e: React.FormEvent<HTMLButtonElement>): boolean => {
    e.preventDefault();

    this.setState({
        title: '',
        content: '',
        seek: '',
        duration: '',
        video: {
          src: '',
          isSelected: false
        },
        thumbnail: {
          src: '',
          isSelected: false
        }
    });

    this.props.onClear();
    return false;
  }

  handleVideoRemove = () : void => {
    this.setState({
      video: {
        name: '',
        isSelected: false
      }
    });
  }

  handleImageRemove = () : void => {
    this.setState({
      thumbnail: {
        src: '',
        isSelected: false
      }
    });
  }
  
  checkVideo = (url: string): void => {
    const videoEl = document.createElement("video");
    videoEl.src = url
    
    videoEl.onloadedmetadata = () => {
      window.URL.revokeObjectURL(videoEl.src);
      const { videoWidth, videoHeight } = videoEl;
      
      if(videoWidth < 1 && videoHeight < 1) {
        Toast.notify("This video can't preview.");
      }
    }

    videoEl.onerror = () => {
      Toast.notify('Please upload a video file.');
    }
  }

  handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = (e.target as HTMLInputElement).files

    if (!files) {
      return
    }

    const video = files[0];
    console.log(video);

    if (!video) {
      console.log('video not found');
      this.setState({
        video: {
          name: '',
          isSelected: false
        }
      });
      return;
    }

    const url = URL.createObjectURL(video);
    this.checkVideo(url);
    
    const requestId = getRequestId(url);
    console.log('requestId: ' + requestId);

    this.setState({
      video: {
          title: video.name,
          type: video.type,
          src: url,
          isSelelcted: true,
          file: video,
          requestId: encodeURIComponent('' + requestId),
      },
      videoError: ''
    });
  }

  handleImgChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = (e.target as HTMLInputElement).files
    if (!files) {
      return
    }

    const thumbnail = files[0];
    console.log("thumbnail", thumbnail);

    if (!thumbnail) {
      console.log('thumbnail not found');
      this.setState({
          thumbnail: {
            src: '',
            isSelected: false
          }
      });
      return;
    }

    const src = URL.createObjectURL(thumbnail);
    this.setState({
      thumbnail: {
          name: thumbnail.name,
          type: thumbnail.type,
          src,
          isSelected: true,
          file: thumbnail
        }
    });
  }

  handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void =>{
    const { name, value } = e.currentTarget
    this.setState({
      ...this.state,
      [name]: value
    })
  }

  onChangeVisiblilty = (visiblity: string) => (): void => {
    this.setState({ visiblity })
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    console.log(this.state);

    if (!this.state.video.src) {
      this.setState({videoError: 'Select a video to upload'})
      return;
    }
    this.setState({videoError: '', confirm: true})
  }

  handleUpload = (): void => {
    this.props.onSubmit(this.state);
    console.log(this.props.onSubmit, "this.props.onSubmit")
    this.setState({
        title: '',
        content: '',
        seek: '',
        duration: '',
        video: {
          src: '',
          isSelected: false
        },
        thumbnail: {
          src: '',
          isSelected: false
        }
    });
  }

  public render = (): JSX.Element => {
    const KEY_SRC = 'src'
    const video = this.state.video ? this.state.video[KEY_SRC] : ''
    const thumbnail = this.state.thumbnail? this.state.thumbnail[KEY_SRC] : ''

    const options = [
      {
        value: 'Private',
        onClick: this.onChangeVisiblilty("Private"),
      },
      {
        value: 'Public',
        onClick: this.onChangeVisiblilty("Public"),
      }
    ]

    return (
      <form className="upload-form" onSubmit={this.handleSubmit}>
        <div className="form-body">
          <div>
            <FileInput label="Video" accept="video/*" description="* Select a video file and click upload button." value={this.state.video} error={this.state.videoError}  onChange={this.handleVideoChange} onRemove={this.handleVideoRemove} required />
            <FileInput label="Thumbnail" accept="image/*" description="* Thumbnails can only select image files." value={this.state.thumbnail}  onChange={this.handleImgChange} onRemove={this.handleImageRemove} />
            <UploadVideoPlayer src={video} poster={thumbnail}/>
            <TextInput type="text" name="title" label="Title" value={this.state.title} onChange={this.handleInputChange} maxLength={MAX_LENGTH.videoTitle} />
            <div className="preview-time-input-group">
              <TextInput type="text" name="seek" label="Seek" value={this.state.seek} onChange={this.handleInputChange} placeholder={'ex) 00:00:30'} />
              <TextInput type="text" name="duration" label="Duration" value={this.state.duration} onChange={this.handleInputChange} placeholder={'ex) 00:00:10'} />
              <div className="input-group">
                <label htmlFor="Visiblilty">Visiblilty</label>
                <Dropdown>
                  <Dropdown.Button toggleIcon>
                    <span>{this.state.visiblity}</span>
                  </Dropdown.Button>
                  <Dropdown.Items options={options} />
                </Dropdown>
              </div>
            </div>
            <TextArea type="text" id="inputContent" name="content" label="Description" value={this.state.content} onChange={this.handleInputChange} rows={3} maxLength={MAX_LENGTH.videoDesc}/>
          </div>
        </div>
        <div className="upload-button-group">
          <Button type="button" size="large" color="white" onClick={this.handleClear} disabled={this.props.percent}>
            Cancel
          </Button>
          <Button type="submit" size="large" color="red" disabled={this.props.percent}>
            Upload
          </Button>
        </div>
          <Confirm visible={this.state.confirm} setVisible={this.setConfirm(false)} onOk={this.handleUpload} message="Do you want to upload a video?" />
      </form>
    );
  }
}

export default UploadForm;
