import * as React from 'react'

export default function VodBadge(): JSX.Element {
  return (
    <svg width="57" height="32" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter x="-17.8%" y="-40%" width="135.6%" height="180%" filterUnits="objectBoundingBox" id="a">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g filter="url(#a)" transform="translate(6 6)" fill="none" fillRule="evenodd">
        <path d="M10 0h25c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10S4.477 0 10 0z" fill="#FFB700" />
        <text fontFamily="NotoSansCJKkr, sans-serif" fontSize="10" fontWeight="bold" fill="#FFF">
          <tspan x="11" y="14">
            VOD
          </tspan>
        </text>
      </g>
    </svg>
  )
}
