import * as React from 'react';

interface SpinnerProps {
  progress: number
  style?: React.CSSProperties
}

export const Spinner: React.FunctionComponent<SpinnerProps> = ({ progress, style }) => {
  if (progress === 2) {
    return (
      <div className="container" style={style}>
        <h6 className="text-muted">Transcoding</h6>
        <svg className="spinner" width="48px" height="48px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
        </svg>
      </div>
    );
  }

  return null;
}