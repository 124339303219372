import * as React from 'react'
import { actions as loginActions } from '../modules/login'
import { actions as contentActions } from '../modules/content'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from '../components/button/Button'
import TextInput from '../components/input/TextInput'
import Title from '../components/title/Title'
import './SignForm.scss'
import { GoSignUpLink } from './GoLoginForms'
import { changePassword } from '../account/Auth'
import Toast from '../components/Toast'
import { Config, Messages } from '../config'
import { MAX_LENGTH } from '../config/Config'
import { Mail } from '../assets/svg'


interface Props {
  logins: typeof loginActions
  contents: typeof contentActions
}

interface State {
  password: string
  passwordError: string
  confirm: string
  confirmError: string
  secret: string
}

class PaswordConfirmForm extends React.Component<Props, State> {
  state: State = {
    password: '',
    confirm: '',
    passwordError: '',
    confirmError: '',
    secret: ''
  }

  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const secret = urlParams.get('secret') || ''

    if (secret === '') {
      Toast.warn('The wrong approach.');
      window.location.href = '/'
    }

    this.setState({ secret })
  }

  onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { password, confirm } = this.state
    
    if (password === '') {
      this.setState({ passwordError: 'Please enter your Password' })
      return
    } else if (password.trim().length < Config.PASSWORD_MIN_LENGTH) {
      this.setState({ passwordError: Messages.PASSWORD_MIN_LENGTH })
      return
    }
    this.setState({ passwordError: '' })

    if (confirm === '') {
      this.setState({ confirmError: 'Please enter confirm Password' })
      return
    } else if (confirm.trim().length < Config.PASSWORD_MIN_LENGTH) {
      this.setState({ confirmError: Messages.CONFIRM_PASSWORD_MIN_LENGTH })
      return
    }
    
    this.setState({ confirmError: '' })

    if (password !== confirm) {
      this.setState({ confirmError: 'Your password and confirmation password do not match.' })
      return
    }

    const { secret } = this.state
    changePassword(secret, password, this.success)
  }

  success = (): void => {
    Toast.warn(
      <div className="toast-warn">
        <Mail />You have changed your password.
      </div>
    )
    window.location.href = '/applications'
  }

  public render = (): JSX.Element => {  
    return (
      <div>
        <form className="sign-group" onSubmit={this.handleSubmit}>
          <Title size="medium" color="black">
            Confirm Password
          </Title>
          <TextInput type="password" id="password" name="password" value={this.state.password} onChange={this.onChangeInput} label="Password" error={this.state.passwordError} required maxLength={MAX_LENGTH.password} />
          <TextInput type="password" id="confirm_password" name="confirm" value={this.state.confirm} onChange={this.onChangeInput} label="Confirm Password" error={this.state.confirmError} required maxLength={MAX_LENGTH.password} />
          <Button color="yellow" size="large">Save</Button>
        </form>
        <div className="goto_tool">
          <GoSignUpLink path={'/applications'} />
        </div>
      </div>
    )
  }
}

export default connect(
  () => ({}),
  (dispatch) => ({
    logins: bindActionCreators(loginActions, dispatch),
    contents: bindActionCreators(contentActions, dispatch),
  }),
)(PaswordConfirmForm)
