import Cookies, { CookieAttributes } from 'js-cookie'
import Config from './Config'

interface Stores {
  init(): void
  set(key: string, value: any): void
  get(key: string): void
  remove(key: string): void
  clear(): void
  logout(): void
}

class Cookie implements Stores {
  cookie: typeof Cookies
  static instance: Cookie | null
  option: CookieAttributes | undefined

  constructor(option?: CookieAttributes) {
    this.cookie = Cookies
    this.option = option
  }

  init = (): void => {
    this.cookie = Cookies
  }

  public static getInstance = (): Cookie => {
    const cookieOption = {
      path: "/",
      domain: window.location.origin,
      secure: process.env.REACT_APP_STAGE === 'production' ? true : false,
      expires: 1/3,
    };

    if(!Cookie.instance) {
      Cookie.instance = new Cookie(cookieOption)
    }

    return Cookie.instance
  }

  set = (key: string, value: string | object): void => {
    this.cookie.set(key, value)
  }

  get = (key: string): string => {
    return this.cookie.get(key) || ''
  }

  remove = (key: string): void => {
    this.cookie.remove(key)
  }

  clear = (): void => {
    Object.keys(this.cookie.get()).forEach((cookieName) => { this.remove(cookieName) })
  }

  logout = (): void => {
    this.clear();
    localStorage.removeItem('app');
    this.cookie.set(Config.ACCESS_TOKEN_KEY, Config.GUEST_TOKEN)
  }
}

export const Store = Cookie.getInstance()
