import * as React from 'react'
import { useRef, useCallback, ChangeEvent } from 'react'
import { DeleteGray } from '../../assets/svg'
import Button from '../button/Button'
import './FileInput.scss'

interface Props {
  label?: string
  id?: string
  value?: any
  required?: boolean
  error?: string
  description?: string | React.ReactNode
  maxLength?: number
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onRemove?: () => void
  [key: string]: any
}

export default function FileInput({
  label,
  id,
  error,
  value,
  description,
  required,
  maxLength = 80,
  onRemove,
  onChange,
  ...props
}: Props): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null)

  const onClickButton = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (inputRef.current instanceof HTMLElement) {
      inputRef.current.click();
    }
  }, [])

  const onClickRemove = useCallback(() => {
    if (inputRef.current instanceof HTMLElement) {
      inputRef.current.value = ""
    }
    if(onRemove) {
      onRemove()
    }
  }, [onRemove])

  return (
    <>
      <div className="file-input-group">
        { label && 
        <label htmlFor={id || label}>
          <span>{`${label}`}</span>
          {required && <span className="input-required">*</span>}
        </label>
        }
        <input ref={inputRef} type="file" className="form-control-file" id={id || label} onChange={onChange} maxLength={maxLength} {...props}/>
        <div className="input-content">
          <Button size="medium" color="black" onClick={onClickButton}>파일 선택</Button>
          <div className="file">{value && (value.title || value.name) && <span>{value.title || value.name}<DeleteGray className="delete-button" onClick={onClickRemove} /></span>}</div>
        </div>
      <input type="text" value={value} id={id || label} onChange={onChange} {...props} />
      {error ? <span className="input-error-message">{`* ${error}`}</span> : description && <div className="input-text">{description}</div>}
      </div>
    </>
  )
}