import * as React from 'react'
import NoVideo from './NoVideo'
import VideosReviewItem from './VideosReviewItem'
import { ReviewInfo } from './Reviews'

interface Props {
  list: ReviewInfo[]
  onBlock: (review: ReviewInfo) => void
  onDelete: (key: string) => void
}

export default function ReviewList({ list, onBlock, onDelete }: Props) : JSX.Element {
  console.log(list);
  return (
    <>
      { list.length > 0 ? (
        list.map((v, index) => <VideosReviewItem key={index} review={v} onBlock={() => {onBlock(v)}} onDelete={() => {onDelete(v.videoKey)}} /> )
      ) : (
        <NoVideo />
      )}
    </>
  )
}
