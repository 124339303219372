import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { actions as settingActions } from '../modules/setting'
import SettingInputText from '../components/input/SettingTextInput'
import { MAX_LENGTH } from '../config/Config'
import { AppUserInfo } from './Settings'
import { StoreState } from '../modules';


interface Props {
  appUser: AppUserInfo
  settingActions: typeof settingActions
}

interface State {
}

class AdminInfoItem extends React.Component<Props, State> {

  onChangeInput = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })

    const { appUser } = this.props
    switch(name) {
      case 'userid':
        appUser.userId = value
        break
      case 'username':
        appUser.username = value
        break
      default:
        // profile_photo
        appUser.profilePhotoUrl = value
    }

    this.props.settingActions.setAppUser(appUser)
  }

  public render = (): JSX.Element => {
    const { appUser } = this.props
    return (
      <div className="settings col-xl-12">
        <div className="settings-header">
          <h5 className="card-title">Admin Info</h5>
        </div>
        <div className="card settings-item">
          <div className="card-body overview-body">
            <SettingInputText type="text" id="uesrid" name="userid" value={appUser.userId} onChange={this.onChangeInput} label="UserID" maxLength={MAX_LENGTH.userId}/>
            <SettingInputText id="username" name="username" value={appUser.username} onChange={this.onChangeInput} label="Username" maxLength={MAX_LENGTH.username}/>
            <SettingInputText id="profile-photo" name="profile_photo" value={appUser.profilePhotoUrl} onChange={this.onChangeInput} label="Profile photo" maxLength={MAX_LENGTH.url}/>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ setting }: StoreState) => ({
    appUser: setting.appUser,
  }),
	(dispatch) => ({
    settingActions: bindActionCreators(settingActions, dispatch),
  })
)(AdminInfoItem)
