import * as React from 'react'
import SettingInputText from '../components/input/SettingTextInput'
import { MAX_LENGTH } from '../config/Config'


interface Props {
  member: any
  onChange(profile: any): void
}

interface State {
  companyError: string
  avatarError: string
  firstnameError: string
  lastnameError: string
}

class MemberInfoItem extends React.Component<Props, State> {
  state: State = {
    companyError: '',
    avatarError: '',
    firstnameError: '',
    lastnameError: ''
  }

  componentDidMount = (): void => {
    console.log(this.props.member)
  }

  onChangeInput = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })

    const { member } = this.props
    // const updated = Object.assign(member)
    member[name] = value

    console.log(name)
    console.log(member)
    this.props.onChange(member)
  }

  public render = (): JSX.Element => {
    const { member } = this.props

    return (
      <div className="settings col-xl-12">
        <div className="settings-header">
          <h5 className="card-title">Admin Info</h5>
        </div>
        <div className="card settings-item">
          <div className="card-body overview-body">
            <SettingInputText type="email" id="email" name="email" value={member.email} onChange={this.onChangeInput} label="Email" readOnly maxLength={MAX_LENGTH.email} />
            <SettingInputText id="firstname" name="firstname" value={member.firstname} onChange={this.onChangeInput} label="First name" error={this.state.firstnameError} maxLength={MAX_LENGTH.firstName}/>
            <SettingInputText id="lastname" name="lastname" value={member.lastname} onChange={this.onChangeInput} label="Last name" error={this.state.lastnameError} maxLength={MAX_LENGTH.lastName}/>
            <SettingInputText id="company" name="company" value={member.company} onChange={this.onChangeInput} label="Company" error={this.state.companyError} maxLength={MAX_LENGTH.company}/>
            <SettingInputText id="avatar" name="avatar" value={member.avatar} onChange={this.onChangeInput} label="Avatar" error={this.state.avatarError} maxLength={MAX_LENGTH.url}/>
          </div>
        </div>
      </div>
    )
  }
}

export default MemberInfoItem