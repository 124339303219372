import * as React from 'react'
import { actions as appContentActions } from '../modules/application'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { saveApplication } from './Applications'
import { TextLength } from '../components/TextLength'
import './ApplicationForm.scss'
import TextInput from '../components/input/TextInput'
import Button from '../components/button/Button'
import { createUUID } from '../config/Format'
import Toast from '../components/Toast'
import Config, { MAX_LENGTH } from '../config/Config'

interface Props {
  actions: typeof appContentActions
  refreshApplication(): void
}

interface State {
  name: string
  nameError: string
}

class ApplicationForm extends React.Component<Props> {
  state: State = {
    name: '',
    nameError: '',
  }

  onChangeRequiredInput = (inputName: string) => (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget

    if (value === '') {
      this.setState({ [name]: value, [`${name}Error`]: `Please enter your Application ${inputName}` })
    } else {
      this.setState({ [name]: value, [`${name}Error`]: '' })
    }
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const { name } = this.state

    if (name === '') {
      this.setState({ nameError: 'Please enter your Application name' })
      return
    }
    this.setState({ nameError: '' })

    const request = {
      name,
      app_key: this.createAppKey(),
      app_secret: this.createAppSecret(),
      user: {
        user_id: Config.APP_DEFAULT_USERNAME,
        username: Config.APP_DEFAULT_USERNAME,
        profile_photh_url: ""
      }
    }

    saveApplication(request).then(
      () => {
        Toast.notify('Saved successfully')
        this.props.refreshApplication()
        this.goApplicationList()
      },
      (err) => {
        console.error(err)
        Toast.notify('Failed to save the Application')
      },
    )
    
    this.props.refreshApplication()
  }

  goApplicationList = (): void => {
    this.props.actions.setContent({ content: 0 })
  }
  
  createAppKey = (): string => {
    return createUUID(10, true)
  }

  createAppSecret = (): string => {
    return createUUID(50, false)
  }

  public render() {
    return (
      <form className="application-modal-form" onSubmit={this.handleSubmit}>
        <div className="generator">
          <div className="app-name-input">
            <TextInput type="text" name="name" label="App name" value={this.state.name} onChange={this.onChangeRequiredInput("name")} maxLength={200} required error={this.state.nameError} description={<small>{`* The application name can be up to ${MAX_LENGTH.appName} characters long.`}<TextLength text={this.state.name} maxLength={MAX_LENGTH.appName} /></small>} />
          </div>
        </div>
        <div className="button">
          <Button type="button" size="large" color="white" onClick={this.goApplicationList}>
            Cancel
          </Button>
          <Button type="submit" size="large" color="red">Ok</Button>
        </div>
      </form>
    )
  }
}

export default connect(
  () => ({}),
  (dispatch) => ({
    actions: bindActionCreators(appContentActions, dispatch),
  })
)(ApplicationForm)
