import * as React from 'react'
import './TextArea.scss'

interface Props {
  label?: string
  id?: string
  value: string
  rows?: number
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  description?: string | React.ReactNode
  required?: boolean
  error?: string
  maxLength?: number
  [key: string]: any
}

export default function TextArea({
  label,
  value,
  id,
  error,
  required,
  rows = 5,
  maxLength = 500,
  description,
  onChange,
  ...props
}: Props): JSX.Element {
  return (
    <div className="input-group">
      <label htmlFor={id || label}>
        <span>{`${label} `}</span>
        {required && <span className="input-required">*</span>}
      </label>
      <textarea
        value={value}
        id={id || label}
        onChange={onChange}
        rows={rows}
        maxLength={maxLength}
        {...props}
      />      
      {error ? <span className="input-error-message">{`* ${error}`}</span> : description && <div className="input-text">{description}</div>}
    </div>
  )
}
