import * as React from 'react'
import { connect } from 'react-redux';
import { StoreState } from '../modules';
import { ApplicationInfo } from '../application/Applications';
import { ReviewInfo } from './Reviews';
import { MessageInfo } from './Messages';
import MessageItem from './MessageItem';
import "./MessageModalContent.scss"

interface Props {
  app?: ApplicationInfo
  review?: ReviewInfo
  list?: MessageInfo[]
  messages: MessageInfo[]
  changeContent(timestamp: string | number, direction: number): void
}

class MessageModal extends React.Component<Props> {
  onPrev = () => {
    const { changeContent, messages } = this.props
    changeContent(messages[0]?.createdAt, 0);
  }

  onNext = () => {
    const { changeContent, messages } = this.props
    changeContent(messages[messages.length -1 ]?.createdAt, 1);
  }

  public render() {
    const items = this.props.list?.map((m, index) =>
      <MessageItem key={index} message={m} />
    )

    return (
      <div>
        <div className="modal-body message-list">
          { items?.length === 0 ? 
            <p className="text-center no-item-message">There is no message</p> :
            <div>
              {items}
            </div>
          }
        </div> 
          <div className="modal-pagination-wrap">
            <button type="button" onClick={this.onPrev}>Prev</button>
            <button type="button" onClick={this.onNext}>Next</button>
          </div>
      </div>
    )
  }
}

export default connect(
	({ application, review } : StoreState) => ({
    app: application.application,
    review: review.review,
    messages: review.messages,
  }),
	() => ({})
)(MessageModal)