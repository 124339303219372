import * as React from 'react'
import { actions as loginActions } from '../modules/login'
import { actions as contentActions } from '../modules/content'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from '../components/button/Button'
import Title from '../components/title/Title'
import './SignForm.scss'
import { verification } from '../account/Auth'
import Toast from '../components/Toast'
import { Mail } from '../assets/svg'


interface Props {
  logins: typeof loginActions
  contents: typeof contentActions
}

interface State {
  email: string
  secret: string
  modal: boolean
}

class EmailVerificationForm extends React.Component<Props, State> {
  state: State = {
    email: '',
    secret: '',
    modal: false
  }

  componentDidMount = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email') || ''
    const secret = urlParams.get('secret') || ''

    if (email === '') {
      Toast.warn('The wrong approach.');
      window.location.href = '/'
    }

    this.setState({ email, secret })
  }

  onChangeInput = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { secret } = this.state
    
    verification(secret, this.success, this.resend)
  }

  success = (): void => {
    Toast.warn(
      <div className="toast-warn">
        <Mail />Email verification was successful.
      </div>
    )
    window.location.href = '/applications'
  }

  resend = (): void => {
    Toast.warn(
      <div className="toast-warn">
        <Mail />Expired email verification
      </div>
    )
    window.location.href = '/resend'
  }

  public render = (): JSX.Element | null => {  
    const { email } = this.state

    if (email === '') {
      return null
    }

    return (
      <form className="sign-group verification-group" onSubmit={this.handleSubmit}>
        <Title size="medium" color="black">Confirm Email</Title>
        <div className="input-group">
          <label htmlFor={'email'}>
            <span>email</span>
            <span className="input-required">*</span>
          </label>
          <p className="confirm-label">{email}</p>
        </div>
        <Button color="yellow" size="medium">Verification and Login</Button>
      </form>
    )
  }
}

export default connect(
  () => ({}),
  (dispatch) => ({
    logins: bindActionCreators(loginActions, dispatch),
    contents: bindActionCreators(contentActions, dispatch),
  }),
)(EmailVerificationForm)
