import * as React from 'react'
import { Link } from 'react-router-dom'
import { LeftArrow, RedBullet } from '../assets/svg'

interface Props {
  onClick(): void
}

interface LinkProps {
  path: string
}

export function GoSignUp({ onClick }: Props): JSX.Element {
  return (
    <p>
      <RedBullet />
      <span>Don’t have an account?</span>
      <a onClick={onClick}>Signup</a>
    </p>
  )
}

export function GoSignUpLink({ path }: LinkProps): JSX.Element {
  return (
    <p>
      <RedBullet />
      <span>Don’t have an account?</span>
      <Link to={path}>Signup</Link>
    </p>
  )
}

export function GoSignIn({ onClick }: Props): JSX.Element {
  return (
    <p>
      <RedBullet />
      <span>Already have an account?</span>
      <a onClick={onClick}>Login</a>
    </p>
  )
}

export function GoForgetPassword({ onClick }: Props): JSX.Element {
  return (
    <p>
      <RedBullet />
      <span>Forgot your password?</span>
      <a onClick={onClick}>Reset</a>
    </p>
  )
}

export function GoBackSignIn({ onClick }: Props): JSX.Element {
  return (
    <p className="back-link">
      <LeftArrow />
      <a onClick={onClick}>Back to Sign in</a>
    </p>
  )
}

export function GoBackSignInLink({ path }: LinkProps): JSX.Element {
  return (
    <p className="back-link">
      <LeftArrow />
      <Link to={path}>Back to Sign in</Link>
    </p>
  )
}
