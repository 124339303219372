import { Client } from '../config'
import { PageRequest, PageInfo } from '../components/pagination/Paginations'
import { AxiosPromise } from 'axios'
import { dateFormat } from '../config/Format'
import { errorHandler, muteHandler} from '../account/Auth'
import { VideoInfo } from '../monitor/Video'


type StreamKey = {
  id: number
  app_key: string
  stream_key: string
  nickname: string
  state: number
  created_at: number
}

export class StreamKeyInfo {
  id: number
  appKey: string
  streamKey: string
  nickname: string
  state: number
  stateAsString: string
  createdAt: string

  constructor(json: StreamKey) {
    this.id = json.id
    this.appKey = json.app_key
    this.streamKey = json.stream_key
    this.nickname = json.nickname || 'N/A'
    this.state = json.state
    this.stateAsString = this.getStateAsString(json.state)
    this.createdAt = dateFormat(json.created_at);
  }

  getStateAsString = (state: number): string => {
    switch(state) {
      case 1:
        return 'Broadcasting'
      case 2:
        return 'End'
      default:
        // 0
        return 'Created'
    }
  }
}


export const getStreamKeys = (appKey: string, page: PageRequest, callback:(list: StreamKeyInfo[], pageInfo: PageInfo) => void): void => {
  const param: {} = { 'page': page.page, 'size': page.size, 'app_key': appKey} 

  new Client(`/api/2/streams`)
    .get(param)
    .then(response => {
      console.log(response)
      
      const StreamKeys: StreamKeyInfo[] = []
      const size = response.data.length;
      const pageInfo : PageInfo = new PageInfo({ 
          size: size,
          total_elements: size,
          total_pages: 1,
          number: 0,
        });

      for (const u of response.data) {
        StreamKeys.push(new StreamKeyInfo(u))
      }

      callback(StreamKeys, pageInfo)
    }, errorHandler)
}

export const saveStreamKey = (request: {}): AxiosPromise => {
  return new Client('/api/2/streams')
      .post(request)
}

export const deleteStreamKey = (id: number): AxiosPromise => {
  return new Client('/api/2/streams')
      .delete('' + id)
}

export const startStream = (videoKey: string, callback:() => void): void => {
  new Client(`/api/2/videos/${videoKey}/start`)
    .patch('', {})
    .then(res => {
      console.log(res.data)
      callback()
    }, errorHandler)
}

export const stopStream = (videoKey: string, callback:() => void): void => {
  new Client(`/api/2/videos/${videoKey}/stop`)
    .patch('', {})
    .then(res => {
      console.log(res.data)
      callback()
    }, errorHandler)
}

export const getVideoByStream = (appKey: string, params: {}, callback:(video: VideoInfo) => void): void => {
  new Client(`/api/2/applications/${appKey}/video`)
    .get(params)
    .then(res => {
      console.log(res.data)
      callback(new VideoInfo(res.data))
    }, muteHandler)
}

export const createBroadcast = (appKey: string, request: {}, callback:(video: VideoInfo) => void): void => {
  new Client(`/api/2/applications/${appKey}/videos`)
    .post(request)
    .then(res => {
      console.log(res.data)
      callback(new VideoInfo(res.data))
    }, errorHandler)
}

export const updateBroadcast = (appKey: string, videoKey: string, request: {}, callback:(video: VideoInfo) => void): void => {
  new Client(`/api/2/applications/${appKey}/videos`)
    .patch(videoKey, request)
    .then(res => {
      console.log(res.data)
      callback(new VideoInfo(res.data))
    }, errorHandler)
}

export const endBroadcast = (appKey: string, videoKey: string, request?: {}, callback?:() => void): void => {
  new Client(`/api/2/applications/${appKey}/videos/${videoKey}/terminate`)
    .post(request || {})
    .then(res => {
      console.log(res.data)
      if(callback) {
        callback()
      }
    }, errorHandler)
}