import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as monitorActions } from '../modules/monitor'
import { StoreState } from '../modules';
import SearchInput from '../components/input/SearchInput'
import { NavItem } from '../components/Navigation'
import ViewerList from './ViewerList'
import WatcherList from './WatcherList'
import './MonitorSubBox.scss'
import { getMonitorViewers, getMonitorWatchers, getViewersCount, ViewerInfo, WatcherInfo } from '../review/Watchers'
import { blockUser, unBlockUser } from '../user/Users'
import { FilterRequest, PageInfo } from '../components/pagination/Paginations';
import { padNumber } from '../config/Format';

interface Props {
  appKey: string
  videoKey: string
  actions: typeof monitorActions
  pageRequest: FilterRequest
  watchers: WatcherInfo[]
  viewers: ViewerInfo[]
}

interface State {
  stage: number
  search: string
  scrollLock: boolean
  lastPage: number
  totalElements: number[]
}

class VodMonitorSubBox extends Component<Props, State> {
  state = {
    stage: 0,
    search: '',
    scrollLock: false,
    lastPage: 0,
    totalElements: []
  }

  initPage() {
    const { pageRequest } = this.props
    pageRequest.page = 0
    pageRequest.direction = 0
    pageRequest.search = ""
    
    this.props.actions.setPageRequest(pageRequest)
    this.setState({ scrollLock: false, search: '', lastPage: 0 })
  }


  onChangeSearch = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget
    if (value === ' ') {
      return
    }

    this.setState({ search: value })
  }

  handleReload = (): void => {
    this.initPage()
    if(this.state.stage === 0) {
      getMonitorWatchers(this.props.appKey, this.props.videoKey, this.props.pageRequest, this.initWatchers)
    } else if(this.state.stage === 1) {
      getMonitorViewers(this.props.appKey, this.props.videoKey, this.props.pageRequest, this.initViewers)
    }
  }

  handleStage = (stage: number) => (): void => {
    this.setState({ stage })
    this.handleReload()
  }

  setWatchers = (list: WatcherInfo[], page: PageInfo, lastPage: number) => {
    this.props.actions.setWatchers({ list: [...this.props.watchers].concat(list), page })
    this.setState({scrollLock: false, lastPage})
  }

  setViewers = (list: ViewerInfo[], page: PageInfo, lastPage: number) => {
    this.props.actions.setViewers({ list: [...this.props.viewers].concat(list), page })
    this.setState({scrollLock: false, lastPage})
  }

  initWatchers = (list: WatcherInfo[], page: PageInfo, lastPage: number) => {
    this.props.actions.setWatchers({ list, page })
    this.setState({scrollLock: false, lastPage})
  }

  initViewers = (list: ViewerInfo[], page: PageInfo, lastPage: number) => {
    this.props.actions.setViewers({ list, page })
    this.setState({scrollLock: false, lastPage})
  }

  setTotalElements = (totalElements: number[]) => {
    this.setState({ totalElements })
  }

  search = (): void => {
    this.props.pageRequest.search = this.state.search
    
    if(this.state.stage === 0) {
      getMonitorWatchers(this.props.appKey, this.props.videoKey, this.props.pageRequest, this.initWatchers)
    } else if(this.state.stage === 1) {
      getMonitorViewers(this.props.appKey, this.props.videoKey, this.props.pageRequest, this.initViewers)
    }
  }

  onBlock = (id: string): void => {
    blockUser(this.props.appKey, id, this.props.videoKey).then(() => this.handleReload())
  }

  onUnBlock = (id: string): void => {
    unBlockUser(this.props.appKey, id).then(() => this.handleReload())
  }

  addScrollEvent = (callback: any) => {
    const subbox: HTMLElement | null = document.querySelector('.monitor-subbox-content');
    if(subbox) {  
      subbox.onscroll = () => {
        if(subbox.scrollTop > subbox.scrollHeight - subbox.offsetHeight - 10) {
          callback()
        }
      }
    }
  }

  componentDidMount () {
    this.addScrollEvent(this.loadMoreViewers)
    getMonitorWatchers(this.props.appKey, this.props.videoKey, this.props.pageRequest, this.setWatchers)
    getMonitorViewers(this.props.appKey, this.props.videoKey, this.props.pageRequest, this.setViewers)
    getViewersCount(this.props.appKey, this.props.videoKey, this.setTotalElements)
  }

  loadMoreViewers = async () => {
    const { scrollLock, lastPage } = this.state
    const hasMore = lastPage > this.props.pageRequest.page

    if (!scrollLock && hasMore) {
      this.setState({ scrollLock : true })
      this.props.pageRequest.page++;

      if(this.state.stage === 0) {
        getMonitorWatchers(this.props.appKey, this.props.videoKey, this.props.pageRequest, this.setWatchers)
      } else if(this.state.stage === 1) {
        getMonitorViewers(this.props.appKey, this.props.videoKey, this.props.pageRequest, this.setViewers)
      }
    } else {
      console.log('scrollLock: ' + scrollLock, hasMore)
    }
  }

  render(): JSX.Element {
    const { stage, search } = this.state;
    const { watchers, viewers } = this.props
    
    return (
      <div className="monitor-subbox-group">
        <div className="tab-group">
          <ul className="nav nav-pills">
            <NavItem stage={stage} value={0} label={`시청자 리스트(${padNumber(this.state.totalElements[0])})`} handleChange={this.handleStage(0)} />
            <NavItem stage={stage} value={1} label={`조회자 리스트(${padNumber(this.state.totalElements[1])})`} handleChange={this.handleStage(1)} />
          </ul>
        </div>
        <SearchInput label="Search Users" value={search} onChange={this.onChangeSearch} onSearch={this.search} maxLength={50} />
        <div className="monitor-subbox-content">
          {stage === 0 ? 
            <WatcherList type="VOD" list={watchers} onBlock={this.onBlock} onUnBlock={this.onUnBlock} handleReload={this.handleReload} /> :
            <ViewerList list={viewers} onBlock={this.onBlock} onUnBlock={this.onUnBlock} handleReload={this.handleReload} />}
        </div>
      </div>
    )
  }
}

export default connect(
  ({ monitor } : StoreState) => ({
    watchers: monitor.watchers,
    viewers: monitor.viewers,
    pageInfo: monitor.page,
    pageRequest: monitor.pageRequest
	}),
	(dispatch) => ({
    actions: bindActionCreators(monitorActions, dispatch)
  })
)(VodMonitorSubBox)
