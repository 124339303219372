import * as React from 'react'
import { Switch, Route } from 'react-router-dom';
import { Application, Profile } from '../pages'


interface Props {
  hasFooter: boolean
}

interface State {
}

class HomeRouter extends React.Component<Props, State> {  
  public render(): JSX.Element {
    return (
      <>
        <div className={this.props.hasFooter ? 'content-container' : ''}>
          <Switch>
            <Route exact path="/applications" component={Application} />
            <Route exact path="/profile" component={Profile} />
            <Route path="/dashboard" component={Application} />
          </Switch>
        </div>
      </>
    )
  }
}

export default HomeRouter