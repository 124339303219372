import * as React from 'react'
import { MessageInfo } from './Messages'

interface Props {
  message: MessageInfo
}

const SystemMessage: React.SFC<Props> = ({ message }) => (
  <div className="bmd-list-group-col">
    <p className="list-group-item-text system-message">{message.message}</p>
  </div>
)

const NormalMessage: React.SFC<Props> = ({ message }) => (
  <div className="list-group-item-text">
  {message.user && message.user.username ? 
    <div className="message-user text-ellipsis">{`${message.user.username} :`}</div> : 
    <div className="message-user">noname:<span className="from-now">{message.createdAt}</span></div> }
    <div className="message-content">{message.message}</div>
  </div>
)


class MessageItem extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { message } = this.props
    return <div className="list-group-item">
        {message.messageType === 'MESSAGE' ? 
          <NormalMessage message={message} /> : 
          <SystemMessage message={message} /> }
      </div>

  }
}


export default MessageItem