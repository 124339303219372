import * as React from 'react'

export default function Next(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" opacity=".3">
        <g fillRule="nonzero">
          <g>
            <g>
              <path d="M0 0H20V20H0z" transform="translate(-892 -879) translate(853 874) translate(39 5)"/>
              <path fill="#000" d="M9 7L13 13 5 13z" transform="translate(-892 -879) translate(853 874) translate(39 5) rotate(-90 9 10)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}