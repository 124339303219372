import * as React from 'react';
import Pagination from '../components/pagination/Pagination';
import { PageState, PageRequest, PageInfo } from '../components/pagination/Paginations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../modules';
import { actions as webinarActions } from '../modules/webinar'
import { ApplicationInfo } from '../application/Applications';
import { NavItem } from '../components/Navigation';
import BreadCrumb from '../components/BreadCrumb';
import './Webinar.scss'
import { getStreamKeys, StreamKeyInfo } from './Webinars';
import StreamKeyTable from './StreamKeyTable';


interface Props extends PageState {
  app: ApplicationInfo
  list: StreamKeyInfo[]
  actions: typeof webinarActions
}

interface State {
  stage: number
}

class StreamKeyContent extends React.Component<Props, State> {
  state: State = {
    stage: 0
  }

  componentDidMount = () => {
    this.loadStreamKeys()
  }

  loadStreamKeys = () => {
    getStreamKeys(this.props.app.appKey, this.props.pageRequest, this.setList)
  }

  handlePage = (page: number): void => {
    const pageRequest = this.props.pageRequest
    pageRequest.page = page
    this.props.actions.setPageRequest(pageRequest)

    this.handleReload(this.state.stage, pageRequest);
  }

  handleReload = (stage: number, pageRequest: PageRequest): void => {
    getStreamKeys(this.props.app.appKey, pageRequest, this.setList)
  }

  setList = (list: StreamKeyInfo[], page: PageInfo): void => {
    this.props.actions.setList({ list, page });
  }
  
  shouldComponentUpdate(nextProps: Props): boolean {
    if (nextProps.app.appKey !== this.props.app.appKey) {
      getStreamKeys(nextProps.app.appKey, this.props.pageRequest, this.setList)
      return true
    }
    return true
  }

  public render = (): JSX.Element => {
    return (
      <div className="dashboard-content col-xl-10">
        <BreadCrumb names={['Dashboard', 'Webinar', 'Stream key']} />
        <StreamKeyTable refresh={this.loadStreamKeys} />
        <div className="mt-50">
          <Pagination page={this.props.pageInfo} handlePage={this.handlePage} />
        </div>
      </div>
    )
  }
}

export default connect(
	({ application, webinar } : StoreState) => ({
    app: application.application,
    list: webinar.list,
    pageInfo: webinar.page,
    pageRequest: webinar.pageRequest
	}),
	(dispatch) => ({
    actions: bindActionCreators(webinarActions, dispatch)
  })
)(StreamKeyContent)