import * as React from 'react';
import { Config } from '../config'
import { MenuProps, ReviewInfo } from './Reviews';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as reviewActions } from '../modules/review'
import { ApplicationInfo } from '../application/Applications';
import { StoreState } from '../modules';
import { MessageInfo, getMessages, loadMessages } from './Messages';
import { getWatchers, WatcherInfo, getViewers, ViewerInfo } from './Watchers';
import "./ReviewItem.scss"
import Confirm from '../components/modal/Confirm';
import Dropdown from '../components/dropdown/Dropdown';
import { FilterRequest, PageInfo, TimeStampInfo } from '../components/pagination/Paginations';
import Modal from '../components/modal/Modal';
import WatcherModalContent from './WatcherModalContent';
import ViewerModalContent from './ViewerModalContent';
import MessageModalContent from './MessageModalContent';
import { AppUserInfo } from '../settings/Settings';
import { LiveBadge, Locked, MoreIcon, Private, UploadBadge, VideoLike, VideoView, VodBadge } from '../assets/svg';
import { openNewPage } from '../config/browser';


interface Props extends MenuProps {
  app: ApplicationInfo
  appUser: AppUserInfo
  review: ReviewInfo
  pageInfo: PageInfo
  pageRequest: FilterRequest
  messages: MessageInfo[]
  watchers: WatcherInfo[]
  viewers: ViewerInfo[]
  actions: typeof reviewActions
}

enum ModalStateCode {
  Block = 1,
  Delete = 2,
  ChatHistory = 3,
  Viewer= 4,
  Watcher= 5
}

interface State {
  visibleModal: ModalStateCode
}

interface ReviewProps {
  review: ReviewInfo
}

const VideoCounts: React.SFC<ReviewProps> = ({ review }) => (
  <div className="icon-group">
    <div className="icon-item"><VideoView /><span>{review.viewCount}</span></div>
    <div className="icon-item"><VideoLike /><span>{review.heartCount}</span></div>
  </div>
)

class VideosReviewItem extends React.Component<Props, State> {  
  state = {
    play: false,
    visibleModal: 0,
  }

  toggleModalGenerator = (modalCode: ModalStateCode) => () => {
    if(this.state.visibleModal === modalCode) {
      this.setState({visibleModal: 0})
    } else {
      this.setState({visibleModal: modalCode})
    }
  }

  toggleBlock = this.toggleModalGenerator(ModalStateCode.Block)
  toggleDelete = this.toggleModalGenerator(ModalStateCode.Delete)
  toggleChatHistory = this.toggleModalGenerator(ModalStateCode.ChatHistory)
  toggleViewer = this.toggleModalGenerator(ModalStateCode.Viewer)
  toggleWatcher = this.toggleModalGenerator(ModalStateCode.Watcher)

  onChange = (id: string, checked: boolean) => {
    const { review, actions } = this.props
    if (checked) {
      actions.checked(review)
    } else {
      actions.unchecked(review)
    }
  }

  playVideo = () => {
    const { review, actions } = this.props

    if(review.type==="BROADCASTED" && review.state === "LIVE") {
      openNewPage(`/monitor/${review.videoKey}`);
      return;
    } else if(review.type==="BROADCASTED" && review.state === "VOD") {
      openNewPage(`/vod/${review.videoKey}`);
      return;
    } else if(review.type === "UPLOADED") {
      openNewPage(`/uploaded/${review.videoKey}`);
      return;
    }
    
    actions.setReview(review)
    localStorage.setItem('dashboard.review', JSON.stringify(review));
  }

  loadDetailedData = () => {
    const { review } = this.props
    this.loadMoreData(review.videoKey)
  }

  loadMoreData = (videoKey: string) => {
    const { app, review } = this.props;
    loadMessages(videoKey, {timestamp: review.createdAt, next_size: Config.MESSAGE_COUNT}, this.setMessages)
    getWatchers(app.appKey, videoKey, this.props.pageRequest, this.setWatchers)
    getViewers(app.appKey, videoKey, this.props.pageRequest, this.setViewers)
  }

  setMessages = (list: MessageInfo[], timestamp: TimeStampInfo) => {
    this.props.actions.setMessages({list, timestamp})
  }

  setWatchers = (list: WatcherInfo[], page: PageInfo) => {
    this.props.actions.setWatchers({ list, page })
  }

  setViewers = (list: ViewerInfo[], page: PageInfo) => {
    this.props.actions.setViewers({ list, page })
  }

  changeMessage = (timestamp: string | number, direction: number) => {
    const { review } = this.props
    getMessages(review.videoKey, {timestamp}, direction, this.setMessages)
  }

  changeWatchers = (pageRequest: FilterRequest) => {
    const { app, review } = this.props
    getWatchers(app.appKey, review.videoKey, pageRequest, this.setWatchers)
  }

  changeViewers = (pageRequest: FilterRequest) => {
    const { app, review } = this.props
    getViewers(app.appKey, review.videoKey, pageRequest, this.setViewers)
  }

  onErrorThubnail = (e: any) => {
    e.target.src = Config.DEFAULT_THUMBNAIL;
  }

  public render = (): JSX.Element => {
    const { review, appUser } = this.props

    const options = [
      {
        value: review.locked ? 'UnBlock' : 'Block',
        onClick: this.toggleBlock,
        disabled: ["DELETED", "LIVE"].some(state => review.state === state)
      },
      {
        value: 'Delete',
        onClick: this.toggleDelete,
        disabled: ["DELETED", "LIVE"].some(state => review.state === state) || review.locked
      },
      {
        value: 'Chat history',
        onClick: this.toggleChatHistory,
        disabled: ["UPLOADED"].some(type => review.type === type)        
      },
      {
        value: 'Viewer',
        onClick: this.toggleViewer,
        disabled: ["UPLOADED"].some(type => review.type === type)
      },
      {
        value: 'Live Viewer',
        onClick: this.toggleWatcher,
        disabled: ["UPLOADED"].some(type => review.type === type)
      }
    ]

    return (
      <div className="col-xl-4">
        <div className="card video-post">
          <div className="card-img-top thumbnail-box" onClick={this.playVideo}>
            <img src={review.thumbnailUrl} alt={review.title + 'thumbnail'} onError={this.onErrorThubnail} />
            <div className="play-overlay">
              <i className="material-icons-round">play_arrow</i>
            </div>
            <div className="video-info-overlay">
              <div className="duration-overlay">
              { review.durationAsString.startsWith('00:') ? 
                review.durationAsString.substring(3) : review.durationAsString }
              </div>
              {review?.ingestionType && <div className="inject-overlay">
                { review?.ingestionType.toUpperCase() }
              </div>}
            </div>
            <div className="video-badge-group">
              <div>
              { review.state ===  'LIVE' ? 
                  <span className="badge no-padding"><LiveBadge /></span> :
                review.type === "UPLOADED" ? 
                  <span className="badge no-padding"><UploadBadge /></span> :
                review.state === "VOD" || review.state === "TERMINATED" || review.state === "DELETED"?
                  <span className="badge no-padding"><VodBadge /></span> : ""
                }
              </div>
              <div className="video-secure-badge">
                { review.locked ? 
                  <span className="badge no-padding"><Locked /></span> : ''}
                { review.visibility === 'PRIVATE' ? 
                  <span className="badge no-padding"><Private /></span> : ''}
              </div>
            </div>
          </div>
          <div className="card-body"  onClick={this.loadDetailedData}>
            <h6 className="card-title">{review.title ? review.title : 'Untitled'}</h6>
            <Dropdown>
              <Dropdown.Button><MoreIcon /></Dropdown.Button>
              <Dropdown.Items options={options} />
            </Dropdown>
            { review.report ? 
              <p className="card-text">{`${review.report.reason} reported by ${review.report.reporter}`}</p>
            : <p className="card-text card-content">{review.content ? review.content : 'No conent'}</p> }
            {/* <a href={`/videos/${review.videoKey}`} className="btn btn-primary" role="button" target="_blank" style={{textTransform: 'none'}}>Watch video and chat</a> */}
            
            <div className="video-count-group">
              <VideoCounts review={review} />
            </div>
          </div>
          <div className="card-footer">
            <div>
              <img className="avatar-thumbnail" src={review.user.profilePhotoUrl || Config.DEFAULT_AVATAR} />
              <span>{review.user.userId === appUser.userId ? "ADMIN" : review.user.username}</span>
            </div>
            <p className="video-date">{review.createdAtAgo}</p>
          </div>
        </div>

        <Confirm message="Do you want to delete this video?" visible={this.state.visibleModal === ModalStateCode.Delete} setVisible={this.toggleDelete} onOk={this.props.onDelete} />
        <Confirm message={`Do you want to ${review.locked ? "unblock" : "block"} this video?`} visible={this.state.visibleModal === ModalStateCode.Block} setVisible={this.toggleBlock} onOk={this.props.onBlock} />
        <Modal title="Chat History" visible={this.state.visibleModal === ModalStateCode.ChatHistory} setVisible={this.toggleChatHistory} onOk={() => ({})} render={<MessageModalContent list={this.props.messages} changeContent={this.changeMessage} />} />
        <Modal title={this.props.watchers.length > 0 ? `Live Viewer (${this.props.watchers.length})` : 'Live Viewer'} visible={this.state.visibleModal === ModalStateCode.Watcher} setVisible={this.toggleWatcher} onOk={() => ({})} render={<WatcherModalContent list={this.props.watchers} changeContent={this.changeWatchers}/>} />
        <Modal title={this.props.viewers.length > 0 ? `Viewer (${this.props.viewers.length})` : 'Viewer'} visible={this.state.visibleModal === ModalStateCode.Viewer} setVisible={this.toggleViewer} onOk={() => ({})} render={<ViewerModalContent list={this.props.viewers} changeContent={this.changeViewers}/>} />
      </div>
    )}
} 

export default connect(
  ({ application, review } : StoreState) => ({
    app: application.application,
    appUser: application.appUser,
    pageInfo: review.modalPage,
    pageRequest: review.modalPageRequest,
    messages: review.messages,
    watchers: review.watchers,
    viewers: review.viewers,
	}),
	(dispatch) => ({
    actions: bindActionCreators(reviewActions, dispatch)
  })
)(VideosReviewItem)