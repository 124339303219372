import moment from 'moment'
import { Client } from '../config'
import { TimeStampInfo } from '../components/pagination/Paginations';
import { Config } from '../config';
import { errorHandler } from '../account/Auth';


export type SystemMessageType = {
  message: string
  messageType: "SYSTEM" | "PENDING"
}

export type Message = {
  channel_key?: string
  user: MessageUser
  message_type: string
  message_id?: string
  message: string
  custom_type?: string
  data?: string
  receiver?: string
  total_user_count?: number
  participant_count?: number
  created_at: number
  elapsedTime?: number
  elapsed?: number
}

export class MessageInfo {
  channelKey?: string
  user: MessageUserInfo
  messageType: string
  messageId?: string
  message: string
  customType?: string
  data?: string
  receiver?: string
  totalUserCount?: number
  participantCount?: number
  createdAt: number
  elapsed: number
  elapsedTime?: number
  fromNow?: string
  createdAtFormat?: string
  createdAtTimeFormat?: string

  constructor(json: Message) {
    this.channelKey = json.channel_key
    this.user = new MessageUserInfo(json.user)
    this.messageType = json.message_type
    this.messageId = json.message_id
    this.message = json.message
    this.customType = json.custom_type
    this.data = json.data
    this.receiver = json.receiver
    this.totalUserCount = json.total_user_count
    this.participantCount = json.participant_count
    this.createdAt = json.created_at;
    this.fromNow = moment(json.created_at).fromNow()
    this.createdAtFormat = moment(json.created_at).format(Config.DATE_FORMAT)
    this.createdAtTimeFormat = moment(json.created_at).format(Config.DURATION_FORMAT)
    this.elapsed = json.elapsed || 0
  }
}

type MessageUser = {
  id: string
  username: string
  avatar?: string
}

export type MessageOption = {
  custom_types? : string[]
  message_types? : string[]
  timestamp? : number | string
  message_id? : number
  prev_size? : number
  next_size? : number
  is_inclusive?: boolean
}

export type MessageParamsType = {[key: string]: number | string | string[] }

export class MessageUserInfo {
  id: string
  username: string
  avatar: string

  constructor(json: MessageUser) {
    this.id = json.id
    this.username = json.username
    this.avatar = json.avatar || ''
  }
}

export const initMessageOption = (param: MessageOption) => {
  if(typeof param.timestamp === "string") {
    param.timestamp = moment(param.timestamp).unix()
  } else if (!param["timestamp"] && !param["message_id"]) {
    param["timestamp"] = new Date().getTime();
  }

  if (!param["prev_size"] && !param["next_size"]) {
    param["prev_size"] = Config.MESSAGE_COUNT;
  }

  if (!param["is_inclusive"]) {
    param["is_inclusive"] = false;
  }
}

export const loadMessages = (videoKey: string, param: MessageOption, callback: (list: MessageInfo[], timestamp: TimeStampInfo) => void): void => {
  initMessageOption(param);

  new Client(`/admin/2/videos/${videoKey}/messages`)
    .get(param as MessageParamsType)
    .then(response => {
      console.log("messages", response)
      
      const messages: MessageInfo[] = []

      for (const m of response.data) {
        messages.push(new MessageInfo(m))
      }

      const timestamp: TimeStampInfo = new TimeStampInfo(messages[0]?.createdAt || 0, messages[messages.length - 1]?.createdAt || 0)

      callback(messages, timestamp)
    }, errorHandler)
}

export const getMessages = (videoKey: string, param: MessageOption, direction: number, callback: (list: MessageInfo[], timestamp: TimeStampInfo) => void): void => {
  if(direction) {
    param['prev_size'] = 0
    param['next_size'] = Config.MESSAGE_COUNT
  }
  else {
    param['prev_size'] = Config.MESSAGE_COUNT
    param['next_size'] = 0
  }

  initMessageOption(param);

  new Client(`/admin/2/videos/${videoKey}/messages`)
    .get(param as MessageParamsType)
    .then(response => {
      console.log("messages", response)
      
      const messages: MessageInfo[] = []

      for (const m of response.data) {
        messages.push(new MessageInfo(m))
      }

      if(messages.length < 1) {
        return;
      }

      const timestamp: TimeStampInfo = new TimeStampInfo(messages[0]?.createdAt || 0, messages[messages.length - 1]?.createdAt || 0)

      callback(messages, timestamp)
    }, errorHandler)
}


export class DirectMessageRoom {
  id: string
  username: string
  last: MessageInfo
  list: MessageInfo[] = []

  constructor(message: MessageInfo) {
    // FIXME: chnage receiver id, reciever username
    this.id = message.receiver || "12345"
    this.username = message.user.username
    this.last = message
    this.list = this.list.concat(message)
  }
 
  append = (message: MessageInfo): void => {
    this.last = message
    this.list = this.list.concat(message)    
  }
}