import * as React from 'react'
import {useEffect, useState} from 'react';
import './Footer.scss'
import { Config, Messages } from '../config'
import { Link } from 'react-router-dom'
import { AddrIcon, EmailIcon, LogoText, PhoneIcon } from '../assets/svg'
import axios from 'axios';


interface InfoProps {
  icon: any
  text: string
}

interface LinkProps extends InfoProps {
  link: string
}

const InfoItem = ({ icon, text }: InfoProps) => (
  <div className="info-item">
    <div className="info-icon">{icon}</div> {text}
  </div>
)

const LinkItem = ({ icon, link, text }: LinkProps) => (
  <a className="info-item" href={link} target="_blank">{icon} {text}</a>
)

interface Props {
  theme?: "white" | "black"
}

export default function Footer({theme}: Props): JSX.Element {
  const [companyInfo, setCompanyInfo] = useState({address: "", email: "", phone: ""});
  
  useEffect(() => {
    axios.get(Messages.COMPANY_INFO_URL).then((result) => {
      console.log("result", result.data)
      if(result.data) {
        setCompanyInfo({address: result.data.address_en, email: result.data.support_email, phone: result.data.company_phone})
      }
    });
  }, [])

  return (
    <div className="footer">
      <div className="content">
        {theme !== "white" && <LogoText />}
        <div className="text-group">
          <div className="policy-group">
            <Link to="/terms_of_service">{Messages.TERMS_OF_SERVICE}</Link>
            <span className="seperator" />
            <Link to="/privacy_policy">{Messages.PRIVACY_POLICY}</Link>
          </div>
          <div className="contact-group">
            <InfoItem icon={<AddrIcon theme={theme}/>} text={companyInfo.address} />
            <LinkItem link={Config.SUPPORT_EMAIL} icon={<EmailIcon theme={theme}/>} text={companyInfo.email} />
            <InfoItem icon={<PhoneIcon theme={theme}/>} text={companyInfo.phone} />
          </div>
          <div className="copyright-group">© {new Date().getFullYear()}. Copyrights Jocoos All right reserved</div>
        </div>      
      </div>
    </div>
  )
}
