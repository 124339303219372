import moment from 'moment';
import { Config, Client } from '../config'
import { PageInfo, FilterRequest } from '../components/pagination/Paginations';
import Axios, { AxiosPromise } from 'axios';
import { dateFormat } from '../config/Format';
import { errorHandler } from '../account/Auth';

type ReviewUser = {
  user_id: string
  username: string
  profile_photo_url: string
}

export class ReviewUserInfo {
  userId: string
  username: string
  profilePhotoUrl: string

  constructor(json?: ReviewUser) {
    this.userId = json?.user_id || ""
    this.username = json?.username  || ""
    this.profilePhotoUrl = json?.profile_photo_url || ""
  }
}

export type Video = {
  id: number
  app_key: string
  video_key: string
  stream_id: number
  stream_key: string
  user_id: string
  path: string
  url: string
  thumbnail_url: string
  heart_count: number
  output_type: string
  title: string
  content: string
  type: string
  state: string
  visibility: string
  duration: number
  created_at: number
  locked: boolean
  watch_count: number
  view_count: number
  like_count: number
  data2: string
  data: string
  user: ReviewUser
  ingestion_type: string
}

export class ReviewInfo {
  id: number
  appKey: string
  videoKey: string
  userId: string
  path: string
  url: string
  thumbnailUrl: string
  outputType: string
  title: string
  content: string
  type: string
  state: string
  heartCount: number
  visibility: string
  duration: number
  durationAsString: string
  createdAt: string
  createdAtAgo: string
  report: ReportInfo | null
  locked: boolean
  watchCount: number
  viewCount: number
  likeCount: number
  heart_count: number
  user: ReviewUserInfo
  ingestionType: string

  constructor(json: Video) {
    this.id = json.id
    this.appKey = json.app_key
    this.videoKey = json.video_key
    this.userId = json.user_id
    this.path = json.path
    this.url = json.url
    this.thumbnailUrl = json.thumbnail_url
    this.outputType = json.output_type
    this.title = json.title
    this.content = json.content
    this.type = json.type
    this.state = json.state
    this.visibility = json.visibility
    this.duration = json.duration
    this.locked = json.locked ? true : false
    this.durationAsString = moment.utc(moment.duration(this.duration).asMilliseconds()).format(Config.DURATION_FORMAT)
    this.watchCount = json.watch_count
    this.viewCount = json.view_count
    this.likeCount = json.like_count
    this.heartCount = json.heart_count
    this.createdAt = dateFormat(json.created_at);
    this.createdAtAgo = moment(json.created_at).fromNow();
    this.user = new ReviewUserInfo(json.user)
    this.ingestionType = json.ingestion_type
  }

  setReport = (report: ReportInfo): void => {
    this.report = report
  }
}

type Report = {
  id: number
  reporter: number
  videoKey: string
  reason: string
  created_at: number
  deleted_at: number
}

export class ReportInfo {
  id: number
  reporter: number
  videoKey: string
  reason: string
  ceratedAt: string
  deletedAt: string

  constructor(json: Report) {
    this.id = json.id
    this.reporter = json.reporter
    this.videoKey = json.videoKey
    this.reason = json.reason
    this.ceratedAt = dateFormat(json.created_at);
    this.deletedAt = json.deleted_at ? dateFormat(json.deleted_at) : '';
  }
}

const paramSize = 'size'
const paramType = 'type'

export const getAllVideos = (appKey: string, page: FilterRequest, callback:(list: ReviewInfo[], pageInfo: PageInfo, totalElements: number[]) => void): void => {
  const params: {[index: string]: any} = { 'app_key': appKey, 'page': 0, 'size': Config.VIDEO_COUNT,  'direction': page.direction || 0, 'sort': page.sort || 'id', 'search': page.search || ""} 

  const requests = [];
  const api = `/api/2/applications/${appKey}/videos`

  for (const stage of [0, 1, 2]) {
    switch(stage) {
      case 1:
        params[paramType] = 'BROADCASTED'
        params[paramSize] = 1
        break
      case 2:
        params[paramType] = 'UPLOADED'
        params[paramSize] = 1
        break
    }

    requests.push(new Client(api).get(params))
  }

  const totalElements: number[] = [];
  Axios.all(requests)
    .then(Axios.spread((...args) => {
      console.log("response", args)
      for (const response of args) {
        totalElements.push(response.data.total_elements)
      }

      const videoResponse = args[0];
      const videos : ReviewInfo[] = [];
      const pageInfo : PageInfo = new PageInfo(videoResponse.data);

      for (const v of videoResponse.data.content) {
        videos.push(new ReviewInfo(v));
      }

      console.log(totalElements)
      callback(videos, pageInfo, totalElements);
  }), errorHandler)
}

export const getReviews = (appKey: string, page: FilterRequest, callback:(list: ReviewInfo[], pageInfo: PageInfo) => void): void => {
  const param: {} = { 'page': page.page, 'size': page.size, 'search': page.search || ""} 

  new Client(`/api/2/applications/${appKey}/videos`)
    .get(param)
    .then(response => {
      console.log(response)
      
      const videos: ReviewInfo[] = []
      const pageInfo : PageInfo = new PageInfo(response.data);

      for (const p of response.data.content) {
        videos.push(new ReviewInfo(p))
      }

      callback(videos, pageInfo)
    }, errorHandler)
}

export const getReviewsByType = (appKey: string, type: keyof typeof VideoType, page: FilterRequest, callback:(list: ReviewInfo[], pageInfo: PageInfo) => void): void => {
  const param: {} = { 'page': page.page, 'size': page.size, 'type': type, 'direction': page.direction || 0, 'sort': page.sort || 'id', 'search': page.search || ''} 

  new Client(`/api/2/applications/${appKey}/videos`)
    .get(param)
    .then(response => {
      console.log(response)
      
      const videos: ReviewInfo[] = []
      const pageInfo : PageInfo = new PageInfo(response.data);

      for (const p of response.data.content) {
        videos.push(new ReviewInfo(p))
      }

      callback(videos, pageInfo)
    }, errorHandler)
}

export const getDeletedVideos = (appKey: string, page: FilterRequest, callback:(list: ReviewInfo[], pageInfo: PageInfo) => void): void => {
  const param: {} = { 'page': page.page, 'size': page.size, 'direction': page.direction || 0, 'sort': page.sort || 'id' } 

  new Client(`/api/2/applications/${appKey}/deleted_videos`)
    .get(param)
    .then(response => {
      console.log(response)
      
      const videos: ReviewInfo[] = []
      const pageInfo : PageInfo = new PageInfo(response.data);

      for (const p of response.data.content) {
        videos.push(new ReviewInfo(p))
      }

      callback(videos, pageInfo)
    }, errorHandler)
}

export const getReportedVideos = (appKey: string, page: FilterRequest, callback:(list: ReviewInfo[], pageInfo: PageInfo) => void): void => {
  const param: {} = { 'app_key': appKey, 'page': page.page, 'size': page.size, 'direction': page.direction || 0, 'sort': page.sort || 'id' }

  new Client(`/api/2/videos/reports`)
    .get(param)
    .then(response => {
      console.log(response)
      
      const videos: ReviewInfo[] = []
      const pageInfo : PageInfo = new PageInfo(response.data);

      for (const v of response.data.content) {
        const r = new ReviewInfo(v.video);
        r.setReport(new ReportInfo(v))
        videos.push(r)
      }

      callback(videos, pageInfo)
    }, errorHandler)
}

export const getBlockedVideos = (appKey: string, page: FilterRequest, callback:(list: ReviewInfo[], pageInfo: PageInfo) => void): void => {
  const param: {} = { 'page': page.page, 'size': page.size, 'direction': page.direction || 0, 'sort': page.sort || 'id' }

  new Client(`/api/2/applications/${appKey}/locked_videos`)
    .get(param)
    .then(response => {
      console.log(response)
      
      const videos: ReviewInfo[] = []
      const pageInfo : PageInfo = new PageInfo(response.data);

      for (const p of response.data.content) {
        videos.push(new ReviewInfo(p))
      }

      callback(videos, pageInfo)
    }, errorHandler)
}

export const deleteVideo = (appKey: string, videoKey: string): AxiosPromise => {
  return new Client(`/api/2/applications/${appKey}/videos`)
    .delete(videoKey)
}

export const blockVideo = (appKey: string, review: ReviewInfo): AxiosPromise => {
  const request = {title: review.title, content: review.content, visibility: review.visibility, locked: !review.locked }

  return new Client(`/api/2/applications/${appKey}/videos`)
    .patch(review.videoKey, request)
}


export interface MenuProps {
  onDelete(): void
  onBlock: () => void
}

export enum VideoType {
  BROADCASTED = 'BROADCASTED',
  UPLOADED = 'UPLOADED'
}