import React from 'react';
import videojs from 'video.js';
import { bindActionCreators } from 'redux';
import { FlipFlop } from 'flipflop-sdk-javascript/dist/flipflop'
import { connect } from 'react-redux';
import { actions as monitorActions } from '../modules/monitor'
import { actions as applicationActions } from '../modules/application'
import { actions as videoActions } from '../modules/video'
import '../monitor/MonitorContainer.scss';
import { getLiveStat, getWatchersHistory, LiveStatInfo, VideoInfo, WatcherHistoryInfo } from './Video';
import { Message, MessageInfo } from '../review/Messages';
import { WatcherInfo } from '../review/Watchers';
import { AppUserInfo, getAppUser } from '../settings/Settings';
import { StoreState } from '../modules';
import { ApplicationInfo } from '../application/Applications';
import { Config } from '../config';
import { isWebRTCSupported, openNewPage } from '../config/browser';
import MonitorChatBox from './MonitorChatBox';
import MonitorChartBox from './MonitorChartBox';
import MonitorLiveStat from './MonitorLiveStat';
import { GoodsInfo } from './Goods';
import { FormBullet, Play, Stop, LiveView, Settings, VideoLike, VideoView, LiveBadge } from '../assets/svg';
import Button from '../components/button/Button';
import Modal from '../components/modal/Modal';
import Toast from '../components/Toast';
import moment from 'moment';
import { throttle } from 'lodash';
import { secondsToTime } from '../config/Format';
import StreamSubBox from './StreamSubBox';
import LiveSettingsModalContent from './LiveSettingsModalContent';

enum ModalStateCode {
  Settings = 1,
}

interface StreamVideoInfo { 
  title: string 
  content: string 
  user_id: string | number 
  user_name: string
  user_avatar_url: string
 }

interface Props {
  history: any
  app: ApplicationInfo
  appUser: AppUserInfo
  goodsList: GoodsInfo[]
  title: string
  description: string
  keepAlive: boolean
  player: videojs.Player
  monitors: typeof monitorActions
  actions: typeof applicationActions
  videos: typeof videoActions
}

interface State {
  video: VideoInfo | StreamVideoInfo | null
  selectedUser: WatcherInfo | null
  goods: GoodsInfo[]
  visibleModal: ModalStateCode
  liveStat: LiveStatInfo
  chartData: WatcherHistoryInfo
  messages: MessageInfo[]
  streamState: string
  channel: string
  username: string
  duration: string
  videoLike: number
  videoView: number
}

class DemoStreamContainer extends React.Component<Props, State> {

  state: State = {
    video: null,
    visibleModal: 0,
    streamState: 'initialized',
    selectedUser: null,
    goods: [],
    liveStat: new LiveStatInfo({max_watcher_count: 0, total_message_count: 0, total_watcher_count: 0, avg_live_watch_time: 0}),
    chartData: new WatcherHistoryInfo({count_list: [], time_list: []}),
    messages: [],
    channel: '',
    username: '',
    duration: '00:00',
    videoLike: 0,
    videoView: 0,
  }

  videoNode: any
  gossip: any
  player: any
  sdk: any
  streamer: any

  toggleModalGenerator = (modalCode: ModalStateCode) => () => {
    if(this.state.visibleModal === modalCode) {
      this.setState({visibleModal: 0})
    } else {
      this.setState({visibleModal: modalCode})
    }
  }

  toggleSettings = this.toggleModalGenerator(ModalStateCode.Settings)


  loadApplication = (): void => {
    const loaded = localStorage.getItem('app')

    if (loaded) {
      const app: ApplicationInfo = JSON.parse(loaded)
      this.props.actions.setApplication(app)
      getAppUser(app.appKey, (appUser: AppUserInfo) => {
        this.props.actions.setAppUser(appUser)
        this.initStreaming();
      })
    } else {
      window.location.href = '/applications'
    }
  }

  loadStatData = (appKey: string, videoKey: string) => {
    getLiveStat(appKey, videoKey, this.setLiveStat)
    getWatchersHistory(videoKey, this.setChartData);
  }

  initStreaming = async (): Promise<void> => {
    const { app, appUser } = this.props;

    const appKey = app.appKey
    const appSecret = app.appSecret

    if (!appKey || !appSecret) {
      Toast.warn('appKey and appSecret parameters are required');
      return;
    }

    if (!appUser) {
      Toast.warn('Failed to load admin user.');
      return
    }

    const userId = appUser.userId
    const username = appUser.username
    const profilePhoto = appUser.profilePhotoUrl
    
    console.log(`appKey: ${appKey}, appSecret: ${appSecret}`);
    console.log(`userId: ${username}, username: ${username}, profilePhoto - ${profilePhoto}`);

    FlipFlop.initialize(appKey, appSecret);
    this.sdk = await FlipFlop.authentication(userId, username, profilePhoto);
        
    this.streamer = this.sdk.getStreamer()
    this.streamer.delegate = this

    this.setVideo({ 
      title: this.props.title, 
      content: this.props.description, 
      user_id: userId, 
      user_name: username,
      user_avatar_url: profilePhoto
     })
  }

  setVideo = (video: StreamVideoInfo) => {
    this.setState({ video })

    if (this.streamer) {
      this.streamer.prepare(this.videoNode)
    }
  }

  setLiveStat = (liveStat: LiveStatInfo) => {
    this.setState({ liveStat })
  }

  setChartData = (chartData: WatcherHistoryInfo) => {
    this.setState({ chartData })
  }

  setMessages = (messages: MessageInfo[]): void => {
    this.setState({ messages })
  }

  setStreamState = (streamState: string) => {
    console.log(`--- janus state: ${streamState}`)
    this.setState({ streamState })
  }

  stop = () => {
    if (!window.confirm('Do you want to stop LIVE?')) {
      return
    }
    
    try {
      if (this.streamer) {
        this.streamer.stop(this.props.keepAlive)
        this.streamer.reset()
        this.streamer = null
      }
    } catch(e) {
      console.log(e);      
    }
  }

  start = async () => {
    const { goodsList } = this.props

    if (this.streamer) {
      const video = await this.streamer.start(
        this.props.title,
        this.props.description,
        JSON.stringify({goods_list: goodsList})
      )

      this.setState({video: new VideoInfo(video)}, () => {
        const { video } = this.state;
        if(video instanceof VideoInfo) {
          this.loadStatData(video?.appKey, video?.videoKey);
        }
      })
    }
  }

  prepare = () => {
    window.location.reload();
  }
  
  onPrepare = () => {
    console.log('onPrepare')
    this.setStreamState('Ready')
  }

  onStart = (streamName: string) => {
    console.log(`onStart: ${streamName}`)
    this.setStreamState('Started')
  }

  onStop = () => {
    console.log(`onStopped`)
    this.setStreamState('Stopped')
  }

  onCompleted = () => {
    console.log(`onCompleted`)
  }

  onError = (error: string) => {
    if (error){
      console.error(error)
    }
  }

  onChatReceived = (message: Message): void => {
    console.log("onChatReceived", message)
    message.elapsedTime = Date.now() - message.created_at

    if (message.message_type ==="MESSAGE" || (message.message_type ==="ADMIN" && message.custom_type ==="")) {
      const { liveStat } = this.state;
      liveStat.totalMessageCount++;
      this.setState({liveStat})
    }
    
    const { messages } = this.state;
    console.log("message.message_type", message.message_type)
    if(message.message_type === "JOIN" || message.message_type === "LEAVE") {
      this.state.video instanceof VideoInfo && this.loadStatData(this.state.video.appKey, this.state.video.videoKey)
      this.setState({ messages: messages.concat(new MessageInfo(message)), videoView: message?.total_user_count || 0});
    } else {
      this.setState({ messages: messages.concat(new MessageInfo(message))});
    }
    if(message.custom_type === "UPDATE") {
      const data = JSON.parse(message?.data || "");
      if(data?.like_count) {
        this.setState({videoLike: data.like_count});
      }
    }
    if(message.custom_type === "CLOSE") {
      this.endLive();
    }
  }

  sendMessage = (message: string): void => {
    if (this.streamer) {
      this.streamer.sendMessage(message)
    }
  }

  onTimeUpdate = throttle((): void => {
    if(this.state.video instanceof VideoInfo) {
      const diffTime = secondsToTime((moment().unix() - moment(this.state.video.createdAt).unix()))
      console.log(diffTime);
      this.setState({ duration: diffTime });
    }
  }, 1000)

  componentDidMount () { 
    if(!isWebRTCSupported()) {
      alert("browser doesn't support webrtc")
     };

     window.addEventListener("beforeunload", () => {
      if (this.streamer) {
        this.streamer.stop(this.props.keepAlive)
        this.streamer.reset()
      }
    });

    this.loadApplication()
  }

  endLive = () => {
    if (this.streamer) {
      this.streamer.stop(this.props.keepAlive)
      this.streamer.reset()
    }
  }
  
  componentWillUnmount() {
    this.endLive()
  }

  render(): JSX.Element | null {
    const { messages, video, chartData, liveStat, streamState } = this.state
    const { appUser } = this.props

    if(!video) {
      return null;
    }

    return (
      <div className="monitor-container streaming-monitor">
        <div className="monitor-content">
          <div className="monitor-header">
            <div>
              <div className="monitor-title-group">
                <h3 className="monitor-title">{this.props.title || "Untitled"}</h3>
                {streamState === 'Ready' && <button onClick={this.toggleSettings} className="monitor-settings-btn"><Settings className="black-svg-icon"/></button>}
                <p className="monitor-status"><FormBullet />{`Stream state : ${streamState}`}</p>
              </div>
              <div className="monitor-desc">{this.props.description}</div>
            </div>
            {streamState === 'Ready' ? 
                <Button size="large" color="upload" onClick={this.start}><Play /><span>Start Live</span></Button> : 
                  streamState === 'Started' ?
                  <div className="stream-btn-group">
                    {video instanceof VideoInfo && <Button size="large" color="white-blue" onClick={() => {openNewPage(`/monitor/${video.videoKey}`)}}><LiveView /><span>Live Viewer</span></Button>}
                    <div className="tool-divider"/>
                    <Button size="large" color="upload" onClick={this.stop}><Stop /><span>Stop Live</span></Button>
                  </div> :
                  streamState === 'Stopped' && ''}
          </div>
            
          <div className="monitor-item-list">
            <div className="monitor-item">
                <div className="video-player-container">
                  <div className="video-player-group">
                    {video instanceof VideoInfo && streamState === 'Started' && <span className="badge no-padding"><LiveBadge /></span>}
                    <video id="screenvideo" ref={node => this.videoNode = node } autoPlay={true} playsInline onTimeUpdate={this.onTimeUpdate}/>
                    
                    {video instanceof VideoInfo &&
                    <div className="video-info-overlay">
                      <div className="duration-overlay">
                        {this.state.duration}
                        </div>
                        <div className="inject-overlay">
                          WEBRTC
                        </div>
                      </div>
                      }  
                  </div>
                  <div className="video-player-footer">
                    <div>
                      <img className="avatar-thumbnail" src={appUser.profilePhotoUrl || Config.DEFAULT_AVATAR} />
                      <span>{appUser.username}</span>
                    </div>
                    <div className="icon-group">
                      <div className="icon-item"><VideoView /><span>&nbsp;{this.state.videoView || 0}&nbsp;</span>•&nbsp;</div>
                      <div className="icon-item"><VideoLike /><span>&nbsp;{this.state.videoLike}&nbsp;</span>•&nbsp;</div>
                      <div className="icon-item"><span>Guest&nbsp;:&nbsp;{this.state.liveStat.guestWatcherCount || 0}&nbsp;</span></div>
                    </div>
                  </div>
                </div>
              <StreamSubBox goodsList={this.props.goodsList} isCheckList={streamState === 'Ready'} />
            </div>
            <div className="monitor-item">
              {video && <MonitorChatBox sendMessage={this.sendMessage} messages={messages} stage={0}/>}
            </div>
            <div className="monitor-item">
              <MonitorChartBox chartData={chartData} messages={messages} />
              <MonitorLiveStat liveStat={liveStat}/>
            </div>
          </div>
        </div>
        <Modal title="Live Settings" visible={this.state.visibleModal === ModalStateCode.Settings} setVisible={this.toggleSettings} okButtonTitle="Upload" render={<LiveSettingsModalContent toggleModal={this.toggleSettings} />} />
      </div>
    )
  }
}

export default connect(
	({ application, video, streaming } : StoreState) => ({
    app: application.application,
    appUser: application.appUser,
    player: video.player,
    goodsList: streaming.goodsList,
    title: streaming.title,
    description: streaming.desc,
    keepAlive: streaming.keepAlive
  }),
	(dispatch) => ({
    actions: bindActionCreators(applicationActions, dispatch),
    monitors: bindActionCreators(monitorActions, dispatch),
    videos: bindActionCreators(videoActions, dispatch),
  })
)(DemoStreamContainer)
