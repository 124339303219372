import * as React from 'react'
import { ApplicationInfo } from '../application/Applications'
import { connect } from 'react-redux'
import { StoreState } from '../modules'
import BreadCrumb from '../components/BreadCrumb'
import OverviewApplicationInfo from './OverviewApplicationInfo'
import OverviewQuota from './OverviewQuota'
import OverviewLiveStat from './OverviewLiveStat'
import OverviewVodStat from './OverviewVodStat'
import './DashboardContent.scss'
import PlanModal from './PlanModal'
import Modal from '../components/modal/Modal'
import { getOverview, OverviewInfo } from './Overview'
import { padNumber } from '../config/Format'

interface Props {
  app: ApplicationInfo
}

interface State {
  visibleModal: boolean
  overview: OverviewInfo | null
}

class OverviewContent extends React.Component<Props, State> {
  state: State = {
    visibleModal: false,
    overview: null,
  }

  toggleVisibleModal = () => {
    this.setState((prevState) => ({ visibleModal: !prevState.visibleModal }))
  }

  setOverview = (overview: OverviewInfo) => {
    this.setState({ overview })
  }

  componentDidMount() {
    getOverview(this.props.app.appKey, this.setOverview)
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    if (nextProps.app.appKey !== this.props.app.appKey) {
      getOverview(nextProps.app.appKey, this.setOverview)
      return true
    }
    return true
  }

  public render() {
    const { app } = this.props
    const { overview } = this.state

    const planInfoList = [
      {
        title: 'Current plan',
        value: app.plan.name === 'Free' ? 'Free Trial' : app.plan.name,
        moreButton: <a onClick={this.toggleVisibleModal}>More Plan</a>,
      },
      {
        title: '동시 라이브 가능 수',
        value: app.plan.channelCount < 0 ? 'Unlimited' : padNumber(app.plan.channelCount),
      },
      {
        title: '라이브 최대 참여자 수',
        value: app.plan.chatParticipants < 0 ? 'Unlimited' : padNumber(app.plan.chatParticipants),
      },
    ]

    const summaryInfoList = [
      {
        title: 'Active users',
        subTitle: '(per month)',
        value: padNumber(overview?.monthlyUserCount),
      },
      {
        title: 'Live',
        subTitle: '(per month)',
        value: padNumber(overview?.monthlyLiveCount),
      },
      {
        title: 'Vod',
        subTitle: '(per month)',
        value: padNumber(overview?.monthlyVodCount),
      },
    ]

    return (
      <div className="dashboard-content">
        <BreadCrumb names={['Dashboard', 'Overview']} />
        <h3 className="content-title">Overview</h3>
        <div className="content-body">
          <div className="application-info-group">
            <OverviewApplicationInfo title="Plan" appInfoList={planInfoList} />
            <OverviewApplicationInfo title="Summary" appInfoList={summaryInfoList} />
          </div>
          {overview && (
            <>
              {app.plan.name !== 'Pro' && <OverviewQuota overview={overview} />}
              <OverviewLiveStat app={app} />
              <OverviewVodStat app={app} />
            </>
          )}
        </div>
        <Modal visible={this.state.visibleModal} setVisible={this.toggleVisibleModal} title="More Plan" onOk={() => ({})} render={<PlanModal plan={app.plan} />} />
      </div>
    )
  }
}

export default connect(
  ({ application }: StoreState) => ({
    app: application.application,
  }),
  () => ({}),
)(OverviewContent)
