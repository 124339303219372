import * as React from 'react'
import { ApplicationInfo } from '../application/Applications'
import OverviewItem from './OverviewItem'
import '../review/OverviewItem.scss'

interface Props {
  title?: string
  app: ApplicationInfo
}

class Item {
  key: string
  value: any

  constructor(key: string, value: any) {
    this.key = key
    this.value = value
  }
}

class OverviewApplicationItem extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { app } = this.props
    const items: Item[] = [
      new Item('Name', app.name),
      new Item('Created', app.createdAt),
      new Item('App Key', app.appKey),
      new Item('App Secret', app.appSecret),
      new Item('Total Live', app.totalLive)
    ]

    const title = this.props.title || 'Applications'
    return (
      <div className="overview col-xl-12">
        <div className="overview-header">
          <h5 className="card-title">{title}</h5>
        </div>
        <OverviewItem itemList={items}/>
      </div>
    )
  }
}

export default OverviewApplicationItem
