import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from '../components/pagination/Pagination';
import { PageState, PageInfo, FilterRequest } from '../components/pagination/Paginations';
import { StoreState } from '../modules';
import { actions as userActions } from '../modules/user'
import { ApplicationInfo } from '../application/Applications';
import { blockUser, getUsers, unBlockUser, UserInfo } from './Users';
import UserTable from './UserTable';
import { NavItem } from '../components/Navigation';
import BreadCrumb from '../components/BreadCrumb';
import SearchInput from '../components/input/SearchInput';
import Dropdown from '../components/dropdown/Dropdown';
import './User.scss'
import Toast from '../components/Toast';

interface Props extends PageState {
  app: ApplicationInfo
  list: UserInfo[]
  actions: typeof userActions
  pageRequest: FilterRequest
}

type SortType = "Sort By Id" | "Sort By CreatedAt"

interface State {
  stage: number
  search: string
  sort: SortType
}

class UserContent extends React.Component<Props, State> {
  state: State = {
    stage: 0,
    search: "",
    sort: "Sort By CreatedAt"
  }

  componentDidMount = () => {
    getUsers(this.props.app.appKey, this.props.pageRequest, this.setList)
  }

  initPage() {
    const { pageRequest } = this.props
    pageRequest.page = 0
    pageRequest.direction = 0
    pageRequest.search = ""
    pageRequest.sort = "id"
    
    this.props.actions.setPageRequest(pageRequest)
    this.setState({ stage: 0, search: '', sort: 'Sort By CreatedAt' })
  }

  handlePage = (page: number) => {
    const pageRequest = this.props.pageRequest
    pageRequest.page = page
    this.props.actions.setPageRequest(pageRequest)

    this.handleReload(this.state.stage, pageRequest);
  }

  handleReload = (stage: number, pageRequest: FilterRequest) => {
    getUsers(this.props.app.appKey, pageRequest, this.setList)
  }
  
  onChangeSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    if (value === ' ') {
      return
    }

    this.setState({ search: value })
  }

  search = () => {
    const pageRequest = this.props.pageRequest
    pageRequest.search = this.state.search
    pageRequest.page = 0
    getUsers(this.props.app.appKey, pageRequest, this.setList)
  }

  onBlock = (user: UserInfo) => {
    blockUser(this.props.app.appKey, user.userId)
    .then(() => {
      this.handleReload(this.state.stage, this.props.pageRequest)
      Toast.notify("Successfully blocked")
    })
  }

  onUnBlock = (user: UserInfo) => {
    unBlockUser(this.props.app.appKey, user.userId)
    .then(() => {
      this.handleReload(this.state.stage, this.props.pageRequest)
      Toast.notify("Successfully unblocked")
    })
  }

  setList = (list: UserInfo[], page: PageInfo) => {
    this.props.actions.setList({ list, page });
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    if (nextProps.app.appKey !== this.props.app.appKey) {
      getUsers(nextProps.app.appKey, this.props.pageRequest, this.setList)
      return true
    }
    return true
  }

  public render() {
    const options = [
      {
        value: 'Sort By Id',
        onClick: () => { 
          this.setState({ sort: "Sort By Id" })
          this.props.pageRequest.sort = 'userId'
          getUsers(this.props.app.appKey, this.props.pageRequest, this.setList)
         },
      },
      {
        value: 'Sort By CreatedAt',
        onClick: () => { 
          this.setState({ sort: "Sort By CreatedAt" })
          this.props.pageRequest.sort = 'createdAt'
          getUsers(this.props.app.appKey, this.props.pageRequest, this.setList)
         },
      }
    ]

    return (
      <div className="dashboard-content col-xl-10">
        <BreadCrumb names={['Dashboard', 'Users']} />
        <h5 className="content-title">Users</h5>
        <div className="content-toolbar">
          <Dropdown>
            <Dropdown.Button toggleIcon><span>{this.state.sort}</span></Dropdown.Button>
            <Dropdown.Items options={options}/>
          </Dropdown>
          <SearchInput label="Search Username" value={this.state.search} onChange={this.onChangeSearch} onSearch={this.search} maxLength={50} />
        </div>
        <UserTable onBlock={this.onBlock} onUnBlock={this.onUnBlock} />
        <div className="mt-50">
          <Pagination page={this.props.pageInfo} handlePage={this.handlePage} />
        </div>
      </div>
    )
  }
}

export default connect(
	({ application, user } : StoreState) => ({
    app: application.application,
    list: user.list,
    pageInfo: user.page,
    pageRequest: user.pageRequest
	}),
	(dispatch) => ({
    actions: bindActionCreators(userActions, dispatch)
  })
)(UserContent)