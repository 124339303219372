import * as React from 'react'
import Button from '../components/button/Button';
import TextInput from '../components/input/TextInput';
import { TextLength } from '../components/TextLength';
import { MAX_LENGTH } from '../config/Config';
import "./RenameModalContent.scss"

interface Props {
  onRename(name: string): void
  onClose(): void
}

interface State {
  name: string
  nameError: string
}

class RenameModalContent extends React.Component<Props> {
  state: State = {
    name: '',
    nameError: '',
  }

  onChangeName = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget

    if (value === '') {
      this.setState({ name: value, nameError: 'Please enter your Application name' })
    } else {
      this.setState({ name: value, nameError: '' })
    }
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { name } = this.state

    if (name === '') {
      this.setState({ nameError: 'Please enter your Application name' })
      return
    }
    this.setState({ nameError: '' })

    this.props.onRename(name)
  }

  public render = (): JSX.Element => {

    return (
      <form className="change-name-modal-container" onSubmit={this.handleSubmit}>    
        <TextInput type="text" label="App name" value={this.state.name} onChange={this.onChangeName} maxLength={MAX_LENGTH.appName} required error={this.state.nameError} description={<small>{`* The application name can be up to ${MAX_LENGTH.appName} characters long.`}<TextLength text={this.state.name} maxLength={MAX_LENGTH.appName} /></small>} />
        <div className="change-name-button-group">
          <Button type="button" size="large" color="white" onClick={this.props.onClose} >
            Cancel
          </Button>
          <Button type="submit" size="large" color="red">
            Rename
          </Button>
        </div>
      </form>
    );
  }
}

export default RenameModalContent