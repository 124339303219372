/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { ReactNode } from 'react';
import './Button.scss';

type ButtonSizeType = 'ssmall' | 'login' | 'create' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
type ButtonColorType = 'yellow' | 'orange' | 'red' | 'red-outline' | 'blue' | 'white-blue' | 'white' | 'black' | 'google' | 'upload' | 'transparent';

interface Props {
  children: string | ReactNode;
  size?: ButtonSizeType
  color?: ButtonColorType
  [key: string]: any;
}

export default function Button({
  children,
  className,
  size = 'medium',
  color = 'white',
  ...props
}: Props) {
  return (
    <button
      className={[className, `common-button`, `${size}-button`, `${color}-button`].join(
        ' ',
      )}
      {...props}
    >
      {children}
    </button>
  );
}
