import { createAction, handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import { PageInfo, PageRequest, TimeStampInfo } from '../components/pagination/Paginations';
import { ReviewInfo } from '../review/Reviews'
import { ApplicationInfo } from '../application/Applications';
import { Config } from '../config'
import { MessageInfo } from '../review/Messages';
import { WatcherInfo, ViewerInfo } from '../review/Watchers';


type AppPayload = ApplicationInfo
type ReviewPayload = ReviewInfo

interface ListPayload {
  list: ReviewInfo[]
  page: PageInfo
}

type PathPayload = string[]
type ModalPayload = number

interface MessagePayload {
  list: MessageInfo[]
  timestamp: TimeStampInfo
}

interface WatcherPayload {
  list: WatcherInfo[]
  page: PageInfo
}

interface ViewerPayload {
  list: ViewerInfo[]
  page: PageInfo
}


const SET_APP = 'review/SET_APP'
const SET_LIST = 'review/SET_LIST'
const SET_REVIEW = 'review/SET_REVIEW'
const SET_PATH = 'review/SET_PATH'
const SET_PAGE_REQUEST = 'review/SET_PAGE_REQUEST'
const SET_MESSAGES = 'review/SET_MESSAGES'
const SET_WATCHERS = 'review/SET_WATCHERS'
const SET_VIEWERS = 'review/SET_VIEWERS'
const SET_MODAL_PAGE_REQUEST = 'review/SET_MODAL_PAGE_REQUEST'

const CHECKED = 'review/CHECKED'
const UNCHECKED = 'review/UNCHECKED'
const CHECKED_ALL = 'review/CHECKED_ALL'
const UNCHECKED_ALL = 'review/UNCHECKED_ALL'
const CLEAR_CHECKLIST = 'review/CLEAR_CHECKLIST'

export const actions = {
  setAppKey: createAction<AppPayload>(SET_APP),
	setList: createAction<ListPayload>(SET_LIST),
  setPageRequest: createAction<PageRequest>(SET_PAGE_REQUEST),
  setPath: createAction<PathPayload>(SET_PATH),
  setReview: createAction<ReviewPayload>(SET_REVIEW),
  setMessages: createAction<MessagePayload>(SET_MESSAGES),
  setWatchers: createAction<WatcherPayload>(SET_WATCHERS),
  setViewers: createAction<ViewerPayload>(SET_VIEWERS),
  setModalPageRequest: createAction<PageRequest>(SET_MODAL_PAGE_REQUEST),

  checked: createAction<ReviewPayload>(CHECKED),
  unchecked: createAction<ReviewPayload>(UNCHECKED),
  checkedAll: createAction(CHECKED_ALL),
  uncheckedAll: createAction(UNCHECKED_ALL),
  clearCheckList: createAction(CLEAR_CHECKLIST),
}


export interface ReviewState {
  app: ApplicationInfo | null,
  list: ReviewInfo[],
  checkList: ReviewInfo[],
  path: string[],
	page: PageInfo
  pageRequest: PageRequest,
  review: ReviewInfo | null,
  modalContent: number,
  checkedAll: boolean,
  messages: MessageInfo[],
  watchers: WatcherInfo[],
  viewers: ViewerInfo[],
	modalPage: PageInfo
  modalPageRequest: PageRequest,
}

const initialState: ReviewState = {
  app: null,
  list: [],
  checkList: [],
  path: ['/'],
  page: new PageInfo(null),
  pageRequest: new PageRequest(0, Config.VIDEO_COUNT),
  review: null,
  modalContent: 0,
  checkedAll: false,
  messages: [],
  watchers: [],
  viewers: [],
  modalPage: new PageInfo(null),
  modalPageRequest: new PageRequest(0, Config.VIDEO_COUNT),
}

export default handleActions<ReviewState, any>({
  [SET_APP]: (state, action: Action<AppPayload>) => produce(state, draft => { draft.app = action.payload, draft.review = null }),
  [SET_LIST]: (state, action: Action<ListPayload>) => produce(state, draft => { draft.list = action.payload.list, draft.page = action.payload.page, draft.review = null }),
  [SET_PAGE_REQUEST]: (state, action: Action<PageRequest>) => produce(state, draft => { draft.pageRequest = action.payload, draft.review = null }),
  [SET_PATH]: (state, action: Action<PathPayload>) => produce(state, draft => { draft.path = action.payload, draft.review = null }),
  [SET_REVIEW]: (state, action: Action<ReviewPayload>) => produce(state, draft => { draft.review = action.payload }),
  [CHECKED]: (state, action: Action<ReviewPayload>) => produce(state, draft => { draft.checkList = [...state.checkList].concat(action.payload) }),
  [UNCHECKED]: (state, action: Action<ReviewPayload>) => produce(state, draft => { draft.checkList = [...state.checkList].filter(f => f.videoKey !== action.payload.videoKey) }),
  [CHECKED_ALL]: (state) => produce(state, draft => { draft.checkedAll = true }),
  [UNCHECKED_ALL]: (state) => produce(state, draft => { draft.checkList = [], draft.checkedAll = false }),
  [CLEAR_CHECKLIST]: (state, action: Action<ModalPayload>) => produce(state, draft => { draft.checkList = [], draft.modalContent = action.payload }),
  [SET_MESSAGES]: (state, action: Action<MessagePayload>) => produce(state, draft => { draft.checkList = [], draft.messages = action.payload.list }),
  [SET_WATCHERS]: (state, action: Action<WatcherPayload>) => produce(state, draft => { draft.checkList = [], draft.watchers = action.payload.list, draft.modalPage = action.payload.page }),
  [SET_VIEWERS]: (state, action: Action<ViewerPayload>) => produce(state, draft => { draft.checkList = [], draft.viewers = action.payload.list, draft.modalPage = action.payload.page }),
  [SET_MODAL_PAGE_REQUEST]: (state, action: Action<PageRequest>) => produce(state, draft => { draft.review = null, draft.modalPageRequest = action.payload }),
	}, initialState
)