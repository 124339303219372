import { Client } from '../config'
import { AxiosPromise } from 'axios';


export const getStorageInfo = (): AxiosPromise => {
  return new Client('/v1/storage-info')
    .get()
}

export const getUserAccessToken = (userId: string, token: string): AxiosPromise => {
  const request = {
    grant_type: 'client_credentials',
    user_id: userId
  }

  return new Client('/oauth/token', token)
    .post(request, 'application/x-www-form-urlencoded')
}

const createVideo = (accessToken: string, request: {}): AxiosPromise => {
  return new Client('/v1/videos', accessToken)
      .post(request)
}


export const uploadVideo = (appKey: string, request: {}, callback: (progress: number) => void): AxiosPromise => {
  return new Client(`/v2/admin/applications/${appKey}/uploads`)
      .upload(request, callback)
}

export const postVideo = (accessToken: string, request: {}): AxiosPromise => {
  return createVideo(accessToken, request)
}

export const setStorage = (storage: string): void => {
  localStorage.setItem('storage', JSON.stringify(storage));
}

type Storage = {
  bucket: string
  region: string
  access_key_id: string
  secret_access_key: string
  session_token: string
}

export class StorageInfo {
  bucket: string
  region: string
  accessKeyId: string
  secretAccessKey: string
  seesionToken: string
  
  constructor(json: Storage) {
    this.bucket = json.bucket
    this.region = json.region
    this.accessKeyId = json.access_key_id
    this.secretAccessKey = json.secret_access_key
    this.seesionToken = json.session_token
  }
}

export class FileRequest {
  accessToken: string
  videoKey: string
  path: string
  filename: string
  file: any
  storage: StorageInfo

  constructor(accessToken: string, videoKey: string, path: string, filename: string, file: File, storage: StorageInfo) {
    this.accessToken = accessToken
    this.videoKey = videoKey
    this.path = path
    this.filename = filename
    this.file = file
    this.storage = storage
  }
}

export const getVideo = (accessToken: string, videoKey: string, callback: (videoUrl: string, thumbnailUrl: string) => void): void => {

  new Client(`/v1/videos/${videoKey}`, accessToken)
    .get()
    .then(res => {
      console.log(res.data);
      if (res.data.state === 'VOD') {
        callback(res.data.url, res.data.thumbnail_url)
      }
    })
}
