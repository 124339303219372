import { createAction, handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import { GoodsInfo } from '../monitor/Goods';
import { VideoInfo } from '../monitor/Video';
import videojs from 'video.js';

type GoodsPayload = GoodsInfo

const SET_TITLE = 'streaming/SET_TITLE'
const SET_DESC = 'streaming/SET_DESC'
const SET_VIDEO = 'streaming/SET_VIDEO'
const SET_STREAM_STATE = 'streaming/SET_STREAM_STATE'
const SET_PLAYER = 'video/SET_PLAERYER'
const SET_SDK = 'video/SET_SDK'
const SET_KEEP_ALIVE = 'streaming/SET_KEEP_ALIVE'
const CHECKED_GOODS = 'streaming/CHECKED_GOODS'
const UNCHECKED_GOODS = 'streaming/UNCHECKED_GOODS'
const RESET = 'streaming/RESET'

export const actions = {
  setTitle: createAction(SET_TITLE),
  setDescription: createAction(SET_DESC),
  setVideo: createAction(SET_VIDEO),
  setPlayer: createAction(SET_PLAYER),
  setSdk: createAction(SET_SDK),
  setStreamState: createAction(SET_STREAM_STATE),
  setKeepAlive: createAction(SET_KEEP_ALIVE),
  checkedGoods: createAction(CHECKED_GOODS),
  uncheckedGoods: createAction(UNCHECKED_GOODS),
  reset: createAction(RESET),
}

export interface StreamingState {
  title: string
  desc: string
  video: VideoInfo | null
  sdk: any
  streamState: string
  player: videojs.Player | null
  keepAlive: boolean
  goodsList: GoodsInfo[]
}

const initialState: StreamingState = {
  title: 'Sample Live title...',
  desc: 'Sample Live content...',
  video: null,
  sdk: null,
  streamState: "initialized",
  player: null,
  keepAlive: false,
  goodsList: []
}

export default handleActions<StreamingState, any>({
  [SET_TITLE]: (state, action:Action<string>) => produce(state, draft => { draft.title = action.payload }),
  [SET_DESC]: (state, action:Action<string>) => produce(state, draft => { draft.desc = action.payload }),
  [SET_VIDEO]: (state, action:Action<VideoInfo | null>) => produce(state, draft => { draft.video = action.payload }),
  [SET_STREAM_STATE]: (state, action:Action<string>) => produce(state, draft => { draft.streamState = action.payload }),
  [SET_PLAYER]: (state, action:Action<videojs.Player>) => produce(state, draft => { draft.player = action.payload }),
  [SET_SDK]: (state, action:Action<any>) => produce(state, draft => { draft.sdk = action.payload }),
  [SET_KEEP_ALIVE]: (state, action:Action<boolean>) => produce(state, draft => { draft.keepAlive = action.payload }),
  [CHECKED_GOODS]: (state, action: Action<GoodsPayload>) => produce(state, draft => { draft.goodsList = [...state.goodsList].concat(action.payload) }),
  [UNCHECKED_GOODS]: (state, action: Action<GoodsPayload>) => produce(state, draft => { draft.goodsList = [...state.goodsList].filter(f => f.id !== action.payload.id) }),
  [RESET]: () => initialState
}, initialState)