import { createAction, handleActions, Action } from 'redux-actions'
import { produce } from 'immer';
import { MemberInfo } from '../account/Auth'
import { Config, Store } from '../config';

const SET_LOG_OUT = 'header/SET_LOG_OUT'
const SET_MEMBER = 'header/SET_MEMBER'

type MemberPayload = MemberInfo

export const actions = {
  setLogOut: createAction(SET_LOG_OUT),
  setMember: createAction(SET_MEMBER)
}

export interface LoginState {
  member: MemberInfo | null
  isLogin: Boolean
}

const initialState: LoginState = {
  member: null,
  isLogin: Boolean(Store.get(Config.MEMBER_KEY))
}

export default handleActions<LoginState, any> ({
  [SET_LOG_OUT]: (state) => produce(state, draft => { draft.member = null; draft.isLogin = false; }),
  [SET_MEMBER]: (state, action: Action<MemberPayload>) => produce(state, draft => { draft.member = action.payload; draft.isLogin = true; }),
}, initialState)


