import * as React from 'react'
import { Send } from '../assets/svg'
import Button from '../components/button/Button'
import ChatItem from './ChatItem'
import "./ChatForm.scss"
import { MessageInfo, SystemMessageType } from '../review/Messages'
import { MAX_LENGTH } from '../config/Config'

interface Props {
  sendMessage(message:string): void
  messages: MessageInfo[]
}

interface State {
  available: boolean
  message: string
}

class ChatForm extends React.Component<Props, State> {
  state = {
    available: false,
    message: ""
  }

  scroller : null | Element = null

  onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget
    this.setState({ message: value })
  }

  send = (): void => {
    const { message } = this.state
    if (message.trim().length === 0) {
      // Toast.warn('Enter messages to send')
      return
    }

    this.props.sendMessage(message)
    this.setState({ message: '' })
  }

  onKeyPress = (e: React.KeyboardEvent<any>): void => {
    if (e.key === 'Enter') {
      this.state.message && this.send()
    }
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    if (nextProps.messages.length !== this.props.messages.length) {
      if (this.scroller) {
        this.scroller.scrollIntoView({block: "end"})
      }
      return true
    }

    return true
  }

  

  render(): JSX.Element {
    const { messages } = this.props
    console.log("chatform", messages)
    const empty = messages.length === 0

    let items
    const { message } = this.state

    if (messages.length > 0) {
      items = messages.map((m: MessageInfo, index: number) => <ChatItem key={index} message={m} />)
    } else {
      const no_message : SystemMessageType = {
        message: 'Preparing for chat messages...',
        messageType: "PENDING",
      }
      
      items = <ChatItem key={0} message={no_message} />
    }

    return (
      <div className="monitor-chat-group">
        <div className={"monitor-chat " + (messages.length < 1 ? "empty" : "")}>
          <div id="message-scroller" ref={(scroller) => (this.scroller = scroller)} className="list-group chat-group">
            {empty ? (
              <ChatItem
                key={0}
                message={{
                  message: 'Preparing for chat messages...',
                  messageType: "PENDING"
                }}
              />
            ) : (
              items
            )}
          </div>
        </div>
        <div className="monitor-chat-input">
          <input type="text" className="form-control" placeholder="메시지를 입력해주세요." aria-label="Messages to send" aria-describedby="basic-addon2" value={this.state.message} onChange={this.onChange} onKeyPress={this.onKeyPress} maxLength={MAX_LENGTH.chatMessage}/>
          {message.length > 0 ? (
            <div className="input-group-append">
              <Button color="red" size="medium" type="button" onClick={this.send}>
                <Send />
                <span>Send</span>
              </Button>
            </div>
          ) : (
            <div className="input-group-append">
              <Button color="red" size="medium" type="button" disabled>
                <Send />
                <span>Send</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ChatForm