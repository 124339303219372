import * as React from 'react'

export default function RedBullet(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g transform="translate(-740 -787) translate(740 780) translate(0 7)">
              <path d="M0 0H4V4H0z" />
              <circle cx="2" cy="2" r="1" fill="#D91414" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
