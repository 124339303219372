import * as React from 'react'

interface Props {
  className?: string
}

export default function MoreIcon({className, ...props}:Props): JSX.Element {
  return (
    <svg xmlns="http ://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className} {...props} >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-1180 -559) translate(920 239) translate(260 320)">
              <path d="M0 0H20V20H0z" />
              <circle cx="10" cy="6" r="1" fill="#444" />
              <circle cx="10" cy="10" r="1" fill="#444" />
              <circle cx="10" cy="14" r="1" fill="#444" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
