import * as React from 'react'
import { Terms } from '../config/Terms'
import './Terms.scss';

interface Props {
  terms: Terms
}

interface ClauseProps {
  id: string
  title: string
}

const ArticleItem = ({ id, title }: ClauseProps) => (
  <div className="article-row">
    <div className="article-row-padding" id={`item-${id}`} />
    <span className="number">{`${id}.`}</span>
    <span className="article-item">{title}</span>
  </div>
)

const ClauseItem = ({ id, title }: ClauseProps) => (
  <div className="clause-row">
    <span className="number">{`${id}.`}</span>
    <span className="clause-item">{title}</span>
  </div>
)

const ClauseDetailItem = ({ id, title }: ClauseProps) => (
  <div className="detail-row">
    <span className="number">{`${id}`}</span>
    <span className="clause-item">{title}</span>
  </div>
)

interface TocProps {
  article: number
  title: string
  content: string[]
  onClick(id: number): void
}

const Toc = ({ article, title, content, onClick }: TocProps) => (
  <div id="toc-list" className="toc-group">
    <p className="title">{title}</p>
    <ol className="list-group">
      { content.map((item, key) => (
        <li key={key} className={"list-group-item list-group-item-action" + (article === (key + 1) ? ' active' : '')}>
          <a href={`#item-${key + 1}`} className="text-ellipsis" onClick={() => onClick(key + 1)}>{`${key + 1}. ${item}`}</a>
        </li>
      ))}
    </ol>
  </div>
)

interface State {
 article: number
}

class TermsContent extends React.Component<Props, State> {
  state = {
    article: 0
  }

  setArticle = (id: number): void => {
    this.setState({ article: id })
  }

  public render = (): JSX.Element => {
    const { terms } = this.props
    const toc: string[] = terms.articles.map((item) => item.title)

    return (
      <div className="terms-container">
        <Toc article={this.state.article} title={'서비스 약관'} content={toc} onClick={this.setArticle}/>
        <div data-spy="scroll" data-target="#toc-list" data-offset="0" className="terms-content">
          <p className="title">{terms.title}</p>
          <p className="desc">{terms.desc}</p>
          { terms.articles.map((article, articleKey) => (
            <div key={articleKey} className="article-group">
              <ArticleItem id={'' + article.id} title={article.title} />
              { article.desc &&
                <div className="article-desc">{article.desc}</div> }
              <div className="clause-group">
              { article.clauses.map((clause, clauseKey) => (
                <div key={clauseKey}>
                  <ClauseItem id={clause.id} title={clause.title} />
                  { clause.details && clause.details.map((detail, detailKey) => (
                    <ClauseDetailItem key={detailKey} id={detail.id} title={detail.title} />
                  ))}
                </div>
            ))}
              </div>
              { article.footer &&
                <div className="article-desc">{article.footer}</div> }
            </div>        
          ))}
        </div>
      </div>
    )
  }
}

export default TermsContent;