import React from 'react'
import { FormBullet } from '../assets/svg'

interface Props {
  title: string
  value: string | number
}

export default function MonitorInfoItem({title, value}: Props): JSX.Element {
  return (
    <li className="monitor-info-item-group">
      <div className="monitor-info">
        <FormBullet />
        <span>{title}</span>
      </div>
      <div className="monitor-info">{value}</div>
    </li>
  )
}