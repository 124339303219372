import * as React from 'react'
import { actions as loginActions } from '../modules/login'
import { actions as contentActions } from '../modules/content'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { googleLogin, login, me, MemberInfo } from '../account/Auth'
import Button from '../components/button/Button'
import TextInput from '../components/input/TextInput'
import Title from '../components/title/Title'
import './SignForm.scss'
import { GoogleLoginResponse } from 'react-google-login'
import GoogleLoginButton from './GoogleLoginButton'
import { GoSignUp, GoForgetPassword } from './GoLoginForms'
import { Config, Messages, Store } from '../config'
import { MAX_LENGTH } from '../config/Config'


interface Props {
  logins: typeof loginActions
  contents: typeof contentActions
}

interface State {
  email: string
  emailError: string
  password: string
  passwordError: string
}

class SignInForm extends React.Component<Props, State> {
  state: State = {
    email: '',
    password: '',
    passwordError: '',
    emailError: '',
  }

  onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const { email, password } = this.state

    if (email === '') {
      this.setState({ emailError: 'Please enter your Email' })
      return
    }
    this.setState({ emailError: '' })

    if (password === '') {
      this.setState({ passwordError: 'Please enter your Password' })
      return
    } else if (password.trim().length < Config.PASSWORD_MIN_LENGTH) {
      this.setState({ passwordError: Messages.PASSWORD_MIN_LENGTH })
      return
    }

    this.setState({ passwordError: '' })

    login(email, password, this.loadMember)
  }

  loadMember = (): void => {
    me(this.setMember)
  }

  setMember = (member: MemberInfo): void => {
    console.log(member)
    Store.set(Config.MEMBER_KEY, member)

    this.props.logins.setMember(member)
    this.props.contents.setContent({ content: 2 })
  }

  onGoogleLoginSuccess = (res: GoogleLoginResponse) => { 
    console.log(res)
    // 로그인 성공 로직
    
    googleLogin(res.profileObj, this.loadMember)
  }

  onGoogleLoginFailure = () => { 
    // Toast.notify('fail to login');
    // 로그인 실패 로직
  }

  goSignUp = () => {
    this.props.contents.setContent({ content: 1 })
  }

  goResetPassword = () => {
    this.props.contents.setContent({ content: 91 })
  }

  public render() {
    return (
      <div>
        <form className="sign-group" onSubmit={this.handleSubmit}>
          <Title size="medium" color="black">
            Sign into your account
          </Title>
          <TextInput type="email" id="email" name="email" value={this.state.email} onChange={this.onChangeInput} label="Email" error={this.state.emailError} required maxLength={MAX_LENGTH.email} />
          <TextInput type="password" id="password" name="password" value={this.state.password} onChange={this.onChangeInput} label="Password" error={this.state.passwordError} required maxLength={MAX_LENGTH.password} />
          <Button type="submit" color="yellow">
            Log in
          </Button>
          <div className="divider">Or</div>
          <GoogleLoginButton onSuccess={this.onGoogleLoginSuccess} onFailure={this.onGoogleLoginFailure} />         
        </form>
        <div className="goto_tool">
          <GoSignUp onClick={this.goSignUp} />
          <GoForgetPassword onClick={this.goResetPassword} />
        </div>
      </div>
    )
  }
}

export default connect(
  () => ({}),
  (dispatch) => ({
    logins: bindActionCreators(loginActions, dispatch),
    contents: bindActionCreators(contentActions, dispatch),
  }),
)(SignInForm)
