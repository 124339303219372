import * as React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../modules';
import { WatcherInfo } from './Watchers';
import { ReviewInfo } from './Reviews';
import { actions as reviewActions } from '../modules/review'
import { ApplicationInfo } from '../application/Applications';
import WatcherItem from './WatcherItem'
import { PageRequest, PageState } from '../components/pagination/Paginations';
import Pagination from '../components/pagination/Pagination';

interface Props extends PageState {
  app: ApplicationInfo
  review: ReviewInfo
  list: WatcherInfo[]
  actions: typeof reviewActions
  changeContent(page: PageRequest): void
}

class WatcherModal extends React.Component<Props> {

  handlePage = (page: number) => {
    const pageRequest = this.props.pageRequest
    pageRequest.page = page

    this.props.actions.setPageRequest(pageRequest)

    this.props.changeContent(pageRequest);
  }
  
  public render() {
    const items = this.props.list.map((w, index) =>
      <WatcherItem key={index} watcher={w} />
    )

    return (
      <div>
        <div className="modal-body message-list modal-pagination-content">
          { items.length === 0 ? 
            <p className="text-center no-item-message">There is no watcher</p> :
            <div>
              {items}
            </div>
          }
        </div>
        <div className="modal-pagination-wrap">
          <Pagination page={this.props.pageInfo} handlePage={this.handlePage} />
        </div>
      </div>
    )
  }
}


export default connect(
	({ application, review } : StoreState) => ({
    app: application.application,
    review: review.review,
    pageInfo: review.modalPage,
    pageRequest: review.modalPageRequest
  }),
	(dispatch) => ({
    actions: bindActionCreators(reviewActions, dispatch)
  })
)(WatcherModal)