import React, { useEffect } from 'react';
import Chart from 'chart.js';
import { Line } from 'react-chartjs-2';
import { StatInfo } from '../../review/Statistics'
import "./MultiLineChart.scss";

interface Props {
  data: Array<StatInfo>
  width?: number
  height?: number
  maxTicksLimit?: number
  labels?: Array<number | string>
  type?: string
  time?: any
}

export default function MultiLineChart({data, type, width, height, maxTicksLimit=7, labels, time}: Props): JSX.Element {

  const chartData = {
    labels: labels || [0, 1, 2, 3, 4, 5, 6, 7],
    datasets: data.map(chartData => ({
      label: chartData.colorTitle,
      fill: false,
      lineTension: 0,
      borderColor: chartData.color,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointHoverRadius: 4.5,
      pointHoverBackgroundColor: "white",
      pointHoverBorderColor: chartData.color,
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      data: chartData.data,
      spanGaps: false,
    }))
  };

  const chartOptions = {   
    maintainAspectRatio : false,
    tooltips: {
      enabled: false,
      custom: function customTooltips(tooltip: any) {
        if (!tooltip) {
          return;
        }
        // Tooltip Element
        let tooltipEl = document.getElementById("chartjs-tooltip");
      
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          document.body.appendChild(tooltipEl);
      }
      
        //Hide the tooltips when mouseout
        if (tooltip.opacity === 0) {
          (tooltipEl as any).style.opacity = 0;
          return;
        }
      
        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltip.yAlign) {
            tooltipEl.classList.add(tooltip.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }
        
        const getBody = (bodyItem: any) => {
          return bodyItem.lines
        };
      
        // Set custom tooltip
        if (tooltip.body) {
          const bodyLines = tooltip.body.map(getBody)
      
          const tooltipData = bodyLines[0][0].tooltipData
      
          let innerHtml = `<ul class="line-chart-tooltip-box">`
          const tooltipValue = tooltipData.map((data: any, index: number) => `<li class="line-chart-tooltip-item" key=${index}><div class="circle ${data.colorTitle}" /><div class="tooltip-item-content">${data.value}</div></div></li>`)

          tooltipValue.forEach((tag: string) => {
            innerHtml += tag
          })

          innerHtml += "</ul>"
      
          // Set inner html to tooltip
          tooltipEl.innerHTML = innerHtml;
      
          // `this` will be the overall tooltip
          const position = (this as any)._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          (tooltipEl as any).style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltip.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltip.caretY + 'px';
          tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
          tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
          tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        }
      },
      callbacks: {
        label: (tooltipItem: any) => {
          const tooltipData = data.map(tooltipData => ({colorTitle: tooltipData.colorTitle, color: tooltipData.color, value: tooltipData.data[tooltipItem.index] }))
          return {
            label: tooltipItem.xLabel,
            tooltipData: tooltipData,
          }
        }
      }
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 4,
          padding: 20,
          min: 0,
        },
        scaleLabel: {
          display: true,
          fontSize: 20 
        },
        gridLines: {
          drawBorder: false,
          tickMarkLength: 10
        },
      }],
      xAxes: [{
        offset: true,
        categoryPercentage: 0.55,
        type,
        time: time || { 
            unit: 'minute',
            displayFormats: {
              minute: 'h:mm'
            },
        },
        gridLines: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          beginAtZero: true,
          autoSkipPadding: 0,
          labelOffset: 0,
          maxTicksLimit,
          min: 0,
          callback: function(value: any) {
            return value;
          }
        }
      }]     
    },
  };


  useEffect(() => {
    Chart.pluginService.register({
      afterDraw: function (chart: any) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.controller.tooltip._active[0]
          const ctx = chart.ctx
          const x = activePoint.tooltipPosition().x
          const topY = chart.scales['y-axis-0']?.top
          const bottomY = chart.scales['y-axis-0']?.bottom

          ctx.save()
          ctx.beginPath()
          ctx.moveTo(x, topY)
          ctx.lineTo(x, bottomY)
          ctx.lineWidth = 2
          ctx.strokeStyle = '#e0e4e7'
          ctx.setLineDash([5])
          ctx.stroke()
          ctx.restore()
        }
      },
    })
  }, [data])

  return (
    <div>
      <Line data={chartData} options={chartOptions} width={width} height={height} />
    </div>
  );
}