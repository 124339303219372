import { createAction, handleActions, Action } from 'redux-actions'
import { produce } from 'immer';
import { ApplicationInfo } from '../application/Applications'
import { AppUserInfo } from '../settings/Settings'

const SET_APP_CONTENT = 'application/SET_APP_CONTENT'
const SET_APPLICATION = 'application/SET_APPLICATION'
const SET_APP_USER = 'application/SET_APP_USER'
const SET_APP_DEMO = 'application/SET_APP_DEMO'
const CLEAR_APP = 'application/CLEAR_APP'
const CLEAR_APP_DEMO = 'application/CLEAR_APP_DEMO'

interface ContentPayload {
  content: number
}

type ApplicationPayload = ApplicationInfo
type AppUserPayload = AppUserInfo
type DemoPayload = string

export const actions = {
  setContent: createAction<ContentPayload>(SET_APP_CONTENT),
  setApplication: createAction<ApplicationPayload>(SET_APPLICATION),
  setAppUser: createAction<AppUserPayload>(SET_APP_USER),
  setDemo: createAction<DemoPayload>(SET_APP_DEMO),
  clearDemo: createAction(CLEAR_APP_DEMO),
  clearApplication: createAction(CLEAR_APP)
}

export interface AppContentState {
  content: number,
  application: ApplicationInfo | null,
  appUser: AppUserInfo | null
  demo: string
}

const initialState: AppContentState = {
  content: 0,
  application: null,
  appUser: null,
  demo: ''

}

export default handleActions<AppContentState, any> ({
  [SET_APP_CONTENT]: (state, action: Action<ContentPayload>) => produce(state, draft => { draft.content = action.payload.content, draft.demo = '' }),
  [SET_APPLICATION]: (state, action: Action<ApplicationPayload>) => produce(state, draft => { draft.content = state.content !== undefined ? state.content : 0, draft.application = action.payload, draft.demo = '' }),
  [SET_APP_USER]: (state, action: Action<AppUserPayload>) => produce(state, draft => { draft.content = state.content !== undefined ? state.content : 0, draft.appUser = action.payload, draft.demo = '' }),
  [SET_APP_DEMO]: (state, action: Action<DemoPayload>) => produce(state, draft => { draft.content = state.content !== undefined ? state.content : 0, draft.demo = action.payload }),
  [CLEAR_APP_DEMO]: (state) => produce(state, draft => { draft.content = state.content !== undefined ? state.content : 0, draft.demo = '' }),
  [CLEAR_APP]: (state) => produce(state, draft => { draft.content = state.content !== undefined ? state.content : 0, draft.application = null, draft.appUser = null }),
}, initialState)