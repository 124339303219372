import modules, { StoreState } from '../modules'
import { createStore, Store } from 'redux';

export default function configureConnection(): Store<StoreState>  {
  const store = createStore(
    modules,
    (window as any).__REDUX_DEV_TOOLS_EXTENTIONS__ &&
    (window as any).__REDUX_DEV_TOOLS_EXTENTIONS__()
  );
  return store
}