import * as React from 'react'

export default function Mail(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <filter id="e95blb036a">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g filter="url(#e95blb036a)" transform="translate(-876 -53) translate(390 40)">
          <g>
              <path stroke="#FFF" strokeWidth="2" d="M18 6c.276 0 .526.112.707.293.181.18.293.43.293.707h0v10c0 .276-.112.526-.293.707-.18.181-.43.293-.707.293h0H6c-.276 0-.526-.112-.707-.293C5.112 17.527 5 17.277 5 17h0V7c0-.276.112-.526.293-.707C5.473 6.112 5.723 6 6 6h0z" transform="translate(486 13)"/>
              <path fill="#FFF" fillRule="nonzero" d="M18.35 6.24l1.3 1.52-7 6c-.342.293-.835.318-1.204.073l-.097-.074-7-6 1.302-1.518L12 11.682l6.35-5.441z" transform="translate(486 13)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}