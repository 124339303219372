import { GoodsInfo } from '../monitor/Goods'

const local = {
  api: {
    url: 'http://localhost:28080',
    basicToken: 'Basic dGVzdGFwcDI6dGVzdGFwcHNlY3JldDI=', // testapp2:testappsecret2
    rtmp: 'rtmp://media-dev.flipflop.tv/live',
  },
  demo: {
    url: 'http://localhost:3100',
  },
}

const dev = {
  api: {
    url: 'https://dev-api.flipflop.tv',
    basicToken: 'Basic dGVzdGFwcDM6dGVzdGFwcHNlY3JldDM=', // testapp3:testappsecret3
    rtmp: 'rtmp://media-dev.flipflop.tv/live',
  },
  demo: {
    url: 'https://dev.flipflop.tv',
  },

  // TODO: s3 configs
}

const prod = {
  api: {
    url: 'https://api.flipflop.tv',
    basicToken: 'Basic OXoyN2h0Vm5LZTpYQXk1WGFtdnlvdmlzN1pIazJsMGlE', // 9z27htVnKe:XAy5Xamvyovis7ZHk2l0iD
    rtmp: 'rtmp://media.flipflop.tv/live',
  },
  demo: {
    url: 'https://demo.flipflop.tv',
  },

  // TODO: s3 configs
}

const env = process.env.REACT_APP_STAGE === 'production' ? prod : process.env.REACT_APP_STAGE === 'dev' ? dev : local

export class Config {
  public dateFormat: string = 'YYYY-MM-DD HH:mm:ss'
  public baseDateFormat: string = 'YYYY-MM-DD'
  public guestToken = 'Basic Z3Vlc3Q6Z3Vlc3RzZWNyZXQ='
  public supportEmail = 'mailto:support@jocoos.com?bcc=dev@jocoos.com'
  public durationFormat: string = 'HH:mm:ss'
}

const config = new Config()

export const MAX_LENGTH = {
  videoTitle: 50,
  videoDesc: 255,
  password: 20,
  email: 50,
  firstName: 20,
  lastName: 20,
  company: 35,
  url: 150,
  username: 80,
  userId: 80,
  appName: 200,
  appKey: 10,
  appSecret: 50,
  channel: 5,
  profanity: 300,
  token: 500,
  streamKey: 10,
  chatMessage: 255,
}

export default {
  API: env.api.url,
  TOKEN: env.api.basicToken,
  TEST_PAGE_SIZE: 10,
  PAGE_SIZE: 20,
  TABLE_ITEM_COUNT: 10,
  VIDEO_COUNT: 12,
  MESSAGE_COUNT: 12,
  CHAT_MESSAGE_SIZE: 10,
  MAX_OWN_APPLICATIONS: 10,
  MAX_OWN_STREAMKEY: 5,
  DATE_FORMAT: config.dateFormat,
  BASE_DATE_FORMAT: config.baseDateFormat,
  GUEST_TOKEN: config.guestToken,
  SUPPORT_EMAIL: config.supportEmail,
  DURATION_FORMAT: config.durationFormat,
  DEMO_URL: env.demo.url,
  DEFAULT_AVATAR: 'https://static.jocoos.com/ffweb/dashboard/images/DefaultAvatar.png',
  ITEM_SIZE: window.innerWidth > 1200 ? 522 : 427,
  TEST_TOKEN: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJfaWQiOiI0Iiwic2NvcGUiOlsiZ3Vlc3QiXSwiZXhwIjoxOTExMjA0MjQ4LCJqdGkiOiI2NmEwMGU1Yy04MmYwLTQ1NGItYjE3ZS05ZjlhMjJjYmE3MWQiLCJjbGllbnRfaWQiOiJndWVzdCJ9.__rPK-3RdhMVYQHh32n3BiTw-Je4MIB1BEwRvuCj9k0',
  GA: 'G-NS32X70QHJ',
  INIT_PROGRESS: 2,
  VIEWER_PAGE_COUNT: 5,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_STAGE === 'production' ? '839706458674-9ea1t47n812un9ic3huq5cmhqusl421q.apps.googleusercontent.com' : '338644605727-ku8p30vhjv692tab97f03pm2t2icl18v.apps.googleusercontent.com',
  SAMPLE_GOODS: [
    new GoodsInfo({ id: 1, title: '무드 레시피 페이스 블러쉬', price: 11150, thumbnailUrl: 'https://static.flipflop.tv/public/demo_goods_img1.jpg' }),
    new GoodsInfo({ id: 2, title: '해피바스 한장 샤워티슈', price: 4900, thumbnailUrl: 'https://static.flipflop.tv/public/demo_goods_img2.jpg' }),
    new GoodsInfo({ id: 3, title: '[밀리마쥬] 무료배송 베스풀 블러셔', price: 12000, thumbnailUrl: 'https://static.flipflop.tv/public/demo_goods_img3.jpg' }),
    new GoodsInfo({ id: 4, title: '려 자양윤모 트리트먼트 200ml', price: 5000, thumbnailUrl: 'https://static.flipflop.tv/public/demo_goods_img4.jpg' }),
    new GoodsInfo({ id: 5, title: '[3CE] 벨벳 립 틴트 4g', price: 9170, thumbnailUrl: 'https://static.flipflop.tv/public/demo_goods_img5.jpg' }),
  ],
  TOAST_DURATION: 3000,
  DEFAULT_THUMBNAIL: 'https://static.flipflop.tv/public/flipflop_thumbnail.png',
  EMAIL_KEY: 'email',
  MEMBER_KEY: 'member',
  APP_DEFAULT_USERNAME: 'ADMIN',
  ACCESS_TOKEN_KEY: 'access_token',
  RTMP_HOST: env.api.rtmp,
  PASSWORD_MIN_LENGTH: 6,
  WEBINAR_RETRY_INTERVAL: 3000, // ms
  DEMO_APP_KEY: '24A7DEF685',
  DEMO_APP_SECRET: 'B5139892-82BA-4609-82BB-AE5FA4EC2E4D',
  MOBILE_BREAK_POINT: 1000,
  IMT_ID: 'imp72430347',
  IMAGE_BASE_URL: 'https://static.jocoos.com/ffweb/dashboard/images/',
}
