import * as React from 'react'
import { useState, useEffect } from 'react';
import { ApplicationInfo } from '../application/Applications';
import MultiLineChart from '../components/chart/MultiLineChart'
import Dropdown from '../components/dropdown/Dropdown';
import "./OverviewVodStat.scss"
import { dateFilterScheme, StatInfo, getStatistics, makeLabels } from './Statistics';

interface Props {
  app: ApplicationInfo
}

export default function OverviewVodStat({ app }: Props): JSX.Element {
  const [chartDateFilter, setChartDateFilter] = useState<keyof typeof dateFilterScheme>("lastest 1 week");
  const [chartData, setChartData] = useState<Array<StatInfo>>([])
  const [chartLabels, setChartLabels] = useState<Array<string>>([])
  const [dateFilter, setDateFilter] = useState<string>("day");

  useEffect(() => {
    getStatistics(app.appKey, chartDateFilter, ["duv"], ["blue"], setChartData)

    const dateLabels = makeLabels(7, "MMM Do")

    setChartLabels(dateLabels)
  }, [app.appKey, chartDateFilter])

  const filterOption = [
    {
      value: 'lastest 1 week',
      onClick: () => {
        setChartDateFilter("lastest 1 week")
        setDateFilter("day")
      },
    },
  ]

  return (
    <>
      <div className="col-xl-12 overview live-stat-overview">
        <div className="overview-header">
          <h5 className="card-title">Vod 기간별 통계</h5>
        </div>
        <div className="card overview-item">
          <div className="chart-filter">
            <Dropdown>
              <Dropdown.Button toggleIcon>
                <span>{chartDateFilter}</span>
              </Dropdown.Button>
              <Dropdown.Items options={filterOption} />
            </Dropdown>
          </div>
          <div className="card-body overview-body">
            <div className="line-chart-group">
              <MultiLineChart data={chartData} width={900} height={300} labels={chartLabels} />
            </div>
            <div className="chart-label-group">
              <div className="chart-label">
                <div className="circle blue" />
                동영상 업로드 수
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
