export class TimeStampInfo {
  fisrtTime: number | string
  lastTime: number | string

  constructor(first: number | string, last: number | string) {
    this.fisrtTime = first
    this.lastTime = last
  } 
}

type Page = {
  size: number
  total_elements: number
  total_pages: number
  number: number  
}

export class PageInfo {
  size: number = 0;
  totalElementes: number = 0;
  totalPages: number = 0;
  number: number = 0;
  prevActived: boolean = false;
  nextActived: boolean = false;
  pagePrevActived: boolean = false;
  pageNextActived: boolean = false;

  constructor(json: Page | null) {
    if (json && json.size) {
      this.size = json.size;
    }
    
    if (json && json.total_elements) {
      this.totalElementes = json.total_elements;
    }
    
    if (json && json.total_pages) {
      this.totalPages = json.total_pages;  
    }
    
    if (json && json.number) {
      this.number = json.number;
    }

    if (json && this.number === 0) {
      this.prevActived = false;
      this.pagePrevActived = false;
    } else {
      this.prevActived = true;
      this.pagePrevActived = true;
    }

    if (json && this.totalPages > 1) {
      this.nextActived = this.totalElementes - (this.number + 1) * this.size > 0 ? true : false;
      this.pageNextActived = this.totalElementes - (this.number + 1) * this.size > 0 ? true : false;
    }
  }
}


export class PageRequest {
  page: number;
  size: number;

  constructor(page: number, size: number) {
    this.page = page;
    this.size = size;
  }
}

export class SearchRequest extends PageRequest {
  search: string;
  
  constructor(page: number, size: number, search: string) {
    super(page, size)
    this.search = search
  }
}

export class FilterRequest extends SearchRequest {
  direction: number;
  sort: string;
  
  constructor(page: number, size: number, search: string, direction: number, sort: string) {
    super(page, size, search)
    this.direction = direction
    this.sort = sort
  }
}

export interface PageState {
  pageInfo: PageInfo;
  pageRequest: PageRequest | SearchRequest | FilterRequest;
}