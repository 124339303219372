import React, { useState } from 'react'
import { FormBullet } from '../assets/svg'
import Switch from '../components/input/Switch'
import { Config } from '../config'
import { dateFormat } from '../config/Format'
import MonitorInfoItem from './MonitorInfoItem'
import MonitorUploadInfo from './MonitorUploadInfo'
import { updateVideo, VideoInfo } from './Video'

interface Props {
  video: VideoInfo
}

export default function MonitorUploaderProfile({ video }: Props): JSX.Element {
  const [check, setCheck] = useState<boolean>(video.visibility === "PUBLIC");

  const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { checked } = e.currentTarget
    
    if (checked) {
      updateVideo(video.appKey, video.videoKey, {visibility: "PUBLIC"})
    } else {
      updateVideo(video.appKey, video.videoKey, {visibility: "PRIVATE"})
    }

    setCheck(checked)
  }

  return (
    <div className="monitor-box-group half-box">
      <div className="monitor-box-title mb-10">Upload Info</div>
      <div style={{overflow: "auto", height: "calc(100% - 35px)"}}>
        <div className="monitor-user-base-info">
          <img className="monitor-user-avatar" src={video.user.profilePhotoUrl || Config.DEFAULT_AVATAR} alt="user_avatar" />
          <span>{video.user.username}</span>
        </div>
        <ul className="monitor-info-list-group base-info-list-group">
          {/* TODO: add vod Catgaroy */}
          <li className="monitor-info-item-group" style={{padding: "10px 20px"}}>
            <div className="monitor-info">
              <FormBullet />
              <span>공개 여부</span>
            </div>
            <div className="monitor-info monitor-visibility"><span>{check ? "공개" : "비공개"}</span><Switch checked={check} onChange={onChange} /></div>
          </li>
          <MonitorInfoItem title="날짜" value={dateFormat(video.createdAt, "YYYY.MM.DD")} /> 
          {video?.type === "UPLOADED" && <MonitorUploadInfo video={video} />}
        </ul>
      </div>
    </div>
  )
}