import { errorHandler } from '../account/Auth'
import { Config, Client } from '../config'

type CallbackSettings = {
  id?: number
  method?: string
  type?: string
  url?: string
  token?: string
  active?: boolean
  app_key?: string
  delay?: number
  updated_at?: number
  live_timer?: number
}

export class CallbackSettingsInfo {
  id?: number
  method?: string
  type?: string
  url: string
  token: string
  active?: boolean
  appKey?: string
  delay: number
  updatedAt?: number

  constructor(json?: CallbackSettings) {
    this.id = json?.id
    this.method = json?.method || 'CREATE'
    this.type = json?.type || 'GET'
    this.url = json?.url || ''
    this.token = json?.token || ''
    this.active = json?.active || true
    this.delay = json?.delay || 0
    this.appKey = json?.app_key
    this.updatedAt = json?.updated_at
  }
}

type WebhookSetting = {
  type?: string
  key?: string
  room_id?: string
}

export class WebhookSettingInfo {
  type: string
  _key: string
  roomId?: string

  constructor(json?: WebhookSetting) {
    this.type = json?.type || ''
    this._key = json?.key || ''
    this.roomId = json?.room_id
  }

  get key(): string {
    return this._key
  }

  set key(key: string) {
    this._key = key
  }
}

type Settings = {
  app_key: string
  thumbnail_interval: number
  callback_settings: CallbackSettingsInfo[]
  profanity_filter: number
  profanity: string
  live_timer: number
  demo: boolean
  extensions: string
}

export class SettingsInfo {
  appKey: string
  thumbnailInterval: number
  callbackSettgins: CallbackSettingsInfo[]
  profanityFilter: number
  profanityFilterName: string
  profanity: string
  demo: boolean
  liveTimer: number
  webhook: WebhookSettingInfo

  constructor(json: Settings) {
    this.appKey = json.app_key
    this.thumbnailInterval = json.thumbnail_interval
    this.callbackSettgins = json.callback_settings || []
    this.profanityFilter = json.profanity_filter
    this.profanity = json.profanity || ''
    this.demo = json.demo
    this.liveTimer = json?.live_timer
    this.profanityFilterName = json.profanity_filter === 2 ? 'Drop' : 'Replace'
    this.webhook = JSON.parse(json.extensions) ? new WebhookSettingInfo(JSON.parse(json.extensions)?.webhooks[0]) : new WebhookSettingInfo()
  }

  setProfanityFilter = (filter: number): void => {
    this.profanityFilter = filter
    this.profanityFilterName = filter === 2 ? 'Drop' : 'Replace'
  }
}

export const getSettings = (appKey: string, callback: (settings: SettingsInfo) => void): void => {
  new Client(`/api/2/applications/${appKey}/settings`).get().then((res) => {
    console.log(res.data)
    const settings = new SettingsInfo(res.data)
    callback(settings)
  }, errorHandler)
}

export const updateSettings = (appKey: string, request: {}, callback: (settings: SettingsInfo) => void): void => {
  new Client(`/api/2/applications/${appKey}/settings`).patch('', request).then((res) => {
    console.log(res.data)
    const settings = new SettingsInfo(res.data)
    callback(settings)
  })
}

export interface SettingProps {
  settings: SettingsInfo
  onChange(settings: SettingsInfo): void
}

export const createDemoUrl = (appKey: string, appSecret: string): string => {
  return `${Config.DEMO_URL}/videos?appKey=${appKey}&appSecret=${appSecret}`
}

type AppUser = {
  user_id: string
  username: string
  profile_photo_url: string
}

export class AppUserInfo {
  userId: string
  username: string
  profilePhotoUrl: string

  constructor(json?: AppUser) {
    this.userId = json?.user_id || ''
    this.username = json?.username || ''
    this.profilePhotoUrl = json?.profile_photo_url || ''
  }
}

export const EmptyAppUser = new AppUserInfo({
  user_id: '',
  username: '',
  profile_photo_url: '',
})

export const getAppUser = (appKey: string, callback: (appUser: AppUserInfo) => void): void => {
  new Client(`/api/2/applications/${appKey}/user`).get().then((res) => {
    console.log('get appUser', res.data)
    const appUser = new AppUserInfo(res.data)
    callback(appUser)
  }, errorHandler)
}

export const updateAppUser = (appKey: string, request: {}, callback: (appUser: AppUserInfo) => void): void => {
  new Client(`/api/2/applications/${appKey}/user`).patch('', request).then((res) => {
    console.log(res.data)
    const appUser = new AppUserInfo(res.data)
    callback(appUser)
  }, errorHandler)
}
