import * as React from 'react'
import { FormBullet } from '../../assets/svg'
import './TextInput.scss'

interface Props {
  label?: string
  id?: string
  type?: string
  value: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  description?: string | React.ReactNode
  error?: string
  [key: string]: any
}

export default function SettingInputText({ label, type, value, id, error, onChange, description, ...props }: Props): JSX.Element {
  const inputType = type || 'text'
  return (
    <div className="setting-input-group">
      { label &&
      <label htmlFor={id || label}>
        <FormBullet /> <span>{`${label} `}</span>
      </label>
      }
      <div>
        <input type={inputType} value={value} id={id || label} onChange={onChange} {...props} />
        {error ? <span className="input-error-message">{`* ${error}`}</span> : description && <div className="input-text">{description}</div>}
      </div>
    </div>
  )
}
