import * as React from 'react'
import { FormBullet } from '../../assets/svg'
import './TextInput.scss'

interface Props {
  placeholder?: string
  id?: string
  value: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  description?: string | React.ReactNode
  error?: string
  rows?: number
  [key: string]: any
}

export default function SettingTextArea({ placeholder, value, id, error, required, onChange, description, rows, ...props }: Props): JSX.Element {
  const rowType = rows || 5
  
  return (
    <div className="setting-input-group">
      { placeholder &&
        <label htmlFor={id || placeholder}>
          <FormBullet /> <span>{`${placeholder} `}</span>
        </label>
      }
      <textarea value={value} id={id || placeholder} onChange={onChange} {...props} rows={rowType} required={required} />
      {error ? <span className="input-error-message">{`* ${error}`}</span> : description && <div className="input-text">{description}</div>}
    </div>
  )
}
