import * as React from 'react'
import { NoContent } from '../assets/svg'
import { Messages } from '../config'


export default function NoVideo(): JSX.Element {
  return <div className="no-content">
      <NoContent />
      <span>{Messages.NO_VIDEO}</span>
    </div> 
}