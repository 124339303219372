import React from 'react'
import { Download, FormBullet, GreenArrow, VideoView } from '../assets/svg'
import MonitorInfoItem from './MonitorInfoItem'
import { VideoInfo } from './Video'

interface Props {
  video: VideoInfo
}

export default function MonitorUploadInfo({ video }: Props): JSX.Element {
  const downloadHref = video.downloadUrl || "javascript:void(0)";
  const downloadTarget = video.downloadUrl ? "_blank" : "_self"
  const previewTarget = video.previewUrl ? "_blank" : "_self"
  const previewHref = video.previewUrl || "javascript:void(0)";

  return (
    <ul className="monitor-info-list-group base-info-list-group">
      {video.inputFileSize > 0 && video.outputFileSize > 0 &&
      <li className="monitor-info-item-group">
        <div className="monitor-info">
          <FormBullet />
          <span>파일 크기</span>
        </div>
        <div className="monitor-info col-info">
          <span>{`원본 크기: ${video.inputFileSize}MB`}</span>
          <GreenArrow />
          <span style={{marginTop: "5px"}} className="monitor-result-info">{`변환된 파일 크기: ${video.outputFileSize}MB`}</span>
          <span className="monitor-result-info">{`압축율: ${video.compressibility}%`}</span>
        </div>
      </li>}
      <MonitorInfoItem title="해상도" value="1920x1080" />
      <li className="monitor-info-item-group" style={{padding: "10px 20px"}}>
        <div className="monitor-info">
          <FormBullet />
          <span>영상 다운로드</span>
        </div>
        <div className="monitor-info">
          <a className={(video.downloadUrl ? "" : "disabled ") + "download-btn"} href={downloadHref} download rel="noopener noreferrer" target={downloadTarget}>
            <Download className={video.downloadUrl ? "orange-svg-icon" : "white-svg-icon"} />
            Download
          </a>
        </div>
      </li>
      <li className="monitor-info-item-group" >
        <div className="monitor-info">
          <FormBullet />
          <span>프리뷰</span>
        </div>
        <div className="monitor-info col-info">
          <div>{video.previewRange.length === 2 ? `${video.previewRange[0]} ~ ${video.previewRange[1]}` : "00:00:00 ~ 00:00:00"}</div>
          <div>
            <a className={(video.previewUrl ? "" : "disabled ") + "download-btn preview-btn"} href={previewHref} rel="noopener noreferrer" target={previewTarget}>
              <VideoView className={video.previewUrl ? "orange-svg-icon" : "white-svg-icon"} />
              Preview
            </a>
            <a className={(video.previewUrl ? "" : "disabled ") + "download-btn"} href={previewHref} download rel="noopener noreferrer" target={previewTarget}>
              <Download className={video.previewUrl ? "orange-svg-icon" : "white-svg-icon"} />
              Download
            </a>
          </div>
        </div>
      </li>
      <li className="monitor-info-item-group" style={{padding: "10px 20px"}}>
        <div className="monitor-info">
          <FormBullet />
          <span>썸네일</span>
        </div>
        <div className="monitor-info">
          <a className={(video.downloadUrl ? "" : "disabled ") + "download-btn preview-btn"} href={downloadHref} rel="noopener noreferrer" target={downloadTarget}>
            <VideoView className={video.downloadUrl ? "orange-svg-icon" : "white-svg-icon"} />
            Preview
          </a>
          <a className={(video.downloadUrl ? "" : "disabled ") + "download-btn"} href={downloadHref} download rel="noopener noreferrer" target={downloadTarget}>
            <Download className={video.downloadUrl ? "orange-svg-icon" : "white-svg-icon"} />
            Download
          </a>
        </div>
      </li>
    </ul>
  )
}
